<template>
    <b-card class="shadow mt-5" v-if="additionalNotesDetails.notes">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Additional Notes Details
                </h2>

                <div>
                    {{ additionalNotesDetails.notes }}
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'AdditionalNotesReviewComponent',

    computed: {
        ...mapGetters({
            additionalNotesDetails: 'submissionStore/getAdditionalNotesDetails',
        })
    }
}
</script>