<template>  
    <div class="inner-modal">
        <h2>
            Add an income source
        </h2>

        <p>
            Please select the income sources that you would like to add to your Tax Return
        </p>

        <div v-if="areAllSourcesAdded">
            <div class="alert alert-info mt-4">
                {{ Notifications.NO_MORE_INCOME_SOURCES_TO_ADD }}
            </div>
            <div class="modal-footer d-flex justify-content-center mt-3">
                <button @click="$emit('close')" class="btn btn-grey">Cancel</button>
            </div>
        </div>
        <div v-else>
            <div class="content">
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                    <form @submit.prevent="submit()">
                        <b-card v-if="!showHasEmploymentIncomeClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/employment.jpg" alt="employment income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Employment Income
                                    </h4>

                                    <p class="mb-0">
                                        Employee, director, office holder or agency worker
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasEmploymentIncomeClone = !hasEmploymentIncomeClone"
                                        :class="{active : hasEmploymentIncomeClone}"
                                    >
                                        {{ (hasEmploymentIncomeClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showIsSelfEmployedClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/self-employed.jpg" alt="self employment income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Self Employed
                                    </h4>

                                    <p class="mb-0">
                                        Self-employed or in a partnership
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="isSelfEmployedClone = !isSelfEmployedClone"
                                        :class="{active : isSelfEmployedClone}"
                                    >
                                        {{ (isSelfEmployedClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasPropertyIncomeClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/property.jpg" alt="property income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Property Income
                                    </h4>

                                    <p class="mb-0">
                                        UK property including land, holiday lettings, renting a room in your house or 'Airbnb type' lets
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasPropertyIncomeClone = !hasPropertyIncomeClone"
                                        :class="{active : hasPropertyIncomeClone}"
                                    >
                                        {{ (hasPropertyIncomeClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasPensionIncomeClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/pension.jpg" alt="pension income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Pension Income
                                    </h4>

                                    <p class="mb-0">
                                        UK pensions, annuities or state benefits
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasPensionIncomeClone = !hasPensionIncomeClone"
                                        :class="{active : hasPensionIncomeClone}"
                                    >
                                        {{ (hasPensionIncomeClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasEtoroClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/etoro-income.jpg" alt="etoro">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Etoro
                                    </h4>

                                    <p class="mb-0">
                                        Etoro statement required
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        ref="etoroBtn"
                                        @click="hasEtoroClone = !hasEtoroClone"
                                        :class="{active : hasEtoroClone}"
                                    >
                                        {{ (hasEtoroClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasDividendIncomeClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/dividend-interest.jpg" alt="dividend interest income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Dividend / Interest Income
                                    </h4>

                                    <p class="mb-0">
                                        UK companies, authorised unit trusts, open ended investment companies or foreign companies, UK banks, building societies, unit trusts or peer-to-peer lending
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasDividendIncomeClone = !hasDividendIncomeClone"
                                        :class="{active : hasDividendIncomeClone}"
                                    >
                                        {{ (hasDividendIncomeClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasCapitalGainsAndLossesClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/capital-gains-losses.jpg" alt="capital gains losses income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Capital Gains / Losses
                                    </h4>

                                    <p class="mb-0">
                                        Shares, property (including your main residence), share of a partnership, UK Life Assurance policies or Bonds
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasCapitalGainsAndLossesClone = !hasCapitalGainsAndLossesClone"
                                        :class="{active : hasCapitalGainsAndLossesClone}"
                                    >
                                        {{ (hasCapitalGainsAndLossesClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasForeignIncomeClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/foreign.jpg" alt="foreign income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Foreign Income
                                    </h4>

                                    <p class="mb-0">
                                        Received income and any special withholding tax
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasForeignIncomeClone = !hasForeignIncomeClone"
                                        :class="{active : hasForeignIncomeClone}"
                                    >
                                        {{ (hasForeignIncomeClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasHigherIncomeChildBenefitChargeClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/child-benefit-charge.jpg" alt="child benefit income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Higher Income Child Benefit Charge
                                    </h4>

                                    <p class="mb-0">
                                        Net adjusted income over £50,000 and received Child Benefit Applies if someone else claims Child Benefit for a child who lives with you and pays you or your partner for the child’s upkeep
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasHigherIncomeChildBenefitChargeClone = !hasHigherIncomeChildBenefitChargeClone"
                                        :class="{active : hasHigherIncomeChildBenefitChargeClone}"
                                    >
                                        {{ (hasHigherIncomeChildBenefitChargeClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasAnyOtherIncomeClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/other-uk-income.jpg" alt="other income">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Any other UK income
                                    </h4>

                                    <p class="mb-0">
                                        Employment lump sums, redundancy pay-outs, Property Income Dividends (PID) from Real Estate Investment Trusts (REIT) , share schemes, life insurance gains, income from settlements or trusts
                                    </p>
                                </b-col>

                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasAnyOtherIncomeClone = !hasAnyOtherIncomeClone"
                                        :class="{active : hasAnyOtherIncomeClone}"
                                    >
                                        {{ (hasAnyOtherIncomeClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>

                        <b-card v-if="!showHasOtherTaxReliefsClone" class="select mt-4 col-12">
                            <b-row class="align-items-center">
                                <b-col md="2">
                                    <img class="img-fluid" src="@/assets/images/questions/other-tax-reliefs.jpg" alt="other tax reliefs">
                                </b-col>

                                <b-col md="6" class="mt-2 mt-md-0">
                                    <h4>
                                        Do you want to claim any other tax reliefs?
                                    </h4>

                                    <p class="mb-0">
                                        Community investment tax relief, venture capital, EIS, SEIS, maintenance/alimony payments, contributions towards a personal pension or retirement annuity, Gift Aid, Marriage Allowance transfer, Receipt of marriage allowance
                                    </p>
                                </b-col>
                                
                                <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                    <b-button variant="outline-primary" block
                                        @click="hasOtherTaxReliefsClone = !hasOtherTaxReliefsClone"
                                        :class="{active : hasOtherTaxReliefsClone}"
                                    >
                                        {{ (hasOtherTaxReliefsClone) ? 'Added' : 'Add' }} 
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                    </form>
                    
                    <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>

                </ValidationObserver>
            </div>

            <div class="d-flex justify-content-center modal-footer mt-3">
                <button @click="$emit('close')" :disabled="loading" class="btn btn-grey" :class="{disabled : loading}">Cancel</button>
                <button @click="next" :disabled="loading" class="btn btn-success ml-0 ml-md-3" :class="{disabled : loading}">Confirm <span v-if="loading" class="btn-loader"></span></button>
            </div>
        </div>
    </div>
</template >

<script>
import {mapActions, mapGetters} from 'vuex'
import QuestionsValidationErrorModalComponent from '@/components/modal-components/QuestionsValidationErrorModalComponent.vue'
import Notifications from '@/Notifications'
export default {
    name: 'AddIncomeSourceModalComponent',

    data() {
        return {
            loading: false,
            allSourcesAdded: null,
            hasEmploymentIncomeClone: null,
            hasAnyOtherIncomeClone: null,
            hasCapitalGainsAndLossesClone: null,
            hasDividendIncomeClone: null,
            hasEtoroClone: null,
            hasForeignIncomeClone: null,
            hasHigherIncomeChildBenefitChargeClone: null,
            hasOtherTaxReliefsClone: null,
            hasPensionIncomeClone: null,
            hasPropertyIncomeClone: null,
            isSelfEmployedClone: null,

            showHasEmploymentIncomeClone: null,
            showHasAnyOtherIncomeClone: null,
            showHasCapitalGainsAndLossesClone: null,
            showHasDividendIncomeClone: null,
            showHasEtoroClone: null,
            showHasForeignIncomeClone: null,
            showHasHigherIncomeChildBenefitChargeClone: null,
            showHasOtherTaxReliefsClone: null,
            showHasPensionIncomeClone: null,
            showHasPropertyIncomeClone: null,
            showIsSelfEmployedClone: null,

            QuestionsValidationErrorModalComponent,
            Notifications
        }
    },

    async mounted() {
        await this.initPage()
    },

    computed: {
        ...mapGetters({
            answers: 'submissionStore/getFirstRoundAnswers',
            reasons: 'submissionStore/getReasons',
            selectedTaxYear: 'submissionStore/getSelectedTaxYearId',
            submissionId: 'submissionStore/getId'
        }),

        areAllSourcesAdded() {
            let answersArray = Object.keys(this.answers).map(i => this.answers[i])  
            if(answersArray.includes(false)) return false 

            return true
        },
    },

    methods: {
        ...mapActions({
            createSubmission: 'submissionStore/createSubmission',
            setNotification: 'notificationsStore/setNotification',
            updateFieldResolverKey: 'submissionStore/updateFieldResolverKey'
        }),

        async initPage() {
            let vals = JSON.parse(JSON.stringify(this.answers))
            this.hasEmploymentIncomeClone = vals.hasEmploymentIncome
            this.hasAnyOtherIncomeClone = vals.hasAnyOtherIncome
            this.hasCapitalGainsAndLossesClone = vals.hasCapitalGainsAndLosses
            this.hasDividendIncomeClone = vals.hasDividendIncome
            this.hasEtoroClone = vals.hasEtoro
            this.hasForeignIncomeClone = vals.hasForeignIncome
            this.hasHigherIncomeChildBenefitChargeClone = vals.hasHigherIncomeChildBenefitCharge
            this.hasOtherTaxReliefsClone = vals.hasOtherTaxReliefs
            this.hasPensionIncomeClone = vals.hasPensionIncome
            this.hasPropertyIncomeClone = vals.hasPropertyIncome
            this.isSelfEmployedClone = vals.isSelfEmployed

            this.showHasEmploymentIncomeClone = vals.hasEmploymentIncome
            this.showHasAnyOtherIncomeClone = vals.hasAnyOtherIncome
            this.showHasCapitalGainsAndLossesClone = vals.hasCapitalGainsAndLosses
            this.showHasDividendIncomeClone = vals.hasDividendIncome
            this.showHasEtoroClone = vals.hasEtoro
            this.showHasForeignIncomeClone = vals.hasForeignIncome
            this.showHasHigherIncomeChildBenefitChargeClone = vals.hasHigherIncomeChildBenefitCharge
            this.showHasOtherTaxReliefsClone = vals.hasOtherTaxReliefs
            this.showHasPensionIncomeClone = vals.hasPensionIncome
            this.showHasPropertyIncomeClone = vals.hasPropertyIncome
            this.showIsSelfEmployedClone = vals.isSelfEmployed
        },

        async next() {
            this.loading = true 

            let submissionModel = {
                taxYearId: this.selectedTaxYear,
                hasEmploymentIncome: this.hasEmploymentIncomeClone,
                isSelfEmployed: this.isSelfEmployedClone,
                hasPropertyIncome: this.hasPropertyIncomeClone,
                numberOfProperties: this.hasPropertyIncomeClone ? 1 : 0,
                hasPensionIncome: this.hasPensionIncomeClone,
                hasDividendIncome: this.hasDividendIncomeClone,
                hasCapitalGainsAndLosses: this.hasCapitalGainsAndLossesClone,
                hasForeignIncome: this.hasForeignIncomeClone,
                hasHigherIncomeChildBenefitCharge: this.hasHigherIncomeChildBenefitChargeClone,
                hasAnyOtherIncome: this.hasAnyOtherIncomeClone,
                hasOtherTaxReliefs: this.hasOtherTaxReliefsClone,
                hasEtoro: this.hasEtoroClone,
                isHMRCInstructedReason: this.reasons.isHMRCInstructedReason,
                isLandlordReason: this.reasons.isLandlordReason,
                isSelfEmployedReason: this.reasons.isSelfEmployedReason,
                isFirstTimeReason: this.reasons.isFirstTimeReason,
                isCompanyDirectorReason: this.reasons.isCompanyDirectorReason,
                isCISContractorReason: this.reasons.isCISContractorReason,
                isInvestorReason: this.reasons.isInvestorReason,
                isHighEarnerReason: this.reasons.isHighEarnerReason,
                isOtherReason: this.reasons.isOtherReason
            }
            
            try {
                await this.createSubmission(submissionModel)
                this.updateFieldResolverKey()
                this.$emit('close')
            }
            catch {
                this.setNotification({
                    message: Notifications.GENERAL_ERROR,
                    type: 'failure'
                })
            }
            finally {
                this.loading = false
                this.$emit('close')
            }
        }
    }
}
</script>