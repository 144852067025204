<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4>
                            Please enter your employment details
                        </h4>

                        <p class="mb-0">
                            Please provide copies of your P60 or P45 and P11D (details of benefits) as issued by your employer
                        </p>
                    </div>

                    <div class="bg-white p-4">
                        <template
                            v-if="employmentIncomeDetails.length"
                        >
                            <employment-income-item-component 
                                v-for="(item, index) in employmentIncomeDetails"
                                :key="index"
                                :item="item"
                                :index="index"
                                :failures="failures"
                            />
                        </template>
                    </div>
                </div>

                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div class="media">
                        <img src="@/assets/icons/questions/employment.svg" width="60" height="60" class="mr-3" alt="add employment">
                        <div class="media-body">
                            <h5 class="mt-0">Add another employment</h5>
                            Would you like to add details of another employment?
                        </div>
                    </div>

                    <b-button variant="primary" class="mt-4 mt-md-0"
                        @click="createDefaultItem()"
                    >
                        Add employment
                    </b-button>     
                </div>

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import EmploymentIncomeItemComponent from '@/components/partials/EmploymentIncomeItemComponent.vue'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'
import Notifications from '@/Notifications'

export default {
    name: 'EmploymentIncomeComponent',

    components: {
        EmploymentIncomeItemComponent,
        SaveAndContinueComponent
    },

    props: {
        tabIndex: Number,
        section: String,
    },

    data() {
        return {
            failures: {},
            errorMessage: '',
            showErrorMessage: false,
            saving: false,
            ValidationErrorModalComponent
        }
    },

    mounted() {
        if(this.employmentIncomeDetails.length) this.markSectionAsComplete()
        if(!this.employmentIncomeDetails.length) this.createDefaultItem()
    },

    computed: {
        ...mapGetters({
            isEmploymentIncomeDetailsComplete: 'submissionStore/getIsEmploymentIncomeDetailsComplete',
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            employmentIncomeDetails: 'submissionStore/getEmploymentIncomeDetails',
            submissionId: 'submissionStore/getId',
        }),
    },

    methods: {
        ...mapActions({
            getSubmissionEmploymentIncomeDetails: 'submissionStore/getSubmissionEmploymentIncomeDetails',
            setNotification: 'notificationsStore/setNotification',
            setEmploymentIncome: 'sectionsCompleteStore/setEmploymentIncome'
        }),

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setEmploymentIncome(true)
        },

        createDefaultItem() {
            let employmentIncomeItem = {
                employerName: null,
                payeReference: null,
                receivedAmount: 0,
                taxDeducted: 0,
                hasBenefitInKind: false,
                benefitInKindType: null,
                p11dValue: 0,
                p11dTaxDeducted: 0,
            }

            this.employmentIncomeDetails.push(employmentIncomeItem)

            setTimeout(() => {
                this.$scrollTo('#index'+(this.employmentIncomeDetails.length - 1), 800)
            }, 500)
            
        },

        async submit() {
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {      
            this.saving = true

            let employmentIncomeModel = {
                submissionId: this.submissionId,
                employments: JSON.parse(JSON.stringify(this.employmentIncomeDetails))
            }

            employmentIncomeModel.employments.forEach(e => {
                e.receivedAmount = parseInt(e.receivedAmount * 100)
                e.taxDeducted = parseInt(e.taxDeducted * 100)
                e.p11dValue = parseInt(e.p11dValue * 100)
                e.p11dTaxDeducted = parseInt(e.p11dTaxDeducted * 100)  
            })

            axios.post(`submission/${this.submissionId}/employment-details`, employmentIncomeModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).catch((err) => {
                this.failures = err.data.failures                
            }).finally(() => this.saving = false)
        }
    }
}
</script>