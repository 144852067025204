<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-8 variant="approval" class="pl-0">
                <h4 class="mb-0">
                    Disguised remuneration avoidance
                </h4>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="Object.keys(disguisedRemunerationAvoidanceDetails).length != 0"
                            >
                                <div class="p-4 bg-white">
                                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                        <h4>
                                            Are you using a disguised remuneration avoidance scheme?
                                        </h4>

                                        <div class="mt-4 mt-md-0 flex-shrink-0 ml-4">
                                            <b-button class="btn-bool mr-1"
                                                @click="disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance = !disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance"
                                                :class="{active: disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance}"
                                            >
                                                Yes
                                            </b-button>

                                            <b-button class="btn-bool"
                                                @click="disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance = !disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance"
                                                :class="{negativeActive: !disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance}"
                                            >
                                                No
                                            </b-button>
                                        </div>
                                    </div>

                                    <b-row class="mt-4"
                                        v-if="disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance"
                                    >
                                        <b-col md="6">
                                            <ValidationProvider name="Description" :rules="`${disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance ? 'required' : ''}`" v-slot="{ errors }">
                                                <b-form-group>
                                                    <label for="description" class="d-block required">Please provide details</label>
                                                    <img class="helper-image" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.DisguisedRemunerationAvoidance.Title, Helpers.DisguisedRemunerationAvoidance.Body)" />

                                                    <b-form-textarea
                                                        name="description"
                                                        v-model="disguisedRemunerationAvoidanceDetails.description"
                                                        size="lg"
                                                        :class="{'is-valid' : disguisedRemunerationAvoidanceDetails.description, 'is-invalid' : errors.length}"
                                                    ></b-form-textarea>
                                                    
                                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                                        {{ errors[0] }}
                                                    </small>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </div>
                            </template>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'DisguisedRemunerationAvoidanceComponent',

    data() {
        return {
            saving: false,
            HelperModalComponent,
            Helpers
        }
    },

    computed: {
        ...mapGetters({
            disguisedRemunerationAvoidanceDetails: 'submissionStore/getDisguisedRemunerationAvoidanceDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getDisguisedRemunerationAvoidanceDetails: 'submissionStore/getSubmissionDisguisedRemunerationAvoidanceDetails',
            setNotification: 'notificationsStore/setNotification'
        }),

        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let disguisedRemunerationAvoidanceModel = {
                submissionId: this.submissionId,
                isUsingDisguisedRemunerationAvoidance: this.disguisedRemunerationAvoidanceDetails.isUsingDisguisedRemunerationAvoidance,
                description: this.disguisedRemunerationAvoidanceDetails.description
            }

            axios.post(`submission/${this.submissionId}/disguised-remuneration-avoidance`, disguisedRemunerationAvoidanceModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).finally(() => this.saving = false)
        }
    }
}
</script>