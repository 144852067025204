<template>
    <b-card class="shadow mt-5" v-if="dividendIncomeDetails.length">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Dividend Income Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredDividendIncomeDetailsFields" :items="dividendIncomeDetails"></b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'DividendIncomeDetailsReviewComponent',

    computed: {
        ...mapGetters({
            dividendIncomeDetails: 'submissionStore/getDividendIncomeDetails',
        }), 

        filteredDividendIncomeDetailsFields() {
            return [
                {
                    key: 'provider',
                    label: 'Provider'
                },

                {
                    key: 'receivedAmount',
                    label: 'Received Amount',
                    formatter: 'formatPrice'
                },

                {
                    key: 'dateReceived',
                    label: 'Date Received',
                    formatter: 'formatDate',
                }
            ]
        }
    },

    methods: {
        formatDate(value) {
            return this.$moment(value).format('ll')
        },

        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        }
    }
}
</script>