<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="approval" class="pl-0">
                <h4 class="mb-0">
                    Scottish Taxpayer?
                </h4>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="Object.keys(scottishTaxpayerDetails).length != 0"
                            >
                                <div class="p-4">
                                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                        <h4>
                                            Are you classed as a Scottish Taxpayer?
                                        </h4>

                                        <div class="mt-4 mt-md-0">
                                            <b-button class="btn-bool mr-1"
                                                @click="scottishTaxpayerDetails.isScottishTaxpayer = !scottishTaxpayerDetails.isScottishTaxpayer"
                                                :class="{active: scottishTaxpayerDetails.isScottishTaxpayer}"
                                            >
                                                Yes
                                            </b-button>

                                            <b-button class="btn-bool"
                                                @click="scottishTaxpayerDetails.isScottishTaxpayer = !scottishTaxpayerDetails.isScottishTaxpayer"
                                                :class="{negativeActive: !scottishTaxpayerDetails.isScottishTaxpayer}"
                                            >
                                                No
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'

export default {
    name: 'ScottishTaxpayerComponent', 

    data() {
        return {
            saving: false
        }
    },

    computed: {
        ...mapGetters({
            scottishTaxpayerDetails: 'submissionStore/getScottishTaxpayerDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getScottishTaxpayerDetails: 'submissionStore/getSubmissionScottishTaxpayerDetails',
            setNotification: 'notificationsStore/setNotification'
        }),

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let scottishTaxpayerModel = {
                submissionId: this.submissionId,
                isScottishTaxpayer: this.scottishTaxpayerDetails.isScottishTaxpayer
            }

            axios.post(`submission/${this.submissionId}/scottish-taxpayer`, scottishTaxpayerModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).finally(() => this.saving = false)
        }
    }
}
</script>