<template>
    <b-card class="shadow mt-5" v-if="Object.keys(disguisedRemunerationAvoidanceDetails).length != 0">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Disguised Remuneration Avoidance Details
                </h2>

                <b-table fixed outlined responsive striped hover :fields="filteredDisguisedRemunerationAvoidanceDetails" :items="disguisedRemunerationAvoidanceDetailsToArray">
                    <template #cell(isUsingDisguisedRemunerationAvoidance)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'TaxAvoidanceDetailsReviewComponent',

    computed: {
        ...mapGetters({
            disguisedRemunerationAvoidanceDetails: 'submissionStore/getDisguisedRemunerationAvoidanceDetails',
        }),

        disguisedRemunerationAvoidanceDetailsToArray() {
            let array = []

            array.push(this.disguisedRemunerationAvoidanceDetails)

            return array
        },

        filteredDisguisedRemunerationAvoidanceDetails() {
            return [
                {
                    key: 'isUsingDisguisedRemunerationAvoidance',
                    label: 'Is Using Disguised Remuneration Avoidance',
                    formatter: 'formatBool'
                },

                {
                    key: 'description',
                    label: 'Description'
                },
            ]
        }
    },

    methods: {
        formatDate(value) {
            return this.$moment(value).format('ll')
        }, 

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>