<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-12 variant="approval" class="pl-0">
                <h4>
                    Marriage Allowance Transfer Component
                </h4>

                <p class="mb-0">
                    Have you made or are making an election to transfer £1,250 of your personal allowance?
                </p>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="Object.keys(marriageAllowanceTransferDetails).length != 0"
                            >
                                <div class="bg-white p-4">
                                    <b-row>
                                        <b-col md="6">
                                            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                                                <b-form-group
                                                    label="Name"
                                                    label-for="name"
                                                    label-class="required"
                                                >
                                                    <b-form-input
                                                        name="name"
                                                        v-model="marriageAllowanceTransferDetails.name"
                                                        type="text"
                                                        placeholder="Name"
                                                        :class="{'is-valid' : marriageAllowanceTransferDetails.name, 'is-invalid' : errors.length}"
                                                    >
                                                    </b-form-input>

                                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                                        {{ errors[0] }}
                                                    </small>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>

                                        <b-col md="6">
                                            <ValidationProvider name="Income Received Amount" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                                <b-form-group
                                                    label="Income Received Amount"
                                                    label-for="incomeReceivedAmount"
                                                    label-class="required"
                                                >
                                                    <b-input-group prepend="£">
                                                        <b-form-input
                                                            name="incomeReceivedAmount"
                                                            v-model="marriageAllowanceTransferDetails.incomeReceivedAmount"
                                                            placeholder="Income Received Amount"
                                                            :class="{'is-valid' : marriageAllowanceTransferDetails.incomeReceivedAmount, 'is-invalid' : errors.length}"
                                                            @change="checkForRoundNumber('incomeReceivedAmount')"
                                                        >
                                                        </b-form-input>
                                                    </b-input-group>

                                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                                        {{ errors[0] }}
                                                    </small>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col md="6">
                                            <ValidationProvider name="Ni Number" rules="required" v-slot="{ errors }">
                                                <b-form-group>
                                                    <label for="descrniNumbeription" class="d-block required">Ni Number</label>
                                                    <img class="helper-image" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.MarriageAllowanceTransferNiNumber.Title, Helpers.MarriageAllowanceTransferNiNumber.Body)" />
                                                    
                                                    <b-form-input
                                                        name="niNumber"
                                                        v-model="marriageAllowanceTransferDetails.niNumber"
                                                        type="text"
                                                        placeholder="Ni Number"
                                                        :class="{'is-valid' : marriageAllowanceTransferDetails.niNumber, 'is-invalid' : errors.length}"
                                                    >
                                                    </b-form-input>

                                                    <small
                                                        v-if="responseFailures && responseFailures.NiNumber"
                                                        class="text-danger"
                                                    >
                                                        {{ responseFailures.NiNumber[0] }}
                                                    </small>

                                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                                        {{ errors[0] }}
                                                    </small>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>

                                        <b-col md="6">
                                            <ValidationProvider name="Date Of Birth" rules="required" v-slot="{ errors }">
                                                <b-form-group
                                                    label="Date Of Birth"
                                                    label-for="dateOfBirth"
                                                    label-class="required"
                                                >
                                                    <b-form-datepicker
                                                        v-model="marriageAllowanceTransferDetails.dateOfBirth" 
                                                        name="dateOfBirth"
                                                        :class="{'is-valid' : marriageAllowanceTransferDetails.dateOfBirth, 'is-invalid' : errors.length}"
                                                        placeholder="Choose a date"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                        :max="max"
                                                    >
                                                    </b-form-datepicker>
                                                        
                                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                                        {{ errors[0] }}
                                                    </small>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col md="6">
                                            <ValidationProvider name="Married On" rules="required" v-slot="{ errors }">
                                                <b-form-group
                                                    label="Married On"
                                                    label-for="marriedOn"
                                                    label-class="required"
                                                >
                                                    <b-form-datepicker
                                                        v-model="marriageAllowanceTransferDetails.marriedOn" 
                                                        name="marriedOn"
                                                        :class="{'is-valid' : marriageAllowanceTransferDetails.marriedOn, 'is-invalid' : errors.length}"
                                                        placeholder="Choose a date"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                        :max="maxMarryDate"
                                                    >
                                                    </b-form-datepicker>
                                                        
                                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                                        {{ errors[0] }}
                                                    </small>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </div>
                            </template>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'MarriageAllowanceTransferComponent',

    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate()-1)
        yesterday.toLocaleDateString()

        const maxDate = new Date(today)
        maxDate.setYear(maxDate.getFullYear() - 16)
        
        return {
            saving: false,
            max: maxDate,
            maxMarryDate: yesterday,
            responseFailures: {},
            HelperModalComponent,
            Helpers
        }
    },

    mounted() {
        this.marriageAllowanceTransferDetails.incomeReceivedAmount = (this.marriageAllowanceTransferDetails.incomeReceivedAmount / 100).toFixed(2)
    },

    computed: {
        ...mapGetters({
            marriageAllowanceTransferDetails: 'submissionStore/getMarriageAllowanceTransferDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getMarriageAllowanceTransferDetails: 'submissionStore/getSubmissionMarriageAllowanceTransferDetails',
            setNotification: 'notificationsStore/setNotification',
        }), 

        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        onSubmit() {
            this.responseFailures = {}
            this.save()  
        },

        save() {
            this.saving = true 

            let marriageAllowanceTransferModel = {
                submissionId: this.submissionId,
                incomeReceivedAmount: parseInt(this.marriageAllowanceTransferDetails.incomeReceivedAmount * 100),
                name: this.marriageAllowanceTransferDetails.name,
                dateOfBirth: this.marriageAllowanceTransferDetails.dateOfBirth,
                niNumber: this.marriageAllowanceTransferDetails.niNumber,
                marriedOn: this.marriageAllowanceTransferDetails.marriedOn
            }

            axios.post(`submission/${this.submissionId}/marriage-allowance-transfer`, marriageAllowanceTransferModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).catch((err) => {
                this.responseFailures = err.data.failures
            }).finally(() => this.saving = false)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.marriageAllowanceTransferDetails[ref]))) {
                this.marriageAllowanceTransferDetails[ref] = parseFloat(this.marriageAllowanceTransferDetails[ref]).toFixed(2)
            }
        }
    }
}
</script>