<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4 class="mb-0">
                            Please tell us more about your self-employment / partnership			
                        </h4>
                    </div>

                    <template
                        v-if="Object.keys(selfEmploymentIncomeDetails).length != 0"
                    >
                        <div class="bg-white p-4">
                            <b-row>
                                <b-col md="6">
                                    <ValidationProvider name="Business Name" rules="required" v-slot="{ errors }">
                                    <b-form-group
                                        label="Business Name"
                                        label-for="businessName"
                                        label-class="required"
                                    >
                                        <b-form-input
                                            name="businessName"
                                            v-model="selfEmploymentIncomeDetails.businessName"
                                            type="text"
                                            placeholder="Business name"
                                            :class="{'is-valid' : selfEmploymentIncomeDetails.businessName, 'is-invalid' : errors.length}"
                                        >
                                        </b-form-input>

                                        <small v-if="errors.length" class="text-danger validation-error d-block">
                                            {{ errors[0] }}
                                        </small>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="6">
                                    <ValidationProvider name="Total Income" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                        <b-form-group
                                            label="Total Income"
                                            label-for="totalIncome"
                                            label-class="required"
                                        >
                                            <b-input-group prepend="£">
                                                <b-form-input
                                                    name="totalIncome"
                                                    v-model="selfEmploymentIncomeDetails.income"
                                                    placeholder="Income"
                                                    :class="{'is-valid' : selfEmploymentIncomeDetails.income, 'is-invalid' : errors.length}"
                                                    @change="checkForRoundNumber('income')"
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </div>

                        <div class="p-4">
                            <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                <h4 class="mb-4 mb-md-0">
                                    CIS Deducted? <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.CisDeducted.Title, Helpers.CisDeducted.Body)" />
                                </h4>

                                <ValidationProvider name="CIS Deducted" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                    <b-form-group
                                        label="CIS Deducted"
                                        label-for="cisDeducted"
                                        label-class="required"
                                    >
                                        <b-input-group prepend="£">
                                            <b-form-input
                                                name="cisDeducted"
                                                v-model="selfEmploymentIncomeDetails.cisDeducted"
                                                placeholder="CIS Deducted"
                                                :class="{'is-valid' : selfEmploymentIncomeDetails.cisDeducted, 'is-invalid' : errors.length}"
                                                @change="checkForRoundNumber('cisDeducted')"
                                            >
                                            </b-form-input>
                                        </b-input-group>
                                        
                                        <small v-if="errors.length" class="text-danger validation-error d-block">
                                            {{ errors[0] }}
                                        </small>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="bg-white p-4">
                            <b-row>
                                <b-col lg>
                                    <ValidationProvider name="Total Expenses" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                        <b-form-group>
                                            <label for="totalExpenses" class="d-block required">Total expenses</label>
                                            <img class="helper-image" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.TotalExpenses.Title, Helpers.TotalExpenses.Body)" />

                                            <b-input-group prepend="£">
                                                <b-form-input
                                                    name="totalExpenses"
                                                    v-model="selfEmploymentIncomeDetails.totalExpenses"
                                                    placeholder="Total expenses"
                                                    :class="{'is-valid' : selfEmploymentIncomeDetails.totalExpenses, 'is-invalid' : errors.length}"
                                                    @change="checkForRoundNumber('totalExpenses')"
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col lg>
                                    <ValidationProvider name="Motor Expenses" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                        <b-form-group
                                            label="Motor Expenses"
                                            label-for="motorExpenses"
                                            label-class="required"
                                        >
                                            <b-input-group prepend="£">
                                                <b-form-input
                                                    name="motorExpenses"
                                                    v-model="selfEmploymentIncomeDetails.motorExpenses"
                                                    placeholder="Motor expenses"
                                                    :class="{'is-valid' : selfEmploymentIncomeDetails.motorExpenses, 'is-invalid' : errors.length}"
                                                    @change="checkForRoundNumber('motorExpenses')"
                                                >
                                                </b-form-input>
                                            </b-input-group>

                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col lg>
                                    <ValidationProvider name="Other Travel Expenses" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                        <b-form-group
                                            label="Travel Expenses"
                                            label-for="otherTravelExpenses"
                                            label-class="required"
                                        >
                                            <b-input-group prepend="£">
                                                <b-form-input
                                                    name="otherTravelExpenses"
                                                    v-model="selfEmploymentIncomeDetails.otherTravelExpenses"
                                                    placeholder="Travel expenses"
                                                    :class="{'is-valid' : selfEmploymentIncomeDetails.otherTravelExpenses, 'is-invalid' : errors.length}"
                                                    @change="checkForRoundNumber('otherTravelExpenses')"
                                                >
                                                </b-form-input>
                                            </b-input-group>

                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col lg>
                                    <ValidationProvider name="Tools Expenses" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                        <b-form-group
                                            label="Tools Expenses"
                                            label-for="toolsExpenses"
                                            label-class="required"
                                        >
                                            <b-input-group prepend="£">
                                                <b-form-input
                                                    v-model="selfEmploymentIncomeDetails.toolsExpenses"
                                                    placeholder="Tools expenses"
                                                    :class="{'is-valid' : selfEmploymentIncomeDetails.toolsExpenses, 'is-invalid' : errors.length}"
                                                    @change="checkForRoundNumber('toolsExpenses')"
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col lg>
                                    <ValidationProvider name="Other Expenses" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                        <b-form-group
                                            label="Other Expenses"
                                            label-for="otherExpenses"
                                            label-class="required"
                                        >
                                            <b-input-group prepend="£">
                                                <b-form-input
                                                    v-model="selfEmploymentIncomeDetails.otherExpenses"
                                                    placeholder="Other expenses"
                                                    :class="{'is-valid' : selfEmploymentIncomeDetails.otherExpenses, 'is-invalid' : errors.length}"
                                                    @change="checkForRoundNumber('otherExpenses')"
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </div>
                    </template>
                </div>

                <self-employment-document-component 
                    class="mt-5"
                    :item="selfEmploymentIncomeDetails"
                />

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import Notifications from '@/Notifications'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'
import SelfEmploymentDocumentComponent from '@/components/partials/SelfEmploymentDocumentComponent.vue'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'SelfEmploymentIncomeComponent',

    components: {
        SelfEmploymentDocumentComponent,
        SaveAndContinueComponent
    },

    data() {
        return {
            saving: false,
            HelperModalComponent,
            Helpers,
            ValidationErrorModalComponent
        }
    },

    mounted() {
        if(this.isSectionComplete()) this.markSectionAsComplete()

        if(this.selfEmploymentIncomeDetails.cisDeducted == null) this.selfEmploymentIncomeDetails.cisDeducted = 0
        if(this.selfEmploymentIncomeDetails.income == null) this.selfEmploymentIncomeDetails.income = 0
        if(this.selfEmploymentIncomeDetails.totalExpenses == null) this.selfEmploymentIncomeDetails.totalExpenses = 0
        if(this.selfEmploymentIncomeDetails.motorExpenses == null) this.selfEmploymentIncomeDetails.motorExpenses = 0
        if(this.selfEmploymentIncomeDetails.otherTravelExpenses == null) this.selfEmploymentIncomeDetails.otherTravelExpenses = 0
        if(this.selfEmploymentIncomeDetails.toolsExpenses == null) this.selfEmploymentIncomeDetails.toolsExpenses = 0
        if(this.selfEmploymentIncomeDetails.otherExpenses == null) this.selfEmploymentIncomeDetails.otherExpenses = 0

        this.selfEmploymentIncomeDetails.cisDeducted = (this.selfEmploymentIncomeDetails.cisDeducted / 100).toFixed(2)
        this.selfEmploymentIncomeDetails.income = (this.selfEmploymentIncomeDetails.income / 100).toFixed(2)
        this.selfEmploymentIncomeDetails.totalExpenses = (this.selfEmploymentIncomeDetails.totalExpenses / 100).toFixed(2)
        this.selfEmploymentIncomeDetails.motorExpenses = (this.selfEmploymentIncomeDetails.motorExpenses / 100).toFixed(2)
        this.selfEmploymentIncomeDetails.otherTravelExpenses = (this.selfEmploymentIncomeDetails.otherTravelExpenses / 100).toFixed(2)
        this.selfEmploymentIncomeDetails.toolsExpenses = (this.selfEmploymentIncomeDetails.toolsExpenses / 100).toFixed(2)
        this.selfEmploymentIncomeDetails.otherExpenses = (this.selfEmploymentIncomeDetails.otherExpenses / 100).toFixed(2)
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            selfEmploymentIncomeDetails: 'submissionStore/getSelfEmploymentIncomeDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getSubmissionSelfEmploymentIncomeDetails: 'submissionStore/getSubmissionSelfEmploymentIncomeDetails',
            setNotification: 'notificationsStore/setNotification',
            setSelfEmploymentIncome: 'sectionsCompleteStore/setSelfEmploymentIncome'
        }), 

        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        isSectionComplete() {
            return this.selfEmploymentIncomeDetails.businessName != null && this.selfEmploymentIncomeDetails.income != null && this.selfEmploymentIncomeDetails.totalExpenses != null && this.selfEmploymentIncomeDetails.motorExpenses != null && this.selfEmploymentIncomeDetails.otherTravelExpenses != null && this.selfEmploymentIncomeDetails.toolsExpenses != null && this.selfEmploymentIncomeDetails.otherExpenses != null
        },

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setSelfEmploymentIncome(true)
        },

        async submit() {
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true

            let selfEmploymentModel = {
                submissionId: this.submissionId,
                businessName: this.selfEmploymentIncomeDetails.businessName,
                income: parseInt(this.selfEmploymentIncomeDetails.income * 100),
                cisDeducted: parseInt(this.selfEmploymentIncomeDetails.cisDeducted * 100),
                totalExpenses: parseInt(this.selfEmploymentIncomeDetails.totalExpenses * 100),
                motorExpenses: parseInt(this.selfEmploymentIncomeDetails.motorExpenses * 100),
                otherTravelExpenses: parseInt(this.selfEmploymentIncomeDetails.otherTravelExpenses * 100),
                toolsExpenses: parseInt(this.selfEmploymentIncomeDetails.totalExpenses * 100),
                otherExpenses: parseInt(this.selfEmploymentIncomeDetails.otherExpenses * 100),
                documentId: this.selfEmploymentIncomeDetails.documentId
            }

            axios.post(`submission/${this.submissionId}/partnership-details`, selfEmploymentModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).finally(() => {
                this.saving = false
            })
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.selfEmploymentIncomeDetails[ref]))) {
                this.selfEmploymentIncomeDetails[ref] = parseFloat(this.selfEmploymentIncomeDetails[ref]).toFixed(2)
            }
        }
    }
}
</script>