<template>  
    <div class="inner-modal text-center">
        <h2>
            Sorry, you need to include details for either dividends or interests
        </h2>

        <div class="mt-4 px-5">
            <b-button variant="primary" block
                @click.prevent="$emit('close')"
            >
                Close
            </b-button>
        </div>
    </div>
</template >

<script>
export default {
    name: 'DividendsOrInterestsErrorModalComponent',
}
</script>