<template>
    <b-container class="pb-5">
        <template 
            v-if="loading"
        >
            <div class="loader-container py-4">
                <div class="loader"></div>
            </div>
        </template>

        <template
            v-else
        >
            <b-row class="mb-5">
                <b-col>
                    <h2>
                        Income sources
                    </h2>
                    
                    <p class="lead"> 
                        Select the types of income that you received during the tax year
                    </p>        
                </b-col>    
            </b-row>

            <ValidationObserver ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submit()">
                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/employment.jpg" alt="employment income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Employment Income
                                </h4>

                                <p class="mb-0">
                                    Employee, director, office holder or agency worker
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasEmploymentIncome = !answers.hasEmploymentIncome"
                                    :class="{active : answers.hasEmploymentIncome}"
                                >
                                    {{ (answers.hasEmploymentIncome) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/self-employed.jpg" alt="self employment income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Self Employed
                                </h4>

                                <p class="mb-0">
                                    Self-employed or in a partnership
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.isSelfEmployed = !answers.isSelfEmployed"
                                    :class="{active : answers.isSelfEmployed}"
                                >
                                    {{ (answers.isSelfEmployed) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/property.jpg" alt="property income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Property Income
                                </h4>

                                <p class="mb-0">
                                    UK property including land, holiday lettings, renting a room in your house or 'Airbnb type' lets
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasPropertyIncome = !answers.hasPropertyIncome"
                                    :class="{active : answers.hasPropertyIncome}"
                                >
                                    {{ (answers.hasPropertyIncome) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/pension.jpg" alt="pension income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Pension Income
                                </h4>

                                <p class="mb-0">
                                    UK pensions, annuities or state benefits
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasPensionIncome = !answers.hasPensionIncome"
                                    :class="{active : answers.hasPensionIncome}"
                                >
                                    {{ (answers.hasPensionIncome) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/etoro-income.jpg" alt="etoro">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Etoro
                                </h4>

                                <p class="mb-0">
                                    Etoro statement required
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    ref="etoroBtn"
                                    @click="answers.hasEtoro = !answers.hasEtoro"
                                    :class="{active : answers.hasEtoro}"
                                >
                                    {{ (answers.hasEtoro) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/dividend-interest.jpg" alt="dividend interest income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Dividend / Interest Income
                                </h4>

                                <p class="mb-0">
                                    UK companies, authorised unit trusts, open ended investment companies or foreign companies, UK banks, building societies, unit trusts or peer-to-peer lending
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasDividendIncome = !answers.hasDividendIncome"
                                    :class="{active : answers.hasDividendIncome}"
                                >
                                    {{ (answers.hasDividendIncome) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/capital-gains-losses.jpg" alt="capital gains losses income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Capital Gains / Losses
                                </h4>

                                <p class="mb-0">
                                    Shares, property (including your main residence), share of a partnership, UK Life Assurance policies or Bonds
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasCapitalGainsAndLosses = !answers.hasCapitalGainsAndLosses"
                                    :class="{active : answers.hasCapitalGainsAndLosses}"
                                >
                                    {{ (answers.hasCapitalGainsAndLosses) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/foreign.jpg" alt="foreign income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Foreign Income
                                </h4>

                                <p class="mb-0">
                                    Received income and any special withholding tax
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasForeignIncome = !answers.hasForeignIncome"
                                    :class="{active : answers.hasForeignIncome}"
                                >
                                    {{ (answers.hasForeignIncome) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/child-benefit-charge.jpg" alt="child benefit income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Higher Income Child Benefit Charge
                                </h4>

                                <p class="mb-0">
                                    Net adjusted income over £50,000 and received Child Benefit Applies if someone else claims Child Benefit for a child who lives with you and pays you or your partner for the child’s upkeep
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasHigherIncomeChildBenefitCharge = !answers.hasHigherIncomeChildBenefitCharge"
                                    :class="{active : answers.hasHigherIncomeChildBenefitCharge}"
                                >
                                    {{ (answers.hasHigherIncomeChildBenefitCharge) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/other-uk-income.jpg" alt="other income">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Any other UK income
                                </h4>

                                <p class="mb-0">
                                    Employment lump sums, redundancy pay-outs, Property Income Dividends (PID) from Real Estate Investment Trusts (REIT) , share schemes, life insurance gains, income from settlements or trusts
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasAnyOtherIncome = !answers.hasAnyOtherIncome"
                                    :class="{active : answers.hasAnyOtherIncome}"
                                >
                                    {{ (answers.hasAnyOtherIncome) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="select mt-4 col-12">
                        <b-row class="align-items-center">
                            <b-col md="2">
                                <img class="img-fluid" src="@/assets/images/questions/other-tax-reliefs.jpg" alt="other tax reliefs">
                            </b-col>

                            <b-col md="6" class="mt-4 mt-md-0">
                                <h4>
                                    Do you want to claim any other tax reliefs?
                                </h4>

                                <p class="mb-0">
                                    Community investment tax relief, venture capital, EIS, SEIS, maintenance/alimony payments, contributions towards a personal pension or retirement annuity, Gift Aid, Marriage Allowance transfer, Receipt of marriage allowance
                                </p>
                            </b-col>
                            
                            <b-col md="3" offset-md="1" class="text-center mt-4 mt-md-0">
                                <b-button variant="outline-primary" block
                                    @click="answers.hasOtherTaxReliefs = !answers.hasOtherTaxReliefs"
                                    :class="{active : answers.hasOtherTaxReliefs}"
                                >
                                    {{ (answers.hasOtherTaxReliefs) ? 'Remove' : 'Add' }} 
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <div class="fixed-bottom bg-white shadow py-3">
                        <b-container>
                            <b-row>
                                <b-col>
                                    <div v-if="priceLoading" class="loader-container">
                                        <div class="loader"></div>
                                    </div>
                                    <div v-else class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <p class="font-weight-bold mb-0">
                                                Total: <span class="lead color-primary font-weight-bold">&pound;{{ price.toFixed(2) }} {{ discountApplied ? '(discount applied)' : '' }}</span>
                                            </p>

                                            <p class="text-sm mb-0">
                                                Inc VAT
                                            </p>
                                        </div>

                                        <b-button type="submit" variant="success">
                                            Continue
                                        </b-button>
                                    </div>
                                    <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
                                </b-col>
                            </b-row>
                        </b-container>
                    </div>
                </form>
            </ValidationObserver>
        </template>
    </b-container>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import QuestionsValidationErrorModalComponent from '@/components/modal-components/QuestionsValidationErrorModalComponent.vue'
import CookieMixin from '@/mixins/CookieMixin'
import Notifications from '@/Notifications'

export default {
    name: 'Questions',

    props: { 
        submissionId: Number
    },

    mixins: [
        CookieMixin
    ],

    data() {
        return {
            loading: false,
            priceLoading: false,
            price: 110,
            cookieDiscountCode: null,
            discountApplied: false,
            QuestionsValidationErrorModalComponent,
            Notifications
        }
    },

    async mounted() {
        if(this.submissionId == null && this.selectedTaxYear == null) this.$router.push({name: 'MyAccount'})
        
        if(this.submissionId != null) {
            this.loading = true

            try {
                await this.getSubmission(this.submissionId)
                this.resetFirstRoundAnswers()
            }
            catch {
                this.setNotification({
                    message: Notifications.COULD_NOT_FETCH_SUBMISSION
                })
            }
            finally {
                this.loading = false
            }
        }

        if(this.discountOfferId) {
            // apply discount to price
            this.applyDiscount()
        }

        // check for a params cookie
        var cookie = this.checkCookie("site_params")

        if(cookie) {
            // check if etoro discount code is included
            var cookieValues = this.getCookie('site_params') 

            if(cookieValues != null) {
                var parsedValues = JSON.parse('{"' + decodeURI(cookieValues).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')                    
                
                if(parsedValues.discountCode) {
                    this.cookieDiscountCode = parsedValues.discountCode   
                    this.answers.hasEtoro = true         
                    // apply discount
                    this.applyDiscount()
                }
            }
        }
    },

    computed: {
        ...mapGetters({
            answers: 'submissionStore/getFirstRoundAnswers',
            reasons: 'submissionStore/getReasons',
            selectedTaxYear: 'submissionStore/getSelectedTaxYearId',
            discountOffers: 'discountOffersStore/getDiscountOffers',
            discountOfferId: 'submissionStore/getDiscountOfferId',
        })
    },

    methods: {   
        ...mapActions({
            getSubmission: 'submissionStore/getSubmission',
            createSubmission: 'submissionStore/createSubmission',
            markQuestionsComplete: 'userProgressStore/setQuestionsComplete',
            setNotification: 'notificationsStore/setNotification',
            getDiscountOffers: 'discountOffersStore/getDiscountOffers',
            resetFirstRoundAnswers: 'submissionStore/resetFirstRoundAnswers'
        }),

        async applyDiscount() {
            if(this.cookieDiscountCode) {
                this.priceLoading = true
                
                try {
                    await this.getDiscountOffers()
                    let offer = this.discountOffers.filter(offer => offer.code == this.cookieDiscountCode)
                    if(offer) {
                        this.price -= (offer[0].amount / 100).toFixed(2)
                        this.discountApplied = true
                    }
                }
                catch {
                    this.setNotification({
                        message: Notifications.DISCOUNT_OFFER_NOT_APPLIED,
                        type: 'failure'
                    })
                }
                finally {
                    this.priceLoading = false
                }                
            }
        },

        async submit() {
            let answersArray = Object.keys(this.answers).map(i => this.answers[i])

            if(answersArray.includes(true)) return this.next()
            
            this.$modal.show(QuestionsValidationErrorModalComponent, {errors: [Notifications.MUST_SELECT_ONE_SERVICE_OPTION]})
        },  

        async next() {
            this.loading = true 

            let newSubmission = {
                taxYearId: this.selectedTaxYear,
                isHMRCInstructedReason: this.reasons.isHMRCInstructedReason,
                isLandlordReason: this.reasons.isLandlordReason,
                isSelfEmployedReason: this.reasons.isSelfEmployedReason,
                isFirstTimeReason: this.reasons.isFirstTimeReason,
                isCompanyDirectorReason: this.reasons.isCompanyDirectorReason,
                isCISContractorReason: this.reasons.isCISContractorReason,
                isInvestorReason: this.reasons.isInvestorReason,
                isHighEarnerReason: this.reasons.isHighEarnerReason,
                isOtherReason: this.reasons.isOtherReason,  
                numberOfProperties: this.answers.hasPropertyIncome ? 1 : 0
            }

            const ObjToSubmit = {...newSubmission, ...this.answers}
            
            try {
                if(this.submissionId) {
                    await axios.post(`submission/${this.submissionId}/restart`, {
                        submissionId: this.submissionId
                    })
                }

                await this.createSubmission(ObjToSubmit)
                this.markQuestionsComplete()
                this.$router.push({name: 'Checklist'})
            }
            catch {
                this.setNotification({
                    message: Notifications.GENERAL_ERROR,
                    type: 'failure'
                })
            }
            finally {
                this.loading = false
            }
        }
    }
}
</script>
