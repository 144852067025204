<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <h1 class="mb-5">Reports</h1>

                <b-card style="overflow: unset;">
                    <div class="d-flex align-items-center">
                        <img src="@/assets/icons/questions/list-icon-etoro.svg" width="120" /> 
                        <h3 class="mb-0 ml-4">Etoro</h3>
                    </div>

                    <ValidationObserver ref="completedObserver">
                        <h5 class="mt-4">Completed submissions</h5>

                        <b-form @submit.prevent="getSubmissions(true)" class="mt-1">
                            <div class="d-flex align-items-end">
                                <div>
                                    <ValidationProvider name="Start Date" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="Start Date"
                                            label-for="startDate"
                                            label-class="required"
                                        >
                                            <b-form-datepicker
                                                v-model="fields.completedStartDate" 
                                                name="startDate"
                                                :class="{'is-valid' : fields.completedStartDate, 'is-invalid' : errors.length}"
                                                placeholder="Choose a date"
                                                hide-header
                                                show-decade-nav
                                                no-flip
                                                :max="max"
                                            >
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            
                                <div class="mx-4">
                                    <ValidationProvider name="End Date" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="End Date"
                                            label-for="endDate"
                                            label-class="required label-block"
                                        >
                                            <b-form-datepicker
                                                v-model="fields.completedEndDate" 
                                                name="endDate"
                                                :class="{'is-valid' : fields.completedEndDate, 'is-invalid' : errors.length}"
                                                placeholder="Choose a date"
                                                hide-header
                                                show-decade-nav
                                                no-flip
                                                :max="max"
                                            >
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <b-form-group>
                                    <b-button type="submit" variant="primary"
                                        :disabled="loading"
                                        :class="{disabled: loading}"
                                    >
                                        Export CSV <span v-if="loading" class="btn-loader"></span>
                                    </b-button>
                                </b-form-group>
                            </div>
                        </b-form>

                        <p class="underlined font-weight-bold cursor-pointer" @click="fields.completedStartDate = null, fields.completedEndDate = null">Clear</p>
                    </ValidationObserver>

                    <ValidationObserver ref="incompleteObserver">
                        <h5 class="mt-4">Incomplete submissions</h5>
                        <b-form @submit.prevent="getSubmissions(false)" class="mt-1">
                            <div class="d-flex align-items-end">
                                <div>
                                    <ValidationProvider name="Start Date" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="Start Date"
                                            label-for="startDate"
                                            label-class="required"
                                        >
                                            <b-form-datepicker
                                                v-model="fields.allStartDate" 
                                                name="startDate"
                                                :class="{'is-valid' : fields.allStartDate, 'is-invalid' : errors.length}"
                                                placeholder="Choose a date"
                                                hide-header
                                                show-decade-nav
                                                no-flip
                                                :max="max"
                                            >
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            
                                <div class="mx-4">
                                    <ValidationProvider name="End Date" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="End Date"
                                            label-for="endDate"
                                            label-class="required label-block"
                                        >
                                            <b-form-datepicker
                                                v-model="fields.allEndDate" 
                                                name="endDate"
                                                :class="{'is-valid' : fields.allEndDate, 'is-invalid' : errors.length}"
                                                placeholder="Choose a date"
                                                hide-header
                                                show-decade-nav
                                                no-flip
                                                :max="max"
                                            >
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <b-form-group>
                                    <b-button type="submit" variant="primary"
                                        :disabled="loading"
                                        :class="{disabled: loading}"
                                    >
                                        Export CSV <span v-if="loading" class="btn-loader"></span>
                                    </b-button>
                                </b-form-group>
                            </div>
                        </b-form>

                        <p class="underlined font-weight-bold cursor-pointer" @click="fields.allStartDate = null, fields.allEndDate = null">Clear</p>

                    </ValidationObserver>

                    <ValidationObserver ref="userObserver">
                        <h5 class="mt-4">All Etoro users</h5>
                        <b-form @submit.prevent="getUsers()" class="mt-1">
                            <div class="d-flex align-items-end">
                                <div>
                                    <ValidationProvider name="Start Date" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="Start Date"
                                            label-for="startDate"
                                            label-class="required"
                                        >
                                            <b-form-datepicker
                                                v-model="fields.userStartDate" 
                                                name="userStartDate"
                                                :class="{'is-valid' : fields.userStartDate, 'is-invalid' : errors.length}"
                                                placeholder="Choose a date"
                                                hide-header
                                                show-decade-nav
                                                no-flip
                                                :max="max"
                                            >
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            
                                <div class="mx-4">
                                    <ValidationProvider name="End Date" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="End Date"
                                            label-for="endDate"
                                            label-class="required label-block"
                                        >
                                            <b-form-datepicker
                                                v-model="fields.userEndDate" 
                                                name="endDate"
                                                :class="{'is-valid' : fields.userEndDate, 'is-invalid' : errors.length}"
                                                placeholder="Choose a date"
                                                hide-header
                                                show-decade-nav
                                                no-flip
                                                :max="max"
                                            >
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <b-form-group>
                                    <b-button type="submit" variant="primary"
                                        :disabled="loading"
                                        :class="{disabled: loading}"
                                    >
                                        Export CSV <span v-if="loading" class="btn-loader"></span>
                                    </b-button>
                                </b-form-group>
                            </div>
                        </b-form>

                        <p class="underlined font-weight-bold cursor-pointer" @click="fields.userStartDate = null, fields.userEndDate = null">Clear</p>

                    </ValidationObserver>

                    <download-csv
                        :data = "submissions"
                        class="btn btn-success mt-4"
                        v-if="canDownload"
                    >
                        Download
                    </download-csv>

                    <p class="text-danger"
                        v-if="nothingToDownload"
                    >
                        {{ Notifications.NO_DATA_TO_EXPORT }}
                    </p>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios'
import {mapActions} from 'vuex'
import Notifications from '@/Notifications'

export default {
    Name: 'Reports',

    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        const maxDate = new Date(today)
        maxDate.setYear(maxDate.getFullYear())

        return {
            loading: false,
            canDownload: false,
            nothingToDownload: false,
            fields: {
                completedStartDate: null,
                completedEndDate: null,
                allStartDate: null,
                allEndDate: null,
                userStartDate: null,
                userEndDate: null
            },
            max: maxDate,
            submissions: [],
            Notifications
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'            
        }),

        async getSubmissions(completed) {
            console.log(completed)
            this.canDownload = false
            this.nothingToDownload = false
            this.loading = true

            let startDate = null,
                endDate = null

            if(completed) {
                startDate = this.fields.completedStartDate
                endDate = this.fields.completedEndDate
            } else {
                startDate = this.fields.allStartDate
                endDate = this.fields.allEndDate
            }

            try {
                let response = await axios.post(`submissions/${startDate}/${endDate}/${completed}/etoro`)

                this.submissions = response.data

                if(this.submissions.length) {
                    this.canDownload = true
                } else {
                    this.nothingToDownload = true
                }
            }
            catch {
                this.setNotification({
                    type: 'failure',
                    message: Notifications.GENERAL_ERROR
                })
            }
            finally {
                this.loading = false
            }
        },

        async getUsers() {
            this.canDownload = false
            this.nothingToDownload = false
            this.loading = true

            try {
                let response = await axios.post(`users/${this.fields.userStartDate}/${this.fields.userEndDate}/etoro`)

                this.submissions = response.data

                if(this.submissions.length) {
                    this.canDownload = true
                } else {
                    this.nothingToDownload = true
                }
            }
            catch(err) {
                console.log(err)
                this.setNotification({
                    type: 'failure',
                    message: Notifications.GENERAL_ERROR
                })
            }
            finally {
                this.loading = false
            }
        }
    }
}
</script>