<template>
    <b-container>
        <b-row class="justify-content-center">
            <b-col md="5">
                <h2
                    class="text-center mb-4 mb-lg-0"
                >
                    Create Staff User 
                </h2> 

                <b-alert variant="success" show dismissible class="mt-5"
                    v-if="createUserSuccess"
                >
                    {{ Notifications.CREATE_USER_SUCCESS }}
                </b-alert>

                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                    <b-form @submit.prevent="handleSubmit(onSubmit)" class="mt-5">
                        <ValidationProvider name="First Name" rules="required" v-slot="{ errors }">
                            <b-form-group
                            label="First Name"
                            label-for="firstName"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="fields.firstName"
                                    type="text"
                                    :class="{'is-invalid' : errors.length}"
                                ></b-form-input>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                        
                        <ValidationProvider name="Last Name" rules="required" v-slot="{ errors }">
                            <b-form-group
                            label="Last Name"
                            label-for="lastName"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="fields.lastName"
                                    type="text"
                                    :class="{'is-invalid' : errors.length}"
                                ></b-form-input>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>

                        <ValidationProvider name="Email Address" rules="required" v-slot="{ errors }">
                            <b-form-group
                            label="Email Address"
                            label-for="emailAddress"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="fields.emailAddress"
                                    type="text"
                                    :class="{'is-invalid' : errors.length}"
                                ></b-form-input>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                
                        <b-button type="submit" variant="primary" block>
                            Create User <span v-if="loading" class="btn-loader"></span>
                        </b-button>
                    </b-form>
                </ValidationObserver> 
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios'
import Notifications from '@/Notifications'

export default {
    name: 'CreateUser', 

    data() {
        return {
            loading: false,
            fields: {},
            createUserSuccess: false,
            Notifications,
        }
    }, 

    methods: {
        onSubmit() {
            this.createUser()
        }, 

        createUser() {
            this.loading = true

            let userModel = {
                firstName: this.fields.firstName,
                lastName: this.fields.lastName,
                emailAddress: this.fields.emailAddress,
                role: "Accountant"
            }

            axios.post('staff', userModel).then(() => {
                this.fields = {}
                this.$refs.observer.reset()
                this.createUserSuccess = true
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>