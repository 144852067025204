<template> 
    <div class="tab-content">
        <template
            v-if="Object.keys(userDetails).length !== 0 && Object.keys(submissionBasicDetails).length !== 0"
        > 
            <ValidationObserver ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submit()">
                    <div class="tab-border">
                        <div class="p-4">
                            <h4 class="mb-0">
                                Enter your telephone number, DOB, UTR and NI
                            </h4>
                        </div>

                        <div class="bg-white p-4">
                            <b-row>
                                <b-col md="6">
                                    <ValidationProvider name="Telephone" rules="required|numeric" v-slot="{ errors }">
                                        <b-form-group
                                            label="Telephone Number"
                                            label-for="telephone"
                                            label-class="required"
                                        >
                                            <b-form-input
                                                name="telephone"
                                                v-model="userDetails.telephone"
                                                type="text"
                                                placeholder="0121 211 2111"
                                                :class="{'is-valid' : userDetails.telephone, 'is-invalid' : userDetailsFailures && userDetailsFailures.Telephone || errors.length}"
                                            >
                                                </b-form-input>

                                            <small
                                                v-if="userDetailsFailures && userDetailsFailures.Telephone"
                                                class="text-danger"
                                            >
                                                {{ userDetailsFailures.Telephone[0] }}
                                            </small>

                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col> 

                                <b-col md="6">
                                    <ValidationProvider name="Date Of Birth" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="Date Of Birth"
                                            label-for="dateOfBirth"
                                            label-class="required"
                                        >
                                            <b-form-datepicker
                                                v-model="userDetails.dateOfBirth" 
                                                name="dateOfBirth"
                                                :class="{'is-valid' : userDetails.dateOfBirth, 'is-invalid' : errors.length}"
                                                placeholder="Choose a date"
                                                hide-header
                                                show-decade-nav
                                                no-flip
                                                :max="max"
                                            >
                                            </b-form-datepicker>

                                            <small
                                                v-if="userDetailsFailures && userDetailsFailures.DateOfBirth"
                                                class="text-danger"
                                            >
                                                {{ userDetailsFailures.DateOfBirth[0] }}
                                            </small>
                                                
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mt-4">
                                <b-col md="6">
                                    <ValidationProvider name="UTR" rules="required|digits:10" v-slot="{ errors }">
                                        <b-form-group>
                                            <label for="utr" class="d-block required">Unique taxpayer reference (UTR)</label>
                                            <img class="helper-image" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.UTR.Title, Helpers.UTR.Body)" />
                                            
                                            <b-form-input
                                                name="utr"
                                                v-model="userDetails.utr"
                                                type="text"
                                                placeholder="10 digit number"
                                                :class="{'is-valid' : userDetails.utr, 'is-invalid' : userDetailsFailures && userDetailsFailures.Utr || errors.length}"
                                            >
                                            </b-form-input>
                                            
                                            <small class="text-danger"
                                                v-if="userDetailsFailures && userDetailsFailures.Utr"
                                            >
                                                {{ userDetailsFailures.Utr[0] }}
                                            </small>

                                            <small class="text-danger validation-error d-block"
                                                v-if="errors.length"
                                            >
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col> 

                                <b-col md="6">
                                    <ValidationProvider name="Ni Number" rules="required|alpha_num" v-slot="{ errors }">
                                        <b-form-group>
                                            <label for="niNumber" class="d-block required">National insurance number (NI)</label>
                                            <img class="helper-image" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.NI.Title, Helpers.NI.Body)" />
                                            
                                            <b-form-input
                                                name="niNumber"
                                                v-model="userDetails.niNumber"
                                                type="text"
                                                placeholder="ab452290f"
                                                :class="{'is-valid' : userDetails.niNumber, 'is-invalid' : userDetailsFailures && userDetailsFailures.NiNumber || errors.length}"
                                            >
                                            </b-form-input>
                                            
                                            <small
                                                v-if="userDetailsFailures && userDetailsFailures.NiNumber"
                                                class="text-danger"
                                            >
                                                {{ userDetailsFailures.NiNumber[0] }}
                                            </small>

                                            <small class="text-danger validation-error d-block"
                                                v-if="errors.length"
                                            >
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </div>

                        <div class="bg-grey p-4">
                            <h4>
                                Please upload a document to help us identify you
                            </h4>
                        </div>

                        <div class="p-4 bg-white">
                            <user-document-item-component 
                                :submissionBasicDetails="submissionBasicDetails"
                            />
                        </div>

                        <div class="p-4">
                            <h4 class="mb-0">
                                Enter your address
                            </h4>
                        </div>

                        <div class="bg-white p-4">
                            <b-row>
                                <b-col md="6">
                                    <ValidationProvider name="Address Line 1" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="Address Line 1"
                                            label-for="addressLine1"
                                            label-class="required"
                                        >
                                            <b-form-input
                                                name="addressLine1"
                                                v-model="submissionBasicDetails.address.line1"
                                                type="text"
                                                placeholder="Address line 1"
                                                :class="{'is-valid' : submissionBasicDetails.address.line1, 'is-invalid' : basicDetailsFailures && basicDetailsFailures['Address.Line1'] || errors.length}"
                                            >
                                            </b-form-input>

                                            <small
                                                v-if="basicDetailsFailures && basicDetailsFailures['Address.Line1']"
                                                class="text-danger"
                                            >
                                                {{ basicDetailsFailures && basicDetailsFailures['Address.Line1'] }}
                                            </small>

                                            <small class="text-danger validation-error d-block"
                                                v-if="errors.length"
                                            >
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>

                                    <b-form-group
                                        label="Address Line 2"
                                        label-for="addressLine2"
                                    >
                                        <b-form-input
                                            name="addressLine2"
                                            v-model="submissionBasicDetails.address.line2"
                                            type="text"
                                            placeholder="Address line 2"
                                            :class="{'is-invalid' : basicDetailsFailures && basicDetailsFailures['Address.Line2'] || errors.length}"
                                        >
                                        </b-form-input>
                                    </b-form-group>

                                    <b-form-group
                                        label="Address Line 3"
                                        label-for="addressLine3"
                                    >
                                        <b-form-input
                                            name="addressLine3"
                                            v-model="submissionBasicDetails.address.line3"
                                            type="text"
                                            placeholder="Address line 3"
                                            :class="{'is-invalid' : basicDetailsFailures && basicDetailsFailures['Address.Line3'] || errors.length}"
                                        >
                                        </b-form-input>
                                    </b-form-group>

                                    <b-form-group
                                        label="Town"
                                        label-for="town"
                                    >
                                        <b-form-input
                                            name="town"
                                            v-model="submissionBasicDetails.address.town"
                                            type="text"
                                            placeholder="Town"
                                            :class="{'is-valid' : submissionBasicDetails.address.town, 'is-invalid' : basicDetailsFailures && basicDetailsFailures['Address.Town'] || errors.length}"
                                        >
                                        </b-form-input>
                                    </b-form-group>
                                </b-col> 

                                <b-col md="6" class="d-flex flex-column font-weight-bold justify-content-end">
                                    <ValidationProvider name="County" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="County"
                                            label-for="county"
                                            label-class="required"
                                        >
                                            <b-form-input
                                                name="county"
                                                v-model="submissionBasicDetails.address.county"
                                                type="text"
                                                placeholder="County"
                                                :class="{'is-valid' : submissionBasicDetails.address.county, 'is-invalid' : basicDetailsFailures && basicDetailsFailures['Address.County'] || errors.length}"                                        >
                                            </b-form-input>

                                            <small
                                                v-if="basicDetailsFailures && basicDetailsFailures['Address.County']"
                                                class="text-danger"
                                            >
                                                {{ basicDetailsFailures && basicDetailsFailures['Address.County'] }}
                                            </small>

                                            <small class="text-danger validation-error d-block"
                                                v-if="errors.length"
                                            >
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>

                                    <ValidationProvider name="Postcode" :rules="{ required: true, regex: /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i }" v-slot="{ errors }">
                                        <b-form-group
                                            label="Postcode"
                                            label-for="postcode"
                                            label-class="required"
                                        >
                                            <b-form-input
                                                name="postcode"
                                                v-model="submissionBasicDetails.address.postcode"
                                                type="text"
                                                placeholder="Postcode"
                                                :class="{'is-valid' : submissionBasicDetails.address.postcode, 'is-invalid' : basicDetailsFailures && basicDetailsFailures['Address.Postcode'] || errors.length}"                                        >
                                            </b-form-input>

                                            <small
                                                v-if="basicDetailsFailures && basicDetailsFailures['Address.Postcode']"
                                                class="text-danger"
                                            >
                                                {{ basicDetailsFailures && basicDetailsFailures['Address.Postcode'][0] }}
                                            </small>

                                            <small class="text-danger validation-error d-block"
                                                v-if="errors.length"
                                            >
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </div>
                    </div>

                    <div class="mt-5 d-flex justify-content-center justify-content-md-end">
                        <b-button type="submit" variant="success" :disabled="saving">
                            Save &amp; Continue <span v-if="saving" class="btn-loader"></span>
                        </b-button>
                    </div>
                    <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
                </form>
            </ValidationObserver>
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Notifications from '@/Notifications'
import Helpers from '@/Helpers'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import UserDocumentItemComponent from '@/components/partials/UserDocumentItemComponent.vue'

export default {
    name: 'BasicDetailsComponent',
    
    components: {
        UserDocumentItemComponent,
    },

    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        const maxDate = new Date(today)
        maxDate.setYear(maxDate.getFullYear() - 16)

        return {
            Helpers,
            ValidationErrorModalComponent,
            HelperModalComponent,
            userDetailsFailures: {},
            basicDetailsFailures: {},
            formErrors: [],
            errors: {},
            sectionComplete: Boolean,
            saving: false,
            max: maxDate
        }
    },

    mounted() {
        if(this.isSectionComplete()) this.markSectionAsComplete()

        if(this.userDetails.dateOfBirth == "0001-01-01T00:00:00") {
            this.setDefaultDob()
        }
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            userDetails: 'userStore/getUserDetails',
            submissionBasicDetails: 'submissionStore/getBasicDetails',
            isBasicDetailsComplete: 'submissionStore/getIsBasicDetailsComplete',
            isUserDetailsComplete: 'userStore/getIsUserDetailsComplete',
            submissionId: 'submissionStore/getId',
        }),
    }, 

    methods: {
        ...mapActions({
            getSubmissionBasicDetails: 'submissionStore/getSubmissionBasicDetails',
            setNotification: 'notificationsStore/setNotification',
            setBasicDetails: 'sectionsCompleteStore/setBasicDetails',
            setDefaultDob: 'userStore/setDefaultDob'
        }),

        isSectionComplete() {
            return Boolean(this.isBasicDetailsComplete && this.isUserDetailsComplete)
        },

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setBasicDetails(true)
        },

        markSectionAsIncomplete() {
            this.completedSubmissionSections.isBasicDetailsComplete = false
        },

        dateformat(value) {
            return this.$moment(value).format('YYYY-MM-DD')
        },

        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        async submit() {            
            this.userDetailsFailures = {}

            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true

            axios.put('users/me/details', this.userDetails).then(() => {
                
                let basicDetailsModel = {
                    submissionId: this.submissionId,
                    address: {
                        line1: this.submissionBasicDetails.address.line1,
                        line2: this.submissionBasicDetails.address.line2,
                        line3: this.submissionBasicDetails.address.line3,
                        town: this.submissionBasicDetails.address.town,
                        county: this.submissionBasicDetails.address.county,
                        postcode: this.submissionBasicDetails.address.postcode
                    },
                    userDocumentId: this.submissionBasicDetails.userDocumentId
                }

                axios.post(`submission/${this.submissionId}/basic-details`, basicDetailsModel).then(() => {
                    this.setNotification({
                        type: 'success',
                        message: Notifications.SAVED_SUCCESS
                    })

                    this.markSectionAsComplete()
                    this.$emit('next')
                }).catch((err) => {
                    this.basicDetailsFailures = err.data.failures
                })
            }).catch((err) => {
                this.userDetailsFailures = err.data.failures
            }).finally(() => this.saving = false)
        }
    }
}
</script>