<template>
    <ValidationObserver ref="observer">
        <ValidationProvider ref="field" name="P11D Document" :rules="`${item.p11dDocumentId == null ? 'required|ext:doc,docx,pdf,xls,xlsx,pages,jpg,png,jpeg|size:10000' : ''}`" v-slot="{ errors }">
            <input 
                ref="imageUpload" 
                style="display: none" 
                type="file" 
                @change="handleUpload()"
            >

            <div class="bg-white d-flex flex-column flex-md-row align-items-center justify-content-between p-4"
                :class="{'border border-success' : (hasBeenUploaded || item.p11dDocumentId != null), 'border border-danger' : errors.length}"
            >
                <div>
                    <h5 class="mb-0">
                        P11D <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.P11D.Title, Helpers.P11D.Body)" />
                    </h5>

                    <p class="mb-0">
                        Please upload a P11D document
                    </p>

                    <p class="mb-0 color-primary text-sm font-weight-bold">
                        Accepted document types are jpg, jpeg, png, pdf, doc & docx.
                    </p>

                    <p class="text-success text-sm mb-0 mt-2"
                        v-if="hasBeenUploaded || item.p11dDocumentId != null"
                    >
                        You have uploaded a P11D document
                    </p>

                    <img class="cursor-pointer" height="20" src="@/assets/icons/delete.svg" alt="delete"
                        v-if="hasBeenUploaded || item.p11dDocumentId != null"
                        @click="deleteUpload()"
                    >
                </div>

                <div class="mt-4 mt-md-0">
                    <b-button block variant="primary"
                        @click="uploadFile()"
                    >
                        <img src="@/assets/icons/upload.svg" width="15" height="15" /> Upload
                    </b-button>
                </div>
            </div>

            <small v-if="errors.length" class="text-danger validation-error d-block">
                {{ errors[0] }}
            </small>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import UploadFileModalComponent from '@/components/modal-components/UploadFileModalComponent.vue'
import Notifications from '@/Notifications'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'P11dItemComponent',

    props: {
        item: Object,
        hasBenefitInKind: Boolean
    },

    data() {
        return {
            UploadFileModalComponent, 
            HelperModalComponent,
            Helpers,
            file: null,
            fieldHasErrors: false,
            hasBeenUploaded: false,
        }
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId'
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'
        }),

        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        uploadFile() {
            this.$refs.imageUpload.click()
        }, 

        handleUpload() {
            this.fieldHasErrors = false
            this.file = this.$refs.imageUpload.files[0]

            this.validate().then(() => {
                if(this.fieldHasErrors == false) {
                    var formData = new FormData();
                    formData.append('file', this.file, this.file.name)

                    this.$modal.show(UploadFileModalComponent)

                    axios.post(`submissions/${this.submissionId}/documents`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response) => {
                       this.item.p11dDocumentId = response.data.documentId
                       this.hasBeenUploaded = true

                        this.setNotification({
                            message: Notifications.FILE_UPLOAD_SUCCESS,
                            type: 'success'
                        })
                    }).catch(() => {
                        this.setNotification({
                            message: Notifications.FILE_UPLOAD_FAILURE,
                            type: 'failure'
                        })
                    }).finally(() => this.$modal.hideAll())
                }
            })
        }, 

        deleteUpload() {
            this.file = null
            this.$refs.imageUpload.value = null
            this.item.p11dDocumentId = null
            this.hasBeenUploaded = false
            
            this.validate()
        },

        async validate() {
            let errors = await this.$refs.field.validate(this.file)
            
            if(!errors.valid) {
                this.fieldHasErrors = true
            } 
        }
    },
}
</script>