<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4>
                            Please tell us more about your property income	
                        </h4>

                        <p class="mb-0">
                            Did you receive income from UK property including income from land, holiday lettings, renting a room in your house or "Airbnb type" lets?
                        </p>
                    </div>

                    <template
                        v-if="propertyIncomeDetails.length"
                    >
                        <property-income-item-component 
                            v-for="(item, index) in propertyIncomeDetails"
                            :key="index"
                            :item="item"
                            :index="index"
                            :failures="failures"
                        />
                    </template>
                </div>

                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div class="media">
                        <img src="@/assets/icons/questions/property.svg" width="60" height="60" class="mr-3" alt="add employment">
                        <div class="media-body">
                            <h5 class="mt-0">Add another property</h5>
                            Would you like to add details of another property?
                        </div>
                    </div>

                    <b-button variant="primary" class="mt-4 mt-md-0"
                        @click="createDefaultItem()"
                    >
                        Add property
                    </b-button>     
                </div>

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import PropertyIncomeItemComponent from '@/components/partials/PropertyIncomeItemComponent.vue'
import TooManyPropertiesErrorModalComponent from '@/components/modal-components/TooManyPropertiesErrorModalComponent.vue'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'PropertyIncomeComponent',

    components: {
        PropertyIncomeItemComponent,
        SaveAndContinueComponent
    },

    data() {
        return {
            failures: {},
            saving: false,
            TooManyPropertiesErrorModalComponent,
            ValidationErrorModalComponent
        }
    },

    mounted() {
        if(this.propertyIncomeDetails.length) this.markSectionAsComplete()
        if(!this.propertyIncomeDetails.length) this.createDefaultItem()
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            propertyIncomeDetails: 'submissionStore/getPropertyIncomeDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getSubmissionPropertyIncomeDetails: 'submissionStore/getSubmissionPropertyIncomeDetails',
            setNotification: 'notificationsStore/setNotification',
            setPropertyIncome: 'sectionsCompleteStore/setPropertyIncome'
        }), 

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setPropertyIncome(true)
        },

        createDefaultItem() {
            let newProperty = {
                address: {
                    line1: null,
                    line2: null,
                    line3: null,
                    town: null,
                    county: null,
                    postcode: null
                },
                rentReceivedAmount: null,
                isFurnished: false,
                mortgageInterestPaidAmount: 0,
                otherCosts: [
                    {
                        name: null,
                        amount: 0
                    }
                ]
            }

            this.propertyIncomeDetails.push(newProperty)

            setTimeout(() => {
                this.$scrollTo('#index'+(this.propertyIncomeDetails.length - 1), 800)
            }, 500)
        },

        async submit() {
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true
            this.failures = {}

            let propertyIncomeModel = {
                submissionId: this.submissionId,
                properties: JSON.parse(JSON.stringify(this.propertyIncomeDetails))
            }

            propertyIncomeModel.properties.forEach(p => {
                p.rentReceivedAmount = parseInt(p.rentReceivedAmount * 100)
                p.mortgageInterestPaidAmount = parseInt(p.mortgageInterestPaidAmount * 100)
                p.otherCosts.forEach(oc => {
                    oc.amount = parseInt(oc.amount * 100)
                })
            })

            axios.post(`submission/${this.submissionId}/property-details`, propertyIncomeModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).catch((err) => {
                this.failures = err.data.failures
            }).finally(() => this.saving = false)
        }
    }
}
</script>