<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-6 variant="approval" class="pl-0">
                <h4 class="mb-0">
                    Pension Annuity Contributions
                </h4>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="pensionAnnuityContributionsDetails.length"
                            >
                                <pension-annuity-contribution-item-component 
                                    v-for="(item, index) in pensionAnnuityContributionsDetails"
                                    :key="index"
                                    :item="item"
                                    :index="index"
                                />
                            </template>

                            <div class="p-4">
                                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                                    <div>
                                        <h5 class="mt-0">Add another pension annuity contribution</h5>
                                        Would you like to add details of another pension annuity contribution?
                                    </div>

                                    <b-button variant="primary" class="mt-4 mt-md-0"
                                        @click="createDefaultPensionAnnuityContributionItem()"
                                    >
                                        Add pension annuity
                                    </b-button>    
                                </div> 
                            </div>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import PensionAnnuityContributionItemComponent from '@/components/partials/PensionAnnuityContributionItemComponent.vue'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'

export default {
    name: 'PensionAnnuityContributionsComponent', 

    components: {
        PensionAnnuityContributionItemComponent
    },

    data() {
        return {
            saving: false,
            responseFailures: {}
        }
    },

    mounted() {
        if(!this.pensionAnnuityContributionsDetails.length) this.createDefaultPensionAnnuityContributionItem()
    },

    computed: {
        ...mapGetters({
            pensionAnnuityContributionsDetails: 'submissionStore/getPensionAnnuityContributionsDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getPensionAnnuityContributionsDetails: 'submissionStore/getSubmissionPensionAnnuityContributionsDetails',
            setNotification: 'notificationsStore/setNotification'
        }),

        createDefaultPensionAnnuityContributionItem() {
            let pensionAnnuityContributionItem = {
                paidAmount: 0,
                isAmountGross: true
            }

            this.pensionAnnuityContributionsDetails.push(pensionAnnuityContributionItem)

            setTimeout(() => {
                this.$scrollTo('#pacindex'+(this.pensionAnnuityContributionsDetails.length - 1), 800)
            }, 500)
        },

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let pensionAnnuityContributionsModel = {
                submissionId: this.submissionId,
                pensionAnnuityContributions: JSON.parse(JSON.stringify(this.pensionAnnuityContributionsDetails))
            }

            pensionAnnuityContributionsModel.pensionAnnuityContributions.forEach(p => {
                p.paidAmount = parseInt(p.paidAmount * 100)
            })

            axios.post(`/submission/${this.submissionId}/pension-annuity-contributions`, pensionAnnuityContributionsModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

            }).catch((err) => {
                this.responseFailures = err.data.failures
            }).finally(() => this.saving = false)
        }
    }
}
</script>