<template>
    <b-container class="pb-5">
        <b-row>
            <b-col>
                <h2>
                    We’d like to understand why you need to prepare a tax return
                </h2>

                <p class="lead">
                    Choose all that applied to you in {{ getTaxLabel(taxYearId) }}
                </p>      
            </b-col>    
        </b-row>

        <b-row class="text-center mt-5">
            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="Asked by HMRC"
                        :img-src="isHMRCInstructedReasonImage"
                        img-alt="Asked by HMRC"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isHMRCInstructedReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                Asked by HMRC
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isHMRCInstructedReason = !reasons.isHMRCInstructedReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isHMRCInstructedReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isHMRCInstructedReason = !reasons.isHMRCInstructedReason"
                    >
                        {{ (reasons.isHMRCInstructedReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>

            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="Landlord"
                        :img-src="isLandlordReasonImage"
                        img-alt="Landlord"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isLandlordReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                Landlord
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isLandlordReason = !reasons.isLandlordReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isLandlordReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isLandlordReason = !reasons.isLandlordReason"
                    >
                        {{ (reasons.isLandlordReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>

            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="Self Employed"
                        :img-src="isSelfEmployedReasonImage"
                        img-alt="Self Employed"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isSelfEmployedReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                Self Employed
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isSelfEmployedReason = !reasons.isSelfEmployedReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isSelfEmployedReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isSelfEmployedReason = !reasons.isSelfEmployedReason"
                    >
                        {{ (reasons.isSelfEmployedReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>
            
            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="First Time Filer"
                        :img-src="isFirstTimeReasonImage"
                        img-alt="First Time Filer"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isFirstTimeReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                First Time Filer
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isFirstTimeReason = !reasons.isFirstTimeReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isFirstTimeReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isFirstTimeReason = !reasons.isFirstTimeReason"
                    >
                        {{ (reasons.isFirstTimeReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>

            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="Company Directors"
                        :img-src="isCompanyDirectorReasonImage"
                        img-alt="Company Directors"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isCompanyDirectorReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                Company Directors
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isCompanyDirectorReason = !reasons.isCompanyDirectorReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isCompanyDirectorReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isCompanyDirectorReason = !reasons.isCompanyDirectorReason"
                    >
                        {{ (reasons.isCompanyDirectorReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>

            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="CIS Contractors"
                        :img-src="isCISContractorReasonImage"
                        img-alt="CIS Contractors"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isCISContractorReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                CIS Contractors
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isCISContractorReason = !reasons.isCISContractorReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isCISContractorReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isCISContractorReason = !reasons.isCISContractorReason"
                    >
                        {{ (reasons.isCISContractorReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>

            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="Investors"
                        :img-src="isInvestorReasonImage"
                        img-alt="Investors"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isInvestorReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                Investors
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isInvestorReason = !reasons.isInvestorReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isInvestorReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isInvestorReason = !reasons.isInvestorReason"
                    >
                        {{ (reasons.isInvestorReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>

            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="High Earners"
                        :img-src="isHighEarnerReasonImage"
                        img-alt="High Earners"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isHighEarnerReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                High Earners
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isHighEarnerReason = !reasons.isHighEarnerReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isHighEarnerReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isHighEarnerReason = !reasons.isHighEarnerReason"
                    >
                        {{ (reasons.isHighEarnerReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>

            <b-col md="4">
                <div class="d-flex flex-column justify-content-between w-100">
                    <b-card
                        title="Other"
                        :img-src="isOtherReasonImage"
                        img-alt="Other"
                        img-top
                        class="reasons-card mb-4"
                        :class="{active : reasons.isOtherReason}"
                    >  
                        <div class="overlay p-4">
                            <h4 class="card-title">
                                Other
                            </h4>

                            <b-button variant="outline-primary" class="mt-3" block
                                @click="reasons.isOtherReason = !reasons.isOtherReason"
                            >
                                Add
                            </b-button>
                        </div> 

                        <div class="close"
                            @click="reasons.isOtherReason = false"
                        >
                            X
                        </div>
                    </b-card>

                    <b-button variant="outline-primary" class="mt-1 mb-5 responsive-btn" block
                        @click="reasons.isOtherReason = !reasons.isOtherReason"
                    >
                        {{ (reasons.isOtherReason) ? 'Remove' : 'Add' }}
                    </b-button>
                </div>
            </b-col>
        </b-row>
    
        <div class="fixed-bottom bg-white shadow py-3">
            <b-container>
                <b-row>
                    <b-col class="text-right">
                        <b-button variant="success"
                            @click="next()"
                        >
                            Continue
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </b-container>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import isHMRCInstructedReasonImage from "@/assets/images/reasons/asked-by-hmrc.svg"
import isLandlordReasonImage from "@/assets/images/reasons/landlord.svg"
import isSelfEmployedReasonImage from "@/assets/images/reasons/self-employed.svg"
import isFirstTimeReasonImage from "@/assets/images/reasons/first-time-filer.svg"
import isCompanyDirectorReasonImage from "@/assets/images/reasons/company-director.svg"
import isCISContractorReasonImage from "@/assets/images/reasons/cis-contractors.svg"
import isInvestorReasonImage from "@/assets/images/reasons/investors.svg"
import isHighEarnerReasonImage from "@/assets/images/reasons/high-earners.svg"
import isOtherReasonImage from "@/assets/images/reasons/other.svg"

export default {
    name: 'Reasons',

    data() {
        return {
            isHMRCInstructedReasonImage,
            isLandlordReasonImage,
            isSelfEmployedReasonImage,
            isFirstTimeReasonImage,
            isCompanyDirectorReasonImage,
            isCISContractorReasonImage,
            isInvestorReasonImage,
            isHighEarnerReasonImage,
            isOtherReasonImage
        }
    },  

    mounted() {
        if(this.taxYearId == null) this.$router.push({name: 'MyAccount'})
    },

    computed: {
        ...mapGetters({
            user: 'userStore/getUser',
            reasons: 'submissionStore/getReasons',
            taxYears: 'taxYearStore/getTaxYears',
            taxYearId: 'submissionStore/getSelectedTaxYearId'
        })
    },

    methods: {
        ...mapActions({
            markReasonsComplete: 'userProgressStore/setReasonsComplete'
        }),

        next() {
            this.markReasonsComplete()
            this.$router.push({name : 'Questions'})
        },

        getTaxLabel(id) {
            let taxYear = this.taxYears.filter(t => {
                if(t.id == id) return t
            })

            if(taxYear.length) return taxYear[0].label + ' ('+ this.$moment(taxYear[0].startDate).format('MMMM Do YYYY') + ' - ' + this.$moment(taxYear[0].endDate).format('MMMM Do YYYY') +')'
        },
    }
}
</script>