<template>
    <div>
        <template
            v-if="loading"
        >
            <div class="loader-container py-4">
                <div class="loader"></div>

                <p>
                    Preparing your submission..
                </p>
            </div>
        </template>

        <template
            v-else
        >
            <div class="tabs-wrap">
                <b-tabs vertical nav-wrapper-class="tabs-navigation" fill v-model="tabIndex">
                    <b-tab
                        :title-link-class="{completed : isBasicDetailsComplete}"
                    >   
                        <template #title>
                            <div>
                                <img src="@/assets/icons/questions/basic-details.svg" width="30" height="30" /> 
                                <span class="mb-0 ml-2 mt-2">Basic Details</span>
                            </div>

                            <div>
                                <img v-if="isBasicDetailsComplete" src="@/assets/icons/green-tick.svg" width="25" height="25" />
                                <img v-else src="@/assets/icons/warning-icon.svg" width="25" height="25" />
                            </div>
                        </template>

                        <div class="d-block d-xl-none bg-secondary navigator">
                            <div class="d-flex justify-content-between mb-3">
                                <b-button variant="primary">
                                    Basic Details <img v-if="isBasicDetailsComplete" src="@/assets/icons/green-tick.svg" width="25" height="25" />
                                </b-button>

                                <b-button variant="secondary"
                                    @click.prevent="moveToNextTab()"
                                >
                                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.6 20">
                                        <path d="M21.2,19.8l9.2-9.3a.7.7,0,0,0,0-1L21.2.2a.8.8,0,0,0-1.3.6V6.4H1a1.1,1.1,0,0,0-1,1H0v5.3a1,1,0,0,0,1,1H19.9v5.5A.8.8,0,0,0,21.2,19.8Z" transform="translate(0 0)" style="fill:#fff"/>
                                    </svg>
                                </b-button>
                            </div>
                        </div>

                        <basic-details-component 
                            @next="moveToNextTab()"
                            @sectionComplete="isBasicDetailsComplete = true"
                        />
                    </b-tab>
                    
                    <tab-section-component 
                        v-for="(answer, index) in filteredFirstRoundAnswers"
                        :key="index"
                        :answer="answer"
                        :index="index"
                        :tabIndex="tabIndex"
                        @next="moveToNextTab()"
                        @back="moveToPrevTab()"
                    /> 

                    <b-tab title="Additional Details">
                        <template #title>
                            <div>
                                <img src="@/assets/icons/questions/additional-details.svg" width="30" height="30" /> 
                                <span class="mb-0 ml-2 mt-2">Additional Details</span>
                            </div>

                            <div>
                                <img src="@/assets/icons/green-tick.svg" width="25" height="25" />
                            </div>
                        </template>

                        <div class="d-block d-xl-none bg-secondary navigator">
                            <div class="d-flex justify-content-between mb-3">
                                <b-button variant="secondary"
                                    @click.prevent="moveToPrevTab()"
                                >
                                    <svg width="25" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 30.6 20" style="enable-background:new 0 0 30.6 20;" xml:space="preserve">
                                    <path id="arrow" style="fill: #fff" class="st0" d="M10.7,19.2v-5.6h18.9c0,0,0,0,0,0c0.5,0,1-0.4,1-1l0-5.3c0,0,0,0,0,0c0-0.5-0.4-1-1-1l-18.9,0V0.8
                                        c0-0.7-0.8-1-1.3-0.5L0.2,9.5c-0.3,0.3-0.3,0.8,0,1.1l9.2,9.2C9.9,20.2,10.7,19.9,10.7,19.2z"/>
                                    </svg>
                                </b-button>

                                <b-button variant="primary">
                                    Additional Details
                                </b-button>
                            </div>
                        </div>

                        <additional-details-component 
                            @next="moveToApproval()"
                        />
                    </b-tab>
                    <template #tabs-end>
                        <button @click="addIncomeSource()" class="btn btn-grey btn-block my-4"><img style="vertical-align: bottom" class="mr-2" src="@/assets/icons/add.svg" width="25" height="25" /> Add an income source</button>
                    </template>
               </b-tabs>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BasicDetailsComponent from '@/components/round-two-question-components/BasicDetailsComponent.vue'
import AdditionalDetailsComponent from '@/components/round-two-question-components/AdditionalDetailsComponent.vue'
import TabSectionComponent from '@/components/field-resolver-components/TabSectionComponent.vue'
import Notifications from '@/Notifications'
import AddIncomeSourceModalComponent from '@/components/modal-components/AddIncomeSourceModalComponent.vue'

export default {
    name: 'FieldResolverComponent',

    components: {
        BasicDetailsComponent,
        AdditionalDetailsComponent,
        TabSectionComponent
    }, 

    data() {
        return {
            loading: true,
            tabIndex: 0,
            isBasicDetailsComplete: false,
            promises: [],
            AddIncomeSourceModalComponent
        }
    },

    async mounted() {
        await this.getSubmission()

        try {
            await Promise.all(this.promises)
        }
        catch {
            this.setNotification({
                message: Notifications.COULD_NOT_FETCH_SUBMISSION,
                type: 'failure'
            })
        }
        finally {
            this.loading = false
        }
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            firstRoundAnswers: 'submissionStore/getFirstRoundAnswers',
            submissionId: 'submissionStore/getId',
        }), 

        filteredFirstRoundAnswers() {
            const answers = this.firstRoundAnswers

            const identifiers = Object.keys(answers)
            const active = identifiers.filter(function(id) {
                return answers[id]
            })

            return active
        }
    }, 

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification',
            getBasicDetails: 'submissionStore/getSubmissionBasicDetails',
            getEmploymentIncomeDetails: 'submissionStore/getSubmissionEmploymentIncomeDetails',
            getEtoroDetails: 'submissionStore/getSubmissionEtoroDetails',
            getSelfEmploymentIncomeDetails: 'submissionStore/getSubmissionSelfEmploymentIncomeDetails',
            getPropertyIncomeDetails: 'submissionStore/getSubmissionPropertyIncomeDetails',
            getPensionIncomeDetails: 'submissionStore/getSubmissionPensionIncomeDetails',
            getDividendIncomeDetails: 'submissionStore/getSubmissionDividendIncomeDetails',
            getCapitalGainsLossesDetails: 'submissionStore/getSubmissionCapitalGainsLossesDetails',
            getCapitalGainsDocuments: 'submissionStore/getSubmissionCapitalGainsDocuments',
            getForeignIncomeDetails: 'submissionStore/getSubmissionForeignIncomeDetails',
            getChildBenefitDetails: 'submissionStore/getSubmissionChildBenefitDetails',
            getOtherIncomeDetails: 'submissionStore/getSubmissionOtherIncomeDetails',
            getOtherTaxReliefDetails: 'submissionStore/getSubmissionOtherTaxReliefDetails',
            getGiftsDetails: 'submissionStore/getSubmissionGiftsDetails',
            getResidenceRemittanceDetails: 'submissionStore/getSubmissionResidenceRemittanceDetails',
            getScottishTaxpayerDetails: 'submissionStore/getSubmissionScottishTaxpayerDetails',
            getTaxAvoidanceDetails: 'submissionStore/getSubmissionTaxAvoidanceDetails',
            getDisguisedRemunerationAvoidanceDetails: 'submissionStore/getSubmissionDisguisedRemunerationAvoidanceDetails',
            getTaxLiabilityDetails: 'submissionStore/getSubmissionTaxLiabilityDetails',
            getJcpRefundsDetails: 'submissionStore/getSubmissionJcpRefundsDetails',
            getStudentLoansDetails: 'submissionStore/getSubmissionStudentLoansDetails',
            getMarriageAllowanceTransferDetails: 'submissionStore/getSubmissionMarriageAllowanceTransferDetails',
            getMarriageAllowanceReceiptDetails: 'submissionStore/getSubmissionMarriageAllowanceReceiptDetails',
            getPensionAnnuityContributionsDetails: 'submissionStore/getSubmissionPensionAnnuityContributionsDetails',
            getGiftAidDonationsDetails: 'submissionStore/getSubmissionGiftAidDonationsDetails',
            getAdditionalNotesDetails: 'submissionStore/getSubmissionAdditionalNotesDetails',
            setBasicDetails: 'sectionsCompleteStore/setBasicDetails',
            setEmploymentIncome: 'sectionsCompleteStore/setEmploymentIncome',
            setEtoroDetails: 'sectionsCompleteStore/setEtoroDetails',
            setSelfEmploymentIncome: 'sectionsCompleteStore/setSelfEmploymentIncome',
            setPropertyIncome: 'sectionsCompleteStore/setPropertyIncome',
            setPensionIncome: 'sectionsCompleteStore/setPensionIncome',
            setDividendIncome: 'sectionsCompleteStore/setDividendIncome',
            setCapitalGainsLosses: 'sectionsCompleteStore/setCapitalGainsLosses',
            setForeignIncome: 'sectionsCompleteStore/setForeignIncome',
            setChildBenefitIncome: 'sectionsCompleteStore/setChildBenefitIncome',
            setOtherIncome: 'sectionsCompleteStore/setOtherIncome',
            setOtherTaxRelief: 'sectionsCompleteStore/setOtherTaxRelief',
        }),

        async getSubmission() {
            this.promises = []

            this.promises.push(await this.getBasicDetails(this.submissionId))
            this.setBasicDetails(false)

            this.promises.push(await this.getGiftsDetails(this.submissionId))
            this.promises.push(await this.getResidenceRemittanceDetails(this.submissionId))
            this.promises.push(await this.getScottishTaxpayerDetails(this.submissionId))
            this.promises.push(await this.getTaxAvoidanceDetails(this.submissionId))
            this.promises.push(await this.getDisguisedRemunerationAvoidanceDetails(this.submissionId))
            this.promises.push(await this.getTaxLiabilityDetails(this.submissionId))
            this.promises.push(await this.getJcpRefundsDetails(this.submissionId))
            this.promises.push(await this.getStudentLoansDetails(this.submissionId))
            this.promises.push(await this.getMarriageAllowanceTransferDetails(this.submissionId))
            this.promises.push(await this.getMarriageAllowanceReceiptDetails(this.submissionId))
            this.promises.push(await this.getPensionAnnuityContributionsDetails(this.submissionId))
            this.promises.push(await this.getGiftAidDonationsDetails(this.submissionId))
            this.promises.push(await this.getAdditionalNotesDetails(this.submissionId))

            if (this.firstRoundAnswers.hasEmploymentIncome) {
                this.setEmploymentIncome(false)
                this.promises.push(await this.getEmploymentIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasEtoro) {
                this.setEtoroDetails(false)
                this.promises.push(await this.getEtoroDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.isSelfEmployed) {
                this.setSelfEmploymentIncome(false)
                this.promises.push(await this.getSelfEmploymentIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasPropertyIncome) {
                this.setPropertyIncome(false)
                this.promises.push(await this.getPropertyIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasPensionIncome) {
                this.setPensionIncome(false)
                this.promises.push(await this.getPensionIncomeDetails(this.submissionId))
            }
            
            if (this.firstRoundAnswers.hasDividendIncome) {
                this.setDividendIncome(false)
                this.promises.push(await this.getDividendIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasCapitalGainsAndLosses) {
                this.setCapitalGainsLosses(false)
                this.promises.push(await this.getCapitalGainsLossesDetails(this.submissionId))
                this.promises.push(await this.getCapitalGainsDocuments(this.submissionId))
            }

            if (this.firstRoundAnswers.hasForeignIncome) {
                this.setForeignIncome(false)
                this.promises.push(await this.getForeignIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasHigherIncomeChildBenefitCharge) {
                this.setChildBenefitIncome(false)
                this.promises.push(await this.getChildBenefitDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasAnyOtherIncome) {
                this.setOtherIncome(false)
                this.promises.push(await this.getOtherIncomeDetails(this.submissionId))
            }
            
            if (this.firstRoundAnswers.hasOtherTaxReliefs) {
                this.setOtherTaxRelief(false)
                this.promises.push(await this.getOtherTaxReliefDetails(this.submissionId))
            }
        },  

        addIncomeSource() {
            this.$modal.show(AddIncomeSourceModalComponent, {}, { width: '50%'})
        },

        moveToNextTab() {
            this.$scrollTo('.tabs-wrap', 800)
            this.tabIndex++
        },

        moveToPrevTab() {
            this.$scrollTo('.tabs-wrap', 800)
            this.tabIndex--
        }, 

        moveToApproval() {
            this.$router.push({name: 'Approval'})
        }
    }
}
</script>