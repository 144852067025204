import axios from 'axios'
export default {
    namespaced: true,

    state: {
        taxYears: [],
        selectedTaxYear: null,
    },

    getters: {
        getTaxYears(state) {
            return state.taxYears
        },

        getSelectedTaxYear(state) {
            return state.selectedTaxYear
        }
    },

    mutations: {
        PUSH_TAX_YEARS(state, data) {
            state.taxYears = data
        }
    }, 

    actions: {
        async getTaxYears({commit}) {
            try {
                let response = await axios.get('tax-years')
            
                commit('PUSH_TAX_YEARS', response.data)
            } catch {
                //
            }
        }
    }
}