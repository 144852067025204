<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4 class="mb-0">
                            Did you receive any foreign income?							
                        </h4>
                    </div>

                    <template
                        v-if="foreignIncomeDetails.length"
                    >
                        <foreign-income-item-component 
                            v-for="(item, index) in foreignIncomeDetails"
                            :key="index"
                            :item="item"
                            :index="index"
                            :failures="failures"
                        />
                    </template>
                </div>

                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div class="media">
                        <img src="@/assets/icons/questions/foreign.svg" width="60" height="60" class="mr-3" alt="add employment">
                        <div class="media-body">
                            <h5 class="mt-0">Add another foreign income</h5>
                            Would you like to add details of another foreign income?
                        </div>
                    </div>

                    <b-button variant="primary" class="mt-4 mt-md-0"
                        @click="createDefaultForeignIncomeItem()"
                    >
                        Add foreign income
                    </b-button>     
                </div>

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions,mapGetters} from 'vuex'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import ForeignIncomeItemComponent from '@/components/partials/ForeignIncomeItemComponent.vue'
import Notifications from '@/Notifications'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'ForeignIncomeComponent',

    components: {
        ForeignIncomeItemComponent,
        SaveAndContinueComponent
    },

    data() {
        return {
            failures: {},
            saving: false,
            ValidationErrorModalComponent
        }
    }, 

    mounted() {
        if(this.foreignIncomeDetails.length) this.markSectionAsComplete()
        if(!this.foreignIncomeDetails.length) this.createDefaultForeignIncomeItem()
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            foreignIncomeDetails: 'submissionStore/getForeignIncomeDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getForeignIncomeDetails: 'submissionStore/getSubmissionForeignIncomeDetails',
            setNotification: 'notificationsStore/setNotification',
            setForeignIncome: 'sectionsCompleteStore/setForeignIncome'
        }), 

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setForeignIncome(true)
        },

        createDefaultForeignIncomeItem() {
            let newForeignIncome = {
                employerName: null,
                payeReference: null,
                receivedAmount: null,
                taxDeductedAmount: null,
            }

            this.foreignIncomeDetails.push(newForeignIncome)

            setTimeout(() => {
                this.$scrollTo('#index'+(this.foreignIncomeDetails.length - 1), 800)
            }, 500)
        },

        async submit() {            
            this.userDetailsFailures = {}

            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true 

            let foreignIncomeModel = {
                submissionId: this.submissionId,
                foreignIncomes: JSON.parse(JSON.stringify(this.foreignIncomeDetails))
            }

            foreignIncomeModel.foreignIncomes.forEach(c => {
                c.receivedAmount = parseInt(c.receivedAmount * 100)
                c.taxDeductedAmount = parseInt(c.taxDeductedAmount * 100)
            })

            axios.post(`submission/${this.submissionId}/foreign-income-details`, foreignIncomeModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).catch((err) => {
                this.failures = err.data.failures
            }).finally(() => this.saving = false)
        }
    }
}
</script>