<template>
    <b-container fluid>
        <template
            v-if="loading"
        >
            <b-row class="justify-content-center">
                <b-col>
                    <div class="loader-container">
                        <div class="loader"></div>
                        
                        <p>
                            Please wait..
                        </p>
                    </div>
                </b-col>
            </b-row>
        </template>

        <template
            v-else
        >  
            <b-row>
                <b-col>
                    <div class="d-flex flex-column flex-lg-row justify-content-between align-items-center">
                        <h2
                            class="text-center mb-4 mb-lg-0"
                        >
                            Welcome back, {{ userFirstName }}!
                        </h2>  
                    </div> 
                </b-col>
            </b-row>

            <b-row class="mt-5">
                <b-col>
                    <ul class="list-unstyled d-flex flex-column flex-md-row justify-content-between">
                        <li>
                            <b-button variant="primary" block
                                @click="activeComponent = 'AllSubmissionsComponent'"
                                :class="{active : activeComponent == 'AllSubmissionsComponent'}"
                            >
                                All submissions <b-badge class="ml-1" variant="light">{{ counts.total }}</b-badge>
                            </b-button>
                        </li>

                        <li>
                            <b-button variant="primary" block
                                @click="activeComponent = 'MySubmissionsComponent'"
                                :class="{active : activeComponent == 'MySubmissionsComponent'}"
                            >
                              My claimed submissions <b-badge class="ml-1" variant="light">{{ counts.assigned }}</b-badge>
                            </b-button>
                        </li>

                        <li>
                            <b-button variant="primary" block
                                @click="activeComponent = 'OpenSubmissionsComponent'"
                                :class="{active : activeComponent == 'OpenSubmissionsComponent'}"
                            >
                                Open submissions <b-badge class="ml-1" variant="light">{{ counts.opened }}</b-badge>
                            </b-button>
                        </li>

                        <li>
                            <b-button variant="primary" block
                                @click="activeComponent = 'ClosedSubmissionsComponent'"
                                :class="{active : activeComponent == 'ClosedSubmissionsComponent'}"
                            >
                                Closed submissions <b-badge class="ml-1" variant="light">{{ counts.closed }}</b-badge>
                            </b-button>
                        </li>
                    </ul>
                </b-col>
            </b-row>

            <component :is="activeComponent" />
        </template>
    </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import AllSubmissionsComponent from '@/components/admin/AllSubmissionsComponent.vue'
import MySubmissionsComponent from '@/components/admin/MySubmissionsComponent.vue'
import OpenSubmissionsComponent from '@/components/admin/OpenSubmissionsComponent.vue'
import ClosedSubmissionsComponent from '@/components/admin/ClosedSubmissionsComponent.vue'

export default {
    name: 'Dashboard',

    components: {
        AllSubmissionsComponent,
        MySubmissionsComponent,
        OpenSubmissionsComponent,
        ClosedSubmissionsComponent
    },
    
    data() {
        return  {
            activeComponent: 'AllSubmissionsComponent',
            loading: true,
        }
    },

    mounted() {
        this.getCounts().then(() => {
            this.loading = false
        })
    },

    computed: {
        ...mapGetters({
            counts: 'adminStore/getCounts',
            userFullName: 'userStore/getUserFullName',
            userFirstName: 'userStore/getCapitalisedFirstName',
        })
    }, 

    methods: {
        ...mapActions({
            getCounts: 'adminStore/getCounts',
        })
    }
}
</script>