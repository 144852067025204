<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-9 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/child-benefit-charge.svg" width="30" height="30" /> Child Benefit Income
            </b-button>
        </b-card-header>
        
        <b-collapse visible id="accordion-9" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Supporting documentation</p>
                                    <p>Should your income be in excess of £50,000, any child benefit you or your partner receive will need to be included on your tax return. We will need to know the amount you have received along with how many children you have received it for.</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
export default {
    name: 'ChildBenefitIncomeWhatYouNeedComponent',

    data() {
        return {
            documentation: false
        }
    }
}
</script>