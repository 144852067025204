<template>
    <div>
        <div class="tab-content">
            <p class="text-danger text-right"
                v-if="!isSubmissionComplete && doesThisSubmissionHaveAnyIncomeSources"
            >
                <img src="@/assets/icons/red-error.svg" width="15" height="15" /> {{ Notifications.ENSURE_ALL_SECTIONS_ARE_COMPLETE }}
            </p>

            <p class="text-danger text-right"
                v-if="!doesThisSubmissionHaveAnyIncomeSources"
            >
                <img src="@/assets/icons/red-error.svg" width="15" height="15" /> {{ Notifications.ADD_AT_LEAST_ONE_INCOME_SOURCE }}
            </p>

            <div class="tab-border green-alert p-3 mb-5 d-flex flex-column flex-md-row align-items-center">
                <div>
                    <img src="@/assets/icons/additional-details-mglass.svg" width="90" />   
                </div>

                <div class="mx-0 mx-md-4 mt-4 mt-md-0">
                    <h4>Additional Details</h4>
                    <p>Information on this page is optional if you feel any of it is relevant to your submission please complete the details.</p>
                </div>
            </div>

            <div class="accordion gtd-accordion-approval-screen" role="tablist">     
                <div class="mt-3">
                    <additional-notes-component />
                </div> 

                <div class="mt-3">
                    <gifts-component />
                </div>

                <div class="mt-3">
                    <gift-aid-donations-component />
                </div>

                <div class="mt-3">
                    <residence-remittance-component/>
                </div>

                <div class="mt-3">
                    <scottish-taxpayer-component/>
                </div>

                <div class="mt-3">
                    <pension-annuity-contributions-component/>
                </div>

                <div class="mt-3">
                    <tax-avoidance-component/>
                </div>

                <div class="mt-3">
                    <disguised-remuneration-avoidance-component />
                </div>

                <div class="mt-3">
                    <tax-liability-component />
                </div>

                <div class="mt-3">
                    <jcp-refunds-component />
                </div>

                <div class="mt-3">
                    <student-loans-component />
                </div>

                <div class="mt-3">
                    <marriage-allowance-transfer-component />
                </div>

                <div class="mt-3">
                    <marriage-allowance-receipt-component />
                </div>
            </div>
        </div>

        <div class="fixed-bottom bg-white shadow pt-2 pt-md-3 pb-2 pb-md-3">
            <b-container fluid>
                <b-row class="justify-content-center">
                    <b-col md="11" class="d-flex flex-column flex-md-row justify-content-end align-items-center">
                        <div>
                            <p class="mb-0 mr-3 text-danger center-mobile"
                                v-if="!isSubmissionComplete && doesThisSubmissionHaveAnyIncomeSources"
                            >
                                <img src="@/assets/icons/red-error.svg" width="15" height="15" /> {{ Notifications.ENSURE_ALL_SECTIONS_ARE_COMPLETE }}
                            </p>

                            <p class="mb-0 mr-3 text-danger center-mobile"
                                v-if="!doesThisSubmissionHaveAnyIncomeSources"
                            >
                                <img src="@/assets/icons/red-error.svg" width="15" height="15" /> {{ Notifications.ADD_AT_LEAST_ONE_INCOME_SOURCE }}
                            </p>
                        </div> 
                        
                        <div>
                            <b-button type="submit" variant="success"
                                @click="next()"
                                :class="{disabled : !isSubmissionComplete}"
                                :disabled="!isSubmissionComplete"
                            >
                                Continue to approval
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Notifications from '@/Notifications'
import AdditionalNotesComponent from '@/components/round-two-question-components/AdditionalNotesComponent.vue'
import ResidenceRemittanceComponent from '@/components/round-two-question-components/ResidenceRemittanceComponent.vue'
import GiftsComponent from '@/components/round-two-question-components/GiftsComponent.vue'
import ScottishTaxpayerComponent from '@/components/round-two-question-components/ScottishTaxpayerComponent.vue'
import TaxAvoidanceComponent from '@/components/round-two-question-components/TaxAvoidanceComponent.vue'
import DisguisedRemunerationAvoidanceComponent from '@/components/round-two-question-components/DisguisedRemunerationAvoidanceComponent.vue'
import TaxLiabilityComponent from '@/components/round-two-question-components/TaxLiabilityComponent.vue'
import JcpRefundsComponent from '@/components/round-two-question-components/JcpRefundsComponent.vue'
import StudentLoansComponent from '@/components/round-two-question-components/StudentLoansComponent.vue'
import MarriageAllowanceTransferComponent from '@/components/round-two-question-components/MarriageAllowanceTransferComponent.vue'
import MarriageAllowanceReceiptComponent from '@/components/round-two-question-components/MarriageAllowanceReceiptComponent.vue'
import PensionAnnuityContributionsComponent from '@/components/round-two-question-components/PensionAnnuityContributionsComponent.vue'
import GiftAidDonationsComponent from '@/components/round-two-question-components/GiftAidDonationsComponent.vue'

export default {
    name: 'AdditionalDetailsComponent',

    components: {
        AdditionalNotesComponent,
        ResidenceRemittanceComponent,
        GiftsComponent,
        ScottishTaxpayerComponent,
        TaxAvoidanceComponent,
        DisguisedRemunerationAvoidanceComponent,
        TaxLiabilityComponent,
        JcpRefundsComponent,
        StudentLoansComponent,
        MarriageAllowanceTransferComponent,
        MarriageAllowanceReceiptComponent,
        PensionAnnuityContributionsComponent,
        GiftAidDonationsComponent
    }, 

    data() {
        return {
            Notifications
        }
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            answers: 'submissionStore/getFirstRoundAnswers'
        }),

        isSubmissionComplete() {            
            let sectionsArray = Object.keys(this.completedSubmissionSections).map(i => this.completedSubmissionSections[i])

            if(sectionsArray.indexOf(false) == -1) return true

            return false
        },

        doesThisSubmissionHaveAnyIncomeSources() {
            let answersArray = Object.keys(this.answers).map(i => this.answers[i])

            if(answersArray.includes(true)) return true

            return false
        }
    },

    methods: {
        ...mapActions({
            markInformationComplete: 'userProgressStore/setInformationComplete'
        }), 

        next() {
            this.markInformationComplete()
            this.$router.push({name: 'Approval'})
        }
    }
}
</script>