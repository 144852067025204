import axios from 'axios'
export default {
    namespaced: true,

    state: {
        discountOffers: [],
    },

    getters: {
        getDiscountOffers(state) {
            return state.discountOffers
        }
    },

    mutations: {
        PUSH_DISCOUNT_OFFERS(state, data) {
            state.discountOffers = data
        }
    }, 

    actions: {
        async getDiscountOffers({commit}) {
            try {
                let response = await axios.get('discount-offers')
            
                commit('PUSH_DISCOUNT_OFFERS', response.data)
            } catch(err) {
                throw Error(err.message)
            }
        }
    }
}