<template>
    <div id="app" :class="{'has-background' : $route.name == 'Login' || $route.name == 'Register' || $route.name == 'ForgotPassword' || $route.name == 'ResetPassword'}">
        <header-component/>

        <!-- Router View -->
        <main class="main py-4 py-md-5" role="main" id="main">
            <router-view/>
        </main>

        <notifications-component></notifications-component>
    </div>
</template>

<script>
import HeaderComponent from '@/components/site-structure-components/HeaderComponent.vue'
import NotificationsComponent from '@/components/site-structure-components/NotificationsComponent.vue'

export default {
    components: {
        HeaderComponent,
        NotificationsComponent
    },

    mounted() {
        console.log(process.env.VUE_APP_VERSION)
    }
}
</script>