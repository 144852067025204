<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-2 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/employment.svg" width="30" height="30" /> Employment Income
            </b-button>
        </b-card-header>
        
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">P60</p>
                                    <p>Issued by your employer at the end of the tax year, your P60 summaries all of your employment income and tax paid from your employment during the tax year, this is normally issued in April or May each year.</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">P45</p>
                                    <p>If you leave your job during the tax year, your former employer will issue you with a P45. Your P45 will show all of your income earned and tax paid during your time with that employer.</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">P11D</p>
                                    <p>Having a benefit in kind from your employer such as a company car or health insurance comes with some added tax implications. You may have already seen this in your tax code. The tax is calculated and submitted to HMRC through a P11d. Your employer will issue you with a copy each year, usually in June or July.</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import Helpers from '@/Helpers'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'

export default {
    name: 'EmploymentIncomeWhatYouNeedComponent',

    data() {
        return {
            documents: false,
            Helpers,
            HelperModalComponent
        }
    },

    methods: {
        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        }
    }
}
</script>