<template>
    <b-container>
        <template
            v-if="loading"
        >
            <div class="loader-container py-4">
                <div class="loader"></div>
            </div>
        </template>

        <template
            v-else
        >
            <b-row class="mb-5">
                <b-col>
                    <h2>
                        Application submission
                    </h2>
                    
                    <p class="lead"> 
                        Please double check that the information you have provided is correct before sending to your appointed tax return accountant for review.</p>        
                </b-col>    
            </b-row>

            <basic-details-review-component />

            <employment-income-details-review-component 
                v-if="showHasEmploymentIncome"
            />
            
            <self-employment-income-details-review-component 
                v-if="showIsSelfEmployed"
            />
        
            <property-income-details-review-component 
                v-if="showHasPropertyIncome"
            />
        
            <pension-income-details-review-component 
                v-if="showHasPensionIncome"
            />

            <etoro-details-review-component
                v-if="showHasEtoroDetails"
            />
        
            <dividend-income-details-review-component 
                v-if="showHasDividendIncome"
            />
        
            <interest-income-details-review-component 
                v-if="showHasDividendIncome"
            />

            <capital-gains-losses-details-review-component 
                v-if="showHasCapitalGainsAndLosses"
            />

            <foreign-income-details-review-component 
                v-if="showHasForeignIncome"
            />

            <child-benefit-details-review-component 
                v-if="showHasHigherIncomeChildBenefitCharge"
            />
        
            <other-income-details-review-component 
                v-if="showHasAnyOtherIncome"
            />
        
            <other-tax-relief-details-review-component 
                v-if="showHasOtherTaxReliefs"
            />

            <additional-notes-details-review-component />
        
            <gifts-details-review-component />

            <gift-aid-donations-details-review-component />

            <residence-remittance-details-review-component />

            <scottish-taxpayer-details-review-component />

            <tax-avoidance-details-review-component />

            <disguised-remuneration-avoidance-details-review-component />

            <tax-liability-details-review-component />

            <jcp-refunds-details-review-component />

            <student-loans-details-review-component />

            <marriage-allowance-transfer-details-review-component />

            <marriage-allowance-receipt-details-review-component />

            <pension-annuity-contributions-details-review-component />

            <div class="scroll-for-more" v-if="showScrollInstruction">
                <div class="media">
                    <img src="@/assets/icons/down-arrow.svg" width="30" class="mr-3" height="30" /> 
                    <div class="media-body">
                        Scroll down to review your application          
                    </div>
                </div>
            </div>

            <div class="fixed-bottom bg-white shadow py-3">
                <b-container>
                    <b-row>
                        <b-col class="d-flex justify-content-end align-items-center">
                            <b-button variant="success" class="cursor-pointer mt-2 mt-md-0 btn-block-on-mobile"
                                @click.prevent="continueToPayment()"
                            >
                                Submit my application
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </template>
    </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BasicDetailsReviewComponent from '@/components/review-components/BasicDetailsReviewComponent.vue'
import EmploymentIncomeDetailsReviewComponent from '@/components/review-components/EmploymentIncomeDetailsReviewComponent.vue'
import EtoroDetailsReviewComponent from '@/components/review-components/EtoroDetailsReviewComponent.vue'
import SelfEmploymentIncomeDetailsReviewComponent from '@/components/review-components/SelfEmploymentIncomeDetailsReviewComponent.vue'
import PropertyIncomeDetailsReviewComponent from '@/components/review-components/PropertyIncomeDetailsReviewComponent.vue'
import PensionIncomeDetailsReviewComponent from '@/components/review-components/PensionIncomeDetailsReviewComponent.vue'
import DividendIncomeDetailsReviewComponent from '@/components/review-components/DividendIncomeDetailsReviewComponent.vue'
import InterestIncomeDetailsReviewComponent from '@/components/review-components/InterestIncomeDetailsReviewComponent.vue'
import CapitalGainsLossesDetailsReviewComponent from '@/components/review-components/CapitalGainsLossesDetailsReviewComponent.vue'
import ForeignIncomeDetailsReviewComponent from '@/components/review-components/ForeignIncomeDetailsReviewComponent.vue'
import ChildBenefitDetailsReviewComponent from '@/components/review-components/ChildBenefitDetailsReviewComponent.vue'
import OtherIncomeDetailsReviewComponent from '@/components/review-components/OtherIncomeDetailsReviewComponent.vue'
import OtherTaxReliefDetailsReviewComponent from '@/components/review-components/OtherTaxReliefDetailsReviewComponent.vue'
import AdditionalNotesDetailsReviewComponent from '@/components/review-components/AdditionalNotesDetailsReviewComponent.vue'
import GiftsDetailsReviewComponent from '@/components/review-components/GiftsDetailsReviewComponent.vue'
import ResidenceRemittanceDetailsReviewComponent from '@/components/review-components/ResidenceRemittanceDetailsReviewComponent.vue'
import ScottishTaxpayerDetailsReviewComponent from '@/components/review-components/ScottishTaxpayerDetailsReviewComponent.vue'
import TaxAvoidanceDetailsReviewComponent from '@/components/review-components/TaxAvoidanceDetailsReviewComponent.vue'
import DisguisedRemunerationAvoidanceDetailsReviewComponent from '@/components/review-components/DisguisedRemunerationAvoidanceDetailsReviewComponent.vue'
import TaxLiabilityDetailsReviewComponent from '@/components/review-components/TaxLiabilityDetailsReviewComponent.vue'
import JcpRefundsDetailsReviewComponent from '@/components/review-components/JcpRefundsDetailsReviewComponent.vue'
import StudentLoansDetailsReviewComponent from '@/components/review-components/StudentLoansDetailsReviewComponent.vue'
import MarriageAllowanceTransferDetailsReviewComponent from '@/components/review-components/MarriageAllowanceTransferDetailsReviewComponent.vue'
import MarriageAllowanceReceiptDetailsReviewComponent from '@/components/review-components/MarriageAllowanceReceiptDetailsReviewComponent.vue'
import PensionAnnuityContributionsDetailsReviewComponent from '@/components/review-components/PensionAnnuityContributionsDetailsReviewComponent.vue'
import GiftAidDonationsDetailsReviewComponent from '@/components/review-components/GiftAidDonationsDetailsReviewComponent.vue'

export default {
    name: 'Approval',

    components: {
        BasicDetailsReviewComponent,
        EmploymentIncomeDetailsReviewComponent,
        EtoroDetailsReviewComponent,
        SelfEmploymentIncomeDetailsReviewComponent,
        PropertyIncomeDetailsReviewComponent,
        PensionIncomeDetailsReviewComponent,
        DividendIncomeDetailsReviewComponent,
        InterestIncomeDetailsReviewComponent,
        CapitalGainsLossesDetailsReviewComponent,
        ForeignIncomeDetailsReviewComponent,
        ChildBenefitDetailsReviewComponent,
        OtherIncomeDetailsReviewComponent,
        OtherTaxReliefDetailsReviewComponent,
        AdditionalNotesDetailsReviewComponent,
        GiftsDetailsReviewComponent,
        ResidenceRemittanceDetailsReviewComponent,
        ScottishTaxpayerDetailsReviewComponent,
        TaxAvoidanceDetailsReviewComponent,
        DisguisedRemunerationAvoidanceDetailsReviewComponent,
        TaxLiabilityDetailsReviewComponent,
        JcpRefundsDetailsReviewComponent,
        StudentLoansDetailsReviewComponent,
        MarriageAllowanceTransferDetailsReviewComponent,
        MarriageAllowanceReceiptDetailsReviewComponent,
        PensionAnnuityContributionsDetailsReviewComponent,
        GiftAidDonationsDetailsReviewComponent
    },

    data() {
        return {
            loading: true,
            showHasEmploymentIncome: false,
            showHasEtoroDetails: false,
            showIsSelfEmployed: false,
            showHasPropertyIncome: false,
            showHasPensionIncome: false,
            showHasDividendIncome: false,
            showHasCapitalGainsAndLosses: false,
            showHasForeignIncome: false,
            showHasHigherIncomeChildBenefitCharge: false,
            showHasAnyOtherIncome: false,
            showHasOtherTaxReliefs: false,
            showScrollInstruction: true,
            enteredDiscountCode: null
        }
    },

    mounted() {
        if(this.submissionId == null) return this.$router.push({name: 'MyAccount'})
        this.scroll()

        this.getSubmission(this.submissionId).then(() => {
            this.getSubmissionAnswers()
        }).finally(() => {
            this.loading = false
        })
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId',
            firstRoundAnswers: 'submissionStore/getFirstRoundAnswers'
        })
    },

    methods: {
        ...mapActions({
            getSubmission: 'submissionStore/getSubmission',
            getBasicDetails: 'submissionStore/getSubmissionBasicDetails',
            getEmploymentIncomeDetails: 'submissionStore/getSubmissionEmploymentIncomeDetails',
            getEtoroDetails: 'submissionStore/getSubmissionEtoroDetails',
            getSelfEmploymentIncomeDetails: 'submissionStore/getSubmissionSelfEmploymentIncomeDetails',
            getPropertyIncomeDetails: 'submissionStore/getSubmissionPropertyIncomeDetails',
            getPensionIncomeDetails: 'submissionStore/getSubmissionPensionIncomeDetails',
            getDividendIncomeDetails: 'submissionStore/getSubmissionDividendIncomeDetails',
            getCapitalGainsLossesDetails: 'submissionStore/getSubmissionCapitalGainsLossesDetails',
            getForeignIncomeDetails: 'submissionStore/getSubmissionForeignIncomeDetails',
            getChildBenefitDetails: 'submissionStore/getSubmissionChildBenefitDetails',
            getOtherIncomeDetails: 'submissionStore/getSubmissionOtherIncomeDetails',
            getOtherTaxReliefDetails: 'submissionStore/getSubmissionOtherTaxReliefDetails',
            getAdditionalNotesDetails: 'submissionStore/getSubmissionAdditionalNotesDetails',
            getGiftsDetails: 'submissionStore/getSubmissionGiftsDetails',
            getResidenceRemittanceDetails: 'submissionStore/getSubmissionResidenceRemittanceDetails',
            getScottishTaxpayerDetails: 'submissionStore/getSubmissionScottishTaxpayerDetails',
            getTaxAvoidanceDetails: 'submissionStore/getSubmissionTaxAvoidanceDetails',
            getDisguisedRemunerationAvoidanceDetails: 'submissionStore/getSubmissionDisguisedRemunerationAvoidanceDetails',
            getTaxLiabilityDetails: 'submissionStore/getSubmissionTaxLiabilityDetails',
            getJcpRefundsDetails: 'submissionStore/getSubmissionJcpRefundsDetails',
            getStudentLoansDetails: 'submissionStore/getSubmissionStudentLoansDetails',
            getMarriageAllowanceTransferDetails: 'submissionStore/getSubmissionMarriageAllowanceTransferDetails',
            getMarriageAllowanceReceiptDetails: 'submissionStore/getSubmissionMarriageAllowanceReceiptDetails',
            getPensionAnnuityContributionsDetails: 'submissionStore/getSubmissionPensionAnnuityContributionsDetails',
            getGiftAidDonationsDetails: 'submissionStore/getSubmissionGiftAidDonationsDetails',
            getDiscountOffers: 'discountOffersStore/getDiscountOffers',
            setNotification: 'notificationsStore/setNotification',
        }),

        scroll () {
            window.onscroll = () => {
                if (window.pageYOffset > 300) {
                    this.showScrollInstruction = false
                }
            }
        },

        async continueToPayment() {            
            return this.$router.push({name: 'Quote'})
        },

       async getSubmissionAnswers() {
            let promises = []

            promises.push(await this.getBasicDetails(this.submissionId))
            promises.push(await this.getAdditionalNotesDetails(this.submissionId))
            promises.push(await this.getGiftsDetails(this.submissionId))
            promises.push(await this.getResidenceRemittanceDetails(this.submissionId))
            promises.push(await this.getScottishTaxpayerDetails(this.submissionId))
            promises.push(await this.getTaxAvoidanceDetails(this.submissionId))
            promises.push(await this.getDisguisedRemunerationAvoidanceDetails(this.submissionId))
            promises.push(await this.getTaxLiabilityDetails(this.submissionId))
            promises.push(await this.getJcpRefundsDetails(this.submissionId))
            promises.push(await this.getStudentLoansDetails(this.submissionId))
            promises.push(await this.getMarriageAllowanceTransferDetails(this.submissionId))
            promises.push(await this.getMarriageAllowanceReceiptDetails(this.submissionId))
            promises.push(await this.getPensionAnnuityContributionsDetails(this.submissionId))
            promises.push(await this.getGiftAidDonationsDetails(this.submissionId))

            if (this.firstRoundAnswers.hasEmploymentIncome) {
                this.showHasEmploymentIncome = true
                promises.push(await this.getEmploymentIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasEtoro) {
                this.showHasEtoroDetails = true
                promises.push(await this.getEtoroDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.isSelfEmployed) {
                this.showIsSelfEmployed = true
                promises.push(await this.getSelfEmploymentIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasPropertyIncome) {
                this.showHasPropertyIncome = true
                promises.push(await this.getPropertyIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasPensionIncome) {
                this.showHasPensionIncome = true
                promises.push(await this.getPensionIncomeDetails(this.submissionId))
            }
            
            if (this.firstRoundAnswers.hasDividendIncome) {
                this.showHasDividendIncome = true
                promises.push(await this.getDividendIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasCapitalGainsAndLosses) {
                this.showHasCapitalGainsAndLosses = true
                promises.push(await this.getCapitalGainsLossesDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasForeignIncome) {
                this.showHasForeignIncome = true
                promises.push(await this.getForeignIncomeDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasHigherIncomeChildBenefitCharge) {
                this.showHasHigherIncomeChildBenefitCharge = true
                promises.push(await this.getChildBenefitDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.hasAnyOtherIncome) {
                this.showHasAnyOtherIncome = true
                promises.push(await this.getOtherIncomeDetails(this.submissionId))
            }
            
            if (this.firstRoundAnswers.hasOtherTaxReliefs) {
                this.showHasOtherTaxReliefs = true
                promises.push(await this.getOtherTaxReliefDetails(this.submissionId))
            }

            return Promise.all(promises)
        },  
    }
}
</script>
