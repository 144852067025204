<template>
    <div>
        <ValidationObserver ref="observer">
            <ValidationProvider ref="field" name="Etoro Document" v-slot="{ errors }">
                <input 
                    ref="imageUpload" 
                    style="display: none" 
                    type="file"
                    @change="handleUpload()"
                >

                <div class="bg-white d-flex flex-column flex-md-row align-items-center justify-content-between border p-4">
                    <div class="d-flex">
                        <div>
                            <img width="60" src="@/assets/icons/statement.svg" />
                        </div>

                        <div class="ml-4">
                            <h5 class="mb-0">
                                Document upload (optional)
                            </h5>

                            <p class="mb-0 color-primary text-sm font-weight-bold">
                                Accepted document types are jpg, jpeg, png, pdf, doc & docx.
                            </p>
                        </div>
                    </div>

                    <div class="mt-4 mt-md-0 ml-4">
                        <b-button block variant="primary" class="mt-2 mt-md-0"
                            v-if="hasBeenUploaded || item.etoroStatementDocumentId != null"
                            @click="deleteUpload()"
                        >
                            Remove
                        </b-button>

                        <b-button block variant="primary"
                            v-else
                            @click="uploadFile()"
                        >
                            <img src="@/assets/icons/upload.svg" width="15" height="15" /> Upload
                        </b-button>

                        <p class="text-success text-sm mb-0 mt-2 d-flex align-items-center"
                            v-if="hasBeenUploaded || item.etoroStatementDocumentId != null"
                        >
                            <img class="mr-1" src="@/assets/icons/green-tick.svg" width="15" height="15" /> You have uploaded a document
                        </p>
                    </div>
                </div>
                
                <small v-if="errors.length" class="text-danger validation-error d-block">
                    {{ errors[0] }}
                </small>
            </ValidationProvider>
        </ValidationObserver>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import UploadFileModalComponent from '@/components/modal-components/UploadFileModalComponent.vue'
import Notifications from '@/Notifications'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'EtoroStatementComponent',

    props: {
        item: Object,
    },

    mounted() {
        this.syncInput()
    },

    data() {
        return {
            UploadFileModalComponent, 
            HelperModalComponent,
            Helpers,
            file: null,
            fieldHasErrors: false,
            hasBeenUploaded: false,
        }
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId'
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'
        }),

        syncInput() {
            if(this.item.etoroStatementDocumentId != null) {
                this.$refs.field.syncValue(this.item.etoroStatementDocumentId)
            }
        },

        uploadFile() {
            this.$refs.imageUpload.click()
        }, 

        handleUpload() {
            this.fieldHasErrors = false
            this.file = this.$refs.imageUpload.files[0]

            this.validate().then(() => {
                if(this.fieldHasErrors == false) {
                    var formData = new FormData();
                    formData.append('file', this.file, this.file.name)

                    this.$modal.show(this.UploadFileModalComponent)

                    axios.post(`submissions/${this.submissionId}/documents`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response) => {
                        this.item.etoroStatementDocumentId = response.data.documentId
                        this.hasBeenUploaded = true

                        this.setNotification({
                            message: Notifications.FILE_UPLOAD_SUCCESS,
                            type: 'success'
                        })
                    }).catch(() => {
                        this.setNotification({
                            message: Notifications.FILE_UPLOAD_FAILURE,
                            type: 'failure'
                        })
                    }).finally(() => this.$modal.hideAll())
                }
            })
        }, 

        deleteUpload() {
            this.file = null
            this.$refs.imageUpload.value = null
            this.item.etoroStatementDocumentId = null
            this.hasBeenUploaded = false

            this.validate()
        },

        async validate() {
            let errors = await this.$refs.field.validate(this.file)
            
            if(!errors.valid) {
                this.fieldHasErrors = true
            } 
        }
    },
}
</script>