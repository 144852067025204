<template>
    <div>
        <div class="header no-print py-2"
            :class="{'bg-transparent' : this.$route.name == 'Login' || this.$route.name == 'Register' || this.$route.name == 'ForgotPassword' || this.$route.name == 'ResetPassword'}"
        >
            <b-container fluid>
                <b-row class="justify-content-between align-items-center">
                    <b-col xs="3">
                        <router-link :to="{name: 'MyAccount'}"
                            v-if="authenticated"
                        >
                            <img width="60" class="img-fluid" src="@/assets/images/logos/logo.svg" alt="Get Tax Done">
                        </router-link>

                        <img v-else width="80" class="img-fluid" src="@/assets/images/logos/logo.svg" alt="Get Tax Done">
                    </b-col>

                    <b-col xs="6" class="d-none d-lg-block"
                        v-if="!$route.meta.hideNavigation || !$route.meta.hideListsInNavigation"
                    >
                        <ol class="reasons-list d-flex justify-content-center"
                            v-if="$route.meta.requiresFirstListInHeader"
                        >
                            <li
                                :class="{
                                    active : this.$route.name == 'TaxYear',
                                    completed: isTaxYearComplete
                                }"
                            >
                                Tax Year
                            </li>
                            
                            <li
                                :class="{
                                    active : this.$route.name == 'Reasons',
                                    completed: isReasonsComplete
                                }"
                            >
                                Reason
                            </li>                     
        
                            <li
                                :class="{
                                    active : this.$route.name == 'Questions',
                                    completed: isQuestionsComplete
                                }"
                            >
                                <template
                                    v-if="isTaxYearComplete"
                                >
                                    <router-link class="follow" :to="{name: 'Questions'}"
                                    >
                                        Income Sources
                                    </router-link>
                                </template>

                                <template
                                    v-else
                                >
                                    Questions
                                </template>
                            </li>
                        </ol>

                        <ol start="4" class="reasons-list d-flex justify-content-center"
                            v-if="$route.meta.requiresSecondListInHeader"
                        >
                            <li
                                :class="{
                                    active : this.$route.name == 'Checklist',
                                    completed: isAccountantComplete
                                }"
                            >
                                <router-link :to="{name: 'Checklist'}">
                                    Checklist
                                </router-link> 
                            </li>
        
                            <li
                                @click="markAccountantComplete()"
                                :class="{
                                    active : this.$route.name == 'Information',
                                    completed: isInformationComplete
                                }"
                            >
                                <router-link 
                                    :to="{name: 'Information'}"
                                >
                                    Information
                                </router-link>
                            </li>
        
                            <li
                                :class="{active : this.$route.name == 'Approval'}"
                            >
                                <template
                                    v-if="isInformationComplete"
                                >
                                    <router-link :to="{name: 'Approval'}"
                                    >
                                        Submit
                                    </router-link>
                                </template>

                                <template
                                    v-else
                                >
                                    Submit
                                </template>
                            </li>
                        </ol>
                    </b-col>

                    <b-col xs="3" class="text-right"
                        v-if="!$route.meta.hideNavigation"
                    >
                        <b-dropdown id="dropdown-right" variant="secondary" right class="m-md-2 header-dropdown"
                            v-if="authenticated && Object.keys(user).length != 0 && !$route.meta.showAdminNavigation"
                        >
                            <template #button-content>
                                My Account
                            </template>

                            <b-dropdown-item
                                :to="{name: 'MyAccount'}"
                            >
                                My Account
                            </b-dropdown-item>

                            <b-dropdown-item
                                @click="signout()"
                            >
                                Signout
                            </b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown id="dropdown-right" variant="secondary" right class="m-md-2 header-dropdown"
                            v-if="authenticated && Object.keys(user).length != 0 && $route.meta.showAdminNavigation"
                        >
                            <template #button-content>
                                {{ user.given_name }}
                            </template>

                            <b-dropdown-item
                                v-if="!isUserEtoroAdmin"
                                :to="{name: 'Dashboard'}"
                            >
                                Dashboard
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-if="!isUserEtoroAdmin"
                                :to="{name: 'CreateUser'}"
                            >
                                Create User
                            </b-dropdown-item>

                            <b-dropdown-item
                                :to="{name: 'Reports'}"
                            >
                                Reports
                            </b-dropdown-item>

                            <b-dropdown-item
                                @click="signout()"
                            >
                                Signout
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        
        <current-tax-year-component 
            v-if="$route.meta.showCurrentTaxYear"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CurrentTaxYearComponent from '@/components/site-structure-components/CurrentTaxYearComponent'

export default {
    name: 'HeaderComponent',

    components: {
        CurrentTaxYearComponent
    },

    computed: {
        ...mapGetters({
            authenticated: 'userStore/authenticated',
            user: 'userStore/getUser',
            isUserEtoroAdmin: 'userStore/getIsUserEtoroAdmin',
            isReasonsComplete: 'userProgressStore/getIsReasonsComplete',
            isTaxYearComplete: 'userProgressStore/getIsTaxYearComplete',
            isQuestionsComplete: 'userProgressStore/getIsQuestionsComplete',
            isAccountantComplete: 'userProgressStore/getIsAccountantComplete',
            isInformationComplete: 'userProgressStore/getIsInformationComplete',
        })
    },

    methods: {
        ...mapActions({
            signoutAction: 'userStore/signout',
            markAccountantComplete: 'userProgressStore/setAccountantComplete'
        }), 

        signout() {
            this.signoutAction()
        }
    }
}
</script>