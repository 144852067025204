<template>  
    <div class="inner-modal">
        <h2>
            Remove income source
        </h2>

        <p>
            Please confirm that you would like to remove the following income source from your tax return:
        </p>

        <p class="lead font-weight-bold">
            {{ getReadableComponentName() }}
        </p>

        <div class="d-flex mt-5">
            <button :disabled="loading" @click="$emit('close')" class="btn btn-grey" :class="{disabled : loading}">Cancel</button>
            <button :disabled="loading" @click="confirmRemove()" class="btn btn-success ml-3" :class="{disabled : loading}">Confirm</button>
        </div>
    </div>
</template >

<script>
import {mapGetters, mapActions} from 'vuex'
import Notifications from '@/Notifications'
export default {
    name: 'RemoveIncomeSourceModalComponent',

    props: {
        componentName: String
    },

    data() {
        return {
            loading: false
        }
    },

    computed: {
        ...mapGetters({
            answers: 'submissionStore/getFirstRoundAnswers',
            reasons: 'submissionStore/getReasons',
            selectedTaxYear: 'submissionStore/getSelectedTaxYearId',
            submissionId: 'submissionStore/getId',
            numberOfProperties: 'submissionStore/getNumberOfProperties'
        }),
    },

    methods: {
        ...mapActions({
            createSubmission: 'submissionStore/createSubmission',
            setNotification: 'notificationsStore/setNotification',
            updateFieldResolverKey: 'submissionStore/updateFieldResolverKey',
            resetSectionsCompleteStore: 'sectionsCompleteStore/clear'
        }),

        getReadableComponentName() {
            if(this.componentName == 'EmploymentIncomeComponent') return 'Employment Income'
            if(this.componentName == 'SelfEmploymentIncomeComponent') return 'Self Employment Income'
            if(this.componentName == 'PropertyIncomeComponent') return 'Property Income'
            if(this.componentName == 'PensionIncomeComponent') return 'Pension Income'
            if(this.componentName == 'EtoroDetailsComponent') return 'Etoro Details'
            if(this.componentName == 'DividendIncomeComponent') return 'Dividend & Interest Income'
            if(this.componentName == 'CapitalGainsLossesComponent') return 'Capital Gains & Losses'
            if(this.componentName == 'ForeignIncomeComponent') return 'Foreign Income'
            if(this.componentName == 'ChildBenefitComponent') return 'Child Benefit'
            if(this.componentName == 'OtherIncomeComponent') return 'Other Income'
            if(this.componentName == 'OtherTaxReliefsComponent') return 'Other Tax Reliefs'
        },

        async confirmRemove() {
            this.loading = true 

            let submissionModel = {
                taxYearId: this.selectedTaxYear,
                hasEmploymentIncome: (this.componentName == 'EmploymentIncomeComponent') ? false : this.answers.hasEmploymentIncome,
                isSelfEmployed: (this.componentName == 'SelfEmploymentIncomeComponent') ? false : this.answers.isSelfEmployed,
                hasPropertyIncome: (this.componentName == 'PropertyIncomeComponent') ? false : this.answers.hasPropertyIncome,
                numberOfProperties: (this.componentName == 'PropertyIncomeComponent') ? 0 : this.numberOfProperties,
                hasPensionIncome: (this.componentName == 'PensionIncomeComponent') ? false : this.answers.hasPensionIncome,
                hasDividendIncome: (this.componentName == 'DividendIncomeComponent') ? false : this.answers.hasDividendIncome,
                hasCapitalGainsAndLosses: (this.componentName == 'CapitalGainsLossesComponent') ? false : this.answers.hasCapitalGainsAndLosses,
                hasForeignIncome: (this.componentName == 'ForeignIncomeComponent') ? false : this.answers.hasForeignIncome,
                hasHigherIncomeChildBenefitCharge: (this.componentName == 'ChildBenefitComponent') ? false : this.answers.hasHigherIncomeChildBenefitCharge,
                hasAnyOtherIncome: (this.componentName == 'OtherIncomeComponent') ? false : this.answers.hasAnyOtherIncome,
                hasOtherTaxReliefs: (this.componentName == 'OtherTaxReliefsComponent') ? false : this.answers.hasOtherTaxReliefs,
                hasEtoro: (this.componentName == 'EtoroDetailsComponent') ? false : this.answers.hasEtoro,
                isHMRCInstructedReason: this.reasons.isHMRCInstructedReason,
                isLandlordReason: this.reasons.isLandlordReason,
                isSelfEmployedReason: this.reasons.isSelfEmployedReason,
                isFirstTimeReason: this.reasons.isFirstTimeReason,
                isCompanyDirectorReason: this.reasons.isCompanyDirectorReason,
                isCISContractorReason: this.reasons.isCISContractorReason,
                isInvestorReason: this.reasons.isInvestorReason,
                isHighEarnerReason: this.reasons.isHighEarnerReason,
                isOtherReason: this.reasons.isOtherReason
            }
            
            try {
                await this.createSubmission(submissionModel)
                this.resetSectionsCompleteStore()
                this.updateFieldResolverKey()
                this.$emit('close')
            }
            catch {
                this.setNotification({
                    message: Notifications.GENERAL_ERROR,
                    type: 'failure'
                })
            }
            finally {
                this.loading = false
                this.$emit('close')
            }
        }
    }
}
</script>