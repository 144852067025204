<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col lg="5">
        <b-card no-body class="p-0 shadow">
          <div class="p-5">
            <h3>Create an account</h3>

            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(onSubmit)" class="mt-4" id="registration-form">
                  <b-row>
                    <b-col lg="6">
                      <ValidationProvider name="First Name" :rules="{ required: true, regex: /^[A-Za-z \-']+$/ }" v-slot="{ errors }">
                        <b-form-group
                          label-for="firstName"
                          label="First Name"
                        >
                          <b-form-input
                            name="firstName"
                            :class="{'is-invalid' : failures && failures.FirstName || errors.length}"
                            v-model="fields.firstName"
                            type="text"
                            placeholder="First Name"
                          ></b-form-input>

                          <small v-if="errors.length" class="text-danger validation-error d-block">
                              {{ errors[0] }}
                          </small>

                          <small class="text-danger validation-error d-block"
                            v-if="failures && failures.FirstName"
                          >
                            {{ failures.FirstName[0] }}
                          </small>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col lg="6">
                      <ValidationProvider name="Last Name" :rules="{ required: true, regex: /^[A-Za-z \-']+$/ }" v-slot="{ errors }">
                        <b-form-group
                          label-for="lastName"
                          label="Surname"
                        >
                          <b-form-input
                            name="lastName"
                            :class="{'is-invalid' : failures && failures.LastName || errors.length}"
                            v-model="fields.lastName"
                            type="text"
                            placeholder="Last Name"
                          ></b-form-input>
                          
                          <small v-if="errors.length" class="text-danger validation-error d-block">
                              {{ errors[0] }}
                          </small>

                          <small class="text-danger validation-error d-block"
                            v-if="failures && failures.LastName"
                          >
                            {{ failures.LastName[0] }}
                          </small>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>

                  <ValidationProvider name="Phone Number" rules="required|numeric" v-slot="{ errors }">
                    <b-form-group
                      label-for="phoneNumber"
                      label="Phone Number"
                    >
                      <b-form-input
                        name="phoneNumber"
                        :class="{'is-invalid' : failures && failures.Telephone || errors.length}"
                        v-model="fields.telephone"
                        type="text"
                        placeholder="Phone Number"
                      ></b-form-input>
                      
                      <small v-if="errors.length" class="text-danger validation-error d-block">
                          {{ errors[0] }}
                      </small>

                      <small class="text-danger validation-error d-block"
                        v-if="failures && failures.Telephone"
                      >
                        {{ failures.Telephone[0] }}
                      </small>
                    </b-form-group>
                  </ValidationProvider>
            
                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                    <b-form-group 
                        label-for="email"
                        label="Email Address"
                    >
                      <b-form-input
                        name="email"
                        :class="{'is-invalid' : failures && failures.EmailAddress || errors.length}"
                        v-model="fields.emailAddress"
                        type="email"
                        placeholder="Email"
                      ></b-form-input>
                      
                      <small v-if="errors.length" class="text-danger validation-error d-block">
                          {{ errors[0] }}
                      </small>

                      <small class="text-danger validation-error d-block"
                        v-if="failures && failures.Emailaddress"
                      >
                        {{ failures.Emailaddress[0] }}
                      </small>
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                    <b-form-group 
                        label-for="password"
                        label="Password"
                    >
                      <b-form-input
                        name="password"
                        :class="{'is-invalid' : failures && failures.Password || errors.length}"
                        v-model="fields.password"
                        type="password"
                        placeholder="Password"
                      ></b-form-input>

                      <small v-if="errors.length" class="text-danger validation-error d-block">
                          {{ errors[0] }}
                      </small>

                      <small class="text-danger validation-error d-block"
                        v-if="failures && failures.Password"
                      >
                        {{ failures.Password[0] }}
                      </small>
                    </b-form-group>
                  </ValidationProvider>

                  <b-form-group>
                    <b-form-checkbox class="form-agree"
                      v-model="status"
                    >
                      I agree that I have read and understand the <a title="Terms & Conds" target="_blank" href="https://gettaxdone.co.uk/terms-and-conditions/">Terms and Conditions</a> and <a title="Privacy Policy" target="_blank" href="https://gettaxdone.co.uk/privacy-policy/">Privacy Policy</a>
                    </b-form-checkbox>
                  </b-form-group>
            
                  <b-button type="submit" variant="primary" block :disabled="!status" class="register-button">
                    Continue <span v-if="loading" class="btn-loader"></span>
                  </b-button>

                  <template
                      v-if="errorMessage != ''"
                  >
                    <b-alert class="mt-4" variant="danger" show dismissible>
                        {{ Notifications.USER_ALREADY_EXISTS }}
                    </b-alert>
                  </template>
              </b-form>
            </ValidationObserver>
          </div>
        </b-card>

        <p class="text-white font-weight-bold mt-4 text-center">Already have an account? <router-link class="follow underlined" :to="{name: 'Login'}">Login</router-link></p>
      </b-col>

      <b-col lg="5">
        <b-card class="carousel pb-4">
          <VueSlickCarousel :arrows="false" :dots="true">
            <div>
              <span class="lead color-star">&starf; &starf; &starf; &starf; &starf;</span>
              <h4>Easy and not expensive</h4>
              <p>"I used Get Tax Done to complete my tax return just before the deadline. It was really easy to use and the tax return was completed within 2 days. They also charged me less than what I was quoted everywhere else. I will be using them every year from now on."</p>
              <p class="mt-3 mb-0">- Richard</p>
            </div>
          </VueSlickCarousel>
        </b-card>
      
        <b-card class="mt-4">
          <h4 class="color-primary">
            Next Steps
          </h4>

          <ol class="mt-4">
            <li>
                Start a new tax return
            </li>

            <li>
                Choose a tax year for your return
            </li>

            <li>
                Find out what documents &amp; info you’ll need to upload
            </li>

            <li>
                Upload your information
            </li>

            <li>
                Submit your application and get paired with an accountant
            </li>

            <li>
                Your accountant prepares &amp; submits your return
            </li>
          </ol>
        </b-card>
      </b-col>
    </b-row> 
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import Notifications from '@/Notifications'
import VueSlickCarousel from 'vue-slick-carousel'
import CookieMixin from '@/mixins/CookieMixin'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'Register', 

    components: {
       VueSlickCarousel 
    },

    mixins: [
      CookieMixin
    ],

    data() {
        return {
            fields: {},
            failures: {},
            errorMessage: '',
            loading: false,
            status: false,
            Notifications,
        }
    },

    watch: {
        isAuthenticated() {
            if(this.isAuthenticated && this.isCustomer) {
                this.$router.push({name: 'MyAccount', query: { registered: 'true' }})
            }
        }
    },

    computed: {
      ...mapGetters({
        isAuthenticated: 'userStore/authenticated',
        isCustomer: 'userStore/getIsUserCustomer',
      })
    },  

    methods: {
        ...mapActions({
          getUser: 'userStore/getUser'
        }),

        onSubmit() {
          this.register()
        },

        register() {
            this.loading = true
            this.errorMessage = ''
            this.failures = {}

            // create params object
            let valuesToSend = {}

            // check for shared cookie with get tax done website
            var cookie = this.checkCookie("site_params")

            // if no cookie, check URL for params
            if (!cookie) {
              if(this.$route.query.adposition) {
                valuesToSend['ads_adposition'] = this.$route.query.adposition
              }

              if(this.$route.query.utm_campaign) {
                valuesToSend['ads_campaign'] = this.$route.query.utm_campaign
              }

              if(this.$route.query.utm_content) {
                valuesToSend['ads_content'] = this.$route.query.utm_content
              }

              if(this.$route.query.device) {
                valuesToSend['ads_device'] = this.$route.query.device
              }

              if(this.$route.query.gclid) {
                valuesToSend['ads_gclid'] = this.$route.query.gclid
              }

              if(this.$route.query.keyword) {
                valuesToSend['ads_keyword'] = this.$route.query.keyword
              }

              if(this.$route.query.matchtype) {
                valuesToSend['ads_matchtype'] = this.$route.query.matchtype
              }

              if(this.$route.query.utm_medium) {
                valuesToSend['ads_medium'] = this.$route.query.utm_medium
              }

              if(this.$route.query.network) {
                valuesToSend['ads_network'] = this.$route.query.network
              }

              if(this.$route.query.utm_source) {
                valuesToSend['ads_source'] = this.$route.query.utm_source
              }
            } else {
              //cookie exists, check for values
              var cookieValues = this.getCookie('site_params')
              var parsedValues = JSON.parse('{"' + decodeURI(cookieValues).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')

              if(parsedValues.adposition) {
                valuesToSend.ads_adposition = parsedValues.adposition;
              }
              
              if(parsedValues.utm_campaign) {
                valuesToSend.ads_campaign = parsedValues.utm_campaign;
              }

              if(parsedValues.utm_content) {
                valuesToSend.ads_content = parsedValues.utm_content;
              }

              if(parsedValues.device) {
                valuesToSend.ads_device = parsedValues.device;
              }

              if(parsedValues.gclid) {
                valuesToSend.ads_gclid = parsedValues.gclid;
              }

              if(parsedValues.keyword) {
                valuesToSend.ads_keyword = parsedValues.keyword;
              }

              if(parsedValues.matchtype) {
                valuesToSend.ads_matchtype = parsedValues.matchtype;
              }

              if(parsedValues.utm_medium) {
                valuesToSend.ads_medium = parsedValues.utm_medium;
              }

              if(parsedValues.network) {
                valuesToSend.ads_network = parsedValues.network;
              }

              if(parsedValues.utm_source) {
                valuesToSend.ads_source = parsedValues.utm_source;
              }

              if(parsedValues.discountCode) {
                valuesToSend.discountCode = parsedValues.discountCode;
              }
            }

            let registrationModel = {
              firstName: this.fields.firstName,
              lastName: this.fields.lastName,
              emailAddress: this.fields.emailAddress,
              telephone: this.fields.telephone,
              password: this.fields.password,
              confirmPassword: this.fields.password,
              additionalFields: valuesToSend
            }

            axios.post('authentication/register', registrationModel).then((response) => {                
                this.getUser(response.data.accessToken)
            }).catch((err) => {
                this.failures = err.data.failures

                if(err.status == 403) this.errorMessage = err.data.message
            }).finally(() => this.loading = false)
        }
    }
}
</script>