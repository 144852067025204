<template>
    <div class="inner-modal">
        <h2>
            Restart a submission
        </h2>

        <p>
            By restarting a submission, all selections, data entered and documents uploaded will be removed. Any progress will be lost.
        </p>

        <div class="d-flex mt-5">
            <button @click="$emit('close')" class="btn btn-grey">Cancel</button>
            <button @click="confirmRestart()" class="btn btn-success ml-3">Restart</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RestartSubmissionModalComponent',

    props: {
        submissionId: Number
    },

    methods: {
        confirmRestart() {
            this.$emit('close')
            this.$router.push({name: 'Questions', params: {submissionId: this.submissionId} })
        }
    }
}
</script>