<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4 class="mb-0">
                            Please tell us more about your child benefit			
                        </h4>
                    </div>

                    <div class="bg-white p-4"
                        v-if="Object.keys(childBenefitDetails).length != 0"
                    >
                        <b-row>
                            <b-col md="6">
                                <ValidationProvider name="Number Of Children" rules="min_value:1|numeric|required" v-slot="{ errors }">
                                    <b-form-group
                                        label="Number of children"
                                        label-for="numberOfChildren"
                                        label-class="required"
                                    >
                                        <b-form-input
                                            name="numberOfChildren"
                                            v-model.number="childBenefitDetails.numberOfChildren"
                                            type="number"
                                            placeholder="Number of children"
                                            :class="{'is-valid' : childBenefitDetails.numberOfChildren, 'is-invalid' : errors.length}"
                                        >
                                        </b-form-input>
                                        
                                        <small v-if="errors.length" class="text-danger validation-error d-block">
                                            {{ errors[0] }}
                                        </small>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col md="6">
                                <ValidationProvider name="Received Amount" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                    <b-form-group
                                        label="Received Amount"
                                        label-for="receivedAmount"
                                        label-class="required"
                                    >
                                        <b-input-group prepend="£">
                                            <b-form-input
                                                name="receivedAmount"
                                                v-model="childBenefitDetails.receivedAmount"
                                                placeholder="Received amount"
                                                :class="{'is-valid' : childBenefitDetails.receivedAmount, 'is-invalid' : errors.length}"
                                                @change="checkForRoundNumber('receivedAmount')"
                                            >
                                            </b-form-input>
                                        </b-input-group>
                                        
                                        <small v-if="errors.length" class="text-danger validation-error d-block">
                                            {{ errors[0] }}
                                        </small>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </div>
                </div>

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import Notifications from '@/Notifications'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'ChildBenefitComponent',
    
    components: {
        SaveAndContinueComponent
    },

    data() {
        return {
            formErrors: {},
            saving: false,
            ValidationErrorModalComponent
        }
    },

    mounted() {
        if(this.childBenefitDetails.receivedAmount != null && this.childBenefitDetails.numberOfChildren != null) this.markSectionAsComplete()
        this.childBenefitDetails.receivedAmount = (this.childBenefitDetails.receivedAmount / 100).toFixed(2)
    },
  
    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            isChildBenefitDetailsComplete: 'submissionStore/getIsChildBenefitDetailsComplete',
            childBenefitDetails: 'submissionStore/getChildBenefitDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getChildBenefitDetails: 'submissionStore/getSubmissionChildBenefitDetails',
            setNotification: 'notificationsStore/setNotification',
            setChildBenefitIncome: 'sectionsCompleteStore/setChildBenefitIncome'
        }), 

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setChildBenefitIncome(true)
        },

        async submit() {            
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true 

            let childBenefitModel = {
                submissionId: this.submissionId,
                receivedAmount: parseInt(this.childBenefitDetails.receivedAmount * 100),
                numberOfChildren: parseInt(this.childBenefitDetails.numberOfChildren)
            }

            axios.post(`submission/${this.submissionId}/child-benefit-details`, childBenefitModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).finally(() => this.saving = false)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.childBenefitDetails[ref]))) {
                this.childBenefitDetails[ref] = parseFloat(this.childBenefitDetails[ref]).toFixed(2)
            }
        }
    }
}
</script>