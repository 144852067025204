<template>
    <div :id="'jcpindex'+index" class="bg-white p-4">
        <b-row>
            <b-col md="1" class="text-center">
                <div class="number mx-auto">
                    {{ index + 1 }}
                </div>

                <div class="remove mt-3 cursor-pointer"
                    v-if="index > 0"
                    @click.prevent="removeItem(index)"
                >
                    <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                </div>
            </b-col>

            <b-col md="11" class="mt-4 mt-md-0">
                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'am'+index" name="Amount" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group
                                label="Amount"
                                label-for="amount"
                                label-class="required"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="amount"
                                        v-model="item.amount"
                                        placeholder="Amount"
                                        :class="{'is-valid' : item.amount, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('amount')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">  
                        <ValidationProvider name="Date" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Date"
                                label-for="date"
                                label-class="required"
                            >
                                <b-form-datepicker
                                    v-model="item.date" 
                                    name="date"
                                    :class="{'is-valid' : item.date, 'is-invalid' : errors.length}"
                                    placeholder="Choose a date"
                                    hide-header
                                    show-decade-nav
                                    no-flip
                                    :max="max"
                                >
                                </b-form-datepicker>
                                    
                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <ValidationProvider name="Description" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Description"
                                label-for="description"
                                label-class="required"
                            >
                                <b-form-textarea
                                    name="description"
                                    v-model="item.description"
                                    placeholder="Description"
                                    size="lg"
                                    :class="{'is-valid' : item.description, 'is-invalid' : errors.length}"
                                ></b-form-textarea>
                                
                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row> 
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'JcpRefundItemComponent',
    
    props: {
        item: {},
        index: Number
    },

    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        const maxDate = new Date(today)
        maxDate.setYear(maxDate.getFullYear())

        return {
            max: maxDate
        }
    },

    mounted() {
        this.item.amount = (this.item.amount / 100).toFixed(2)
    },

    computed: {
        ...mapGetters({
            jcpRefundsDetails: 'submissionStore/getJcpRefundsDetails',
        })
    },

    methods: {     
        removeItem(index) {
            this.jcpRefundsDetails.splice(index, 1)

            setTimeout(() => {
                this.$scrollTo('#jcpindex'+(this.jcpRefundsDetails.length - 1), 800)
            }, 500)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        }
    }
}
</script>