<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4>
                            Please tell us more about your pension income							
                        </h4>

                        <p class="mb-0">
                            For example, State Pension, Occupational Pension, Retirement Annuity and Incapability Benefit
                        </p>
                    </div>

                    <template
                        v-if="pensionIncomeDetails.length"
                    >
                        <pension-income-item-component 
                            v-for="(item, index) in pensionIncomeDetails"
                            :key="index"
                            :item="item"
                            :index="index"
                            :failures="failures"
                        />
                    </template>
                </div>

                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div class="media">
                        <img src="@/assets/icons/questions/pension.svg" width="60" height="60" class="mr-3" alt="add employment">
                        <div class="media-body">
                            <h5 class="mt-0">Add another pension item</h5>
                            Would you like to add details of another pension?
                        </div>
                    </div>

                    <b-button variant="primary" class="mt-4 mt-md-0"
                        @click="createDefaultPensionItem()"
                    >
                        Add pension
                    </b-button>     
                </div>

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import PensionIncomeItemComponent from '@/components/partials/PensionIncomeItemComponent.vue'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import Notifications from '@/Notifications'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'PensionIncomeComponent',

    components: {
        PensionIncomeItemComponent,
        SaveAndContinueComponent
    },

    data() {
        return {
            fields: {},
            failures: {},
            saving: false,
            ValidationErrorModalComponent
        }
    },

    mounted() {
        if(this.pensionIncomeDetails.length) this.markSectionAsComplete()
        if(!this.pensionIncomeDetails.length) this.createDefaultPensionItem()
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            pensionIncomeDetails: 'submissionStore/getPensionIncomeDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getPensionIncomeDetails: 'submissionStore/getSubmissionPensionIncomeDetails',
            setNotification: 'notificationsStore/setNotification',
            setPensionIncome: 'sectionsCompleteStore/setPensionIncome'
        }), 

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setPensionIncome(true)
        },
        
        createDefaultPensionItem() {
            let newPension = {
                provider: null,
                payeReference: null,
                receivedAmount: null,
                taxDeductedAmount: null,
                p60DocumentId: null,
            }

            this.pensionIncomeDetails.push(newPension)

            setTimeout(() => {
                this.$scrollTo('#index'+(this.pensionIncomeDetails.length - 1), 800)
            })
        },

        async submit() {            
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true

            let pensionIncomeModel = {
                submissionId: this.submissionId,
                pensions: JSON.parse(JSON.stringify(this.pensionIncomeDetails))
            }

            pensionIncomeModel.pensions.forEach(p => {
                p.receivedAmount = parseInt(p.receivedAmount * 100)
                p.taxDeductedAmount = parseInt(p.taxDeductedAmount * 100)
            })

            axios.post(`/submission/${this.submissionId}/pension-details`, pensionIncomeModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).catch((err) => {
                this.failures = err.data.failures
            }).finally(() => this.saving = false)
        }
    }
}
</script>