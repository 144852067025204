export default {
    // Basic Details
    UTR: {
        Title: "Unique Taxpayer Reference",
        Body: "Your UTR is a 10 digit number issued by HMRC when you first register for self assessment. Your UTR can be found on any documents HMRC have sent to you"
    },

    NI: {
        Title: "National Insurance Number",
        Body: "National insurance number can be found on your payslips or national insurance card"
    },

    PhotoId: {
        Title: "Photo ID",
        Body: "Photo ID - Passport or Driving Licence"
    },

    // Employment Income
    PayeReference: {
        Title: "Paye Reference", 
        Body: "PAYE reference will be included on your P60 or P45"
    }, 

    P11DBenefitInKind: {
        Title: "P11d Benefit In Kind",
        Body: "If you recevie a benefit in kind such as a company car or health insurance you should receive a P11D"
    },

    P45: {
        Title: "P45",
        Body: "Issued by your employer if you leave your role breaking down your income and tax paid during your time with them"
    }, 

    P60: {
        Title: "P60",
        Body: "Issued by your employer at the end of the tax year detailing your employment income"
    },

    P11D: {
        Title: "P11D",
        Body: "P11D will detail the taxable income from your benefit in kind"
    },

    // Self Employment Income
    CisDeducted: {
        Title: "CIS Deducted",
        Body: "Are you registered for CIS and stopped tax on your invoice amounts?"
    },

    CisDeductedAmount: {
        Title: "CIS Deducted Amount",
        Body: "Total of the amount of CIS stopped on your invoices across the tax year"
    },

    TotalExpenses: {
        Title: "Total Expenses",
        Body: "Breakdown the expenses you have incurred during the tax year"
    },

    // Property Income
    OtherCosts: {
        Title: "Other Costs",
        Body: "Costs associated with letting of your property. This includes gas safe certifcates and landlord insurance"
    },

    // Pension Income
    PensionPayeReference: {
        Title: "PAYE Reference",
        Body: "PAYE reference will be included on your pension income documents"
    },

    P60OrPensionStatement: {
        Title: "P60 Or Pension Statement",
        Body: "In that same way you receive a P60 from your employer each year, if you receive pension income you should receive a P60 too. If not, the minimum you should receive is a pension statement. Any pension income you receive will be included in this paperwork."
    },

    // Dividend Income
    Dividends: {
        Title: "Dividends",
        Body: "Dividends are paid from share investments that you may have"
    },

    ReceivedAnyDividends: {
        Title: "Did You Received Any Dividends?",
        Body: "Dividends are paid from share investments that you may have"
    },

    ReceivedAnyInterest: {
        Title: "Did You Received Any Interest?",
        Body: "Paid on savings income and should have an annual statement to support the amounts paid"
    },

    // Capital Gains / Losses
    EntrepeneursRelief: {
        Title: "Entrepeneurs Relief", 
        Body: "Did your asset disposal qualify for Entreprenuers relief?"
    }, 

    PrivateResidentsRelief: {
        Title: "Private Residents Relief",
        Body: "Did your property disposal qualify for Private Residence Relief?"
    },

    // Foreign Income
    ForeignIncomePayeReference: {
        Title: "PAYE Reference",
        Body: "Official reference given to support your foreign employment income"
    },

    // Additional Details
    GiftAidDonations: {
        Title: 'Gift Aid Donations',
        Body: 'Did you make any charitable donations that qualify for Gift Aid?'
    },

    // Tax Avoidance
    TaxAvoidance: {
        Title: 'Tax Avoidance',
        Body: 'Do you need to declare any previous income that was received using a tax avoidance scheme?'
    },

    // Disguised Remuneration Avoidance
    DisguisedRemunerationAvoidance: {
        Title: 'Disguised Remuneration Avoidance',
        Body: 'Do you need to declare any previous income that was received using a tax avoidance scheme?'
    },

    // Marriage Allowance Transfer 
    MarriageAllowanceTransferNiNumber: {
        Title: 'NI Number',
        Body: 'Have you transferred part of your personal allowance to your spouse? If so, please provide their National Insurance number'
    },

    MarriageAllowanceReceiptNiNumber: {
        Title: 'NI Number',
        Body: 'Have you received part of your spouse\'s personal allowance? If so, please provide their National Insurance number'
    }
}