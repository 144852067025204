export default function redirectIfAdminOrAccountant({ next, store }) {
    if(store.getters['userStore/authenticated'] && (store.getters['userStore/getIsUserAdmin'] || store.getters['userStore/getIsUserAccountant'])) {
        return next({
            name: 'Dashboard'
        })
    }

    if(store.getters['userStore/authenticated'] && store.getters['userStore/getIsUserEtoroAdmin']) {
        return next({
            name: 'Reports'
        })
    }

    return next()
}