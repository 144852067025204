<template>
    <div :id="'gadindex'+index" class="bg-white p-4">
        <b-row>
            <b-col md="1" class="text-center">
                <div class="number mx-auto">
                    {{ index + 1 }}
                </div>

                <div class="remove mt-3 cursor-pointer"
                    v-if="index > 0"
                    @click.prevent="removeItem(index)"
                >
                    <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                </div>
            </b-col>

            <b-col md="11" class="mt-4 mt-md-0">
                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'fa'+index" name="Full Amount" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group>   
                                <label for="fullAmount" class="d-block required">Full Amount</label>
                                <img class="helper-image" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.GiftAidDonations.Title, Helpers.GiftAidDonations.Body)" />

                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="fullAmount"
                                        v-model="item.fullAmount"
                                        placeholder="Full Amount"
                                        :class="{'is-valid' : item.fullAmount, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('fullAmount')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <h4 class="mb-0">
                                Is Regular Payment?
                            </h4>

                            <div class="mt-4 mt-md-0">
                                <b-button class="btn-bool mr-1"
                                    @click="item.isRegularPayment = !item.isRegularPayment"
                                    :class="{active: item.isRegularPayment}"
                                >
                                    Yes
                                </b-button>

                                <b-button class="btn-bool"
                                    @click="item.isRegularPayment = !item.isRegularPayment"
                                    :class="{negativeActive: !item.isRegularPayment}"
                                >
                                    No
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>    
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'GiftAidDonationItemComponent',

    props: {
        item: {},
        index: Number,
    },

    data() {
        return {
            HelperModalComponent,
            Helpers
        }
    },

    mounted() {
        this.item.fullAmount = (this.item.fullAmount / 100).toFixed(2)
    },

    computed: {
        ...mapGetters({
            giftAidDonationsDetails: 'submissionStore/getGiftAidDonationsDetails',
        })
    },

    methods: {
        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        removeItem(index) {
            this.giftAidDonationsDetails.splice(index, 1);

            setTimeout(() => {
                this.$scrollTo('#gadindex'+(this.giftAidDonationsDetails.length - 1), 800)
            }, 500)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        }
    }
}
</script>