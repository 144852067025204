<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="approval" class="pl-0">
                <h4 class="mb-0">
                    Gift Aid Donations
                </h4>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="giftAidDonationsDetails.length"
                            >
                                <gift-aid-donation-item-component 
                                    v-for="(item, index) in giftAidDonationsDetails"
                                    :key="index"
                                    :item="item"
                                    :index="index"
                                />
                            </template>

                            <div class="p-4">
                                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                                    <div>
                                        <h5 class="mt-0">Add another gift aid donation</h5>
                                        Would you like to add details of another gift aid donation?
                                    </div>

                                    <b-button variant="primary" class="mt-4 mt-md-0"
                                        @click="createDefaultGiftAidDonationItem()"
                                    >
                                        Add gift aid donation
                                    </b-button>    
                                </div> 
                            </div>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import GiftAidDonationItemComponent from '@/components/partials/GiftAidDonationItemComponent.vue'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'

export default {
    name: 'GiftAidDonationsComponent', 

    components: {
        GiftAidDonationItemComponent
    },

    data() {
        return {
            saving: false,
            responseFailures: {}
        }
    },

    mounted() {
        if(!this.giftAidDonationsDetails.length) this.createDefaultGiftAidDonationItem()
    },

    computed: {
        ...mapGetters({
            giftAidDonationsDetails: 'submissionStore/getGiftAidDonationsDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getGiftAidDonationsDetails: 'submissionStore/getSubmissionGiftAidDonationsDetails',
            setNotification: 'notificationsStore/setNotification'
        }),
 
        createDefaultGiftAidDonationItem() {
            let giftAidDonationItem = {
                fullAmount: 0,
                isRegularPayment: true
            }

            this.giftAidDonationsDetails.push(giftAidDonationItem)

            setTimeout(() => {
                this.$scrollTo('#gadindex'+(this.giftAidDonationsDetails.length - 1), 800)
            }, 500)
        },

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let giftAidDonationsModel = {
                submissionId: this.submissionId,
                giftAidDonations: JSON.parse(JSON.stringify(this.giftAidDonationsDetails))
            }

            giftAidDonationsModel.giftAidDonations.forEach(g => {
                g.fullAmount = parseInt(g.fullAmount * 100)
            })

            axios.post(`/submission/${this.submissionId}/gift-aid-donations`, giftAidDonationsModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

            }).catch((err) => {
                this.responseFailures = err.data.failures
            }).finally(() => this.saving = false)
        }
    }
}
</script>