var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white p-4",attrs:{"id":'gindex'+_vm.index}},[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"1"}},[_c('div',{staticClass:"number mx-auto"},[_vm._v(" "+_vm._s(_vm.index + 1)+" ")]),(_vm.index > 0)?_c('div',{staticClass:"remove mt-3 cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.removeItem(_vm.index)}}},[_c('img',{attrs:{"src":require("@/assets/icons/delete.svg"),"alt":"delete item","width":"40","height":"40"}})]):_vm._e()]),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"md":"11"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'rn'+_vm.index,"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"name","label-class":"required"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.recipientName, 'is-invalid' : errors.length},attrs:{"name":"name","placeholder":"Enter a name"},model:{value:(_vm.item.recipientName),callback:function ($$v) {_vm.$set(_vm.item, "recipientName", $$v)},expression:"item.recipientName"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'am'+_vm.index,"name":"Amount","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount","label-for":"amount","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.amount, 'is-invalid' : errors.length},attrs:{"name":"amount","placeholder":"Amount"},on:{"change":function($event){return _vm.checkForRoundNumber('amount')}},model:{value:(_vm.item.amount),callback:function ($$v) {_vm.$set(_vm.item, "amount", $$v)},expression:"item.amount"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'v'+_vm.index,"name":"Value","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Value","label-for":"value","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.value, 'is-invalid' : errors.length},attrs:{"name":"value","placeholder":"Value"},on:{"change":function($event){return _vm.checkForRoundNumber('value')}},model:{value:(_vm.item.value),callback:function ($$v) {_vm.$set(_vm.item, "value", $$v)},expression:"item.value"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date","label-for":"date","label-class":"required"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.item.date, 'is-invalid' : errors.length},attrs:{"name":"date","placeholder":"Choose a date","no-flip":"","max":_vm.max},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center justify-content-between mt-5"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Has this been received? ")]),_c('div',{staticClass:"mt-4 mt-md-0"},[_c('b-button',{staticClass:"btn-bool mr-1",class:{active: _vm.item.isReceived},on:{"click":function($event){_vm.item.isReceived = !_vm.item.isReceived}}},[_vm._v(" Yes ")]),_c('b-button',{staticClass:"btn-bool",class:{negativeActive: !_vm.item.isReceived},on:{"click":function($event){_vm.item.isReceived = !_vm.item.isReceived}}},[_vm._v(" No ")])],1)]),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center justify-content-between mt-4"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Is this regular? ")]),_c('div',{staticClass:"mt-4 mt-md-0"},[_c('b-button',{staticClass:"btn-bool mr-1",class:{active: _vm.item.isRegular},on:{"click":function($event){_vm.item.isRegular = !_vm.item.isRegular}}},[_vm._v(" Yes ")]),_c('b-button',{staticClass:"btn-bool",class:{negativeActive: !_vm.item.isRegular},on:{"click":function($event){_vm.item.isRegular = !_vm.item.isRegular}}},[_vm._v(" No ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }