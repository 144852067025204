<template>
    <b-container class="mt-5">
        <b-row class="justify-content-center">
            <b-col md="6">
                <div class="loader-container">
                    <div class="loader"></div>

                    <p class="lead">
                        Please wait while we redirect you
                    </p>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    name: 'PaymentSuccess', 

    mounted() {
        let promises = []
            promises.push(this.markSubmissionAsPaidAction(this.$route.params.id))
            promises.push(this.markSubmissionAsCompleteAction(this.$route.params.id))

            Promise.all(promises).then(() => {
                this.$router.push({name: 'PaymentComplete', query: { submissionCompleted: 'true' }})
            }).catch(() => {
                // 
            })
    }, 

    methods: {
        ...mapActions({
            getSubmission: 'submissionStore/getSubmission',
            markSubmissionAsPaidAction: 'submissionStore/markSubmissionAsPaid',
            markSubmissionAsCompleteAction: 'submissionStore/markSubmissionAsComplete'
        })
    }
}
</script>