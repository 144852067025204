<template>
    <ValidationObserver ref="observer">
        <ValidationProvider ref="field" name="User Document" rules="required" v-slot="{ errors }">
            <input 
                ref="imageUpload" 
                style="display: none" 
                type="file" 
                @change="handleUpload()"
            >

            <div class="bg-white d-flex flex-column flex-md-row align-items-center justify-content-between p-4"
                :class="{'border border-success' : (hasBeenUploaded || submissionBasicDetails.userDocumentId != null), 'border border-danger' : errors.length}"
            >
                <div class="d-flex flex-column flex-md-row align-items-center">
                    <div>
                        <img src="@/assets/icons/user-document.svg" width="100" />
                    </div>

                    <div class="ml-4 mt-4 mt-md-0">
                        <h5 class="mb-0">
                            User Document <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.PhotoId.Title, Helpers.PhotoId.Body)" />
                        </h5>

                        <p class="text-sm mb-0">
                            Upload an image of your driving licence or passport.
                            <span class="d-block text-sm color-primary font-weight-bold">Accepted document types are jpg, jpeg, png, pdf, doc & docx.</span>
                        </p>
                    </div>
                </div>

                <div>
                    <b-button block variant="primary" class="mt-2 mt-md-0"
                        v-if="hasBeenUploaded || submissionBasicDetails.userDocumentId != null"
                        @click="deleteUpload()"
                    >
                        Remove
                    </b-button>
                    
                    <b-button block variant="primary" class="mt-2 mt-md-0"
                        v-else
                        @click="uploadFile()"
                    >
                        <img src="@/assets/icons/upload.svg" width="15" height="15" /> Upload
                    </b-button>

                    <p class="text-success text-sm mb-0 mt-2 d-flex align-items-center"
                        v-if="hasBeenUploaded || submissionBasicDetails.userDocumentId != null"
                    >
                        <img class="mr-1" src="@/assets/icons/green-tick.svg" width="15" height="15" /> You have uploaded a document
                    </p>
                </div>
            </div>

            <small v-if="errors.length" class="text-danger validation-error d-block">
                {{ errors[0] }}
            </small>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import UploadFileModalComponent from '@/components/modal-components/UploadFileModalComponent.vue'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Notifications from '@/Notifications'
import Helpers from '@/Helpers'

export default {
    name: 'UserDocumentItemComponent',

    props: {
        submissionBasicDetails: Object
    },

    data() {
        return {
            UploadFileModalComponent, 
            HelperModalComponent,
            Helpers,
            file: null,
            fieldHasErrors: false,
            hasBeenUploaded: false,
        }
    },

    mounted() {
        this.syncInput()
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId'
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'
        }),

        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        syncInput() {
            if(this.submissionBasicDetails.userDocumentId != null) {
                this.$refs.field.syncValue(this.submissionBasicDetails.userDocumentId)
            }
        },

        uploadFile() {
            this.$refs.imageUpload.click()
        }, 

        handleUpload() {
            this.fieldHasErrors = false
            this.file = this.$refs.imageUpload.files[0]

            this.$refs.field.validate(this.file)

            if(this.fieldHasErrors == false) {
                var formData = new FormData();
                formData.append('file', this.file, this.file.name)

                this.$modal.show(this.UploadFileModalComponent)

                axios.post(`submissions/${this.submissionId}/documents`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((response) => {
                    this.submissionBasicDetails.userDocumentId = response.data.documentId
                    this.hasBeenUploaded = true

                    this.setNotification({
                        message: Notifications.FILE_UPLOAD_SUCCESS,
                        type: 'success'
                    })
                }).catch(() => {
                    this.setNotification({
                        message: Notifications.FILE_UPLOAD_FAILURE,
                        type: 'failure'
                    })
                }).finally(() => this.$modal.hideAll())
            }
        }, 

        deleteUpload() {
            this.file = null
            this.$refs.imageUpload.value = null
            this.submissionBasicDetails.userDocumentId = null
            this.hasBeenUploaded = false
            
            this.validate()
        },

        async validate() {
            let errors = await this.$refs.field.validate(this.file)
            
            if(!errors.valid) {
                this.fieldHasErrors = true
            } 
        }
    },
}
</script>