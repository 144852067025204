var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white p-4",attrs:{"id":'index'+_vm.index}},[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"1"}},[_c('div',{staticClass:"number mx-auto"},[_vm._v(" "+_vm._s(_vm.index + 1)+" ")]),(_vm.index > 0)?_c('div',{staticClass:"remove mt-3 cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.removeItem(_vm.index)}}},[_c('img',{attrs:{"src":require("@/assets/icons/delete.svg"),"alt":"delete item","width":"40","height":"40"}})]):_vm._e()]),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"md":"11"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'Employers Name '+(_vm.index+1),"name":"Employers Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employers Name","label-for":"employersName","label-class":"required"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.employerName, 'is-invalid' : errors.length},attrs:{"name":"employersName","type":"text","placeholder":"Employers name"},model:{value:(_vm.item.employerName),callback:function ($$v) {_vm.$set(_vm.item, "employerName", $$v)},expression:"item.employerName"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'PAYE Reference '+(_vm.index+1),"name":"Paye Reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"d-block required",attrs:{"for":"payeReference"}},[_vm._v("PAYE Reference")]),_c('img',{staticClass:"helper-image",attrs:{"src":require("@/assets/icons/question-icon.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.openHelperModal(_vm.Helpers.ForeignIncomePayeReference.Title, _vm.Helpers.ForeignIncomePayeReference.Body)}}}),_c('b-form-input',{class:{'is-valid' : _vm.item.payeReference, 'is-invalid' : errors.length},attrs:{"name":"payeReference","type":"text","placeholder":"PAYE ref"},model:{value:(_vm.item.payeReference),callback:function ($$v) {_vm.$set(_vm.item, "payeReference", $$v)},expression:"item.payeReference"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'Amount Received '+(_vm.index+1),"name":"Amount Received","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount Received","label-for":"amountReceived","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.receivedAmount, 'is-invalid' : errors.length},attrs:{"name":"amountReceived","placeholder":"Amount received"},on:{"change":function($event){return _vm.checkForRoundNumber('receivedAmount')}},model:{value:(_vm.item.receivedAmount),callback:function ($$v) {_vm.$set(_vm.item, "receivedAmount", $$v)},expression:"item.receivedAmount"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'Tax Deducted '+(_vm.index+1),"name":"Tax Deducted","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tax Deducted","label-for":"taxDeducted","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.taxDeductedAmount, 'is-invalid' : errors.length},attrs:{"name":"taxDeducted","placeholder":"Tax deducted"},on:{"change":function($event){return _vm.checkForRoundNumber('taxDeductedAmount')}},model:{value:(_vm.item.taxDeductedAmount),callback:function ($$v) {_vm.$set(_vm.item, "taxDeductedAmount", $$v)},expression:"item.taxDeductedAmount"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }