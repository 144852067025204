<template>
    <b-card class="shadow mt-5" v-if="jcpRefundsDetails.length">
            <b-row>
                <b-col>
                    <h2 class="mb-3">
                        Tax Refunds Details
                    </h2>

                    <b-table fixed outlined responsive striped hover :fields="filteredJcpRefundsDetails" :items="jcpRefundsDetails">
                    </b-table>
                </b-col>
            </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'JcpRefundsDetailsReviewComponent',

    computed: {
        ...mapGetters({
            jcpRefundsDetails: 'submissionStore/getJcpRefundsDetails',
        }),

        filteredJcpRefundsDetails() {
            return [
                {
                    key: 'amount',
                    label: 'Amount',
                    formatter: 'formatPrice'
                },

                {
                    key: 'date',
                    label: 'Date',
                    formatter: 'formatDate'
                }, 

                {
                    key: 'description',
                    label: 'Description'
                }
            ]
        }
    },

    methods: {
        formatDate(value) {
            return this.$moment(value).format('ll')
        }, 

        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        }
    }
}
</script>