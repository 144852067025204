<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-14 variant="approval" class="pl-0">
                <h4 class="mb-0">
                    Additional information
                </h4>

                <p class="mb-0">
                    If there is any other information or documents for your submission, please add them here
                </p>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-14" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div class="p-4">
                            <ValidationProvider name="Note" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label="Note"
                                    label-for="note"
                                    label-class="required"
                                >
                                    <b-form-textarea
                                        name="note"
                                        v-model="additionalNotesDetails.notes"
                                        rows="5"
                                        placeholder="Add any notes here"
                                        :class="{'is-valid' : additionalNotesDetails.notes, 'is-invalid' : errors.length}"
                                    >
                                    </b-form-textarea>
                                    
                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                        {{ errors[0] }}
                                    </small>
                                </b-form-group>
                            </ValidationProvider>

                            <additional-notes-documents-component 
                                :items="additionalNotesDetails.items"
                            />

                            <div class="text-right mt-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapActions} from 'vuex'
import AdditionalNotesDocumentsComponent from '@/components/partials/AdditionalNotesDocumentsComponent.vue'
import Notifications from '@/Notifications'
export default {
    name: 'AdditionalNotesComponent',

    components: {
        AdditionalNotesDocumentsComponent
    },

    data() {
        return {
            saving: false,
            modelHere: 'test'
        }
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId',
            additionalNotesDetails: 'submissionStore/getAdditionalNotesDetails'
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'
        }),

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let additionalNotesModel = {
                submissionId: this.submissionId,
                notes: this.additionalNotesDetails.notes,
                items: this.additionalNotesDetails.items
            }

            axios.post(`submission/${this.submissionId}/additional-information`, additionalNotesModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).finally(() => this.saving = false)
        }
    }
}
</script>