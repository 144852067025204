<template>
    <div class="tab-content">
        <template
            v-if="Object.keys(etoroDetails).length !== 0"
        > 
            <ValidationObserver ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="submit()">
                    <div class="tab-border">
                        <div class="p-4">
                            <h4 class="mb-0">
                                Etoro Information
                            </h4>
                        </div>

                        <div class="bg-white p-4">
                            <div class="d-flex flex-column flex-md-row align-items-center justify-content-between mb-5">
                                <h4>
                                    Are you a member of Etoro?
                                </h4>

                                <div class="mt-4 mt-md-0 flex-shrink-0 ml-4">
                                    <b-button class="btn-bool mr-1"
                                        @click="toggleIsMember()"
                                        :class="{active: etoroDetails.isMember}"
                                    >
                                        Yes
                                    </b-button>

                                    <b-button class="btn-bool"
                                        @click="etoroDetails.isMember = !etoroDetails.isMember"
                                        :class="{negativeActive: !etoroDetails.isMember}"
                                    >
                                        No
                                    </b-button>
                                </div>
                            </div>

                            <template
                                v-if="etoroDetails.isMember"
                            >
                                <b-row class="mt-4">
                                    <b-col md="4">
                                        <ValidationProvider name="Member Level" :rules="`${etoroDetails.isMember ? 'required' : ''}`" v-slot="{ errors }">
                                            <b-form-group
                                                label="Member Level"
                                                label-for="memberLevel"
                                                label-class="required"
                                            >
                                                <b-form-select 
                                                    class="form-control" 
                                                    v-model="etoroDetails.memberLevel"
                                                    name="memberLevel" 
                                                    :options="memberLevelOptions"
                                                    :class="{'is-valid' : etoroDetails.memberLevel, 'is-invalid' : errors.length}"
                                                >
                                                </b-form-select>
                                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                                    {{ errors[0] }}
                                                </small>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>

                                <etoro-statement-component 
                                    :item="etoroDetails"
                                />
                            </template>

                            <template
                                v-if="!etoroDetails.isMember"
                            >
                                <etoro-sales-item-component 
                                    v-for="(item, index) in etoroSaleItemDetails"
                                    :key="index"
                                    :item="item"
                                    :index="index"
                                />

                                <etoro-document-component 
                                    class="add-item-container mt-4"
                                    :item="etoroDetails"
                                />

                                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="mt-0">Add another sale</h5>
                                            Would you like to add details of other shares you have sold during this period?
                                        </div>
                                    </div>

                                    <b-button variant="primary" class="mt-4 mt-md-0"
                                        @click="createDefaultItem()"
                                    >
                                        Add a sale
                                    </b-button>     
                                </div>
                            </template>
                        </div>
                    </div>

                    <save-and-continue-component
                        :saving="saving"
                        :componentName="$options.name"
                        @clickBack="$emit('back')"
                    />
                    
                    <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
                </form>
            </ValidationObserver>
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import {mapGetters, mapActions} from 'vuex'
import Notifications from '@/Notifications'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import EtoroStatementComponent from '@/components/partials/EtoroStatementComponent.vue'
import EtoroDocumentComponent from '@/components/partials/EtoroDocumentComponent.vue'
import EtoroSalesItemComponent from '@/components/partials/EtoroSalesItemComponent.vue'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'EtoroDetailsComponent',

    components: {
        SaveAndContinueComponent,
        EtoroStatementComponent,
        EtoroDocumentComponent,
        EtoroSalesItemComponent
    },

    mounted() {
        if(this.isSectionComplete()) this.markSectionAsComplete()
        if(!this.etoroSaleItemDetails.length) this.createDefaultItem();
    },

    data() {
        return {
            saving: false,
            selected: null,
            memberLevelOptions: [
                { value: null, text: 'Please select an option' },
                { value: 'SILVER', text: 'Silver' },
                { value: 'GOLD', text: 'Gold' },
                { value: 'PLATINUM', text: 'Platinum' },
                { value: 'PLATINUM_PLUS', text: 'Platinum Plus' },
                { value: 'DIAMOND', text: 'Diamond' }
            ],
            ValidationErrorModalComponent
        }
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            etoroDetails: 'submissionStore/getEtoroDetails',
            etoroSaleItemDetails: 'submissionStore/getEtoroSaleItemDetails',
            submissionId: 'submissionStore/getId',
        })
    }, 

    methods: {
        ...mapActions({
            getSubmissionEtoroDetails: 'submissionStore/getSubmissionEtoroDetails',
            setNotification: 'notificationsStore/setNotification',
            setEtoroDetails: 'sectionsCompleteStore/setEtoroDetails',
            setEtoroSaleItemsToEmptyArray: 'submissionStore/setEtoroSaleItemsToEmptyArray'
        }), 

        createDefaultItem() {
            let etoroSaleItemModel = {
                stockName: null,
                purchasedUnits: null,
                purchasedUnitPrice: 0,
                purchasePrice: 0,
                soldOn: null,
                saleUnits: null,
                saleUnitPrice: 0,
                salePrice: 0
            }

            this.etoroSaleItemDetails.push(etoroSaleItemModel)

            // setTimeout(() => {
            //     this.$scrollTo('#index'+(this.etoroSaleItemDetails.length - 1), 800)
            // }, 500)
        },

        isSectionComplete() {
            return this.etoroSaleItemDetails.length || (this.etoroDetails.memberLevel != null)
        },

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setEtoroDetails(true)
        },

        toggleIsMember() {
            this.etoroDetails.isMember = !this.etoroDetails.isMember
        },

        async submit() {
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },
        
        save() {
            this.saving = true

            if(this.etoroSaleItemDetails.length == 1) {
                if(this.etoroSaleItemDetails[0].stockName == null || this.etoroSaleItemDetails[0].soldOn == null) {
                  this.setEtoroSaleItemsToEmptyArray()
                }
            }

            let etoroModel = {
                submissionId: this.submissionId,
                isMember: this.etoroDetails.isMember,
                memberNumber: this.etoroDetails.memberNumber,
                memberLevel: this.etoroDetails.memberLevel,
                etoroStatementDocumentId: this.etoroDetails.etoroStatementDocumentId,
                etoroSaleItems: JSON.parse(JSON.stringify(this.etoroSaleItemDetails))
            }

            etoroModel.etoroSaleItems.forEach(e => {
                e.purchasePrice = parseInt(e.purchasePrice * 100)
                e.salePrice = parseInt(e.salePrice * 100)
                e.saleUnitPrice = String(parseFloat(e.saleUnitPrice * 100).toFixed(5))
                e.purchasedUnitPrice = String(parseFloat(e.purchasedUnitPrice * 100).toFixed(5))
                e.purchasedUnits = String(parseFloat(e.purchasedUnits * 100).toFixed(5))
                e.saleUnits = String(parseFloat(e.saleUnits * 100).toFixed(5))
            })

            axios.post(`submission/${this.submissionId}/etoro-details`, etoroModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                if(!this.etoroSaleItemDetails.length) {
                    if(!this.etoroSaleItemDetails.length) {
                        this.createDefaultItem()
                    }
                }
                this.markSectionAsComplete()
                this.$emit('next')
            }).catch(() => {
                this.setNotification({
                    message: Notifications.SAVED_FAILURE,
                    type: 'failure'
                })
            }).finally(() => {
                this.saving = false
            })
        }
    }
}
</script>