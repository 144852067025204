<template>   
    <b-card class="shadow mt-5" v-if="propertyIncomeDetails.length">
        <b-row>   
            <b-col>
                <h2 class="mb-3">
                    Property Income Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredPropertytIncomeDetailsFields" :items="propertyIncomeDetails">
                    <template #cell(address)="data">
                        <div v-html="data.value"></div>
                    </template>

                    <template #cell(isFurnished)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>

                    <template #cell(otherCosts)="data">
                        <b-table class="mb-0" outlined hover :fields="filteredOtherCostsFields" :items="data.value">
                            <template #cell(amount)="data">
                                &pound;{{ (data.item.amount / 100).toFixed(2) }}
                            </template>
                        </b-table>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'PropertyIncomeDetailsReviewComponent',

    computed: {
        ...mapGetters({
            propertyIncomeDetails: 'submissionStore/getPropertyIncomeDetails',
        }),

        filteredOtherCostsFields() {
            return [
                {
                    key: 'name',
                    label: 'Description',
                },

                {
                    key: 'amount',
                    label: 'Amount',
                },
            ]
        },

        filteredPropertytIncomeDetailsFields() {
            return [
                {
                    key: 'address',
                    label: 'Address',
                    formatter: 'formatAddress'
                }, 

                {
                    key: 'rentReceivedAmount',
                    label: 'Rent Received Amount',
                    formatter: 'formatPrice'
                }, 
                
                {
                    key: 'isFurnished',
                    label: 'Is Furnished',
                    formatter: 'formatBool'
                }, 

                {
                    key: 'mortgageInterestPaidAmount',
                    label: 'Mortgage Interest Paid',
                    formatter: 'formatPrice'
                }, 

                {
                    key: 'otherCosts',
                    label: 'Other Costs'             
                }, 
            ]
        },
    }, 

    methods: {
        formatAddress(value) {
            let addressString = ''

            if(value.line1) addressString += value.line1+',<br/>'
            if(value.line2) addressString += value.line2+',<br/>'
            if(value.line3) addressString += value.line3+',<br/>'
            if(value.town) addressString += value.town+',<br/>'
            if(value.county) addressString += value.county+',<br/>'
            if(value.postcode) addressString += value.postcode

            return addressString
        }, 

        formatOtherCosts(value) {
            return value
        }, 

        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        },

        formatPercentage(value) {
            return (value * 100).toFixed(2)+'%' 
        },

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>