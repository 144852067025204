<template>
    <b-container class="mt-5">
        <b-row class="justify-content-center text-center">
            <b-col md="6">
                <h2>
                    Sorry, that went wrong..
                </h2>

                <b-button variant="primary" class="mt-5"
                    :to="{name: 'MyAccount'}"
                >
                    Try again
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'PaymentFailure'
}
</script>