<template>   
    <b-card class="shadow mt-5" v-if="Object.keys(selfEmploymentIncomeDetails).length != 0">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Self Employment Income Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredSelfEmploymentIncomeDetails" :items="selfEmploymentIncomeDetailsToArray">
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'SelfEmploymentIncomeDetailsReviewComponent',

    computed: {
        ...mapGetters({
            selfEmploymentIncomeDetails: 'submissionStore/getSelfEmploymentIncomeDetails',
        }),

        selfEmploymentIncomeDetailsToArray() {
            let array = []

            array.push(this.selfEmploymentIncomeDetails)

            return array
        },

        filteredSelfEmploymentIncomeDetails() {
            return [
                {
                    key: 'businessName',
                    label: 'Business Name',
                },

                {
                    key: 'income',
                    label: 'Income',
                    formatter: 'formatPrice'
                },

                {
                    key: 'cisDeducted',
                    label: 'CIS Deducted',
                    formatter: 'formatPrice'
                },

                {
                    key: 'totalExpenses',
                    label: 'Total Expenses',
                    formatter: 'formatPrice'
                },

                {
                    key: 'motorExpenses',
                    label: 'Motor Expenses',
                    formatter: 'formatPrice'
                },

                {
                    key: 'otherTravelExpenses',
                    label: 'Other Travel Expenses',
                    formatter: 'formatPrice'
                },

                {
                    key: 'toolsExpenses',
                    label: 'Tools Expenses',
                    formatter: 'formatPrice'
                },

                {
                    key: 'otherExpenses',
                    label: 'Other Expenses',
                    formatter: 'formatPrice'
                }
            ]
        }
    }, 

    methods: {
        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        },

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>