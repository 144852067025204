<template>  
    <div class="inner-modal">
        <h2 class="mb-4" v-if="title != null">
            {{ title }}
        </h2>

        <div v-if="body != null" v-html="body"></div>

        <div class="mt-5">
            <b-button variant="primary" block
                @click.prevent="$emit('close')"
            >
                Close
            </b-button>
        </div>
    </div>
</template >

<script>
export default {
    name: 'HelperModalComponent',

    props: {
        title: String,
        body: String
    }
}
</script>