<template>
    <b-card class="shadow mt-5" v-if="studentLoansDetails.length">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Student Loans Details
                </h2>

                <b-table fixed outlined responsive striped hover :fields="filteredStudentLoansDetails" :items="studentLoansDetails">
                    <template #cell(willRepayInNextTwoYears)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'StudentLoansDetailsReviewComponent',

    computed: {
        ...mapGetters({
            studentLoansDetails: 'submissionStore/getStudentLoansDetails',
        }),

        filteredStudentLoansDetails() {
            return [
                {
                    key: 'startedOn',
                    label: 'Started On',
                    formatter: 'formatDate'
                }, 

                {
                    key: 'deductedAmount',
                    label: 'Deducted Amount',
                    formatter: 'formatPrice',
                }, 

                {
                    key: 'startedOn',
                    label: 'Started On',
                    formatter: 'formatDate'
                }, 

                {
                    key: 'planType',
                    label: 'Plan Type'
                }, 
                
                {
                    key: 'willRepayInNextTwoYears',
                    label: 'Will Repay In Next Two Years',
                    formatter: 'formatBool'
                }
            ]
        }
    },

    methods: {
        formatDate(value) {
            return this.$moment(value).format('ll')
        }, 

        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        },

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>