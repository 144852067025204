<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-3 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/etoro.svg" width="30" height="30" /> Etoro Details
            </b-button>
        </b-card-header>
        
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Member Level</p>
                                    <p>Your Etoro membership level - Silver, Gold, Platinum, Platinum Plus &amp; Diamond</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Member Number</p>
                                    <p>Your Etoro membership number</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Etoro Statement</p>
                                    <p>Accepted document type is a PDF</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
    export default {
        name: 'EtoroDetailsWhatYouNeedComponent',


    }
</script>