var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{staticClass:"p-0 shadow",attrs:{"no-body":""}},[_c('div',{staticClass:"p-5"},[_c('h2',[_vm._v(" Reset Password ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"mt-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"email","label":"Email Address"}},[_c('b-form-input',{class:{'is-invalid' : errors.length},attrs:{"name":"email","type":"email","placeholder":"Email"},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min_password_length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"password","label":"Password"}},[_c('b-form-input',{class:{'is-invalid' : errors.length},attrs:{"name":"password","type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.failures && _vm.failures.NewPassword)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(_vm.failures.NewPassword[0])+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password Confirm","vid":"passwordConfirm","rules":"required|min_password_length:8|passwordConfirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"passwordConfirm","label":"Confirm Password"}},[_c('b-form-input',{class:{'is-invalid' : errors.length},attrs:{"name":"passwordConfirm","type":"password","placeholder":"Re-enter Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)}),_c('b-button',{staticClass:"mt-3",attrs:{"type":"submit","variant":"primary","size":"lg","block":""}},[_vm._v(" Reset password "),(_vm.loading)?_c('span',{staticClass:"btn-loader"}):_vm._e()])],1)]}}])})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }