export default {
    namespaced: true, 

    state: {
        reasonsComplete: false,
        taxYearComplete: false,
        questionsComplete: false,
        accountantComplete: false,
        informationComplete: false,
        approvalComplete: false,
    }, 

    getters: {
        getIsReasonsComplete(state) {
            return state.reasonsComplete
        },

        getIsTaxYearComplete(state) {
            return state.taxYearComplete
        },

        getIsQuestionsComplete(state) {
            return state.questionsComplete
        },

        getIsAccountantComplete(state) {
            return state.accountantComplete
        },

        getIsInformationComplete(state) {
            return state.informationComplete
        },

        getIsApprovalComplete(state) {
            return state.approvalComplete
        },
    }, 

    mutations: {
        SET_REASONS_COMPLETE(state) {
            state.reasonsComplete = true
        },

        SET_TAX_YEAR_COMPLETE(state) {
            state.taxYearComplete = true
        },

        SET_QUESTIONS_COMPLETE(state) {
            state.questionsComplete = true
        },

        SET_ACCOUNTANT_COMPLETE(state) {
            state.accountantComplete = true
        },

        SET_INFORMATION_COMPLETE(state) {
            state.informationComplete = true
        },

        SET_APPROVAL_COMPLETE(state) {
            state.approvalComplete = true
        },

        CLEAR_STATE(state) {
            state.reasonsComplete = false
            state.taxYearComplete = false
            state.questionsComplete = false
            state.accountantComplete = false
            state.informationComplete = false
            state.approvalComplete = false
        }
    }, 

    actions: {
        setReasonsComplete({commit}) {
            commit('SET_REASONS_COMPLETE')
        },

        setTaxYearComplete({commit}) {
            commit('SET_TAX_YEAR_COMPLETE')
        },

        setQuestionsComplete({commit}) {
            commit('SET_QUESTIONS_COMPLETE')
        },

        setAccountantComplete({commit}) {
            commit('SET_ACCOUNTANT_COMPLETE')
        },

        setInformationComplete({commit}) {
            commit('SET_INFORMATION_COMPLETE')
        },

        setApprovalComplete({commit}) {
            commit('SET_APPROVAL_COMPLETE')
        },

        clearUserProgress({commit}) {
            commit('CLEAR_STATE')
        }
    }
}