<template>  
    <b-card class="shadow mt-5" v-if="employmentIncomeDetails.length">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Employment Income Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredEmploymentIncomeDetails" :items="employmentIncomeDetails">                
                    <template #cell(hasBenefitInKind)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'EmploymentIncomeDetailsReviewComponent',

    computed: {
        ...mapGetters({
            employmentIncomeDetails: 'submissionStore/getEmploymentIncomeDetails', 
        }), 

        filteredEmploymentIncomeDetails() {
            return [
                {
                    key: 'employerName',
                    label: 'Employer Name',
                },

                {
                    key: 'payeReference',
                    label: 'PAYE Reference',
                    formatter: 'formatReference'
                },

                {
                    key: 'receivedAmount',
                    label: 'Received Amount',
                    formatter: 'formatPrice'
                },

                {
                    key: 'taxDeducted',
                    label: 'Tax Deducted',
                    formatter: 'formatPrice'
                },

                {
                    key: 'hasBenefitInKind',
                    label: 'Has Benefit In Kind',
                    formatter: 'formatBool'
                },

                {
                    key: 'benefitInKindType',
                    label: 'Benefit In Kind Type',
                },

                {
                    key: 'p11dValue',
                    label: 'P11D Value',
                    formatter: 'formatPrice'
                },

                {
                    key: 'p11dTaxDeducted',
                    label: 'P11D Tax Deducted',
                    formatter: 'formatPrice'
                },
            ]
        },
    }, 

    methods: {
        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        }, 

        formatReference(value) {
            return '#'+value
        },

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>