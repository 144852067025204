<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="approval" class="pl-0">
                <h4>
                    Residence remittance
                </h4>

                <p class="mb-0">
                    Were you, for all or part of the year to 5 April 2020, one or more of the following - not resident, not ordinarily resident or not domiciled in the UK and claiming the remittance basis; or dual resident in the UK and another country?
                </p>
            </b-button>
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="Object.keys(residenceRemittanceDetails).length != 0"
                            >
                                <div class="bg-white p-4">
                                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                        <h4>
                                            Non UK resident?
                                        </h4>

                                        <div class="mt-4 mt-md-0">
                                            <b-button class="btn-bool mr-1"
                                                @click="residenceRemittanceDetails.isNonUkResident = !residenceRemittanceDetails.isNonUkResident"
                                                :class="{active: residenceRemittanceDetails.isNonUkResident}"
                                            >
                                                Yes
                                            </b-button>

                                            <b-button class="btn-bool"
                                                @click="residenceRemittanceDetails.isNonUkResident = !residenceRemittanceDetails.isNonUkResident"
                                                :class="{negativeActive: !residenceRemittanceDetails.isNonUkResident}"
                                            >
                                                No
                                            </b-button>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-4"
                                    v-if="residenceRemittanceDetails.isNonUkResident"
                                >
                                    <b-row>
                                        <b-col md="6">
                                            <ValidationProvider name="Description" :rules="`${residenceRemittanceDetails.isNonUkResident ? 'required' : ''}`" v-slot="{ errors }">
                                                <b-form-group
                                                    label="Provide dates of residing/visiting UK, whether for work or pleasure and usual country of residence"
                                                    label-for="description"
                                                >
                                                    <b-form-textarea
                                                        name="description"
                                                        v-model="residenceRemittanceDetails.description"
                                                        size="lg"
                                                        :class="{'is-valid' : residenceRemittanceDetails.description, 'is-invalid' : errors.length}"
                                                    ></b-form-textarea>
                                                    
                                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                                        {{ errors[0] }}
                                                    </small>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </div>
                            </template>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'

export default {
    name: 'ResidenceRemittanceComponent', 

    data() {
        return {
            saving: false
        }
    },

    computed: {
        ...mapGetters({
            residenceRemittanceDetails: 'submissionStore/getResidenceRemittanceDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getResidenceRemittanceDetails: 'submissionStore/getSubmissionResidenceRemittanceDetails',
            setNotification: 'notificationsStore/setNotification'
        }),

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let residenceRemittanceModel = {
                submissionId: this.submissionId,
                isNonUkResident: this.residenceRemittanceDetails.isNonUkResident,
                description: this.residenceRemittanceDetails.description
            }

            axios.post(`submission/${this.submissionId}/residence-remittance`, residenceRemittanceModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).finally(() => this.saving = false)
        }
    }
}
</script>