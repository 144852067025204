<template>  
    <div class="inner-modal validation-error-modal">
        <div class="media flex-column flex-md-row">
            <img src="@/assets/icons/magnify-error.svg" width="70" height="70" class="mr-0 mr-md-3 d-block mx-auto mb-4 mb-md-0" alt="error">
            <div class="media-body">
                <h3 class="mt-0">You’ve missed something…</h3>
                To continue:

                <ul
                    class="mt-4 ml-0 pl-3"
                >
                    <li
                        v-for="(error, index) in errorsList"
                        :key="index"
                    >
                        <span>{{ error }} is required</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="mt-4 px-5">
            <b-button variant="primary" block
                @click.prevent="$emit('close')"
            >
                Close
            </b-button>
        </div>
    </div>
</template >

<script>
export default {
    name: 'ValidationErrorModalComponent',

    props: {
        errors: {}
    }, 

    computed: {
        errorsList() {
            var list = Object.keys(this.errors).filter(key => Array.isArray(this.errors[key]) && this.errors[key].length)
            var listToShow = []

            list.forEach(l => {
                if(l[0] != '_') {
                    listToShow.push(l)
                }
            })

            return listToShow
        }
    }
}
</script>