var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":'index'+_vm.index}},[(_vm.index !== 0)?_c('hr',{staticClass:"mt-5"}):_vm._e(),_c('b-row',{class:{'mt-5' : _vm.index !== 0}},[_c('b-col',{staticClass:"text-center",attrs:{"md":"1"}},[_c('div',{staticClass:"number mx-auto"},[_vm._v(" "+_vm._s(_vm.index + 1)+" ")]),(_vm.index > 0)?_c('div',{staticClass:"remove mt-3 cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.removeItem(_vm.index)}}},[_c('img',{attrs:{"src":require("@/assets/icons/delete.svg"),"alt":"delete item","width":"40","height":"40"}})]):_vm._e()]),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"md":"11"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'Name '+(_vm.index+1),"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Stock Name","label-for":"name","label-class":"required"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.stockName, 'is-invalid' : errors.length},attrs:{"name":"name","type":"text","placeholder":"Name"},model:{value:(_vm.item.stockName),callback:function ($$v) {_vm.$set(_vm.item, "stockName", $$v)},expression:"item.stockName"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'Purchased On '+(_vm.index+1),"name":"Purchased On","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Purchase Date","label-for":"purchasedOn","label-class":"required"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.item.purchasedOn, 'is-invalid' : errors.length},attrs:{"name":"purchasedOn","placeholder":"Choose a date","no-flip":"","max":_vm.max},model:{value:(_vm.item.purchasedOn),callback:function ($$v) {_vm.$set(_vm.item, "purchasedOn", $$v)},expression:"item.purchasedOn"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'Units '+(_vm.index+1),"name":"Units Purchased","rules":{regex: /^[0-9]+(\.[0-9]{1,5})?$/i}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Units Purchased","label-for":"units"}},[_c('b-input-group',[_c('b-form-input',{class:{'is-valid' : _vm.item.purchasedUnits, 'is-invalid' : errors.length},attrs:{"name":"units","placeholder":"Units purchased"},model:{value:(_vm.item.purchasedUnits),callback:function ($$v) {_vm.$set(_vm.item, "purchasedUnits", $$v)},expression:"item.purchasedUnits"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'Unit Price '+(_vm.index+1),"name":"Unit Price","rules":{regex: /^[0-9]+(\.[0-9]{1,5})?$/i}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Unit Price","label-for":"unitPrice"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.purchasedUnitPrice, 'is-invalid' : errors.length},attrs:{"name":"unitPrice","placeholder":"Unit Price"},model:{value:(_vm.item.purchasedUnitPrice),callback:function ($$v) {_vm.$set(_vm.item, "purchasedUnitPrice", $$v)},expression:"item.purchasedUnitPrice"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'Purchase Price '+(_vm.index+1),"name":"Purchase Price","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Price","label-for":"purchasePrice","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.purchasePrice, 'is-invalid' : errors.length},attrs:{"name":"purchasePrice","placeholder":"Purchase Price"},on:{"change":function($event){return _vm.checkForRoundNumber('purchasePrice')}},model:{value:(_vm.item.purchasePrice),callback:function ($$v) {_vm.$set(_vm.item, "purchasePrice", $$v)},expression:"item.purchasePrice"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'Sold On '+(_vm.index+1),"name":"Sold On","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sale Date","label-for":"soldOn","label-class":"required"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.item.soldOn,'is-invalid' : errors.length},attrs:{"name":"soldOn","placeholder":"Choose a date","no-flip":"","max":_vm.max},model:{value:(_vm.item.soldOn),callback:function ($$v) {_vm.$set(_vm.item, "soldOn", $$v)},expression:"item.soldOn"}}),(_vm.failures && _vm.failures['CapitalGains['+_vm.index+'].SoldOn'])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.failures && _vm.failures['CapitalGains['+_vm.index+'].SoldOn'][0])+" ")]):_vm._e(),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'Units Sold '+(_vm.index+1),"name":"Units Sold","rules":{regex: /^[0-9]+(\.[0-9]{1,5})?$/i}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Units Sold","label-for":"unitsSold"}},[_c('b-input-group',[_c('b-form-input',{class:{'is-valid' : _vm.item.saleUnits, 'is-invalid' : errors.length},attrs:{"name":"unitsSold","placeholder":"Units Sold"},model:{value:(_vm.item.saleUnits),callback:function ($$v) {_vm.$set(_vm.item, "saleUnits", $$v)},expression:"item.saleUnits"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'Unit Sold Price '+(_vm.index+1),"name":"Unit Sold Price","rules":{regex: /^[0-9]+(\.[0-9]{1,5})?$/i}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Unit Sold Price","label-for":"unitSoldPrice"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.saleUnitPrice, 'is-invalid' : errors.length},attrs:{"name":"unitSoldPrice","placeholder":"Unit Sold Price"},model:{value:(_vm.item.saleUnitPrice),callback:function ($$v) {_vm.$set(_vm.item, "saleUnitPrice", $$v)},expression:"item.saleUnitPrice"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'Sale Price '+(_vm.index+1),"name":"Sale Price","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Sold Price","label-for":"salePrice","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.salePrice, 'is-invalid' : errors.length},attrs:{"name":"salePrice","placeholder":"Sale Price"},on:{"change":function($event){return _vm.checkForRoundNumber('salePrice')}},model:{value:(_vm.item.salePrice),callback:function ($$v) {_vm.$set(_vm.item, "salePrice", $$v)},expression:"item.salePrice"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }