<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-11 variant="approval" class="pl-0">
                <h4>
                    Student Loans	
                </h4>

                <p class="mb-0">
                    Please confirm when the loan commenced, whether it is plan type 1 or 2, details of amounts deducted through PAYE or paid via self assessment and a copy of your annual statement
                </p>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="studentLoansDetails.length"
                            >
                                <student-loan-item-component 
                                    v-for="(item, index) in studentLoansDetails"
                                    :key="index"
                                    :item="item"
                                    :index="index"
                                />
                            </template>

                            <div class="p-4">
                                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                                    <div>
                                        <h5 class="mt-0">Add another student loan</h5>
                                        Would you like to add details of another student loan?
                                    </div>

                                    <b-button variant="primary" class="mt-4 mt-md-0"
                                        @click="createDefaultStudentLoansItem()"
                                    >
                                        Add student loan
                                    </b-button>    
                                </div> 
                            </div>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import StudentLoanItemComponent from '@/components/partials/StudentLoanItemComponent.vue'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'

export default {
    name: 'StudentLoansComponent', 

    components: {
        StudentLoanItemComponent
    },

    data() {
        return {
            saving: false
        }
    },

    mounted() {
        if(!this.studentLoansDetails.length) this.createDefaultStudentLoansItem()
    },

    computed: {
        ...mapGetters({
            studentLoansDetails: 'submissionStore/getStudentLoansDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getStudentLoansDetails: 'submissionStore/getSubmissionStudentLoansDetails',
            setNotification: 'notificationsStore/setNotification'
        }),

        createDefaultStudentLoansItem() {
            let studentLoanItem = {
                startedOn: null,
                planType: 1,
                deductedAmount: 0,
                willRepayInNextTwoYears: false
            }

            this.studentLoansDetails.push(studentLoanItem)

            setTimeout(() => {
                this.$scrollTo('#slindex'+(this.studentLoansDetails.length - 1), 800)
            }, 500)
        },

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let studentLoansModel = {
                submissionId: this.submissionId,
                studentLoans: JSON.parse(JSON.stringify(this.studentLoansDetails))
            }

            studentLoansModel.studentLoans.forEach(s => {
                s.deductedAmount = parseInt(s.deductedAmount * 100)
                s.planType = parseInt(s.planType)
            })

            axios.post(`submission/${this.submissionId}/student-loans`, studentLoansModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).finally(() => this.saving = false)
        }
    }
}
</script>