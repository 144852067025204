<template>   
    <b-card class="shadow mt-5" v-if="Object.keys(marriageAllowanceTransferDetails).length != 0 && marriageAllowanceTransferDetails.name != null">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Marriage Allowance Transfer Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredMarriageAllowanceTransferDetails" :items="marriageAllowanceTransferDetailsToArray">
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'MarriageAllowanceTransferDetailsReviewComponent',

    computed: {
        ...mapGetters({
            marriageAllowanceTransferDetails: 'submissionStore/getMarriageAllowanceTransferDetails',
        }),

        marriageAllowanceTransferDetailsToArray() {
            let array = []

            array.push(this.marriageAllowanceTransferDetails)

            return array
        },

        filteredMarriageAllowanceTransferDetails() {
            return [
                {
                    key: 'name',
                    label: 'Name',
                },

                {
                    key: 'incomeReceivedAmount',
                    label: 'Income Received Amount',
                    formatter: 'formatPrice'
                },

                {
                    key: 'dateOfBirth',
                    label: 'Date Of Birth',
                    formatter: 'formatDate'
                },

                {
                    key: 'niNumber',
                    label: 'Ni Number',
                },

                {
                    key: 'marriedOn',
                    label: 'Married On',
                    formatter: 'formatDate'
                }
            ]
        }
    }, 

    methods: {
        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        },

        formatDate(value) {
            return this.$moment(value).format('ll')
        },
    }
}
</script>