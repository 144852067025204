<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4>
                            Please tell us more about your other income							
                        </h4>

                        <p class="mb-0">
                            Did you receive any other UK income? For example employment lump sums, redundancy pay-outs, Property Income Dividends (PID) from Real Estate Investment Trusts (REIT)  , share schemes, life insurance gains, income from  settlements or trusts?
                        </p>
                    </div>

                    <template
                        v-if="otherIncomeDetails.length"
                    >
                        <div class="bg-white p-4">
                            <b-row
                                v-for="(item, index) in otherIncomeDetails"
                                :key="index"
                            >
                                <b-col md="6">
                                    <ValidationProvider name="Description Of Income" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="Details of income"
                                            label-for="description"
                                            label-class="required"
                                        >
                                            <b-form-textarea
                                                name="description"
                                                v-model="item.description"
                                                placeholder="Details of income"
                                                size="lg"
                                                :class="{'is-valid' : item.description, 'is-invalid' : errors.length}"
                                            ></b-form-textarea>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </div>
                    </template>
                </div>

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import Notifications from '@/Notifications'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'OtherIncomeComponent',

    components: {
        SaveAndContinueComponent
    },

    data() {
        return {
            saving: false,
            ValidationErrorModalComponent
        }
    },

    mounted() {
        if(this.otherIncomeDetails.length) this.markSectionAsComplete()
        if(!this.otherIncomeDetails.length) this.createDefaultItem()
    },  

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            otherIncomeDetails: 'submissionStore/getOtherIncomeDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getOtherIncomeDetails: 'submissionStore/getSubmissionOtherIncomeDetails',
            setNotification: 'notificationsStore/setNotification',
            setOtherIncome: 'sectionsCompleteStore/setOtherIncome'
        }), 

        createDefaultItem() {
            let newOtherIncomeItem = {
                description: null
            }

            this.otherIncomeDetails.push(newOtherIncomeItem)
        },

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setOtherIncome(true)
        },

        async submit() {            
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true 

            let otherIncomesModel = {
                submissionId: this.submissionId,
                otherIncomes: this.otherIncomeDetails
            }

            axios.post(`submission/${this.submissionId}/other-income-details`, otherIncomesModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).catch(() => {
                //
            }).finally(() => this.saving = false)
        }
    }
}
</script>