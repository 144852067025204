<template>
    <b-card class="shadow mt-5" v-if="Object.keys(taxLiabilityDetails).length != 0">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Tax Liability Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredTaxLiabilityDetails" :items="taxLiabilityDetailsToArray">
                    <template #cell(hasTaxLiabilityToCollectNextYear)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'TaxLiabilityDetailsReviewComponent',

    computed: {
        ...mapGetters({
            taxLiabilityDetails: 'submissionStore/getTaxLiabilityDetails',
        }),

        taxLiabilityDetailsToArray() {
            let array = []

            array.push(this.taxLiabilityDetails)

            return array
        },

        filteredTaxLiabilityDetails() {
            return [
                {
                    key: 'hasTaxLiabilityToCollectNextYear',
                    label: 'Has Tax Liability To Collect Next Year',
                    formatter: 'formatBool'
                },
            ]
        }
    },

    methods: {
         formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>