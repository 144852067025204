<template>
    <b-card class="shadow mt-5" v-if="Object.keys(taxAvoidanceDetails).length != 0">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Tax Avoidance Details
                </h2>

                <b-table fixed outlined responsive striped hover :fields="filteredTaxAvoidanceDetails" :items="taxAvoidanceDetailsToArray">
                    <template #cell(isUsingTaxAvoidance)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'TaxAvoidanceDetailsReviewComponent',

    computed: {
        ...mapGetters({
            taxAvoidanceDetails: 'submissionStore/getTaxAvoidanceDetails',
        }),

        taxAvoidanceDetailsToArray() {
            let array = []

            array.push(this.taxAvoidanceDetails)

            return array
        },

        filteredTaxAvoidanceDetails() {
            return [
                {
                    key: 'isUsingTaxAvoidance',
                    label: 'Is Using Tax Avoidance',
                    formatter: 'formatBool'
                },

                {
                    key: 'description',
                    label: 'Description'
                },
            ]
        }
    },

    methods: {
        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>