<template>
    <b-card class="shadow mt-5" v-if="Object.keys(childBenefitDetails).length != 0">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Child Benefit Details
                </h2>
                
                <b-table outlined responsive striped hover :fields="filteredChildBenefitDetailsFields" :items="childBenefitDetailsToArray">
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'ChildBenefitDetailsReviewComponent',

    computed: {
        ...mapGetters({
            childBenefitDetails: 'submissionStore/getChildBenefitDetails',
        }), 

        childBenefitDetailsToArray() {
            let array = []

            array.push(this.childBenefitDetails)

            return array
        },

        filteredChildBenefitDetailsFields() {
            return [
                {
                    key: 'receivedAmount',
                    label: 'Received Amount',
                    formatter: 'formatPrice',
                }, 

                {
                    key: 'numberOfChildren',
                    label: 'Number Of Children',
                }
            ]
        }
    }, 

    methods: {
        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        }
    }
}
</script>