<template>
    <b-container>
        <b-row>
            <b-col class="text-center">
                <h2>
                    Tax Year
                </h2>
                
                <p class="lead"> 
                    Which tax year are you filing for?
                </p>        
            </b-col>    
        </b-row>

        <template
            v-if="loading"
        >
            <b-row class="justify-content-center mt-4">
                <b-col>
                    <div class="loader-container">
                        <div class="loader"></div>
                        
                        <p>
                            Please wait..
                        </p>
                    </div>
                </b-col>
            </b-row>
        </template>

        <template
            v-else
        >
            <b-card class="select mt-4"
                v-for="(year, index) in taxYears"
                :class="{active : selectedTaxYear == year.id}"
                :key="index"
            >
                <b-row class="align-items-center">
                    <b-col md="2">
                        <img class="img-fluid" src="@/assets/icons/taxyear1.svg" alt="tax year">
                    </b-col>

                    <b-col md="7" class="mt-4 mt-md-0">
                        <h5>
                            {{ year.label }}
                        </h5>

                        <p class="mb-0">
                            {{ year.startDate | moment("ll") }} - {{ year.endDate | moment("ll") }}
                        </p>
                        
                        <p class="mb-0">
                            Deadline {{ year.deadline | moment("ll") }}
                        </p>
                    </b-col>
                    
                    <template
                        v-if="canSelectThisYear(year)"
                    >
                        <b-col md="3" class="mt-4 mt-md-0">
                            <b-button variant="primary" block
                                @click="next(year)"
                            >
                                {{ (selectedTaxYear == year.id) ? 'Selected' : 'Select' }}
                            </b-button>
                        </b-col>
                    </template>

                    <template
                        v-else
                    >
                        <b-col md="3" class="mt-4 mt-md-0">
                            <p class="mb-0 color-primary">
                                You already have a submission for this tax year!
                            </p>
                        </b-col>
                    </template>
                </b-row>
            </b-card>
        </template>
    </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'TaxYear',

    data() {
        return {
            loading: true
        }
    },

    mounted() {
        this.getTaxYears().then(() => {
            this.getSubmissions()
        }).finally(() => {
            this.loading = false
        })
    },

    computed: {
        ...mapGetters({
            selectedTaxYear: 'submissionStore/getSelectedTaxYearId',
            taxYears: 'taxYearStore/getTaxYears',
            submissions: 'submissionsStore/getSubmissions',
        })
    },

    methods: {
        ...mapActions({
            getTaxYears: 'taxYearStore/getTaxYears',
            getSubmissions: 'submissionsStore/getSubmissions',
            selectTaxYear: 'submissionStore/setSubmissionTaxYear',
            markTaxYearComplete: 'userProgressStore/setTaxYearComplete'
        }),

        next(year) {
            this.selectTaxYear(year.id)
            this.markTaxYearComplete()
            this.$router.push({name: 'Questions'})
        }, 

        canSelectThisYear(year) {
            if(!this.submissions.length) {
                return true
            }

            let answer = true

            this.submissions.filter(sub => {
                if(sub.taxYearId == year.id) {
                    answer = false
                }
            })

            return answer
        }
    }
}
</script>
