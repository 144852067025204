<template>
    <div :id="'pacindex'+index" class="bg-white p-4">
        <b-row>
            <b-col md="1" class="text-center">
                <div class="number mx-auto">
                    {{ index + 1 }}
                </div>

                <div class="remove mt-3 cursor-pointer"
                    v-if="index > 0"
                    @click.prevent="removeItem(index)"
                >
                    <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                </div>
            </b-col>

            <b-col md="11" class="mt-4 mt-md-0">
                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'ar'+index" name="Amount Received" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group
                                label="Paid Amount"
                                label-for="paidAmount"
                                label-class="required"
                            >   
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="paidAmount"
                                        v-model="item.paidAmount"
                                        placeholder="Paid Amount"
                                        :class="{'is-valid' : item.paidAmount, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('paidAmount')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <div class="d-flex flex-column flex-md-row align-items-center justify-content-between mt-4 mt-md-0">
                            <h4 class="mb-0">
                                Is Amount Gross?
                            </h4>

                            <div class="mt-4 mt-md-0">
                                <b-button class="btn-bool mr-1"
                                    @click="item.isAmountGross = !item.isAmountGross"
                                    :class="{active: item.isAmountGross}"
                                >
                                    Yes
                                </b-button>

                                <b-button class="btn-bool"
                                    @click="item.isAmountGross = !item.isAmountGross"
                                    :class="{negativeActive: !item.isAmountGross}"
                                >
                                    No
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>    
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'PensionAnnuityContributionItemComponent',

    props: {
        item: {},
        index: Number,
    },

    mounted() {
        this.item.paidAmount = (this.item.paidAmount / 100).toFixed(2)
    },

    computed: {
        ...mapGetters({
            pensionAnnuityContributionsDetails: 'submissionStore/getPensionAnnuityContributionsDetails',
        })
    },

    methods: {
        removeItem(index) {
            this.pensionAnnuityContributionsDetails.splice(index, 1);

            setTimeout(() => {
                this.$scrollTo('#pacindex'+(this.pensionAnnuityContributionsDetails.length - 1), 800)
            }, 500)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        }
    }
}
</script>