<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-5 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/pension.svg" width="30" height="30" /> Pension Income
            </b-button>
        </b-card-header>
        
        <b-collapse visible id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">P60 or pension statement</p>
                                    <p>In that same way you receive a P60 from your employer each year, if you receive pension income you should receive a P60 too. If not, the minimum you should receive is a pension statement. Any pension income you receive will be included in this paperwork.</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import Helpers from '@/Helpers'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'

export default {
    name: 'PensionIncomeWhatYouNeedComponent',

    data() {
        return {
            statements: false,
            Helpers,
            HelperModalComponent
        }
    },

    methods: {
        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        }
    }
}
</script>