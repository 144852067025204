<template>
    <b-container fluid>
        <b-row class="justify-content-center">
            <b-col md="11">
                <b-row class="mb-3 mb-md-5">
                    <b-col>
                        <h2>
                            Enter your information
                        </h2>
                        
                        <p class="lead"> 
                            Ok, let's get your information entered and the relevant documents uploaded for your Self Assessment Tax Return Assistant to review and get your approval for sending! 
                        </p>        
                    </b-col>    
                </b-row>

                <template>
                    <b-row>
                        <b-col>
                            <field-resolver-component 
                                v-if="submissionId != null"
                                :key="fieldResolverKey"
                            />
                        </b-col>
                    </b-row>
                </template>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex'
import FieldResolverComponent from '@/components/field-resolver-components/FieldResolverComponent.vue'

export default {
    name: 'Information',

    mounted() {
        if(!this.submissionId) {
            return this.$router.push({
                name: 'MyAccount'
            })
        }
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId',
            fieldResolverKey: 'submissionStore/getFieldResolverKey'
        })
    },

    components: {
        FieldResolverComponent
    }
}
</script>