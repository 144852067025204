<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4>
                            Please tell us more about your other tax reliefs							
                        </h4>

                        <p class="mb-0">
                            For example, community investment tax relief, venture capital, EIS, SEIS, maintenance/alimony payments?  
                        </p>
                    </div>

                    <template
                        v-if="otherTaxReliefDetails.length"
                    >
                        <div class="bg-white p-4">
                            <b-row
                                v-for="(item, index) in otherTaxReliefDetails"
                                :key="index"
                            >
                                <b-col md="6">
                                    <ValidationProvider name="Description Of Tax Relief" rules="required" v-slot="{ errors }">
                                        <b-form-group
                                            label="Details of tax relief"
                                            label-for="description"
                                            label-class="required"
                                        >
                                            <b-form-textarea
                                                name="description"
                                                v-model="item.description"
                                                placeholder="Details of tax relief"
                                                size="lg"
                                                :class="{'is-valid' : item.description, 'is-invalid' : errors.length}"
                                            ></b-form-textarea>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </div>
                    </template>
                </div>

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import Notifications from '@/Notifications'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'OtherTaxReliefsComponent',

    components: {
        SaveAndContinueComponent
    },

    data() {
        return {
            failures: {},
            saving: false,
            ValidationErrorModalComponent
        }
    },

    mounted() {
        if(this.otherTaxReliefDetails.length) this.markSectionAsComplete()
        if(!this.otherTaxReliefDetails.length) this.createDefaultItem()
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            otherTaxReliefDetails: 'submissionStore/getOtherTaxReliefDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getOtherTaxReliefDetails: 'submissionStore/getSubmissionOtherTaxReliefDetails',
            setNotification: 'notificationsStore/setNotification',
            setOtherTaxRelief: 'sectionsCompleteStore/setOtherTaxRelief'
        }), 

        createDefaultItem() {
            let newOtherTaxReliefItem = {
                description: null
            }

            this.otherTaxReliefDetails.push(newOtherTaxReliefItem)
        },

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setOtherTaxRelief(true)
        },

        async submit() {            
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true

            let otherTaxReliefModel = {
                submissionId: this.submissionId,
                otherTaxReliefs: this.otherTaxReliefDetails
            }

            axios.post(`submission/${this.submissionId}/other-tax-reliefs`, otherTaxReliefModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).catch(() => {
                //
            }).finally(() => this.saving = false)
        }
    }
}
</script>