<template>
    <div>
        <b-container>
            <b-row class="justify-content-center">
                <b-col md="6">
                    <b-card no-body class="p-0 shadow">
                        <div class="p-5">
                            <h2>
                                Reset Password
                            </h2>

                            <ValidationObserver v-slot="{ handleSubmit }">
                                <b-form @submit.prevent="handleSubmit(onSubmit)" class="mt-4">
                                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                        <b-form-group 
                                            label-for="email"
                                            label="Email Address"
                                        >
                                            <b-form-input
                                                name="email"
                                                :class="{'is-invalid' : errors.length}"
                                                v-model="emailAddress"
                                                type="email"
                                                placeholder="Email"
                                            ></b-form-input>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>

                                    <ValidationProvider name="Password" rules="required|min_password_length:8" v-slot="{ errors }">
                                        <b-form-group 
                                            label-for="password"
                                            label="Password"
                                        >
                                            <b-form-input
                                                name="password"
                                                :class="{'is-invalid' : errors.length}"
                                                v-model="password"
                                                type="password"
                                                placeholder="Password"
                                            ></b-form-input>

                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>

                                            <small class="text-danger validation-error d-block"
                                                v-if="failures && failures.NewPassword"
                                            >
                                                {{ failures.NewPassword[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>

                                    <ValidationProvider name="Password Confirm" vid="passwordConfirm" rules="required|min_password_length:8|passwordConfirmed:Password" v-slot="{ errors }">
                                        <b-form-group 
                                            label-for="passwordConfirm"
                                            label="Confirm Password"
                                        >
                                            <b-form-input
                                                name="passwordConfirm"
                                                :class="{'is-invalid' : errors.length}"
                                                v-model="confirmPassword"
                                                type="password"
                                                placeholder="Re-enter Password"
                                            ></b-form-input>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>

                                    <b-button type="submit" variant="primary" size="lg" class="mt-3" block>
                                        Reset password <span v-if="loading" class="btn-loader"></span>
                                    </b-button>
                                </b-form>
                            </ValidationObserver>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import axios from 'axios'
import Notifications from '@/Notifications'

export default {
    name: 'ResetPassword',

    data() {
        return {
            loading: false,
            emailAddress: '',
            password: '',
            confirmPassword: '',
            showPasswordResetSuccessMessage: false,
            passwordResetSuccessMessage: Notifications.PASSWORD_RESET_SUCCESS_MESSAGE,
            failures: {}
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'
        }),

        onSubmit() {
            this.resetPassword()
        },

        resetPassword() {
            this.loading = true
            this.failures = {}

            let encodedToken = encodeURIComponent(this.$route.query.token).replace(/%20/g, '+')
            let decodedToken = decodeURIComponent(encodedToken)

            let resetPasswordModel = {
                email: this.emailAddress,
                passwordResetToken: decodedToken,
                newPassword: this.password,
                confirmNewPassword: this.confirmPassword,
            }

            axios.put('authentication/password', resetPasswordModel).then(() => {
                this.$router.push({name: 'Login', params: {passwordResetSuccessMessage : this.passwordResetSuccessMessage}})
            }).catch((err) => {
                this.failures = err.data.failures

                this.setNotification({
                    type: 'failure',
                    message: Notifications.RESET_PASSWORD_FAILURE
                })
            }).finally(() => {
                this.loading = false
            })
        }
    }   
}
</script>