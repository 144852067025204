<template>  
    <b-card class="shadow mt-5" v-if="Object.keys(etoroDetails).length">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Etoro Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredEtoroDetails" :items="etoroDetailsToArray">
                    <template #cell(isMember)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>

                    <template #cell(memberLevel)="data">
                        {{ (data.item.memberLevel != null) ? data.item.memberLevel : 'N/A' }}
                    </template>
                </b-table>

                <b-table class="mb-0" outlined responsive striped hover :fields="filteredEtoroSaleItemFields" :items="etoroDetails.etoroSaleItems"
                    v-if="!etoroDetails.isMember && etoroDetails.etoroSaleItems.length"
                ></b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'EtoroDetailsReviewComponent',

    computed: {
        ...mapGetters({
            etoroDetails: 'submissionStore/getEtoroDetails'
        }),

        etoroDetailsToArray() {
            let array = []

            array.push(this.etoroDetails)

            return array
        },

        filteredEtoroSaleItemFields() {
            return [
                {
                    key: 'stockName',
                    label: 'Stock Name'
                },

                {
                    key: 'purchasedUnits',
                    label: 'Purchased Units',
                    formatter: 'formatUnitDecimal'
                },

                {
                    key: 'purchasedUnitPrice',
                    label: 'Purchased Unit Price',
                    formatter: 'formatDecimal'
                },

                {
                    key: 'purchasePrice',
                    label: 'Purchase Price',
                    formatter: 'formatPrice'
                },

                {
                    key: 'soldOn',
                    label: 'Sold On',
                    formatter: 'formatDate'
                },

                {
                    key: 'saleUnits',
                    label: 'Sale Units',
                    formatter: 'formatUnitDecimal'
                },

                {
                    key: 'saleUnitPrice',
                    label: 'Sale Units Price',
                    formatter: 'formatDecimal'
                },

                {
                    key: 'salePrice',
                    label: 'Sale Price',
                    formatter: 'formatPrice'
                }
            ]
        },

        filteredEtoroDetails() {
            return [
                {
                    key: 'isMember',
                    label: 'Is Member',
                    formatter: 'formatBool'
                },

                {
                    key: 'memberLevel',
                    label: 'Member Level'
                }
            ]
        }
    },

    methods: {
        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        }, 

        formatDecimal(value) {
            let v = value / 100
            return '£'+parseFloat(v.toFixed(5))
        },

        formatUnitDecimal(value) {
            let v = value / 100
            return parseFloat(v.toFixed(5))
        },

        formatReference(value) {
            return '#'+value
        },

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        },
        
        formatDate(value) {
            return this.$moment(value).format('DD/MM/YYYY')
        }
    }
}
</script>