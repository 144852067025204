<template>
    <div :id="'slindex'+index" class="bg-white p-4">
        <b-row>
            <b-col md="1" class="text-center">
                <div class="number mx-auto">
                    {{ index + 1 }}
                </div>

                <div class="remove mt-3 cursor-pointer"
                    v-if="index > 0"
                    @click.prevent="removeItem(index)"
                >
                    <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                </div>
            </b-col>

            <b-col md="11" class="mt-4 mt-md-0">
                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'da'+index" name="Deducted Amount" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group
                                label="Deducted Amount"
                                label-for="deductedAmount"
                                label-class="required"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="deductedAmount"
                                        v-model="item.deductedAmount"
                                        placeholder="Deducted Amount"
                                        :class="{'is-valid' : item.deductedAmount, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('deductedAmount')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">  
                        <ValidationProvider :vid="'so'+index" name="Started On" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Started On"
                                label-for="startedOn"
                                label-class="required"
                            >
                                <b-form-datepicker
                                    v-model="item.startedOn" 
                                    name="startedOn"
                                    :class="{'is-valid' : item.startedOn, 'is-invalid' : errors.length}"
                                    placeholder="Choose a date"
                                    hide-header
                                    show-decade-nav
                                    no-flip
                                    :max="max"
                                >
                                </b-form-datepicker>
                                    
                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'pt'+index" name="Plan Type" rules="required|numeric|min_plan_type:1|max_plan_type:2" v-slot="{ errors }">
                            <b-form-group
                                label="Plan Type"
                                label-for="planType"
                                label-class="required"
                            >
                                <b-input-group>
                                    <b-form-input
                                        name="planType"
                                        v-model.number="item.planType"
                                        type="number"
                                        placeholder="Plan Type"
                                        :class="{'is-valid' : item.planType, 'is-invalid' : errors.length}"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row> 

                <div class="py-4">
                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <h4 class="mb-0">
                            Will you repay in next two years?
                        </h4>

                        <div class="mt-4 mt-md-0 flex-shrink-0 ml-4">
                            <b-button class="btn-bool mr-1"
                                @click="item.willRepayInNextTwoYears = !item.willRepayInNextTwoYears"
                                :class="{active: item.willRepayInNextTwoYears}"
                            >
                                Yes
                            </b-button>

                            <b-button class="btn-bool"
                                @click="item.willRepayInNextTwoYears = !item.willRepayInNextTwoYears"
                                :class="{negativeActive: !item.willRepayInNextTwoYears}"
                            >
                                No
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'StudentLoanItemComponent',
    
    props: {
        item: {},
        index: Number
    },

    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const maxDate = new Date(today)

        return {
            max: maxDate
        }
    },

    mounted() {
        this.item.deductedAmount = (this.item.deductedAmount / 100).toFixed(2)
    },

    computed: {
        ...mapGetters({
            studentLoansDetails: 'submissionStore/getStudentLoansDetails',
        })
    },

    methods: {     
        removeItem(index) {
            this.studentLoansDetails.splice(index, 1)

            setTimeout(() => {
                this.$scrollTo('#slindex'+(this.studentLoansDetails.length - 1), 800)
            }, 500)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        }
    }
}
</script>