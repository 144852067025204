<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-9 variant="approval" class="pl-0">
                <h4 class="mb-0">
                    Tax Liability
                </h4>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="Object.keys(taxLiabilityDetails).length != 0"
                            >
                                <div class="p-4 bg-white">
                                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                        <h4 class="mb-0">
                                            If you believe you will have a tax liability and have a PAYE code (i.e are in employment), let us know if you wish HMRC to collect the tax due
                                        </h4>

                                        <div class="flex-shrink-0 ml-0 ml-md-3 mt-4 mt-md-0">
                                            <b-button class="btn-bool mr-1"
                                                @click="taxLiabilityDetails.hasTaxLiabilityToCollectNextYear = !taxLiabilityDetails.hasTaxLiabilityToCollectNextYear"
                                                :class="{active: taxLiabilityDetails.hasTaxLiabilityToCollectNextYear}"
                                            >
                                                Yes
                                            </b-button>

                                            <b-button class="btn-bool"
                                                @click="taxLiabilityDetails.hasTaxLiabilityToCollectNextYear = !taxLiabilityDetails.hasTaxLiabilityToCollectNextYear"
                                                :class="{negativeActive: !taxLiabilityDetails.hasTaxLiabilityToCollectNextYear}"
                                            >
                                                No
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'

export default {
    name: 'TaxLiabilityComponent', 

    data() {
        return {
            saving: false
        }
    },

    computed: {
        ...mapGetters({
            taxLiabilityDetails: 'submissionStore/getTaxLiabilityDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'
        }),

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let taxLiabilityModel = {
                submissionId: this.submissionId,
                hasTaxLiabilityToCollectNextYear: true
            }

            axios.post(`submission/${this.submissionId}/tax-liability`, taxLiabilityModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).finally(() => this.saving = false)
        }
    }
}
</script>