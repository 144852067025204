<template>
    <b-card class="shadow mt-5" v-if="foreignIncomeDetails.length">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Foreign Income Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredForeignIncomeDetailsFields" :items="foreignIncomeDetails"></b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'ForeignIncomeDetailsReviewComponent',

    computed: {
        ...mapGetters({
            foreignIncomeDetails: 'submissionStore/getForeignIncomeDetails',
        }),

        filteredForeignIncomeDetailsFields() {
            return [
                {
                    key: 'employerName',
                    label: 'Employer Name'
                },

                {
                    key: 'payeReference',
                    label: 'PAYE Reference',
                    formatter: 'formatReference'
                },

                {
                    key: 'receivedAmount',
                    label: 'Received Amount',
                    formatter: 'formatPrice'
                },

                {
                    key: 'taxDeductedAmount',
                    label: 'Tax Deducted Amount',
                    formatter: 'formatPrice'
                },
            ]
        }
    },

    methods: {
        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        }, 

        formatReference(value) {
            return '#'+value
        }
    }
}
</script>