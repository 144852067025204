<template>
    <div class="card card-body shadow">
        <h3 class="mb-4">
            Tax Returns <span v-if="loading" class="btn-loader"></span>
        </h3>

        <template
            v-if="taxYears.length"
        >
            <b-card class="mt-2"
                v-for="taxYear in filteredTaxYears"
                :key="taxYear.id"
                :class="{
                    'completed-submission' : doesThisTaxYearHaveACompleteSubmission(taxYear)
                }"
            >
                <b-row class="align-items-center">
                    <b-col md="8">
                        <h5 class="mb-0">{{ taxYear.label }}</h5>
                        <p class="mt-2 mb-0" v-html="getTextForTaxYear(taxYear)"></p>
                    </b-col>

                    <b-col md="4" class="text-right center-mobile">
                        <template
                            v-if="canSelectThisYear(taxYear) && !doesThisTaxYearHaveACompleteSubmission(taxYear)"
                        >
                            <b-button variant="success" class="flex-shrink-0 d-flex w-100 justify-content-center mt-3 mt-md-0" @click="startNewSubmission(taxYear)">Start</b-button>
                        </template>

                        <template
                            v-if="!canSelectThisYear(taxYear) && !doesThisTaxYearHaveACompleteSubmission(taxYear)"
                        >
                            <b-button variant="primary" class="flex-shrink-0 d-flex w-100 justify-content-center mt-3 mt-md-0" @click="continueSubmission(taxYear)">Continue</b-button>
                            <div class="text-center">
                                <span @click="restartSubmission(taxYear)" class="text-primary text-underlined cursor-pointer d-block mt-1 font-weight-bold">Restart submission</span>
                            </div>
                            
                        </template>

                        <template
                            v-if="doesThisTaxYearHaveACompleteSubmission(taxYear)"
                        >
                            <img src="@/assets/icons/gradient-tick.svg" width="40" height="40" />
                        </template>
                    </b-col>
                </b-row>
            </b-card>
        </template>

        <template
            v-if="!taxYears.length && !loading"
        >
            <b-alert show variant="warning">
                {{ Notifications.NO_TAX_YEARS_RETURNED }}
            </b-alert>
        </template>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Notifications from '@/Notifications'
import RestartSubmissionModalComponent from '@/components/modal-components/RestartSubmissionModalComponent.vue'

export default {
    name: 'AvailableTaxReturnsComponent',

    data() {
        return {
            loading: true,
            Notifications,
            RestartSubmissionModalComponent
        }
    },

    async mounted() {
        try {
            await this.getSubmissions()
            await this.getTaxYears()
        }
        catch {
            this.setNotification({
                message: Notifications.GENERAL_ERROR,
                type: 'failure'
            })
        }
        finally {
            this.loading = false
        }
    },

    computed: {
        ...mapGetters({
            taxYears: 'taxYearStore/getTaxYears',
            submissions: 'submissionsStore/getSubmissions',
        }),

        filteredTaxYears() {
            var years = this.taxYears.filter(x => x.showOnUi)

            years.sort((a, b) => {
                return new Date(a.startDate) - new Date(b.startDate)
            })

            return years
        }
    },

    methods: {
        ...mapActions({
            getSubmissions: 'submissionsStore/getSubmissions',
            getSubmission: 'submissionStore/getSubmission',
            getTaxYears: 'taxYearStore/getTaxYears',
            selectTaxYear: 'submissionStore/setSubmissionTaxYear',
            markTaxYearComplete: 'userProgressStore/setTaxYearComplete',
            clearSubmissionState: 'submissionStore/clearSubmussionState',
            setNotification: 'notificationsStore/setNotification'
        }),

        checkDateIsInPast(date) {
            return this.$moment(date).add(1, 'days').isBefore()
        },

        getFormattedDate(date) {
            return this.$moment(date).format('ll')
        },

        getSubmissionForTaxYear(year) {
            let submission = null 

            this.submissions.filter(sub => {
                if(sub.taxYearId == year.id) {
                    submission = sub
                }
            })

            return submission
        },

        canSelectThisYear(year) {
            if(!this.submissions.length) {
                return true
            }

            let answer = true

            this.submissions.filter(sub => {
                if(sub.taxYearId == year.id) {
                    answer = false
                }
            })

            return answer
        }, 

        doesThisTaxYearHaveACompleteSubmission(year) {
            if(!this.submissions.length) {
                return false
            }

            let answer = false 

            this.submissions.filter(sub => {
                if(sub.taxYearId == year.id) {
                    if(sub.isSubmitted) answer = true
                }
            })

            return answer
        },

        getTextForTaxYear(year) {
            if(this.checkDateIsInPast(year.deadline) && !this.doesThisTaxYearHaveACompleteSubmission(year)) {
                return `Deadline was ${this.getFormattedDate(year.deadline)}.<br/><span class="color-primary">Dont' worry, we can still submit this return for you.</span>`;
            } 
            
            if(this.canSelectThisYear(year) && !this.doesThisTaxYearHaveACompleteSubmission(year)) {
                return `Due on ${this.getFormattedDate(year.deadline)}.`;
            }

            if(this.doesThisTaxYearHaveACompleteSubmission(year)) {
                return "This tax return has been completed.";
            } else {
                return `Due on ${this.getFormattedDate(year.deadline)}.<br/>Continue with this application.`
            }
        },

        continueSubmission(year) {
            this.submissions.filter(sub => {
                if(sub.taxYearId == year.id) {
                    this.getSubmission(sub.id).then(() => this.$router.push({name: 'Checklist'}))
                }
            })
        },

        restartSubmission(year) {
            let sub = this.getSubmissionForTaxYear(year)

            if(sub) {
                this.$modal.show(RestartSubmissionModalComponent, { submissionId: sub.id })
            }
        },

        startNewSubmission(year) {
            this.clearSubmissionState()
            this.selectTaxYear(year.id)
            this.markTaxYearComplete()
            this.$router.push({name: 'Reasons'})
        }
    }
}
</script>