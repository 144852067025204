<template>
    <div>
        <b-container>
            <b-row class="mb-5">
                <b-col class="text-center">
                    <div class="bg-primary alt py-5 text-white">
                        <h2 class="text-white">
                            Payment Complete
                        </h2>
                        
                        <p> 
                            You will receive an email from Stripe with a copy of your invoice
                        </p>  

                        <p class="mb-0 mt-5">
                            Your payment reference Is
                        </p>

                        <p class="lead">
                            {{ submission.reference }}
                        </p>

                        <p class="mb-0">
                            Date of payment
                        </p>

                        <p class="lead mb-0">
                            {{ submission.paidOn | moment("dddd, MMMM Do YYYY") }}
                        </p>
                    </div>      
                </b-col>    
            </b-row>

            <b-row>
                <b-col>
                    <p class="mb-0">
                        Name
                    </p>

                    <p class="text-dark lead">
                        {{ userFullName }}
                    </p>

                    <p class="mb-0">
                        Email address
                    </p>

                    <p class="text-dark lead">
                        {{ user.email }}
                    </p>

                    <p class="mb-0">
                        Customer reference
                    </p>

                    <p class="text-dark lead">
                        {{ submission.reference }}
                    </p>

                    <p class="h5 text-dark font-weight-bold mb-0">
                        Amount paid
                    </p>

                    <p class="h1 text-dark heading-font">
                        &pound;{{ (submission.amount / 100).toFixed(2) }}
                    </p>
                </b-col>
            </b-row>
        </b-container>

        <div class="bg-grey py-5 mt-5">
            <b-container>
                <b-row>
                    <b-col>
                        <h4>
                           What's next?
                        </h4>

                        <ol class="mt-4">
                            <li>
                                Get paired with a tax return accountant
                            </li>

                            <li>
                                Your tax return accountant will prepare your Self Assessment and send it to you for approval
                            </li>
                        </ol>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="py-5">
            <b-row class="text-center justify-content-center">
                <b-col md="10">
                    <h2>
                        Thank you for your payment!
                    </h2>

                    <p>
                        {{ Notifications.SUBMISSION_COMPLETION_SUCCESS }}
                    </p>

                    <div class="mt-5">
                        <b-button variant="outline-primary" class="mr-2"
                            :to="{name: 'MyAccount'}"
                        >
                            My Account
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Notifications from '@/Notifications'

export default {
    name: 'PaymentComplete',

    data() {
        return {
            Notifications
        }
    },

    mounted() {
        if(this.submission.reference == null || this.submission.paidOn == null || this.submission.amount == null) {
            return this.$router.push({name: 'MyAccount'})
        }
    },

    computed: {
        ...mapGetters({
            submission: 'submissionStore/getPaymentReferenceDetails',
            user: 'userStore/getUser',
            userFullName: 'userStore/getUserFullName'
        })
    }
}
</script>
