<template>
    <b-container>
        <b-row class="justify-content-center">
            <b-col md="6">
                <h1>
                    Error - 404
                </h1>

                <p class="lead">
                    It looks like you're in the wrong place...
                </p>

                <b-button variant="primary" class="mt-5"
                    :to="{name: 'MyAccount'}"
                >
                    Go Home
                </b-button>     
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'Error404'
    }
</script>