<template>
    <b-container>
        <b-row class="justify-content-center"
            v-if="registerSuccessMessage"
        >
            <b-col md="5">
                <b-alert variant="success" show dismissible>
                    {{ registerSuccessMessage }}
                </b-alert>
            </b-col>
        </b-row>

        <b-row class="justify-content-center"
            v-if="passwordResetSuccessMessage"
        >
            <b-col md="5">
                <b-alert variant="success" show dismissible>
                    {{ passwordResetSuccessMessage }}
                </b-alert>
            </b-col>
        </b-row>
        
        <b-row class="justify-content-center">
            <b-col md="8" lg="5">
                <b-card no-body class="p-0 shadow">
                    <div class="p-5">
                        <h2>
                            Log in
                        </h2>
    
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <b-form @submit.prevent="handleSubmit(onSubmit)" class="mt-4">
                                <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
                                    <b-form-group
                                        label-for="email"
                                        label="Email Address"
                                    >
                                        <b-form-input
                                            id="email"
                                            v-model="fields.email"
                                            type="text"
                                            placeholder="Email"
                                            :class="{'is-invalid' : errors.length}"
                                        ></b-form-input>

                                        <small v-if="errors.length" class="text-danger validation-error d-block">
                                            {{ errors[0] }}
                                        </small>
                                    </b-form-group>
                                </ValidationProvider>
                                
                                <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                                    <b-form-group 
                                        label-for="password"
                                        label="Password"
                                    >
                                        <b-form-input
                                            id="password"
                                            v-model="fields.password"
                                            type="password"
                                            placeholder="Password"
                                            :class="{'is-invalid' : errors.length}"
                                        ></b-form-input>

                                        <small v-if="errors.length" class="text-danger validation-error d-block">
                                            {{ errors[0] }}
                                        </small>
                                    </b-form-group>
                                </ValidationProvider>
                        
                                <b-button type="submit" variant="primary" block>
                                    Log in <span v-if="loading" class="btn-loader"></span>
                                </b-button>
                            </b-form>

                            <p class="text-sm text-center mt-2 mb-0">
                                <router-link class="follow underlined" :to="{name: 'ForgotPassword'}">
                                    Forgot password?
                                </router-link>
                            </p>
                        </ValidationObserver>

                        <template
                            v-if="authError != null"
                        >
                            <b-alert variant="danger" show class="mt-3">
                                {{ authError }}
                            </b-alert>
                        </template>
                    </div>
                </b-card>

                <p class="text-white font-weight-bold mt-4 text-center">New user? <router-link class="follow underlined" :to="{name: 'Register'}">Create an account</router-link></p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'Login', 

    props: {
        registerSuccessMessage: String,
        passwordResetSuccessMessage: String
    },

    data() {
        return {
            fields: {},
            loading: false,
            
        }
    },

    watch: {
        isAuthenticated() {
            if(this.isAuthenticated && this.isCustomer) {
                this.$router.push({name: 'MyAccount'})
            } else {
                this.$router.push({name: 'Dashboard'})
            }
        },

        authError() {
            if(this.authError != null) this.loading = false
        }
    },

    computed: {
        ...mapGetters({
            isAuthenticated: 'userStore/authenticated',
            isCustomer: 'userStore/getIsUserCustomer',
            authError: 'userStore/getAuthError'
        })
    },

    methods: {
        ...mapActions({
            authenticate: 'userStore/getToken'
        }), 

        onSubmit() {
            this.loading = true    
    
            this.authenticate(this.fields)
        }
    }
}
</script>