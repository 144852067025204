export default {
    namespaced: true,

    state: {
        notifications: []
    },

    getters: {
        getNotifications(state) {
            return state.notifications
        }
    },

    mutations: {
        PUSH_NOTIFICATION(state, data) {
            state.notifications.push(data)
        },

        REMOVE_NOTIFICATION(state) {
            state.notifications.pop()
        }
    }, 

    actions: {
        async setNotification({commit}, data) {

            commit('PUSH_NOTIFICATION', data)

            setTimeout(() => {
                commit('REMOVE_NOTIFICATION')
            }, 3000) 
        }
    }
}