<template>
    <b-card class="shadow mt-5" v-if="Object.keys(capitalGainsLossesDetails).length != 0">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Capital Gains &amp; Losses Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredCapitalGainsDetailsFields" :items="capitalGainsDetails">
                    <template #cell(hasEntrepreneursRelief)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-su.ccess' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>

                    <template #cell(hasPrivateResidenceRelief)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>
                </b-table>

                <p class="text-black">
                    Has bonds gains:
                    <b-badge 
                        style="font-size: 1rem"
                        :class="{
                            'badge-success' : capitalGainsLossesDetails.hasBondGains,
                            'badge-danger' : !capitalGainsLossesDetails.hasBondGains
                        }"
                    >
                        {{ (capitalGainsLossesDetails.hasBondGains) ? 'Yes' : 'No' }}
                    </b-badge>
                </p>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'CapitalGainsLossesDetailsReviewComponent',

    computed: {
        ...mapGetters({
            capitalGainsLossesDetails: 'submissionStore/getCapitalGainsLossesDetails',
            capitalGainsDetails: 'submissionStore/getCapitalGainsDetails',
        }),

        filteredCapitalGainsDetailsFields() {
            return [
                {
                    key: 'name',
                    label: 'Name'
                },

                {
                    key: 'purchasedOn',
                    label: 'Purchased On',
                    formatter: 'formatDate'
                },

                {
                    key: 'purchasedUnits',
                    label: 'Purchased Units',
                    formatter: 'formatUnitDecimal'
                },

                {
                    key: 'purchasedUnitPrice',
                    label: 'Purchased Unit Price',
                    formatter: 'formatDecimal'
                },

                {
                    key: 'purchasePrice',
                    label: 'Purchase Price',
                    formatter: 'formatPrice'
                },

                {
                    key: 'soldOn',
                    label: 'Sold On',
                    formatter: 'formatDate'
                },

                {
                    key: 'saleUnits',
                    label: 'Sale Units',
                    formatter: 'formatUnitDecimal'
                },

                {
                    key: 'saleUnitPrice',
                    label: 'Sale Unit Price',
                    formatter: 'formatDecimal'
                },

                {
                    key: 'salePrice',
                    label: 'Sale Price',
                    formatter: 'formatPrice'
                },

                {
                    key: 'hasEntrepreneursRelief',
                    label: 'Has Entrepreneurs Relief',
                    formatter: 'formatBool'
                },

                {
                    key: 'hasPrivateResidenceRelief',
                    label: 'Has Private Residence Relief',
                    formatter: 'formatBool'
                },
            ]
        }
    },

    methods: {
        formatDate(value) {
            return this.$moment(value).format('ll')
        },

        formatPrice(value) {
            return '£'+(value / 100).toFixed(2)
        },

        formatDecimal(value) {
            let v = value / 100
            return '£'+parseFloat(v.toFixed(5))
        },

        formatUnitDecimal(value) {
            let v = value / 100
            return parseFloat(v.toFixed(5))
        },

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>