export default function redirectIfNotAdmin({ next, to, store }) {
    
    if(store.getters['userStore/authenticated'] && store.getters['userStore/getIsUserCustomer']) {
        return next({
            name: 'MyAccount'
        })
    }

    if(store.getters['userStore/authenticated'] && store.getters['userStore/getIsUserEtoroAdmin'] && to.name != 'Reports') {
        return next({
            name: 'Reports'
        })
    }

    return next()
}