<template>
    <div>
        <b-container>
            <b-row class="justify-content-center">
                <b-col md="6" class="text-center">
                    <h2>
                        We are confirming your email
                    </h2>

                    <div class="mt-5">
                        <template
                            v-if="loading"
                        >
                            <div class="loader-container">
                                <div class="loader"></div>
                                
                                <p>
                                    Please wait..
                                </p>
                            </div>
                        </template>

                        <template
                            v-if="error"
                        >
                            <b-alert variant="danger" show>
                                {{ Notifications.CONFIRM_ACCOUNT_FAILURE }}
                            </b-alert>
                        </template>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
import Notifications from '@/Notifications'

export default {
    name: 'RegisterConfirm',

    data() {
        return {
            error: '',
            failures: {},
            loading: true,
            Notifications
        }
    },

    mounted() {
        this.confirmAccount()
    },

    methods: {
        confirmAccount() {
            let encodedToken = encodeURIComponent(this.$route.query.token).replace(/%20/g, '+')
            let decodedToken = decodeURIComponent(encodedToken)

            let confirmAccountModel = {
                userId: this.$route.query.id,
                token: decodedToken, 
            }

            axios.post('authentication/confirmation', confirmAccountModel).then(() => {
                this.$router.push({name: 'Login', params: {registerSuccessMessage : Notifications.CONFIRM_ACCOUNT_SUCCESS}})
            }).catch(() => {
                this.error = true
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>