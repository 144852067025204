var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',[_c('div',{staticClass:"loader-container"},[_c('div',{staticClass:"loader"}),_c('p',[_vm._v(" Please wait.. ")])])])],1)]:_vm._e(),(_vm.allSubmissions.length && !_vm.loading)?[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","fields":_vm.filteredAllSubmissionsFields,"items":_vm.allSubmissions,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(userEmail)",fn:function(data){return [_c('a',{attrs:{"href":'mailto:'+data.value}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(assignedTo)",fn:function(data){return [_c('div',{domProps:{"innerHTML":_vm._s(data.value)}})]}},{key:"cell(hasEtoro)",fn:function(data){return [_c('b-badge',{class:{
                        'badge-success' : data.value == 'Yes',
                        'badge-danger' : data.value == 'No'
                    },staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{class:{
                        'badge-success' : (data.value == 'Paid' || data.value == 'Completed'),
                        'badge-info' : (data.value == 'Submitted' || data.value == 'New' || data.value == 'Processing'),
                        'badge-warning' : data.value == 'PendingPayment',
                        'badge-danger' : (data.value == 'PaymentFailed' || data.value == 'Unknown')
                    },staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(buttons)",fn:function(data){return [_c('table-actions-component',{attrs:{"data":data,"showViewButton":true,"showClaimButton":true,"showMarkCompleteButton":false}})]}},{key:"cell(lastSyncResponse)",fn:function(data){return [_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){return _vm.retryHubspot(data.item.id)}}},[_vm._v(" Sync ")])]}}],null,false,2179162129)}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-pagination',{attrs:{"total-rows":_vm.counts.total,"per-page":_vm.perPage,"first-text":"First","prev-text":"Prev","next-text":"Next","last-text":"Last"},on:{"change":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]:_vm._e(),(!_vm.allSubmissions.length && !_vm.loading)?[_c('b-row',[_c('b-col',[_c('p',{staticClass:"lead"},[_vm._v(" There are no submissions to view ")])])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }