import axios from 'axios'
export default {
    namespaced: true, 

    state: {
        submissions: []
    },

    getters: {
        getSubmissions(state) {
            return state.submissions
        }
    },

    mutations: {
        PUSH_SUBMISSIONS(state, data) {
            state.submissions = data
        }
    },

    actions: {
        async getSubmissions({commit}) {
            try {
                let response = await axios.get('submissions/0/10/0')
            
                commit('PUSH_SUBMISSIONS', response.data)
            } catch {
                //
            }
        }
    }
}