import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VModal from 'vue-js-modal'
import store from '@/store/index'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/scss/app.scss'
import JsonCSV from 'vue-json-csv'

if(process.env.VUE_APP_ENVIRONMENT != 'development') {
  require('./gtm.js')
  require('./livechat.js')
  Vue.config.productionTip = false
}

require('@/store/subscriber')
require('@/Validator')

var VueScrollTo = require('vue-scrollto');

Vue.use(BootstrapVue)
Vue.use(router)
Vue.use(IconsPlugin)
Vue.use(VueScrollTo)
Vue.use(require('vue-moment'))
Vue.use(VModal, { 
  dynamic: true,
  dynamicDefaults: {
    height: 'auto',
    scrollable: true,
    classes: 'outer-modal'
  }
})
Vue.component('downloadCsv', JsonCSV)

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      return Promise.resolve(response)
    }

    if (response.status === 401) {
      return Promise.reject(response)
    }
  },

  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          store.dispatch('userStore/signout')
          break;
      }
      return Promise.reject(error.response);
    }
})

// Re-authenticate
store.dispatch('userStore/getUser', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
