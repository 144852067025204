<template>
    <div>
        <b-button variant="outline-primary" size="sm" block
            v-if="showViewButton"
            @click.prevent="viewSubmission()"
        >   
            View
        </b-button>

        <b-button variant="primary" size="sm" block
            v-if="showClaimButton && data.item.status !== 'Completed' && user.email !== data.item.assignedToEmail"
            @click.prevent="claimSubmission()"
        >
            Claim
        </b-button>

        <b-button variant="success" size="sm" block
            v-if="showMarkCompleteButton && data.item.status !== 'Completed'"
            @click.prevent="markSubmissionAsComplete()"
        >
            Mark Complete
        </b-button>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'TableActionsComponent',

    props: {
        data: {},
        showViewButton: Boolean,
        showClaimButton: Boolean,
        showMarkCompleteButton: Boolean
    },

    computed: {
        ...mapGetters({
            user: 'userStore/getUser',
            userFullName: 'userStore/getUserFullName'
        })
    },

    methods: {
        ...mapActions({
            claimSubmissionAction: 'adminStore/claimSubmission',
            markSubmissionAsCompleteAction: 'adminStore/markSubmissionAsComplete'
        }),

        viewSubmission() {
            this.$router.push({name: 'ViewSubmission', params: {id: this.data.item.id}})
        },

        claimSubmission() {
            this.claimSubmissionAction({
                submissionId: this.data.item.id,
                user: this.user,
                userFullName: this.userFullName,
            })
        }, 

        markSubmissionAsComplete() {
            this.markSubmissionAsCompleteAction(this.data.item.id)
        }
    }
}
</script>