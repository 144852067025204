<template>
    <div class="bg-primary light py-2">
        <b-container>
            <b-row>
                <b-col class="text-center">
                    <div class="d-flex align-items-center justify-content-center">
                        <img width="20" src="@/assets/icons/taxyear1.svg" /> <span class="font-weight-bold text-dark ml-3">{{ getTaxLabel(taxYearId) }}</span>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'CurrentTaxYearComponent', 

    mounted() {
        if(!this.taxYears.length) {
            this.getTaxYears()
        }
    },

    computed: {
        ...mapGetters({
            taxYears: 'taxYearStore/getTaxYears',
            taxYearId: 'submissionStore/getSelectedTaxYearId'
        })
    }, 

    methods: {
        ...mapActions({
            getTaxYears: 'taxYearStore/getTaxYears'
        }), 

        getTaxLabel(id) {
            let taxYear = this.taxYears.filter(t => {
                if(t.id == id) return t
            })

            if(taxYear.length) return taxYear[0].label
        },
    }
}
</script>