var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-content"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('div',{staticClass:"tab-border"},[_c('div',{staticClass:"p-4"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Please tell us more about your self-employment / partnership ")])]),(Object.keys(_vm.selfEmploymentIncomeDetails).length != 0)?[_c('div',{staticClass:"bg-white p-4"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Business Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Business Name","label-for":"businessName","label-class":"required"}},[_c('b-form-input',{class:{'is-valid' : _vm.selfEmploymentIncomeDetails.businessName, 'is-invalid' : errors.length},attrs:{"name":"businessName","type":"text","placeholder":"Business name"},model:{value:(_vm.selfEmploymentIncomeDetails.businessName),callback:function ($$v) {_vm.$set(_vm.selfEmploymentIncomeDetails, "businessName", $$v)},expression:"selfEmploymentIncomeDetails.businessName"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Total Income","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Total Income","label-for":"totalIncome","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.selfEmploymentIncomeDetails.income, 'is-invalid' : errors.length},attrs:{"name":"totalIncome","placeholder":"Income"},on:{"change":function($event){return _vm.checkForRoundNumber('income')}},model:{value:(_vm.selfEmploymentIncomeDetails.income),callback:function ($$v) {_vm.$set(_vm.selfEmploymentIncomeDetails, "income", $$v)},expression:"selfEmploymentIncomeDetails.income"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-4 mb-md-0"},[_vm._v(" CIS Deducted? "),_c('img',{staticClass:"helper-image-inline",attrs:{"src":require("@/assets/icons/question-icon.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.openHelperModal(_vm.Helpers.CisDeducted.Title, _vm.Helpers.CisDeducted.Body)}}})]),_c('ValidationProvider',{attrs:{"name":"CIS Deducted","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CIS Deducted","label-for":"cisDeducted","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.selfEmploymentIncomeDetails.cisDeducted, 'is-invalid' : errors.length},attrs:{"name":"cisDeducted","placeholder":"CIS Deducted"},on:{"change":function($event){return _vm.checkForRoundNumber('cisDeducted')}},model:{value:(_vm.selfEmploymentIncomeDetails.cisDeducted),callback:function ($$v) {_vm.$set(_vm.selfEmploymentIncomeDetails, "cisDeducted", $$v)},expression:"selfEmploymentIncomeDetails.cisDeducted"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1)]),_c('div',{staticClass:"bg-white p-4"},[_c('b-row',[_c('b-col',{attrs:{"lg":""}},[_c('ValidationProvider',{attrs:{"name":"Total Expenses","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"d-block required",attrs:{"for":"totalExpenses"}},[_vm._v("Total expenses")]),_c('img',{staticClass:"helper-image",attrs:{"src":require("@/assets/icons/question-icon.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.openHelperModal(_vm.Helpers.TotalExpenses.Title, _vm.Helpers.TotalExpenses.Body)}}}),_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.selfEmploymentIncomeDetails.totalExpenses, 'is-invalid' : errors.length},attrs:{"name":"totalExpenses","placeholder":"Total expenses"},on:{"change":function($event){return _vm.checkForRoundNumber('totalExpenses')}},model:{value:(_vm.selfEmploymentIncomeDetails.totalExpenses),callback:function ($$v) {_vm.$set(_vm.selfEmploymentIncomeDetails, "totalExpenses", $$v)},expression:"selfEmploymentIncomeDetails.totalExpenses"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":""}},[_c('ValidationProvider',{attrs:{"name":"Motor Expenses","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Motor Expenses","label-for":"motorExpenses","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.selfEmploymentIncomeDetails.motorExpenses, 'is-invalid' : errors.length},attrs:{"name":"motorExpenses","placeholder":"Motor expenses"},on:{"change":function($event){return _vm.checkForRoundNumber('motorExpenses')}},model:{value:(_vm.selfEmploymentIncomeDetails.motorExpenses),callback:function ($$v) {_vm.$set(_vm.selfEmploymentIncomeDetails, "motorExpenses", $$v)},expression:"selfEmploymentIncomeDetails.motorExpenses"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":""}},[_c('ValidationProvider',{attrs:{"name":"Other Travel Expenses","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Travel Expenses","label-for":"otherTravelExpenses","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.selfEmploymentIncomeDetails.otherTravelExpenses, 'is-invalid' : errors.length},attrs:{"name":"otherTravelExpenses","placeholder":"Travel expenses"},on:{"change":function($event){return _vm.checkForRoundNumber('otherTravelExpenses')}},model:{value:(_vm.selfEmploymentIncomeDetails.otherTravelExpenses),callback:function ($$v) {_vm.$set(_vm.selfEmploymentIncomeDetails, "otherTravelExpenses", $$v)},expression:"selfEmploymentIncomeDetails.otherTravelExpenses"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":""}},[_c('ValidationProvider',{attrs:{"name":"Tools Expenses","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tools Expenses","label-for":"toolsExpenses","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.selfEmploymentIncomeDetails.toolsExpenses, 'is-invalid' : errors.length},attrs:{"placeholder":"Tools expenses"},on:{"change":function($event){return _vm.checkForRoundNumber('toolsExpenses')}},model:{value:(_vm.selfEmploymentIncomeDetails.toolsExpenses),callback:function ($$v) {_vm.$set(_vm.selfEmploymentIncomeDetails, "toolsExpenses", $$v)},expression:"selfEmploymentIncomeDetails.toolsExpenses"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":""}},[_c('ValidationProvider',{attrs:{"name":"Other Expenses","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Other Expenses","label-for":"otherExpenses","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.selfEmploymentIncomeDetails.otherExpenses, 'is-invalid' : errors.length},attrs:{"placeholder":"Other expenses"},on:{"change":function($event){return _vm.checkForRoundNumber('otherExpenses')}},model:{value:(_vm.selfEmploymentIncomeDetails.otherExpenses),callback:function ($$v) {_vm.$set(_vm.selfEmploymentIncomeDetails, "otherExpenses", $$v)},expression:"selfEmploymentIncomeDetails.otherExpenses"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1)],1)],1)]:_vm._e()],2),_c('self-employment-document-component',{staticClass:"mt-5",attrs:{"item":_vm.selfEmploymentIncomeDetails}}),_c('save-and-continue-component',{attrs:{"saving":_vm.saving,"componentName":_vm.$options.name},on:{"clickBack":function($event){return _vm.$emit('back')}}}),(invalid)?_c('p',{staticClass:"text-sm text-danger text-right center-mobile mt-1"},[_vm._v("Please fill out the required fields before saving")]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }