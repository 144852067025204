<template>
    <div>
        <template
            v-if="loading"
        >
            <b-row class="justify-content-center">
                <b-col>
                    <div class="loader-container">
                        <div class="loader"></div>
                        
                        <p>
                            Please wait..
                        </p>
                    </div>
                </b-col>
            </b-row>
        </template>

        <template 
            v-if="allSubmissions.length && !loading"
        >
            <b-table responsive striped hover 
                :fields="filteredAllSubmissionsFields" 
                :items="allSubmissions"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template #cell(userEmail)="data">
                    <a :href="'mailto:'+data.value">{{ data.value }}</a>
                </template>

                <template #cell(assignedTo)="data">
                    <div v-html="data.value"></div>
                </template>

                <template #cell(hasEtoro)="data">
                    <b-badge 
                        style="font-size: 1rem"
                        :class="{
                            'badge-success' : data.value == 'Yes',
                            'badge-danger' : data.value == 'No'
                        }"
                    >
                        {{ data.value }}
                    </b-badge>
                </template>

                <template #cell(status)="data">
                    <b-badge 
                        style="font-size: 1rem"
                        :class="{
                            'badge-success' : (data.value == 'Paid' || data.value == 'Completed'),
                            'badge-info' : (data.value == 'Submitted' || data.value == 'New' || data.value == 'Processing'),
                            'badge-warning' : data.value == 'PendingPayment',
                            'badge-danger' : (data.value == 'PaymentFailed' || data.value == 'Unknown')
                        }"
                    >
                        {{ data.value }}
                    </b-badge>
                </template>

                <template #cell(buttons)="data">
                    <table-actions-component
                        :data="data"
                        :showViewButton="true"
                        :showClaimButton="true"
                        :showMarkCompleteButton="false"      
                    />
                </template>

                <template #cell(lastSyncResponse)="data">
                    <b-button variant="warning"
                        @click="retryHubspot(data.item.id)"
                    >
                        Sync
                    </b-button>  
                </template>
            </b-table>

            <div class="d-flex justify-content-end">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="counts.total"
                    :per-page="perPage"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    @change="handlePageChange"
                ></b-pagination>
            </div>
        </template>

        <template
            v-if="!allSubmissions.length && !loading"
        >
            <b-row>
                <b-col>
                    <p class="lead">
                        There are no submissions to view
                    </p>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import TableActionsComponent from '@/components/admin/TableActionsComponent.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'AllSubmissionsComponent',

    components: {
        TableActionsComponent
    },

    data() {
        return {
            loading: true,
            syncLoading: false,
            sortBy: 'updatedOn',
            sortDesc: true,
            currentPage: 1,
            perPage: 5,
        }
    },

    mounted() {
        if(!this.taxYears.length) {
            this.getTaxYears()
        }

        this.getAllSubmissions({
            amount: this.perPage,
            offset: 0,
        }).then(() => {
            this.loading = false
        })
    },

    computed: {
        ...mapGetters({
            taxYears: 'taxYearStore/getTaxYears',
            counts: 'adminStore/getCounts',
            allSubmissions: 'adminStore/getAllSubmissions',
        }),

        filteredAllSubmissionsFields() {
            return [
                {
                    key: 'userEmail',
                    label: 'User Email',
                    sortable: true,
                },

                {
                    key: 'userName',
                    label: 'User Name',
                    sortable: true,
                },

                {
                    key: 'taxYearId',
                    label: 'Tax Year',
                    formatter: 'formatTaxYear',
                },
                
                {
                    key: 'createdOn',
                    label: 'Created On',
                    formatter: 'formatDate',
                    sortable: true,
                },

                {
                    key: 'updatedOn',
                    label: 'Updated On',
                    formatter: 'formatDate',
                    sortable: true,
                },

                {
                    key: 'assignedTo',
                    label: 'Assigned To',
                    formatter: 'formatAssignedTo',
                    sortable: true,
                },

                {
                    key: 'hasEtoro',
                    label: 'Has Etoro',
                    formatter: 'formatBool',
                    sortable: true
                },

                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                },

                {
                    key: 'buttons',
                    label: 'Actions',
                },

                {
                    key: 'lastSyncResponse',
                    formatter: 'formatLastSyncResponse',
                    label: 'Hubspot Response',
                }
            ]
        },
    }, 

    methods: {
        ...mapActions({
            getTaxYears: 'taxYearStore/getTaxYears',
            getAllSubmissions: 'adminStore/getAllSubmissions'
        }),

        retryHubspot(id) {
            this.syncLoading = true

            axios.post(`/submission/${id}/sync`, {
                submissionId: id
            }).then(() => {
                this.getAllSubmissions({
                    amount: this.perPage,
                    offset: 0,
                }).then(() => {
                    this.loading = false
                })
            }).catch(() => {
                //
            }).finally(() => this.syncLoading = false)
        },

        handlePageChange(value) {
            this.getAllSubmissions({
                amount: this.perPage,
                offset: (value - 1) * this.perPage
            }).then(() => this.loading = false)
        },

        formatDate(value) {
            return this.$moment(value).format('DD/MM/YYYY HH:mm')
        },

        formatTaxYear(value) {
            let label = ''

            this.taxYears.filter(taxYear => {
                if(taxYear.id == value) label = taxYear.label
            })

            return label.replace("Tax Year", "")
        },

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        },

        formatAssignedTo(value) {
            if(value == null) return '-'; return value
        },

        formatLastSyncResponse(value) {
            if(value && value.status == 'error') return 'error'; return 'ok';
        }
    }
}
</script>