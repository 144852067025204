<template>
    <div :id="'dindex'+index" class="bg-white p-4">
        <b-row> 
            <b-col md="1" class="text-center">
                <div class="number mx-auto">
                    {{ index + 1 }}
                </div>

                <div class="remove mt-3 cursor-pointer"
                    @click.prevent="removeItem(index)"
                >
                    <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                </div>
            </b-col>

            <b-col md="11" class="mt-4 mt-md-0">
                <b-row> 
                    <b-col md="4">
                        <ValidationProvider :vid="'Provider '+(index+1)" name="Provider" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Provider"
                                label-for="provider"
                                label-class="required"
                            >
                                <b-form-input
                                    name="provider"
                                    v-model="item.provider"
                                    type="text"
                                    placeholder="Provider"
                                    :class="{'is-valid' : item.provider, 'is-invalid' : errors.length}"
                                >
                                </b-form-input>
                                
                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="4">  
                        <ValidationProvider :vid="'Received Amount '+(index+1)" name="Received Amount" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group>
                                <label for="receivedAmount" class="d-block required">Received Amount</label>
                                <img class="helper-image" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.ReceivedAnyDividends.Title, Helpers.ReceivedAnyDividends.Body)" />
                                
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="receivedAmount"
                                        v-model="item.receivedAmount"
                                        placeholder="Amount received"
                                        :class="{'is-valid' : item.receivedAmount, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('receivedAmount')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="4">
                        <ValidationProvider :vid="'Date Received '+(index+1)" name="Date Received" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Date Received"
                                label-for="dateReceived"
                                label-class="required"
                            >
                                <b-form-datepicker
                                    name="dateReceived"
                                    v-model="item.dateReceived"
                                    :class="{'is-valid' : item.dateReceived, 'is-invalid' : errors.length}"
                                    placeholder="Choose a date"
                                    no-flip
                                    :max="max"
                                >
                                </b-form-datepicker>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'DividenIncomeItemComponent',

    props: {
        item: {},
        index: Number
    },

    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const maxDate = new Date(today)

        return {
            max: maxDate,
            HelperModalComponent,
            Helpers
        }
    },

    mounted() {
        this.item.receivedAmount = (this.item.receivedAmount / 100).toFixed(2)
    },

    computed: {
        ...mapGetters({
            dividendIncomeDetails: 'submissionStore/getDividendIncomeDetails',
        })
    },

    methods: {
        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        removeItem(index) {
            this.dividendIncomeDetails.splice(index, 1);

            setTimeout(() => {
                this.$scrollTo('#dindex'+(this.dividendIncomeDetails.length - 1), 800)
            }, 500)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        }
    }
}
</script>