<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-3 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/self-employed.svg" width="30" height="30" /> Self Employment Income
            </b-button>
        </b-card-header>
        
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Summary of sales invoices</p>
                                    <p>Please provide a list of all of your sales invoices raised during the year. This should be the same as the money you have received from your customers during the tax year.</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Summary of expenses</p>
                                    <p>During your period trading you would have incurred several expenses. In order for these to be included on your return we will need a summary of these to support your tax return. A simple spreadsheet is sufficient.</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import Helpers from '@/Helpers'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'

export default {
    name: 'SelfEmploymentIncomeWhatYouNeedComponent',

    data() {
        return {
            Helpers,
            HelperModalComponent
        }
    },

    methods: {
        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        }
    }
}
</script>