<template>
    <div :id="'index'+index" class="bg-white p-4">
        <div class="py-4">
            <b-row>
                <b-col md="1" class="text-center">
                    <div class="number mx-auto">
                        {{ index + 1 }}
                    </div>

                    <div class="remove mt-3 cursor-pointer"
                        @click.prevent="removeItem(index)"
                        v-if="index > 0"
                    >
                        <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                    </div>
                </b-col>

                <b-col md="11" class="mt-4 mt-md-0">
                    <b-row>
                        <b-col md="6">
                            <ValidationProvider :vid="'Address Line 1 ('+(index+1)+')'" name="Address Line 1" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label="Address Line 1"
                                    label-for="addressLine1"
                                    label-class="required"
                                >
                                    <b-form-input
                                        name="addressLine1"
                                        v-model="item.address.line1"
                                        type="text"
                                        placeholder="Address line 1"
                                        :class="{'is-valid' : item.address.line1, 'is-invalid' : failures && failures['Properties['+index+'].Address.Line1'] || errors.length}"
                                    >
                                    </b-form-input>

                                    <small
                                        v-if="failures && failures['Properties['+index+'].Address.Line1']"
                                        class="text-danger"
                                    >
                                        {{ failures['Properties['+index+'].Address.Line1'][0] }}
                                    </small>

                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                        {{ errors[0] }}
                                    </small>
                                </b-form-group>
                            </ValidationProvider>

                            <b-form-group
                                label="Address Line 2"
                                label-for="addressLine2"
                            >
                                <b-form-input
                                    name="addressLine2"
                                    v-model="item.address.line2"
                                    type="text"
                                    placeholder="Address line 2"
                                >
                                </b-form-input>
                            </b-form-group>

                            <b-form-group
                                label="Address Line 3"
                                label-for="addressLine3"
                            >
                                <b-form-input
                                    name="addressLine3"
                                    v-model="item.address.line3"
                                    type="text"
                                    placeholder="Address line 3"
                                >
                                </b-form-input>
                            </b-form-group>

                            <b-form-group
                                label="Town"
                                label-for="town"
                            >
                                <b-form-input
                                    name="town"
                                    v-model="item.address.town"
                                    type="text"
                                    placeholder="Town"
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col> 

                        <b-col md="6" class="d-flex flex-column font-weight-bold justify-content-end">
                            <ValidationProvider :vid="'County '+(index+1)" name="County" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label="County"
                                    label-for="county"
                                    label-class="required"
                                >
                                    <b-form-input
                                        name="county"
                                        v-model="item.address.county"
                                        type="text"
                                        placeholder="County"
                                        :class="{'is-valid' : item.address.county, 'is-invalid' : failures && failures['Properties['+index+'].Address.County'] || errors.length}"
                                    >
                                    </b-form-input>

                                    <small
                                        v-if="failures && failures['Properties['+index+'].Address.County']"
                                        class="text-danger"
                                    >
                                        {{ failures['Properties['+index+'].Address.County'][0] }}
                                    </small>

                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                        {{ errors[0] }}
                                    </small>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider :vid="'Postcode '+(index+1)" name="Address Postcode" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label="Postcode"
                                    label-for="postcode"
                                    label-class="required"
                                >
                                    <b-form-input
                                        name="postcode"
                                        v-model="item.address.postcode"
                                        type="text"
                                        placeholder="Postcode"
                                        :class="{'is-valid' : item.address.postcode, 'is-invalid' : failures && failures['Properties['+index+'].Address.Postcode'] || errors.length}"
                                    >
                                    </b-form-input>

                                    <small
                                        v-if="failures && failures['Properties['+index+'].Address.Postcode']"
                                        class="text-danger"
                                    >
                                        {{ failures['Properties['+index+'].Address.Postcode'][0] }}
                                    </small>

                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                        {{ errors[0] }}
                                    </small>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>

                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between mt-5">
                        <h4 class="mb-0">
                            Was the property furnished?
                        </h4>

                        <div class="mt-4 mt-md-0">
                            <b-button class="btn-bool mr-1"
                                @click="item.isFurnished = !item.isFurnished"
                                :class="{active: item.isFurnished}"
                            >
                                Yes
                            </b-button>

                            <b-button class="btn-bool"
                                @click="item.isFurnished = !item.isFurnished"
                                :class="{negativeActive: !item.isFurnished}"
                            >
                                No
                            </b-button>
                        </div>
                    </div>

                    <b-row class="mt-5">
                        <b-col md="6">
                            <ValidationProvider :vid="'Rent Received '+(index+1)" name="Rent Received" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                <b-form-group
                                    label="Rent Received"
                                    label-for="rentReceived"
                                    label-class="required"
                                >
                                    <b-input-group prepend="£">
                                        <b-form-input
                                            name="rentReceived"
                                            v-model="item.rentReceivedAmount"
                                            placeholder="Rent received"
                                            :class="{'is-valid' : item.rentReceivedAmount, 'is-invalid' : errors.length}"
                                            @change="checkForRoundNumber('rentReceivedAmount')"
                                        >
                                        </b-form-input>
                                    </b-input-group>

                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                        {{ errors[0] }}
                                    </small>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col md="6">
                            <ValidationProvider :vid="'Mortgage Interest Paid'+(index+1)" name="Mortgage Interest Paid" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                <b-form-group
                                    label="Mortgage Interest Paid"
                                    label-for="mortgageInterestPaid"
                                    label-class="required"
                                >
                                    <b-input-group prepend="£">
                                        <b-form-input
                                            name="mortgageInterestPaid"
                                            v-model="item.mortgageInterestPaidAmount"
                                            placeholder="Mortgage Interest Paid"
                                            :class="{'is-valid' : item.mortgageInterestPaidAmount, 'is-invalid' : errors.length}"
                                            @change="checkForRoundNumber('mortgageInterestPaidAmount')"
                                        >
                                        </b-form-input>
                                    </b-input-group>

                                    <small v-if="errors.length" class="text-danger validation-error d-block">
                                        {{ errors[0] }}
                                    </small>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>

                    <hr>

                    <h4 class="center-mobile">
                        Any other costs? <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.OtherCosts.Title, Helpers.OtherCosts.Body)" />
                    </h4>

                    <template
                        v-if="item.otherCosts.length"
                    >
                        <b-row class="mt-4">
                            <b-col md="8">
                                <p class="label mb-2">
                                    Other costs description
                                </p>
                            </b-col>

                            <b-col md="3">
                                <p class="label mb-2">
                                    Amount
                                </p>
                            </b-col>
                        </b-row>

                        <div
                            v-for="(item, key) in item.otherCosts"
                            :key="key"
                            :parentIndex="index"
                            :id="'otherCostsIndex'+index"
                        >
                            <b-row>
                                <b-col md="8">
                                    <ValidationProvider :vid="'Description '+(key+1)" name="Description" rules="required" v-slot="{ errors }">
                                        <b-form-group>
                                            <b-form-input
                                                name="description"
                                                v-model="item.name"
                                                type="text"
                                                placeholder="Description"
                                                :class="{'is-valid' : item.name, 'is-invalid' : errors.length}"
                                            >
                                            </b-form-input>
                                            
                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>  

                                <b-col md="3">
                                    <ValidationProvider :vid="'Amount '+(key+1)" name="Amount" rules="required|min_value:0|double:2" v-slot="{ errors }">
                                        <b-form-group>
                                            <b-input-group prepend="£">
                                                <b-form-input
                                                    name="amount"
                                                    v-model="item.amount"
                                                    placeholder="Amount"
                                                    :class="{'is-valid' : item.amount, 'is-invalid' : errors.length}"
                                                    @change="checkForOtherCostsRoundNumber('amount', key)"
                                                >
                                                </b-form-input>
                                            </b-input-group>

                                            <small v-if="errors.length" class="text-danger validation-error d-block">
                                                {{ errors[0] }}
                                            </small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="1" class="text-center">
                                    <div class="cursor-pointer mt-2"
                                        @click.prevent="removeOtherCostsItem(key)"
                                    >
                                        <img src="@/assets/icons/delete.svg" alt="delete item" width="30" />
                                    </div>
                                </b-col>      
                            </b-row> 

                            <hr class="my-3 d-block d-md-none"/>
                        </div>                 
                    </template>

                    <div class="d-flex justify-content-center justify-content-md-start">
                        <b-button variant="primary"
                            @click.prevent="createDefaultOtherCostsItem()"
                        >
                            Add +
                        </b-button> 
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'PropertyIncomeItemComponent',

    props: {
        item: {},
        index: Number,
        failures: {}
    }, 

    data() {
        return {
            HelperModalComponent,
            Helpers
        }
    },
    
    mounted() {
        this.item.mortgageInterestPaidAmount = (this.item.mortgageInterestPaidAmount / 100).toFixed(2)
        this.item.rentReceivedAmount = (this.item.rentReceivedAmount / 100).toFixed(2)
        this.item.otherCosts.forEach(oc => {
            oc.amount = (oc.amount / 100).toFixed(2)
        })
    },

    computed: {
        ...mapGetters({
            propertyIncomeDetails: 'submissionStore/getPropertyIncomeDetails',
        })
    },

    methods: {
        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },
        
        createDefaultOtherCostsItem() {
            let newOtherCosts = {
                name: null,
                amount: null
            }

            this.item.otherCosts.push(newOtherCosts)
        },

        removeItem(index) {
            this.propertyIncomeDetails.splice(index, 1);

            setTimeout(() => {
                this.$scrollTo('#index'+(this.propertyIncomeDetails.length - 1), 800)
            }, 500)
        },

        removeOtherCostsItem(index) {
            this.item.otherCosts.splice(index, 1);
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        },

        checkForOtherCostsRoundNumber(ref, index) {
            if(Number.isInteger(parseFloat(this.item.otherCosts[index][ref]))) {
                this.item.otherCosts[index][ref] = parseFloat(this.item.otherCosts[index][ref]).toFixed(2)
            }
        }
    }
}
</script>