var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"mb-5"},[_vm._v("Reports")]),_c('b-card',{staticStyle:{"overflow":"unset"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":require("@/assets/icons/questions/list-icon-etoro.svg"),"width":"120"}}),_c('h3',{staticClass:"mb-0 ml-4"},[_vm._v("Etoro")])]),_c('ValidationObserver',{ref:"completedObserver"},[_c('h5',{staticClass:"mt-4"},[_vm._v("Completed submissions")]),_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.getSubmissions(true)}}},[_c('div',{staticClass:"d-flex align-items-end"},[_c('div',[_c('ValidationProvider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Date","label-for":"startDate","label-class":"required"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.fields.completedStartDate, 'is-invalid' : errors.length},attrs:{"name":"startDate","placeholder":"Choose a date","hide-header":"","show-decade-nav":"","no-flip":"","max":_vm.max},model:{value:(_vm.fields.completedStartDate),callback:function ($$v) {_vm.$set(_vm.fields, "completedStartDate", $$v)},expression:"fields.completedStartDate"}})],1)]}}])})],1),_c('div',{staticClass:"mx-4"},[_c('ValidationProvider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Date","label-for":"endDate","label-class":"required label-block"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.fields.completedEndDate, 'is-invalid' : errors.length},attrs:{"name":"endDate","placeholder":"Choose a date","hide-header":"","show-decade-nav":"","no-flip":"","max":_vm.max},model:{value:(_vm.fields.completedEndDate),callback:function ($$v) {_vm.$set(_vm.fields, "completedEndDate", $$v)},expression:"fields.completedEndDate"}})],1)]}}])})],1),_c('b-form-group',[_c('b-button',{class:{disabled: _vm.loading},attrs:{"type":"submit","variant":"primary","disabled":_vm.loading}},[_vm._v(" Export CSV "),(_vm.loading)?_c('span',{staticClass:"btn-loader"}):_vm._e()])],1)],1)]),_c('p',{staticClass:"underlined font-weight-bold cursor-pointer",on:{"click":function($event){_vm.fields.completedStartDate = null, _vm.fields.completedEndDate = null}}},[_vm._v("Clear")])],1),_c('ValidationObserver',{ref:"incompleteObserver"},[_c('h5',{staticClass:"mt-4"},[_vm._v("Incomplete submissions")]),_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.getSubmissions(false)}}},[_c('div',{staticClass:"d-flex align-items-end"},[_c('div',[_c('ValidationProvider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Date","label-for":"startDate","label-class":"required"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.fields.allStartDate, 'is-invalid' : errors.length},attrs:{"name":"startDate","placeholder":"Choose a date","hide-header":"","show-decade-nav":"","no-flip":"","max":_vm.max},model:{value:(_vm.fields.allStartDate),callback:function ($$v) {_vm.$set(_vm.fields, "allStartDate", $$v)},expression:"fields.allStartDate"}})],1)]}}])})],1),_c('div',{staticClass:"mx-4"},[_c('ValidationProvider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Date","label-for":"endDate","label-class":"required label-block"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.fields.allEndDate, 'is-invalid' : errors.length},attrs:{"name":"endDate","placeholder":"Choose a date","hide-header":"","show-decade-nav":"","no-flip":"","max":_vm.max},model:{value:(_vm.fields.allEndDate),callback:function ($$v) {_vm.$set(_vm.fields, "allEndDate", $$v)},expression:"fields.allEndDate"}})],1)]}}])})],1),_c('b-form-group',[_c('b-button',{class:{disabled: _vm.loading},attrs:{"type":"submit","variant":"primary","disabled":_vm.loading}},[_vm._v(" Export CSV "),(_vm.loading)?_c('span',{staticClass:"btn-loader"}):_vm._e()])],1)],1)]),_c('p',{staticClass:"underlined font-weight-bold cursor-pointer",on:{"click":function($event){_vm.fields.allStartDate = null, _vm.fields.allEndDate = null}}},[_vm._v("Clear")])],1),_c('ValidationObserver',{ref:"userObserver"},[_c('h5',{staticClass:"mt-4"},[_vm._v("All Etoro users")]),_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.getUsers()}}},[_c('div',{staticClass:"d-flex align-items-end"},[_c('div',[_c('ValidationProvider',{attrs:{"name":"Start Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Date","label-for":"startDate","label-class":"required"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.fields.userStartDate, 'is-invalid' : errors.length},attrs:{"name":"userStartDate","placeholder":"Choose a date","hide-header":"","show-decade-nav":"","no-flip":"","max":_vm.max},model:{value:(_vm.fields.userStartDate),callback:function ($$v) {_vm.$set(_vm.fields, "userStartDate", $$v)},expression:"fields.userStartDate"}})],1)]}}])})],1),_c('div',{staticClass:"mx-4"},[_c('ValidationProvider',{attrs:{"name":"End Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Date","label-for":"endDate","label-class":"required label-block"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.fields.userEndDate, 'is-invalid' : errors.length},attrs:{"name":"endDate","placeholder":"Choose a date","hide-header":"","show-decade-nav":"","no-flip":"","max":_vm.max},model:{value:(_vm.fields.userEndDate),callback:function ($$v) {_vm.$set(_vm.fields, "userEndDate", $$v)},expression:"fields.userEndDate"}})],1)]}}])})],1),_c('b-form-group',[_c('b-button',{class:{disabled: _vm.loading},attrs:{"type":"submit","variant":"primary","disabled":_vm.loading}},[_vm._v(" Export CSV "),(_vm.loading)?_c('span',{staticClass:"btn-loader"}):_vm._e()])],1)],1)]),_c('p',{staticClass:"underlined font-weight-bold cursor-pointer",on:{"click":function($event){_vm.fields.userStartDate = null, _vm.fields.userEndDate = null}}},[_vm._v("Clear")])],1),(_vm.canDownload)?_c('download-csv',{staticClass:"btn btn-success mt-4",attrs:{"data":_vm.submissions}},[_vm._v(" Download ")]):_vm._e(),(_vm.nothingToDownload)?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.Notifications.NO_DATA_TO_EXPORT)+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }