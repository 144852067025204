<template>
    <b-card no-body>
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="approval" class="pl-0">
                <h4>
                    Gifts	
                </h4>

                <p class="mb-0">
                    Have you made or received any gifts? For example, amounts received under a Will, Shares (including shares in a family company), or transfer of a share of a Partnership
                </p>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="giftsDetails.length"
                            >
                                <gift-item-component 
                                    v-for="(item, index) in giftsDetails"
                                    :key="index"
                                    :item="item"
                                    :index="index"
                                />
                            </template>

                            <div class="p-4">
                                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                                    <div>
                                        <h5 class="mt-0">Add another gift</h5>
                                        Would you like to add details of another gift?
                                    </div>

                                    <b-button variant="primary" class="mt-4 mt-md-0"
                                        @click="createDefaultGiftItem()"
                                    >
                                        Add gift
                                    </b-button>    
                                </div> 
                            </div>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import GiftItemComponent from '@/components/partials/GiftItemComponent.vue'
import Notifications from '@/Notifications'

export default {
    name: 'ResidenceRemittanceComponent', 

    components: {
        GiftItemComponent
    },

    data() {
        return {
            saving: false
        }
    },

    mounted() {
        if(!this.giftsDetails.length) this.createDefaultGiftItem()
    },

    computed: {
        ...mapGetters({
            giftsDetails: 'submissionStore/getGiftsDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getGiftsDetails: 'submissionStore/getSubmissionGiftsDetails',
            setNotification: 'notificationsStore/setNotification'
        }),

        createDefaultGiftItem() {
            let giftItem = {
                recipientName: null,
                isReceived: false,
                amount: 0,
                value: 0,
                date: null,
                isRegular: false
            }

            this.giftsDetails.push(giftItem)

            setTimeout(() => {
                this.$scrollTo('#gindex'+(this.giftsDetails.length - 1), 800)
            }, 500)
        },

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let giftsModel = {
                submissionId: this.submissionId,
                gifts: JSON.parse(JSON.stringify(this.giftsDetails))
            }

            giftsModel.gifts.forEach(g => {
                g.amount = parseInt(g.amount * 100)
                g.value = parseInt(g.value * 100)
            })

            axios.post(`submission/${this.submissionId}/gifts`, giftsModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).finally(() => this.saving = false)
        }
    }
}
</script>