<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-4 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/property.svg" width="30" height="30" /> Property Income
            </b-button>
        </b-card-header>
        
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Summary of rental income</p>
                                    <p>Whether you have a property empire or a single buy to let you will need to declare the income on your tax return. In order to do this we will need the amount of rental income you have received.</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Summary of property costs</p>
                                    <p>When letting your property you will undoubtabley incur costs associated with letting. You will need to detail these on your tax return.</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Mortgage interest statement</p>
                                    <p>The interest you pay on any mortgage you have on your buy to let is an allowable expense. Please provide the amount of interest you have paid on your mortgage during the year. To confirm, this is the interest only element of the mortgage and not the full repayment.</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Management statements (if applicable)</p>
                                    <p>If your property is let via a managment company they are likely to privde you with a statement each month. If this applies, you are likely to find a full breakdown of your income on these statements.</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
export default {
    name: 'PropertyIncomeWhatYouNeedComponent',

    data() {
        return {
            rentalIncome: false,
            propertyCosts: false,
            interestStatement: false,
            managementStatements: false
        }
    }

}
</script>