var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header no-print py-2",class:{'bg-transparent' : this.$route.name == 'Login' || this.$route.name == 'Register' || this.$route.name == 'ForgotPassword' || this.$route.name == 'ResetPassword'}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"justify-content-between align-items-center"},[_c('b-col',{attrs:{"xs":"3"}},[(_vm.authenticated)?_c('router-link',{attrs:{"to":{name: 'MyAccount'}}},[_c('img',{staticClass:"img-fluid",attrs:{"width":"60","src":require("@/assets/images/logos/logo.svg"),"alt":"Get Tax Done"}})]):_c('img',{staticClass:"img-fluid",attrs:{"width":"80","src":require("@/assets/images/logos/logo.svg"),"alt":"Get Tax Done"}})],1),(!_vm.$route.meta.hideNavigation || !_vm.$route.meta.hideListsInNavigation)?_c('b-col',{staticClass:"d-none d-lg-block",attrs:{"xs":"6"}},[(_vm.$route.meta.requiresFirstListInHeader)?_c('ol',{staticClass:"reasons-list d-flex justify-content-center"},[_c('li',{class:{
                                active : this.$route.name == 'TaxYear',
                                completed: _vm.isTaxYearComplete
                            }},[_vm._v(" Tax Year ")]),_c('li',{class:{
                                active : this.$route.name == 'Reasons',
                                completed: _vm.isReasonsComplete
                            }},[_vm._v(" Reason ")]),_c('li',{class:{
                                active : this.$route.name == 'Questions',
                                completed: _vm.isQuestionsComplete
                            }},[(_vm.isTaxYearComplete)?[_c('router-link',{staticClass:"follow",attrs:{"to":{name: 'Questions'}}},[_vm._v(" Income Sources ")])]:[_vm._v(" Questions ")]],2)]):_vm._e(),(_vm.$route.meta.requiresSecondListInHeader)?_c('ol',{staticClass:"reasons-list d-flex justify-content-center",attrs:{"start":"4"}},[_c('li',{class:{
                                active : this.$route.name == 'Checklist',
                                completed: _vm.isAccountantComplete
                            }},[_c('router-link',{attrs:{"to":{name: 'Checklist'}}},[_vm._v(" Checklist ")])],1),_c('li',{class:{
                                active : this.$route.name == 'Information',
                                completed: _vm.isInformationComplete
                            },on:{"click":function($event){return _vm.markAccountantComplete()}}},[_c('router-link',{attrs:{"to":{name: 'Information'}}},[_vm._v(" Information ")])],1),_c('li',{class:{active : this.$route.name == 'Approval'}},[(_vm.isInformationComplete)?[_c('router-link',{attrs:{"to":{name: 'Approval'}}},[_vm._v(" Submit ")])]:[_vm._v(" Submit ")]],2)]):_vm._e()]):_vm._e(),(!_vm.$route.meta.hideNavigation)?_c('b-col',{staticClass:"text-right",attrs:{"xs":"3"}},[(_vm.authenticated && Object.keys(_vm.user).length != 0 && !_vm.$route.meta.showAdminNavigation)?_c('b-dropdown',{staticClass:"m-md-2 header-dropdown",attrs:{"id":"dropdown-right","variant":"secondary","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" My Account ")]},proxy:true}],null,false,2004294874)},[_c('b-dropdown-item',{attrs:{"to":{name: 'MyAccount'}}},[_vm._v(" My Account ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.signout()}}},[_vm._v(" Signout ")])],1):_vm._e(),(_vm.authenticated && Object.keys(_vm.user).length != 0 && _vm.$route.meta.showAdminNavigation)?_c('b-dropdown',{staticClass:"m-md-2 header-dropdown",attrs:{"id":"dropdown-right","variant":"secondary","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.user.given_name)+" ")]},proxy:true}],null,false,474172246)},[(!_vm.isUserEtoroAdmin)?_c('b-dropdown-item',{attrs:{"to":{name: 'Dashboard'}}},[_vm._v(" Dashboard ")]):_vm._e(),(!_vm.isUserEtoroAdmin)?_c('b-dropdown-item',{attrs:{"to":{name: 'CreateUser'}}},[_vm._v(" Create User ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"to":{name: 'Reports'}}},[_vm._v(" Reports ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.signout()}}},[_vm._v(" Signout ")])],1):_vm._e()],1):_vm._e()],1)],1)],1),(_vm.$route.meta.showCurrentTaxYear)?_c('current-tax-year-component'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }