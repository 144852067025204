var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.capitalGainsLossesDetails).length != 0)?_c('b-card',{staticClass:"shadow mt-5"},[_c('b-row',[_c('b-col',[_c('h2',{staticClass:"mb-3"},[_vm._v(" Capital Gains & Losses Details ")]),_c('b-table',{attrs:{"outlined":"","responsive":"","striped":"","hover":"","fields":_vm.filteredCapitalGainsDetailsFields,"items":_vm.capitalGainsDetails},scopedSlots:_vm._u([{key:"cell(hasEntrepreneursRelief)",fn:function(data){return [_c('b-badge',{class:{
                            'badge-su.ccess' : data.value == 'Yes',
                            'badge-danger' : data.value == 'No'
                        },staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(hasPrivateResidenceRelief)",fn:function(data){return [_c('b-badge',{class:{
                            'badge-success' : data.value == 'Yes',
                            'badge-danger' : data.value == 'No'
                        },staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(data.value)+" ")])]}}],null,false,3847510494)}),_c('p',{staticClass:"text-black"},[_vm._v(" Has bonds gains: "),_c('b-badge',{class:{
                        'badge-success' : _vm.capitalGainsLossesDetails.hasBondGains,
                        'badge-danger' : !_vm.capitalGainsLossesDetails.hasBondGains
                    },staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s((_vm.capitalGainsLossesDetails.hasBondGains) ? 'Yes' : 'No')+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }