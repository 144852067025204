<template>
    <b-card class="shadow mt-5" v-if="otherTaxReliefDetails.length">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Other Tax Relief Details
                </h2>

                <div>
                    {{ otherTaxReliefDetails[0].description }}
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'otherTaxReliefDetailsReviewComponent',

    computed: {
        ...mapGetters({
            otherTaxReliefDetails: 'submissionStore/getOtherTaxReliefDetails',
        })
    }
}
</script>