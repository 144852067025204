export default {
    GENERAL_ERROR: 'Sorry, something went wrong. Please try again',
    SAVED_SUCCESS: 'Saved successfully!',
    SAVED_FAILURE: 'Sorry, something went wrong while saving. Please try again',
    COULD_NOT_FETCH_SUBMISSION: 'Sorry, something went wrong when fetching the submission. Please try again',
    REGISTER_SUCCESS_MESSAGE: 'Thank you for registering with Get Tax Done. We have sent you an email to verify your email address. If you cannot find it please check your junk folder',
    USER_ALREADY_EXISTS: 'Sorry, we are unable to register your account. Please check your details and try again. If your account already exists, you can login using your details or reset your account password',
    CONFIRM_ACCOUNT_SUCCESS: 'Thank you for confirming your account. You may now sign in',
    CONFIRM_ACCOUNT_FAILURE: 'Sorry, we were not able to confirm your account. Please try again',
    NO_PREVIOUS_SUBMISSIONS: 'You currently have no submissions',
    PAYMENT_STATUS_UPDATE_FAILURE: 'Sorry, something went wrong. Please try again',
    PAYMENT_FAILURE: 'No payment was taken.',
    FILE_UPLOAD_SUCCESS: 'Document uploaded',
    FILE_UPLOAD_FAILURE: 'Sorry, we could not process this file. Please check the file type is allowed. Max file size is 10mb',
    PASSWORD_RESET_EMAIL_SENT_SUCCESS_MESSAGE: 'Thank you. If your email address is associated to an account, you will receive an email shortly',
    SEND_PASSWORD_RESET_FAILURE: 'Sorry, we could not complete this request. Please try again',
    RESET_PASSWORD_FAILURE: 'Sorry, we could not reset your password. Please try again',
    PASSWORD_RESET_SUCCESS_MESSAGE: 'Thank you. Your password has been changed',
    SUBMISSION_COMPLETION_SUCCESS: 'Thank you. Your submission was successfully submitted. A member of our team will be in touch shortly',
    SUBMISSION_COMPLETION_FAILURE: 'Sorry, we could not complete your submission. Please try again',
    CREATE_USER_SUCCESS: 'The user was created',
    MAXIMUM_PROPERTIES_ERROR: 'You have added the maximum amount of properties',
    NO_TAX_YEARS_RETURNED: 'Sorry, something went wrong. Please try again. If the problem persists, please contact us',
    MUST_SELECT_ONE_SERVICE_OPTION: 'Please select at least one option for your income source',
    MUST_CHOOSE_AT_LEAST_ONE_PROPERTY: 'You must select at least one property',
    DISCOUNT_OFFER_SAVED: 'Discount offer applied and saved',
    DISCOUNT_OFFER_NOT_SAVED: 'Sorry, something went wrong. Please check your code and try again',
    DISCOUNT_OFFER_NOT_APPLIED: 'Sorry, the discount code was not applied',
    ENSURE_ALL_SECTIONS_ARE_COMPLETE: 'Please ensure you have completed all required fields on the previous pages',
    NO_DATA_TO_EXPORT: 'Sorry, there is no data to export in this date range',
    NO_MORE_INCOME_SOURCES_TO_ADD: 'There are no more income sources to add!',
    ADD_AT_LEAST_ONE_INCOME_SOURCE: 'Add at least one Income Source, as well as Basic and Additional details to continue'
}