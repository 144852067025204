<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-8 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/foreign.svg" width="30" height="30" /> Foreign Income
            </b-button>
        </b-card-header>
        
        <b-collapse visible id="accordion-8" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Income statement from the appropriate country</p>
                                    <p>Any income received from overseas should declared on your UK tax return if your a UK resident. You should receive a document or letter confirming your foreign income in the same way you would receive a P60/P45 in the UK.</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
export default {
    name: 'ForeignIncomeWhatYouNeedComponent',

    data() {
        return {
            statement: false
        }
    }
}
</script>