<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-7 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/capital-gains-losses.svg" width="30" height="30" /> Capital Gains / Losses Income
            </b-button>
        </b-card-header>
        
        <b-collapse visible id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Completion statement detailing capital gain</p>
                                    <p>Buying and selling assets comes with its tax implications. If you dispose of an asset for a gain or a loss it will need to be declared on your tax return. Upon disposal you should receive a statement detailing the gain or loss made.</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
export default {
    name: 'CapitalGainsLossesWhatYouNeedComponent',

    data() {
        return {
            statement: false
        }
    }
}
</script>