<template>
    <div class="mt-5 d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div>
            <b-button @click="removeIncomeSource()" variant="outline-danger">
                Remove income source
            </b-button> 
        </div>
        <div class="d-flex mt-3 mt-md-0">
            <b-button class="btn btn-grey mr-3 d-none d-sm-block" @click.prevent="$emit('clickBack')">
                Back
            </b-button>

            <b-button class="btn btn-grey mr-3 d-block d-sm-none" @click.prevent="$emit('clickBack')">
                <img src="@/assets/icons/back-chevron.svg" width="15" />
            </b-button>

            <b-button variant="success" type="submit">
                Save &amp; Continue <span v-if="saving" class="btn-loader"></span>
            </b-button>
        </div>
    </div>
</template>

<script>
import RemoveIncomeSourceModalComponent from '@/components/modal-components/RemoveIncomeSourceModalComponent.vue'
    export default {
        name: 'SaveAndContinueComponent',

        props: {
            saving: Boolean,
            componentName: String
        }, 

        data() {
            return {
                RemoveIncomeSourceModalComponent
            }
        },

        methods: {
            removeIncomeSource() {
                this.$modal.show(RemoveIncomeSourceModalComponent, {componentName: this.componentName})
            }
        }
    }
</script>