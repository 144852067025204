export default {
    methods: {
        // function to check if cookie exists by name
        checkCookie(name) {
            var cookie = this.getCookie(name);
            if (cookie == "") {
                return false
            }

            return cookie;
        },

        // function for getting a cookie by name
        getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },

        // function for setting a cookie
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; expires = " + expires + "; path=/";
        }
    }
}