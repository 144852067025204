import axios from 'axios'

export default {
    namespaced: true,

    state: {
        counts: {},
        submissions: [],
        mySubmissions: [],
        openSubmissions: [],
        closedSubmissions: [],
        etoroSubmissions: []
    },

    getters: {
        getCounts(state) {
            return state.counts
        },

        getAllSubmissions(state) {
            return state.submissions
        },

        getMySubmissions(state) {
            return state.mySubmissions
        },

        getOpenSubmissions(state) {
            return state.openSubmissions
        },

        getClosedSubmissions(state) {
            return state.closedSubmissions
        }
    },

    mutations: {
        PUSH_COUNTS(state, data) {
            state.counts = data
        },

        PUSH_SUBMISSIONS(state, data) {
            state.submissions = data
        },

        PUSH_MY_SUBMISSIONS(state, data) {
            state.mySubmissions = data
        },

        PUSH_OPEN_SUBMISSIONS(state, data) {
            state.openSubmissions = data
        },

        PUSH_CLOSED_SUBMISSIONS(state, data) {
            state.closedSubmissions = data
        },

        SET_ASSIGNED_TO_ON_SUBMISSION(state, data) {
            state.submissions.filter(s => {
                if(s.id == data.submissionId) {
                    s.assignedTo = data.userFullName 
                    s.assignedToEmail = data.user.email
                }
            })
        }, 

        MARK_SUBMISSION_AS_COMPLETE(state, data) {
            state.submissions.filter(s => {
                if(s.id == data) {
                    s.status = 'Completed'
                }
            })
        },

        PUSH_ETORO_SUBMISSIONS(state, data) {
            state.etoroSubmissions = data
        }
    },

    actions: {
        async getCounts({commit}) {
            try {
                let response = await axios.get('/submissions/counts')

                commit('PUSH_COUNTS', response.data)
                
                return response
            } catch {
                //
            }
        },

        async getAllSubmissions({commit}, data) {
            try {
                let response = await axios.get(`submissions/0/${data.amount}/${data.offset}`)

                commit('PUSH_SUBMISSIONS', response.data)
                
                return response
            } catch {
                //
            }
        }, 

        async getMySubmissions({commit}, data) {
            try {
                let response = await axios.get(`submissions/assigned/${data.amount}/${data.offset}`)

                commit('PUSH_MY_SUBMISSIONS', response.data)
                
                return response
            } catch {
                //
            }
        },

        async getOpenSubmissions({commit}, data) {
            try {
                let response = await axios.get(`submissions/open/${data.amount}/${data.offset}`)

                commit('PUSH_OPEN_SUBMISSIONS', response.data)
                
                return response
            } catch {
                //
            }
        },

        async getClosedSubmissions({commit}, data) {
            try {
                let response = await axios.get(`submissions/completed/${data.amount}/${data.offset}`)

                commit('PUSH_CLOSED_SUBMISSIONS', response.data)
                
                return response
            } catch {
                //
            }
        }, 

        async claimSubmission({commit, dispatch}, data) {
            try {
                let response = await axios.post(`submission/${data.submissionId}/assigned-to/me`, {
                    submissionId: data.submissionId
                })

                commit('SET_ASSIGNED_TO_ON_SUBMISSION', data)
                dispatch('getCounts')
                
                return response
            } catch {
                //
            }
        },

        async markSubmissionAsComplete({commit,dispatch}, data) {
            try {
                let response = await axios.post(`submission/${data}/completed`, {
                    submissionId: data
                })
    
                commit('MARK_SUBMISSION_AS_COMPLETE', data)
                dispatch('getCounts')
    
                return response
            } catch {
                //
            }
        }, 

        async getEtoroSubmissions({commit}, data) {
            try {
                let response = await axios.post(`submissions/${data.startDate}/${data.endDate}/etoro`)

                commit('PUSH_ETORO_SUBMISSIONS', response.data)
            } catch {
                //
            }
        }
    }
}