<template>
    <div class="inner-modal text-center">
        <h2>
            There are no documents to download
        </h2>

        <div class="mt-4 px-5">
            <b-button variant="primary" block
                @click.prevent="$emit('close')"
            >
                Close
            </b-button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NothingToDownloadModalComponent',
}
</script>