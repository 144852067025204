<template>
    <div :id="'index'+index" class="bg-white p-4">
        <b-row>
            <b-col md="1" class="text-center">
                <div class="number mx-auto">
                    {{ index + 1 }}
                </div>

                <div class="remove mt-3 cursor-pointer"
                    v-if="index > 0"
                    @click.prevent="removeItem(index)"
                >
                    <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                </div>
            </b-col>

            <b-col md="11" class="mt-4 mt-md-0">
                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'Name '+(index+1)" name="Name" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Stock Name"
                                label-for="name"
                                label-class="required"
                            >
                                <b-form-input
                                    name="name"
                                    v-model="item.name"
                                    type="text"
                                    placeholder="Name"
                                    :class="{'is-valid' : item.name, 'is-invalid' : errors.length}"
                                >
                                </b-form-input>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'Purchased On '+(index+1)" name="Purchased On" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Purchase Date"
                                label-for="purchasedOn"
                                label-class="required"
                            >
                                <b-form-datepicker
                                    name="purchasedOn"
                                    v-model="item.purchasedOn"
                                    :class="{'is-valid' : item.purchasedOn, 'is-invalid' : errors.length}"
                                    placeholder="Choose a date"
                                    no-flip
                                    :max="max"
                                >
                                </b-form-datepicker>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="4">
                        <ValidationProvider :vid="'Units '+(index+1)" name="Units" :rules="{regex: /^[0-9]+(\.[0-9]{1,5})?$/i}" v-slot="{ errors }">
                            <b-form-group
                                label="Units Purchased"
                                label-for="units"
                            >
                                <b-input-group>
                                    <b-form-input
                                        name="units"
                                        v-model="item.purchasedUnits"
                                        placeholder="Units purchased"
                                        :class="{'is-valid' : item.purchasedUnits, 'is-invalid' : errors.length}"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="4">
                        <ValidationProvider :vid="'Unit Price '+(index+1)" name="Unit Price" :rules="{regex: /^[0-9]+(\.[0-9]{1,5})?$/i}" v-slot="{ errors }">
                            <b-form-group
                                label="Unit Price"
                                label-for="unitPrice"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="unitPrice"
                                        v-model="item.purchasedUnitPrice"
                                        placeholder="Unit Price"
                                        :class="{'is-valid' : item.purchasedUnitPrice, 'is-invalid' : errors.length}"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="4">
                        <ValidationProvider :vid="'Purchase Price '+(index+1)" name="Purchase Price" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group
                                label="Total Price"
                                label-for="purchasePrice"
                                label-class="required"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="purchasePrice"
                                        v-model="item.purchasePrice"
                                        placeholder="Purchase Price"
                                        :class="{'is-valid' : item.purchasePrice, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('purchasePrice')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <hr />

                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'Sold On '+(index+1)" name="Sold On" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Sale Date"
                                label-for="soldOn"
                                label-class="required"
                            >
                                <b-form-datepicker
                                    name="soldOn"
                                    v-model="item.soldOn"
                                    :class="{'is-valid' : item.soldOn,'is-invalid' : errors.length}"
                                    placeholder="Choose a date"
                                    no-flip
                                    :max="max"
                                >
                                </b-form-datepicker>

                                <small
                                    v-if="failures && failures['CapitalGains['+index+'].SoldOn']"
                                    class="text-danger"
                                >
                                    {{ failures && failures['CapitalGains['+index+'].SoldOn'][0] }}
                                </small>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="4">
                        <ValidationProvider :vid="'Units Sold '+(index+1)" name="Units Sold" :rules="{regex: /^[0-9]+(\.[0-9]{1,5})?$/i}" v-slot="{ errors }">
                            <b-form-group
                                label="Units Sold Price"
                                label-for="unitsSold"
                            >
                                <b-input-group>
                                    <b-form-input
                                        name="unitsSold"
                                        v-model="item.saleUnits"
                                        placeholder="Units Sold"
                                        :class="{'is-valid' : item.saleUnits, 'is-invalid' : errors.length}"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="4">
                        <ValidationProvider :vid="'Unit Sold Price '+(index+1)" name="Unit Sold Price" :rules="{regex: /^[0-9]+(\.[0-9]{1,5})?$/i}" v-slot="{ errors }">
                            <b-form-group
                                label="Unit Sold Price"
                                label-for="unitSoldPrice"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="unitSoldPrice"
                                        v-model="item.saleUnitPrice"
                                        placeholder="Unit Sold Price"
                                        :class="{'is-valid' : item.saleUnitPrice, 'is-invalid' : errors.length}"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="4">
                        <ValidationProvider :vid="'Sale Price '+(index+1)" name="Sale Price" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group
                                label="Total Sold Price"
                                label-for="salePrice"
                                label-class="required"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="salePrice"
                                        v-model="item.salePrice"
                                        placeholder="Sale Price"
                                        :class="{'is-valid' : item.salePrice, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('salePrice')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <hr />

                <div class="mt-4">
                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <h4 class="mb-0">
                            Entrepreneurs Relief? <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.EntrepeneursRelief.Title, Helpers.EntrepeneursRelief.Body)" />
                        </h4>

                        <div class="mt-4 mt-md-0">
                            <b-button class="btn-bool mr-1"
                                @click="item.hasEntrepreneursRelief = !item.hasEntrepreneursRelief"
                                :class="{active: item.hasEntrepreneursRelief}"
                            >
                                Yes
                            </b-button>

                            <b-button class="btn-bool"
                                @click="item.hasEntrepreneursRelief = !item.hasEntrepreneursRelief"
                                :class="{negativeActive: !item.hasEntrepreneursRelief}"
                            >
                                No
                            </b-button>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <h4 class="mb-0">
                            Private Residence Relief? <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.PrivateResidentsRelief.Title, Helpers.PrivateResidentsRelief.Body)" />
                        </h4>

                        <div class="mt-4 mt-md-0">
                            <b-button class="btn-bool mr-1"
                                @click="item.hasPrivateResidenceRelief = !item.hasPrivateResidenceRelief"
                                :class="{active: item.hasPrivateResidenceRelief}"
                            >
                                Yes
                            </b-button>

                            <b-button class="btn-bool"
                                @click="item.hasPrivateResidenceRelief = !item.hasPrivateResidenceRelief"
                                :class="{negativeActive: !item.hasPrivateResidenceRelief}"
                            >
                                No
                            </b-button>
                        </div>
                    </div>
                </div>                
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'CapitalGainsLossesItemComponent',

    props: {
        item: {},
        index: Number,
        failures: {}
    },

    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const maxDate = new Date(today)

        return {
            max: maxDate,
            HelperModalComponent,
            Helpers
        }
    },

    mounted() {
        this.item.purchasePrice = (this.item.purchasePrice / 100).toFixed(2)
        this.item.salePrice = (this.item.salePrice / 100).toFixed(2)
        this.item.purchasedUnitPrice = parseFloat((this.item.purchasedUnitPrice / 100).toFixed(5))
        this.item.saleUnitPrice = parseFloat((this.item.saleUnitPrice / 100).toFixed(5))
        this.item.saleUnits = parseFloat((this.item.saleUnits / 100).toFixed(5))
        this.item.purchasedUnits = parseFloat((this.item.purchasedUnits / 100).toFixed(5))
    },
    
    computed: {
        ...mapGetters({
            capitalGainsDetails: 'submissionStore/getCapitalGainsDetails',
        })
    },

    methods: {
        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        removeItem(index) {
            this.capitalGainsDetails.splice(index, 1)

            setTimeout(() => {
                this.$scrollTo('#index'+(this.capitalGainsDetails.length - 1), 800)
            }, 500)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        }
    }
}
</script>