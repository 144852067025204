<template>
    <div class="notifications">
        <div 
            v-for="(notification, index) in notifications" 
            :key="index"
            class="alert mb-1" 
            :class="{'notification-success' : notification.type == 'success', 'alert-danger' : notification.type == 'failure'}"
        >
            {{ notification.message }}
        </div>
    </div>
</template>

<script>
import{mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            notifications: 'notificationsStore/getNotifications'
        })  
    }
}
</script>