<template>
    <div class="pb-5">
        <template
            v-if="loading"
        >
            <div class="loader-container py-4">
                <div class="loader"></div>
            </div>
        </template>

        <template
            v-else
        >
            <b-container>
                <b-row>
                    <b-col>
                        <h2>
                            Your Checklist
                        </h2>
                        
                        <p class="lead"> 
                            To make the process as pain free as possible, we’ve compiled a list of documents that you will need ahead of completing each section of your application. However if you do not have all of the required information, you can save your progress and come back to it at a later date.
                        </p>        
                    </b-col>    
                </b-row>

                <b-row class="mt-4">
                    <b-col>
                        <div class="accordion gtd-accordion" role="tablist">
                            <basic-details-what-you-need-component />
                            
                            <employment-income-what-you-need-component 
                                v-if="showHasEmploymentIncome"
                            />

                            <etoro-details-what-you-need-component 
                                v-if="showEtoroDetails"
                            />

                            <self-employment-income-what-you-need-component 
                                v-if="showIsSelfEmployed"
                            />

                            <property-income-what-you-need-component 
                                v-if="showHasPropertyIncome"
                            />

                            <pension-income-what-you-need-component 
                                v-if="showHasPensionIncome" 
                            />
                            
                            <dividend-income-what-you-need-component 
                                v-if="showHasDividendIncome"
                            />

                            <capital-gains-losses-what-you-need-component 
                                v-if="showHasCapitalGainsAndLosses"
                            />

                            <foreign-income-what-you-need-component 
                                v-if="showHasForeignIncome"
                            />

                            <child-benefit-income-what-you-need-component 
                                v-if="showHasHigherIncomeChildBenefitCharge"
                            />
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </template>

        <div class="fixed-bottom bg-white shadow py-3">
            <b-container>
                <b-row>
                    <b-col class="text-right center-mobile">
                        <b-button variant="success"
                            @click="next()"
                        >
                            Ok, I'm ready
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex'
import BasicDetailsWhatYouNeedComponent from '@/components/what-you-need-components/BasicDetailsWhatYouNeedComponent.vue'
import SelfEmploymentIncomeWhatYouNeedComponent from '@/components/what-you-need-components/SelfEmploymentIncomeWhatYouNeedComponent.vue'
import EmploymentIncomeWhatYouNeedComponent from '@/components/what-you-need-components/EmploymentIncomeWhatYouNeedComponent.vue'
import EtoroDetailsWhatYouNeedComponent from '@/components/what-you-need-components/EtoroDetailsWhatYouNeedComponent.vue'
import CapitalGainsLossesWhatYouNeedComponent from '@/components/what-you-need-components/CapitalGainsLossesWhatYouNeedComponent.vue'
import PropertyIncomeWhatYouNeedComponent from '@/components/what-you-need-components/PropertyIncomeWhatYouNeedComponent.vue'
import PensionIncomeWhatYouNeedComponent from '@/components/what-you-need-components/PensionIncomeWhatYouNeedComponent.vue'
import DividendIncomeWhatYouNeedComponent from '@/components/what-you-need-components/DividendIncomeWhatYouNeedComponent.vue'
import ForeignIncomeWhatYouNeedComponent from '@/components/what-you-need-components/ForeignIncomeWhatYouNeedComponent.vue'
import ChildBenefitIncomeWhatYouNeedComponent from '@/components/what-you-need-components/ChildBenefitIncomeWhatYouNeedComponent.vue'
import CookieMixin from '@/mixins/CookieMixin'
import Notifications from '@/Notifications'

export default {
    name: 'Checklist',

    components: {
        BasicDetailsWhatYouNeedComponent,
        EmploymentIncomeWhatYouNeedComponent,
        EtoroDetailsWhatYouNeedComponent,
        SelfEmploymentIncomeWhatYouNeedComponent,
        CapitalGainsLossesWhatYouNeedComponent,
        PropertyIncomeWhatYouNeedComponent,
        PensionIncomeWhatYouNeedComponent,
        DividendIncomeWhatYouNeedComponent,
        ForeignIncomeWhatYouNeedComponent,
        ChildBenefitIncomeWhatYouNeedComponent
    },

    mixins: [
        CookieMixin
    ],

    data() {
        return {
            loading: true, 
            showHasEmploymentIncome: false,
            showIsSelfEmployed: false,
            showEtoroDetails: false,
            showHasPropertyIncome: false,
            showHasPensionIncome: false,
            showHasDividendIncome: false,
            showHasCapitalGainsAndLosses: false,
            showHasForeignIncome: false,
            showHasHigherIncomeChildBenefitCharge: false,
            showHasAnyOtherIncome: false,
            showHasOtherTaxReliefs: false,
            enteredDiscountCode: null,
        }
    },

    async mounted() {
        if(this.submissionId == null) return this.$router.push({name: 'MyAccount'})
        
        this.getSubmissionAnswers()

        // check for discount code in cookie. 
        if(this.discountOfferId == 0) {
            var cookie = this.checkCookie("site_params")

            if(cookie) {
                var cookieValues = this.getCookie('site_params')

                if(cookieValues != null) {
                    var parsedValues = JSON.parse('{"' + decodeURI(cookieValues).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')                    
                    
                    if(parsedValues.discountCode) {
                        this.enteredDiscountCode = parsedValues.discountCode

                        try {
                            await this.applyDiscount()

                            this.setNotification({
                                type: 'success',
                                message: Notifications.DISCOUNT_OFFER_SAVED
                            })
                        }
                        catch {
                            this.setNotification({
                                type: 'failure',
                                message: Notifications.DISCOUNT_OFFER_NOT_APPLIED
                            })
                        }       
                        finally {
                            this.loading = false
                        }
                    }
                } 
            } 
        }

        // if a cookie is still set, remove it so it can't be reused
        document.cookie = 'site_params=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=gettaxdone.co.uk';

        this.loading = false
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId',
            firstRoundAnswers: 'submissionStore/getFirstRoundAnswers',
            discountOffers: 'discountOffersStore/getDiscountOffers',
            discountOfferId: 'submissionStore/getDiscountOfferId'
        })
    },

    methods: {
        ...mapActions({
            setDiscountCode: 'submissionStore/setDiscountCode',
            getDiscountOffers: 'discountOffersStore/getDiscountOffers',
            setNotification: 'notificationsStore/setNotification',
            markAccountantComplete: 'userProgressStore/setAccountantComplete'
        }),

        getSubmissionAnswers() {
            if (this.firstRoundAnswers.hasEmploymentIncome) {
                this.showHasEmploymentIncome = true
            }

            if (this.firstRoundAnswers.hasEtoro) {
                this.showEtoroDetails = true
            }

            if (this.firstRoundAnswers.isSelfEmployed) {
                this.showIsSelfEmployed = true
            }

            if (this.firstRoundAnswers.hasPropertyIncome) {
                this.showHasPropertyIncome = true
            }

            if (this.firstRoundAnswers.hasPensionIncome) {
                this.showHasPensionIncome = true
            }
            
            if (this.firstRoundAnswers.hasDividendIncome) {
                this.showHasDividendIncome = true
            }

            if (this.firstRoundAnswers.hasCapitalGainsAndLosses) {
                this.showHasCapitalGainsAndLosses = true
            }

            if (this.firstRoundAnswers.hasForeignIncome) {
                this.showHasForeignIncome = true
            }

            if (this.firstRoundAnswers.hasHigherIncomeChildBenefitCharge) {
                this.showHasHigherIncomeChildBenefitCharge = true
            }

            if (this.firstRoundAnswers.hasAnyOtherIncome) {
                this.showHasAnyOtherIncome = true
            }
            
            if (this.firstRoundAnswers.hasOtherTaxReliefs) {
                this.showHasOtherTaxReliefs = true
            }
        },

        async applyDiscount() {
            try {
                await this.getDiscountOffers()
                
                let offer = this.discountOffers.filter(offer => offer.code == this.enteredDiscountCode)
                
                if(offer && offer != null) {
                    this.setDiscountCode({
                        submissionId: this.submissionId,
                        discountOfferId: offer[0].id
                    })
                }
            }
            catch(err) {
                throw new Error(err.message)
            }
        },

        next() {
            this.markAccountantComplete()
            this.$router.push({name: 'Information'})
        }
    }
}
</script>
