import axios from "axios"

export default {
    namespaced: true,

    state: {
        fieldResolverKey: 1,
        id: null,
        selectedTaxYearId: null,
        reasonsAnswers: {
            isHMRCInstructedReason: false,
            isLandlordReason: false,
            isSelfEmployedReason: false,
            isFirstTimeReason: false,
            isCompanyDirectorReason: false,
            isCISContractorReason: false,
            isInvestorReason: false,
            isHighEarnerReason: false,
            isOtherReason: false,
        },
        firstRoundAnswers: {
            hasEmploymentIncome: false,
            isSelfEmployed: false,
            hasPropertyIncome: false,
            hasPensionIncome: false,
            hasEtoro: false,
            hasDividendIncome: false,
            hasCapitalGainsAndLosses: false,
            hasForeignIncome: false,
            hasHigherIncomeChildBenefitCharge: false,
            hasAnyOtherIncome: false,
            hasOtherTaxReliefs: false,
        },
        numberOfProperties: 0,
        quote: Number,
        paymentProviderToken: 12345,
        hasBeenPaidFor: false,
        hasBeenSubmitted: false,
        basicDetails: {},
        paymentRef: null,
        paidOn: null,
        paidAmount: null,
        userId: null,
        discountOfferId: null,

        employmentIncomeDetails: [],

        etoroDetails: {},

        etoroSaleItemDetails: [],

        selfEmploymentIncomeDetails: {},

        propertyIncomeDetails: [],
        
        otherIncomeDetails: [],

        otherTaxReliefDetails: [],

        dividendIncomeDetails: [],
        
        interestsIncomeDetails: [],

        pensionIncomeDetails: [],
        
        childBenefitDetails: {},

        capitalGainsLossesDetails: {},

        capitalGainsDocuments: [],
        
        foreignIncomeDetails: [], 

        residenceRemittanceDetails: {}, 

        giftsDetails: [],

        scottishTaxpayerDetails: {},

        taxAvoidanceDetails: {},

        disguisedRenumerationAvoidanceDetails: {},

        taxLiabilityDetails: {},

        jcpRefundsDetails: [],

        studentLoans: [],

        marriageAllowanceTransferDetails: {},

        marriageAllowanceReceiptDetails: {},

        pensionAnnuityContributionsDetails: [],

        giftAidDonationsDetails: [],

        additionalNotesDetails: {}
    },

    getters: {
        getFieldResolverKey(state) {
            return state.fieldResolverKey
        },

        getId(state) {
            return state.id
        },

        getHasId(state) {
            return state.id != null
        },

        getUserId(state) {
            return state.userId
        },

        getReasons(state) {
            return state.reasonsAnswers
        },

        getSelectedTaxYearId(state) {
            return state.selectedTaxYearId
        },

        getHasBeenPaidFor(state) {
            return state.hasBeenPaidFor
        },

        getFirstRoundAnswers(state) {
            return state.firstRoundAnswers
        },

        getQuote(state) {
            return state.quote
        },

        getBasicDetails(state) {
            return state.basicDetails
        },

        getIsBasicDetailsComplete(state) {
            return Boolean(state.basicDetails.address.line1 !== null && state.basicDetails.address.county !== null && state.basicDetails.address.postcode !== null && state.basicDetails.userDocumentId != null)
        },  

        getEmploymentIncomeDetails(state) {
            return state.employmentIncomeDetails
        },

        getEtoroDetails(state) {
            return state.etoroDetails
        },

        getEtoroSaleItemDetails(state) {
            return state.etoroSaleItemDetails
        },

        getIsEmploymentIncomeDetailsComplete(state) {
            return Boolean(state.employmentIncomeDetails.length > 0)
        },

        getSelfEmploymentIncomeDetails(state) {
            return state.selfEmploymentIncomeDetails
        },

        getPropertyIncomeDetails(state) {
            return state.propertyIncomeDetails
        }, 

        getNumberOfProperties(state) {
            return state.numberOfProperties
        },

        getOtherIncomeDetails(state) {
            return state.otherIncomeDetails
        },

        getOtherTaxReliefDetails(state) {
            return state.otherTaxReliefDetails
        },

        getInterestsIncomeDetails(state) {
            return state.interestsIncomeDetails
        },

        getDividendIncomeDetails(state) {
            return state.dividendIncomeDetails
        },

        getPensionIncomeDetails(state) {
            return state.pensionIncomeDetails
        },

        getChildBenefitDetails(state) {
            return state.childBenefitDetails
        },

        getIsChildBenefitDetailsComplete(state) {
            return Boolean(state.childBenefitDetails.numberOfChildren !== null && state.childBenefitDetails.receivedAmount !== null)
        },

        getCapitalGainsLossesDetails(state) {
            return state.capitalGainsLossesDetails
        },

        getCapitalGainsDocuments(state) {
            return state.capitalGainsDocuments
        },

        getCapitalGainsDetails(state) {
            return state.capitalGainsLossesDetails.capitalGains
        },

        getForeignIncomeDetails(state) {
            return state.foreignIncomeDetails
        },

        getResidenceRemittanceDetails(state) {
            return state.residenceRemittanceDetails
        },

        getGiftsDetails(state) {
            return state.giftsDetails
        },

        getScottishTaxpayerDetails(state) {
            return state.scottishTaxpayerDetails
        },

        getTaxAvoidanceDetails(state) {
            return state.taxAvoidanceDetails
        },

        getDisguisedRemunerationAvoidanceDetails(state) {
            return state.disguisedRenumerationAvoidanceDetails
        },

        getTaxLiabilityDetails(state) {
            return state.taxLiabilityDetails
        },

        getJcpRefundsDetails(state) {
            return state.jcpRefundsDetails
        },
        
        getStudentLoansDetails(state) {
            return state.studentLoans
        },

        getMarriageAllowanceTransferDetails(state) {
            return state.marriageAllowanceTransferDetails
        },

        getMarriageAllowanceReceiptDetails(state) {
            return state.marriageAllowanceReceiptDetails
        },

        getPensionAnnuityContributionsDetails(state) {
            return state.pensionAnnuityContributionsDetails
        },

        getGiftAidDonationsDetails(state) {
            return state.giftAidDonationsDetails
        },

        getAdditionalNotesDetails(state) {
            return state.additionalNotesDetails
        },

        getPaymentReferenceDetails(state) {
            var data = {
                reference: state.paymentRef,
                paidOn: state.paidOn,
                amount: state.paidAmount,
            }

            return data
        },

        getDiscountOfferId(state) {
            return state.discountOfferId
        }
    },

    mutations: {
        CLEAR_SUBMISSION_STATE(state) {
                state.id = null,
                state.selectedTaxYearId = null,
                state.reasonsAnswers = {
                    isHMRCInstructedReason: false,
                    isLandlordReason: false,
                    isSelfEmployedReason: false,
                    isFirstTimeReason: false,
                    isCompanyDirectorReason: false,
                    isCISContractorReason: false,
                    isInvestorReason: false,
                    isHighEarnerReason: false,
                    isOtherReason: false,
                },
                state.firstRoundAnswers = {
                    hasEmploymentIncome: false,
                    isSelfEmployed: false,
                    hasPropertyIncome: false,
                    hasPensionIncome: false,
                    hasEtoro: false,
                    hasDividendIncome: false,
                    hasCapitalGainsAndLosses: false,
                    hasForeignIncome: false,
                    hasHigherIncomeChildBenefitCharge: false,
                    hasAnyOtherIncome: false,
                    hasOtherTaxReliefs: false,
                },
                state.numberOfProperties = 0,
                state.quote = Number,
                state.paymentProviderToken = 12345,
                state.hasBeenPaidFor = false,
                state.hasBeenSubmitted = false,
                state.basicDetails = {},
                state.paymentRef = null,
                state.paidOn = null,
                state.paidAmount = null,
                state.userId = null,
                state.employmentIncomeDetails = [],
                state.etoroDetails = {},
                state.etoroSaleItemDetails = [],
                state.selfEmploymentIncomeDetails = {},
                state.propertyIncomeDetails = [],
                state.otherIncomeDetails = [],
                state.otherTaxReliefDetails = [],
                state.dividendIncomeDetails = [],
                state.interestsIncomeDetails = [],
                state.pensionIncomeDetails = [],
                state.childBenefitDetails = {},
                state.capitalGainsLossesDetails = {},
                state.foreignIncomeDetails = [], 
                state.residenceRemittanceDetails = {}, 
                state.giftsDetails = [],
                state.scottishTaxpayerDetails = {},
                state.taxAvoidanceDetails = {},
                state.disguisedRenumerationAvoidanceDetails = {},
                state.taxLiabilityDetails = {},
                state.jcpRefundsDetails = [],
                state.studentLoans = [],
                state.marriageAllowanceTransferDetails = {},
                state.marriageAllowanceReceiptDetails = {},
                state.pensionAnnuityContributionsDetails = [],
                state.giftAidDonationsDetails = []
        },

        SET_SUBMISSION_TAX_YEAR(state, data) {
            state.selectedTaxYearId = data
        },

        PUSH_SUBMISSION(state, data) {            
            state.id = data.id
            if(data.userId) state.userId = data.userId
            state.selectedTaxYearId = data.taxYearId
            state.hasBeenPaidFor = data.hasBeenPaidFor
            state.numberOfProperties = data.numberOfProperties
            state.discountOfferId = data.discountOfferId
            state.reasonsAnswers = {
                isHMRCInstructedReason: data.isHMRCInstructedReason,
                isLandlordReason: data.isLandlordReason,
                isSelfEmployedReason: data.isSelfEmployedReason,
                isFirstTimeReason: data.isFirstTimeReason,
                isCompanyDirectorReason: data.isCompanyDirectorReason,
                isCISContractorReason: data.isCISContractorReason,
                isInvestorReason: data.isInvestorReason,
                isHighEarnerReason: data.isHighEarnerReason,
                isOtherReason: data.isOtherReason,
            }
            state.firstRoundAnswers = {
                hasEmploymentIncome: data.hasEmploymentIncome,
                isSelfEmployed: data.isSelfEmployed,
                hasPropertyIncome: data.hasPropertyIncome,
                hasPensionIncome: data.hasPensionIncome,
                hasEtoro: data.hasEtoro,
                hasDividendIncome: data.hasDividendIncome,
                hasCapitalGainsAndLosses: data.hasCapitalGainsAndLosses,
                hasForeignIncome: data.hasForeignIncome,
                hasHigherIncomeChildBenefitCharge: data.hasHigherIncomeChildBenefitCharge,
                hasAnyOtherIncome: data.hasAnyOtherIncome,
                hasOtherTaxReliefs: data.hasOtherTaxReliefs,
            }
        },

        PUSH_BASIC_DETAILS(state, data) {
            state.basicDetails = data
        },

        PUSH_EMPLOYMENT_INCOME_DETAILS(state, data) {
            state.employmentIncomeDetails = data
        },

        PUSH_ETORO_DETAILS(state, data) {
            state.etoroDetails = data
        },

        PUSH_ETORO_SALE_ITEM_DETAILS(state, data) {
            state.etoroSaleItemDetails = data
        },

        PUSH_SELF_EMPLOYMENT_INCOME_DETAILS(state, data) {
            state.selfEmploymentIncomeDetails = data
        },

        PUSH_PROPERTY_INCOME_DETAILS(state, data) {
            state.propertyIncomeDetails = data
        },

        PUSH_OTHER_INCOME_DETAILS(state, data) {
            state.otherIncomeDetails = data
        },

        PUSH_OTHER_TAX_RELIEF_DETAILS(state, data) {
            state.otherTaxReliefDetails = data
        },

        PUSH_DIVIDEND_INCOME_DETAILS(state, data) {
            if(data.interests.isAmountGross == null) data.interests.isAmountGross = false
            state.dividendIncomeDetails = data.dividends
            state.interestsIncomeDetails = data.interests
        },

        PUSH_PENSION_INCOME_DETAILS(state, data) {
            state.pensionIncomeDetails = data
        },

        PUSH_CHILD_BENEFIT_DETAILS(state, data) {
            state.childBenefitDetails = data
        },

        PUSH_CAPITAL_GAINS_DETAILS(state, data) {
            if(data.hasBondGains == null) data.hasBondGains = false
            state.capitalGainsLossesDetails = data
        },

        PUSH_CAPITAL_GAINS_DOCUMENTS(state, data) {
            state.capitalGainsDocuments = data.capitalGainDocuments
        },

        PUSH_FOREIGN_INCOME_DETAILS(state, data) {
            state.foreignIncomeDetails = data
        },

        PUSH_RESIDENCE_REMITTANCE_DETAILS(state, data) {
            if(data.isNonUkResident == null) data.isNonUkResident = false
            state.residenceRemittanceDetails = data
        },

        PUSH_GIFTS_DETAILS(state, data) {
            state.giftsDetails = data
        },

        PUSH_SCOTTISH_TAXPAYER_DETAILS(state, data) {
            if(data.isScottishTaxpayer == null) data.isScottishTaxpayer = false
            state.scottishTaxpayerDetails = data
        },

        PUSH_TAX_AVOIDANCE_DETAILS(state, data) {
            if(data.isUsingTaxAvoidance == null) data.isUsingTaxAvoidance = false
            state.taxAvoidanceDetails = data
        },

        PUSH_DISGUISED_RENUMERATION_AVOIDANCE_DETAILS(state, data) {
            if(data.isUsingDisguisedRemunerationAvoidance == null) data.isUsingDisguisedRemunerationAvoidance = false
            state.disguisedRenumerationAvoidanceDetails = data
        },

        PUSH_TAX_LIABILITY_DETAILS(state, data) {
            if(data.hasTaxLiabilityToCollectNextYear == null) data.hasTaxLiabilityToCollectNextYear = false
            state.taxLiabilityDetails = data
        },

        PUSH_JCP_REFUNDS_DETAILS(state, data) {
            state.jcpRefundsDetails = data
        },

        PUSH_STUDENT_LOANS_DETAILS(state, data) {
            if(data.willRepayInNextTwoYears == null) data.willRepayInNextTwoYears = false

            state.studentLoans = data
        },

        PUSH_MARRIAGE_ALLOWANCE_TRANSFER_DETAILS(state, data) {
            state.marriageAllowanceTransferDetails = data
        },

        PUSH_MARRIAGE_ALLOWANCE_RECEIPT_DETAILS(state, data) {
            state.marriageAllowanceReceiptDetails = data
        },

        PUSH_PENSION_ANNUITY_CONTRIBUTIONS_DETAILS(state, data) {
            state.pensionAnnuityContributionsDetails = data
        },

        PUSH_GIFT_AID_DONATIONS_DETAILS(state, data) {
            state.giftAidDonationsDetails = data
        },

        PUSH_ADDITIONAL_NOTES_DETAILS(state, data) {
            state.additionalNotesDetails = data
        },

        PUSH_QUOTE(state, data) {
            state.quote = data
        },

        SET_SUBMISSION_AS_PAID(state, data) {
            state.hasBeenPaidFor = true
            state.paymentRef = data.paymentRef
            state.paidOn = data.paidOn
            state.paidAmount = data.paidAmount
        },

        SET_SUBMISSION_AS_COMPLETE(state) {
            state.hasBeenSubmitted = true
        },

        SET_NUMBER_OF_PROPERTIES(state, data) {
            state.numberOfProperties = data
        },

        SET_DISCOUNT_OFFER_ID(state, data) {
            state.discountOfferId = data
        },

        SET_ETORO_SALE_ITEMS_TO_EMPTY_ARRAY(state) {
            state.etoroSaleItemDetails = []
        },

        DELETE_ADDITIONAL_NOTES_DOCUMENTS(state) {
            state.additionalNotesDetails.items = []
        },

        DELETE_CAPITAL_GAINS_DOCUMENTS(state) {
            state.capitalGainsDocuments = []
        },

        RESET_FIRST_ROUND_ANSWERS(state) {
            state.firstRoundAnswers = {
                hasEmploymentIncome: false,
                isSelfEmployed: false,
                hasPropertyIncome: false,
                hasPensionIncome: false,
                hasEtoro: false,
                hasDividendIncome: false,
                hasCapitalGainsAndLosses: false,
                hasForeignIncome: false,
                hasHigherIncomeChildBenefitCharge: false,
                hasAnyOtherIncome: false,
                hasOtherTaxReliefs: false,
            }
        },

        UPDATE_FIELD_RESOLVER_KEY(state) {
            state.fieldResolverKey += 1
        }
    },

    actions: {
        clearSubmussionState({commit}) {
            commit('CLEAR_SUBMISSION_STATE')
        },

        setSubmissionTaxYear({commit}, data) {
            commit('SET_SUBMISSION_TAX_YEAR', data)
        },

        async createSubmission({commit}, data) {
            try {
                let response = await axios.post('submissions', data)

                commit('PUSH_SUBMISSION', response.data)
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmission({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}`)

                commit('PUSH_SUBMISSION', response.data)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionBasicDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/basic-details`)

                commit('PUSH_BASIC_DETAILS', response.data)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        }, 

        async getSubmissionEmploymentIncomeDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/employment-details`)

                commit('PUSH_EMPLOYMENT_INCOME_DETAILS', response.data.employments)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        }, 

        async getSubmissionEtoroDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/etoro-details`)
                commit('PUSH_ETORO_DETAILS', response.data)
                commit('PUSH_ETORO_SALE_ITEM_DETAILS', response.data.etoroSaleItems)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        }, 

        async getSubmissionSelfEmploymentIncomeDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/partnership-details`)

                commit('PUSH_SELF_EMPLOYMENT_INCOME_DETAILS', response.data)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        }, 

        async getSubmissionPropertyIncomeDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/property-details`)

                commit('PUSH_PROPERTY_INCOME_DETAILS', response.data.properties)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionOtherIncomeDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/other-income-details`)

                commit('PUSH_OTHER_INCOME_DETAILS', response.data.otherIncomes)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionOtherTaxReliefDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/other-tax-reliefs`)

                commit('PUSH_OTHER_TAX_RELIEF_DETAILS', response.data.otherTaxReliefs)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionDividendIncomeDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/dividends-interests-details`)

                commit('PUSH_DIVIDEND_INCOME_DETAILS', response.data)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionPensionIncomeDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/pension-details`)

                commit('PUSH_PENSION_INCOME_DETAILS', response.data.pensions)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionChildBenefitDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/child-benefit-details`)

                commit('PUSH_CHILD_BENEFIT_DETAILS', response.data)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionCapitalGainsLossesDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/capital-gains-details`)

                commit('PUSH_CAPITAL_GAINS_DETAILS', response.data)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionCapitalGainsDocuments({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/capital-gains-documents`)

                commit('PUSH_CAPITAL_GAINS_DOCUMENTS', response.data)
    
                return response
            }
            catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionForeignIncomeDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/foreign-income-details`)

                commit('PUSH_FOREIGN_INCOME_DETAILS', response.data.foreignIncomes)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionResidenceRemittanceDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/residence-remittance`)

                commit('PUSH_RESIDENCE_REMITTANCE_DETAILS', response.data)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionGiftsDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/gifts`)

                commit('PUSH_GIFTS_DETAILS', response.data.gifts)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionScottishTaxpayerDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/scottish-taxpayer`)

                commit('PUSH_SCOTTISH_TAXPAYER_DETAILS', response.data)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionTaxAvoidanceDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/tax-avoidance`)

                commit('PUSH_TAX_AVOIDANCE_DETAILS', response.data)
    
                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionDisguisedRemunerationAvoidanceDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/disguised-remuneration-avoidance`)

                commit('PUSH_DISGUISED_RENUMERATION_AVOIDANCE_DETAILS', response.data)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionTaxLiabilityDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/tax-liability`)

                commit('PUSH_TAX_LIABILITY_DETAILS', response.data)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionJcpRefundsDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/jcp-refunds`)

                commit('PUSH_JCP_REFUNDS_DETAILS', response.data.jcpRefunds)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionStudentLoansDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/student-loans`)

                commit('PUSH_STUDENT_LOANS_DETAILS', response.data.studentLoans)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionMarriageAllowanceTransferDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/marriage-allowance-transfer`)

                commit('PUSH_MARRIAGE_ALLOWANCE_TRANSFER_DETAILS', response.data)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionMarriageAllowanceReceiptDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/marriage-allowance-receipt`)

                commit('PUSH_MARRIAGE_ALLOWANCE_RECEIPT_DETAILS', response.data)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionPensionAnnuityContributionsDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/pension-annuity-contributions`)

                commit('PUSH_PENSION_ANNUITY_CONTRIBUTIONS_DETAILS', response.data.pensionAnnuityContributions)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionGiftAidDonationsDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/gift-aid-donations`)

                commit('PUSH_GIFT_AID_DONATIONS_DETAILS', response.data.giftAidDonations)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getSubmissionAdditionalNotesDetails({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/additional-information`)

                commit('PUSH_ADDITIONAL_NOTES_DETAILS', response.data)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async getQuote({commit}, data) {
            try {
                let response = await axios.get(`submission/${data}/quote`)
                
                commit('PUSH_QUOTE', response.data.amount)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async markSubmissionAsPaid({commit}, data) {
            try {
                let response = await axios.post(`submission/${data}/payment-status`, {
                    submissionId: Number(data),
                    isPaymentSuccessful: true
                })
    
                commit('SET_SUBMISSION_AS_PAID', response.data)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async markSubmissionAsComplete({commit}, data) {
            try {
                let response = await axios.post(`submission/${data}/submitted`, {
                    submissionId: Number(data)
                })

                commit('SET_SUBMISSION_AS_COMPLETE', true)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        async setDiscountCode({commit}, data) {
            try {
                let response = await axios.post(`submission/${data.submissionId}/discount-code`, {
                    submissionId: Number(data.submissionId),
                    discountOfferId: Number(data.discountOfferId)
                })

                commit('SET_DISCOUNT_OFFER_ID', data.discountOfferId)

                return response
            } catch(err) {
                throw Error(err.message)
            }
        },

        updateNumberOfProperties({commit}, data) {
            commit('SET_NUMBER_OF_PROPERTIES', data)
        },

        setEtoroSaleItemsToEmptyArray({commit}) {
            commit('SET_ETORO_SALE_ITEMS_TO_EMPTY_ARRAY')
        },

        deleteAdditionalNotesDocuments({commit}) {
            commit('DELETE_ADDITIONAL_NOTES_DOCUMENTS')
        },

        deleteCapitalGainsDocuments({commit}) {
            commit('DELETE_CAPITAL_GAINS_DOCUMENTS')
        },

        resetFirstRoundAnswers({commit}) {
            commit('RESET_FIRST_ROUND_ANSWERS')
        },

        updateFieldResolverKey({commit}) {
            commit('UPDATE_FIELD_RESOLVER_KEY')
        }
    }
}