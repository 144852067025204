<template>
    <b-container>
        <template
            v-if="loading"
        >
            <div class="loader-container py-4">
                <div class="loader"></div>
            </div>
        </template>

        <template
            v-else
        >   
            <b-row class="mt-5 no-print">
                <b-col class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                    <b-button variant="primary"
                        @click.prevent="downloadDocuments()"
                    >
                        Download Documents
                    </b-button>    

                    <b-button variant="primary"
                        class="ml-0 ml-md-2 mt-2 mt-md-0"
                        @click.prevent="printPage()"
                    >
                        Print Page
                    </b-button>     
                </b-col>
            </b-row>

            <div class="mt-5">
                <h5 class="mb-5">{{ formatTaxYear() }}</h5>

                <basic-details-review-component />

                <employment-income-details-review-component 
                    v-if="showHasEmploymentIncome"
                />
                
                <self-employment-income-details-review-component 
                    v-if="showIsSelfEmployed"
                />
                    
                <property-income-details-review-component 
                    v-if="showHasPropertyIncome"
                />
                
                <pension-income-details-review-component 
                    v-if="showHasPensionIncome"
                />

                <etoro-details-review-component
                    v-if="showHasEtoroDetails"
                />
                
                <dividend-income-details-review-component 
                    v-if="showHasDividendIncome"
                />
                
                <interest-income-details-review-component 
                    v-if="showHasDividendIncome"
                />

                <capital-gains-losses-details-review-component 
                    v-if="showHasCapitalGainsAndLosses"
                />

                <foreign-income-details-review-component 
                    v-if="showHasForeignIncome"
                />

                <child-benefit-details-review-component 
                    v-if="showHasHigherIncomeChildBenefitCharge"
                />
                
                <other-income-details-review-component 
                    v-if="showHasAnyOtherIncome"
                />
                
                <other-tax-relief-details-review-component 
                    v-if="showHasOtherTaxReliefs"
                />
                
                <additional-notes-details-review-component />
                <gifts-details-review-component />
                <gift-aid-donations-details-review-component />
                <residence-remittance-details-review-component />
                <scottish-taxpayer-details-review-component />
                <tax-avoidance-details-review-component />
                <disguised-remuneration-avoidance-details-review-component />
                <tax-liability-details-review-component />
                <jcp-refunds-details-review-component />
                <student-loans-details-review-component />
                <marriage-allowance-transfer-details-review-component />
                <marriage-allowance-receipt-details-review-component />
                <pension-annuity-contributions-details-review-component />
            </div>
        </template>
    </b-container>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import BasicDetailsReviewComponent from '@/components/review-components/BasicDetailsReviewComponent.vue'
import EmploymentIncomeDetailsReviewComponent from '@/components/review-components/EmploymentIncomeDetailsReviewComponent.vue'
import EtoroDetailsReviewComponent from '@/components/review-components/EtoroDetailsReviewComponent.vue'
import SelfEmploymentIncomeDetailsReviewComponent from '@/components/review-components/SelfEmploymentIncomeDetailsReviewComponent.vue'
import PropertyIncomeDetailsReviewComponent from '@/components/review-components/PropertyIncomeDetailsReviewComponent.vue'
import PensionIncomeDetailsReviewComponent from '@/components/review-components/PensionIncomeDetailsReviewComponent.vue'
import DividendIncomeDetailsReviewComponent from '@/components/review-components/DividendIncomeDetailsReviewComponent.vue'
import InterestIncomeDetailsReviewComponent from '@/components/review-components/InterestIncomeDetailsReviewComponent.vue'
import CapitalGainsLossesDetailsReviewComponent from '@/components/review-components/CapitalGainsLossesDetailsReviewComponent.vue'
import ForeignIncomeDetailsReviewComponent from '@/components/review-components/ForeignIncomeDetailsReviewComponent.vue'
import ChildBenefitDetailsReviewComponent from '@/components/review-components/ChildBenefitDetailsReviewComponent.vue'
import OtherIncomeDetailsReviewComponent from '@/components/review-components/OtherIncomeDetailsReviewComponent.vue'
import OtherTaxReliefDetailsReviewComponent from '@/components/review-components/OtherTaxReliefDetailsReviewComponent.vue'
import AdditionalNotesDetailsReviewComponent from '@/components/review-components/AdditionalNotesDetailsReviewComponent.vue'
import GiftsDetailsReviewComponent from '@/components/review-components/GiftsDetailsReviewComponent.vue'
import ResidenceRemittanceDetailsReviewComponent from '@/components/review-components/ResidenceRemittanceDetailsReviewComponent.vue'
import ScottishTaxpayerDetailsReviewComponent from '@/components/review-components/ScottishTaxpayerDetailsReviewComponent.vue'
import TaxAvoidanceDetailsReviewComponent from '@/components/review-components/TaxAvoidanceDetailsReviewComponent.vue'
import DisguisedRemunerationAvoidanceDetailsReviewComponent from '@/components/review-components/DisguisedRemunerationAvoidanceDetailsReviewComponent.vue'
import TaxLiabilityDetailsReviewComponent from '@/components/review-components/TaxLiabilityDetailsReviewComponent.vue'
import JcpRefundsDetailsReviewComponent from '@/components/review-components/JcpRefundsDetailsReviewComponent.vue'
import StudentLoansDetailsReviewComponent from '@/components/review-components/StudentLoansDetailsReviewComponent.vue'
import MarriageAllowanceTransferDetailsReviewComponent from '@/components/review-components/MarriageAllowanceTransferDetailsReviewComponent.vue'
import MarriageAllowanceReceiptDetailsReviewComponent from '@/components/review-components/MarriageAllowanceReceiptDetailsReviewComponent.vue'
import PensionAnnuityContributionsDetailsReviewComponent from '@/components/review-components/PensionAnnuityContributionsDetailsReviewComponent.vue'
import GiftAidDonationsDetailsReviewComponent from '@/components/review-components/GiftAidDonationsDetailsReviewComponent.vue'
import NothingToDownloadModalComponent from '@/components/modal-components/NothingToDownloadModalComponent.vue'

export default {
    name: 'ViewSubmission', 

    components: {
        BasicDetailsReviewComponent,
        EmploymentIncomeDetailsReviewComponent,
        EtoroDetailsReviewComponent,
        SelfEmploymentIncomeDetailsReviewComponent,
        PropertyIncomeDetailsReviewComponent,
        PensionIncomeDetailsReviewComponent,
        DividendIncomeDetailsReviewComponent,
        InterestIncomeDetailsReviewComponent,
        CapitalGainsLossesDetailsReviewComponent,
        ForeignIncomeDetailsReviewComponent,
        ChildBenefitDetailsReviewComponent,
        OtherIncomeDetailsReviewComponent,
        OtherTaxReliefDetailsReviewComponent,
        AdditionalNotesDetailsReviewComponent,
        GiftsDetailsReviewComponent,
        ResidenceRemittanceDetailsReviewComponent,
        ScottishTaxpayerDetailsReviewComponent,
        TaxAvoidanceDetailsReviewComponent,
        DisguisedRemunerationAvoidanceDetailsReviewComponent,
        TaxLiabilityDetailsReviewComponent,
        JcpRefundsDetailsReviewComponent,
        StudentLoansDetailsReviewComponent,
        MarriageAllowanceTransferDetailsReviewComponent,
        MarriageAllowanceReceiptDetailsReviewComponent,
        PensionAnnuityContributionsDetailsReviewComponent,
        GiftAidDonationsDetailsReviewComponent
    },
    
    data() {
        return {
            loading: true,
            showHasEmploymentIncome: false,
            showHasEtoroDetails: false,
            showIsSelfEmployed: false,
            showHasPropertyIncome: false,
            showHasPensionIncome: false,
            showHasDividendIncome: false,
            showHasCapitalGainsAndLosses: false,
            showHasForeignIncome: false,
            showHasHigherIncomeChildBenefitCharge: false,
            showHasAnyOtherIncome: false,
            showHasOtherTaxReliefs: false,
            documents: String,
            NothingToDownloadModalComponent
        }
    },

    mounted() {
        if(!this.taxYears.length) {
            this.getTaxYears()
        }

        Promise.all([
            this.getSubmission(this.$route.params.id)
        ]).then(() => {
            this.getSubmissionAnswers(),
            this.loading = false
        })
    }, 

    computed: {
        ...mapGetters({
            taxYears: 'taxYearStore/getTaxYears',
            submissionTaxYearId: 'submissionStore/getSelectedTaxYearId',
            submissionId: 'submissionStore/getId',
            firstRoundAnswers: 'submissionStore/getFirstRoundAnswers',
        })
    },

    methods: {
        ...mapActions({
            getTaxYears: 'taxYearStore/getTaxYears',
            getSubmission: 'submissionStore/getSubmission',
            getBasicDetails: 'submissionStore/getSubmissionBasicDetails',
            getEmploymentIncomeDetails: 'submissionStore/getSubmissionEmploymentIncomeDetails',
            getEtoroDetails: 'submissionStore/getSubmissionEtoroDetails',
            getSelfEmploymentIncomeDetails: 'submissionStore/getSubmissionSelfEmploymentIncomeDetails',
            getPropertyIncomeDetails: 'submissionStore/getSubmissionPropertyIncomeDetails',
            getPensionIncomeDetails: 'submissionStore/getSubmissionPensionIncomeDetails',
            getDividendIncomeDetails: 'submissionStore/getSubmissionDividendIncomeDetails',
            getCapitalGainsLossesDetails: 'submissionStore/getSubmissionCapitalGainsLossesDetails',
            getForeignIncomeDetails: 'submissionStore/getSubmissionForeignIncomeDetails',
            getChildBenefitDetails: 'submissionStore/getSubmissionChildBenefitDetails',
            getOtherIncomeDetails: 'submissionStore/getSubmissionOtherIncomeDetails',
            getOtherTaxReliefDetails: 'submissionStore/getSubmissionOtherTaxReliefDetails',
            getAdditionalNotesDetails: 'submissionStore/getSubmissionAdditionalNotesDetails',
            getGiftsDetails: 'submissionStore/getSubmissionGiftsDetails',
            getResidenceRemittanceDetails: 'submissionStore/getSubmissionResidenceRemittanceDetails',
            getScottishTaxpayerDetails: 'submissionStore/getSubmissionScottishTaxpayerDetails',
            getTaxAvoidanceDetails: 'submissionStore/getSubmissionTaxAvoidanceDetails',
            getDisguisedRemunerationAvoidanceDetails: 'submissionStore/getSubmissionDisguisedRemunerationAvoidanceDetails',
            getTaxLiabilityDetails: 'submissionStore/getSubmissionTaxLiabilityDetails',
            getJcpRefundsDetails: 'submissionStore/getSubmissionJcpRefundsDetails',
            getStudentLoansDetails: 'submissionStore/getSubmissionStudentLoansDetails',
            getMarriageAllowanceTransferDetails: 'submissionStore/getSubmissionMarriageAllowanceTransferDetails',
            getMarriageAllowanceReceiptDetails: 'submissionStore/getSubmissionMarriageAllowanceReceiptDetails',
            getPensionAnnuityContributionsDetails: 'submissionStore/getSubmissionPensionAnnuityContributionsDetails',
            getGiftAidDonationsDetails: 'submissionStore/getSubmissionGiftAidDonationsDetails',
        }),

        async getSubmissionAnswers() {
            let promises = []

            promises.push(await this.getBasicDetails(this.$route.params.id))
            promises.push(await this.getAdditionalNotesDetails(this.$route.params.id))
            promises.push(await this.getGiftsDetails(this.$route.params.id))
            promises.push(await this.getResidenceRemittanceDetails(this.$route.params.id))
            promises.push(await this.getScottishTaxpayerDetails(this.$route.params.id))
            promises.push(await this.getTaxAvoidanceDetails(this.$route.params.id))
            promises.push(await this.getDisguisedRemunerationAvoidanceDetails(this.$route.params.id))
            promises.push(await this.getTaxLiabilityDetails(this.$route.params.id))
            promises.push(await this.getJcpRefundsDetails(this.$route.params.id))
            promises.push(await this.getStudentLoansDetails(this.$route.params.id))
            promises.push(await this.getMarriageAllowanceTransferDetails(this.$route.params.id))
            promises.push(await this.getMarriageAllowanceReceiptDetails(this.$route.params.id))
            promises.push(await this.getPensionAnnuityContributionsDetails(this.$route.params.id))
            promises.push(await this.getGiftAidDonationsDetails(this.$route.params.id))

            if (this.firstRoundAnswers.hasEmploymentIncome) {
                this.showHasEmploymentIncome = true
                promises.push(await this.getEmploymentIncomeDetails(this.$route.params.id))
            }

            if (this.firstRoundAnswers.hasEtoro) {
                this.showHasEtoroDetails = true
                promises.push(await this.getEtoroDetails(this.submissionId))
            }

            if (this.firstRoundAnswers.isSelfEmployed) {
                this.showIsSelfEmployed = true
                promises.push(await this.getSelfEmploymentIncomeDetails(this.$route.params.id))
            }

            if (this.firstRoundAnswers.hasPropertyIncome) {
                this.showHasPropertyIncome = true
                promises.push(await this.getPropertyIncomeDetails(this.$route.params.id))
            }

            if (this.firstRoundAnswers.hasPensionIncome) {
                this.showHasPensionIncome = true
                promises.push(await this.getPensionIncomeDetails(this.$route.params.id))
            }
            
            if (this.firstRoundAnswers.hasDividendIncome) {
                this.showHasDividendIncome = true
                promises.push(await this.getDividendIncomeDetails(this.$route.params.id))
            }

            if (this.firstRoundAnswers.hasCapitalGainsAndLosses) {
                this.showHasCapitalGainsAndLosses = true
                promises.push(await this.getCapitalGainsLossesDetails(this.$route.params.id))
            }

            if (this.firstRoundAnswers.hasForeignIncome) {
                this.showHasForeignIncome = true
                promises.push(await this.getForeignIncomeDetails(this.$route.params.id))
            }

            if (this.firstRoundAnswers.hasHigherIncomeChildBenefitCharge) {
                this.showHasHigherIncomeChildBenefitCharge = true
                promises.push(this.getChildBenefitDetails(this.$route.params.id))
            }

            if (this.firstRoundAnswers.hasAnyOtherIncome) {
                this.showHasAnyOtherIncome = true
                promises.push(await this.getOtherIncomeDetails(this.$route.params.id))
            }
            
            if (this.firstRoundAnswers.hasOtherTaxReliefs) {
                this.showHasOtherTaxReliefs = true
                promises.push(await this.getOtherTaxReliefDetails(this.$route.params.id))
            }

            Promise.all(promises)
        },  

        formatTaxYear() {
            let label = ''

            this.taxYears.filter(taxYear => {
                if(taxYear.id == this.submissionTaxYearId) label = taxYear.label
            })

            return label
        },

        downloadDocuments() {
            let config = {
                url: `submissions/${this.submissionId}/documents/zip`,
                method: 'GET',
                responseType: 'blob'
            }

            axios(config).then(response => {
                var blob = new Blob([response.data], { type: 'application/zip' })

                if(blob.size == 0) return this.$modal.show(NothingToDownloadModalComponent)

                var FileSaver = require('file-saver')
                FileSaver.saveAs(blob, "submissions.zip")
            })
        },

        printPage() {
            window.print()
        }
    }
}
</script>