<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <template>
                    <div class="tab-border">
                        <div class="p-4">
                            <h4>
                                Did you receive any dividends? <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.Dividends.Title, Helpers.Dividends.Body)" />					
                            </h4>

                            <p class="mb-0">
                                For example, from UK companies, authorised unit trusts, open ended investment companies or foreign companies?
                            </p>
                        </div>

                        <dividend-income-item-component 
                            v-for="(item,index) in dividendIncomeDetails"
                            :key="index"
                            :item="item"
                            :index="index"
                        />
                    </div>

                    <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <div class="media">
                            <img src="@/assets/icons/questions/dividend-interest.svg" width="60" height="60" class="mr-3" alt="add employment">
                            <div class="media-body">
                                <h5 class="mt-0">Add another dividend item</h5>
                                Would you like to add details of another dividend?
                            </div>
                        </div>

                        <b-button variant="primary" class="mt-4 mt-md-0"
                            @click="createDefaultDividendItem()"
                        >
                            Add dividend item
                        </b-button>     
                    </div>
                </template>

                <template>
                    <div class="tab-border mt-5">
                        <div class="p-4">
                            <h4>
                                Did you receive any interest? <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.ReceivedAnyInterest.Title, Helpers.ReceivedAnyInterest.Body)" />					
                            </h4>

                            <p class="mb-0">
                                For example, from UK banks, UK building societies, UK unit trusts or Peer to Peer lending?
                            </p>
                        </div>

                        <interest-income-item-component 
                            v-for="(item,index) in interestsIncomeDetails"
                            :key="index"
                            :item="item"
                            :index="index"
                        />
                    </div>

                    <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <div class="media">
                            <img src="@/assets/icons/questions/dividend-interest.svg" width="60" height="60" class="mr-3" alt="add employment">
                            <div class="media-body">
                                <h5 class="mt-0">Add another interest item</h5>
                                Would you like to add details of another interest?
                            </div>
                        </div>

                        <b-button variant="primary" class="mt-4 mt-md-0"
                            @click="createDefaultInterestItem()"
                        >
                            Add interest item
                        </b-button>     
                    </div>

                </template>
            
                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import DividendIncomeItemComponent from '@/components/partials/DividendIncomeItemComponent.vue'
import InterestIncomeItemComponent from '@/components/partials/InterestIncomeItemComponent.vue'
import Notifications from '@/Notifications'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'
import DividendsOrInterestsErrorModalComponent from '@/components/modal-components/DividendsOrInterestsErrorModalComponent.vue'

export default {
    name: 'DividendIncomeComponent',

    components: {
        DividendIncomeItemComponent,
        InterestIncomeItemComponent,
        SaveAndContinueComponent
    },

    data() {
        return {
            formErrors: {},
            saving: false,
            HelperModalComponent,
            Helpers,
            ValidationErrorModalComponent,
            DividendsOrInterestsErrorModalComponent
        }
    },

    mounted() {
        if(this.dividendIncomeDetails.length) this.markSectionAsComplete()
        if(!this.dividendIncomeDetails.length) this.createDefaultDividendItem() 
        if(!this.interestsIncomeDetails.length) this.createDefaultInterestItem() 
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            dividendIncomeDetails: 'submissionStore/getDividendIncomeDetails',
            interestsIncomeDetails: 'submissionStore/getInterestsIncomeDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getDividendIncomeDetails: 'submissionStore/getSubmissionDividendIncomeDetails',
            setNotification: 'notificationsStore/setNotification',
            setDividendIncome: 'sectionsCompleteStore/setDividendIncome'
        }), 

        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setDividendIncome(true)
        },

        createDefaultDividendItem() {
            let newDividendItem = {
                provider: null,
                receivedAmount: null,
                dateReceived: null
            }

            this.dividendIncomeDetails.push(newDividendItem)

            setTimeout(() => {
                this.$scrollTo('#dindex'+(this.dividendIncomeDetails.length - 1), 800)
            })
        },

        createDefaultInterestItem() {
            let newIterestItem = {
                provider: null,
                receivedAmount: null,
                dateReceived: null, 
                isAmountGross: false,
            }

            this.interestsIncomeDetails.push(newIterestItem)

            setTimeout(() => {
                this.$scrollTo('#intindex'+(this.interestsIncomeDetails.length - 1), 800)
            })
        },

        async submit() {     
            if(!this.dividendIncomeDetails.length && !this.interestsIncomeDetails.length) {
                return this.$modal.show(DividendsOrInterestsErrorModalComponent)
            }

            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        save() {
            this.saving = true

            let dividendsAndInterestsIncomeModel = {
                submissionId: this.submissionId,
                dividends: JSON.parse(JSON.stringify(this.dividendIncomeDetails)),
                interests: JSON.parse(JSON.stringify(this.interestsIncomeDetails))
            }

            dividendsAndInterestsIncomeModel.dividends.forEach(d => {
                d.receivedAmount = parseInt(d.receivedAmount * 100)
            })

            dividendsAndInterestsIncomeModel.interests.forEach(d => {
                d.receivedAmount = parseInt(d.receivedAmount * 100)
            })

            axios.post(`/submission/${this.submissionId}/dividends-interests-details`, dividendsAndInterestsIncomeModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.markSectionAsComplete()
                this.$emit('next')
            }).finally(() => this.saving = false)
        }
    }
}
</script>