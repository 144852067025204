export default {
    namespaced: true,
    
    state: {
        prices: {
            110: 'price_1IT7D3AOTmDyJOmW1sn4863j',
            135: 'price_1IT7D3AOTmDyJOmWSPJPvhTm',
            160: 'price_1IT7D3AOTmDyJOmWmG70vsmx',
            185: 'price_1IT7D3AOTmDyJOmW9tGpZoVb',
            210: 'price_1IT7D3AOTmDyJOmWo4Qb0eUD',
            235: 'price_1IT7D3AOTmDyJOmWS0mZtYXV',
            260: 'price_1IT7D2AOTmDyJOmWxUy7jwYh',
            285: 'price_1IT7D2AOTmDyJOmWvQEOt4fm',
            310: 'price_1IT7D2AOTmDyJOmWhGDUMdTX',
            335: 'price_1IT7D2AOTmDyJOmWmxlEVWyR',
            360: 'price_1IT7D2AOTmDyJOmW2FJMVHMD',
            385: 'price_1IT7D2AOTmDyJOmWKBOAIFRa',
            410: 'price_1IT7D2AOTmDyJOmWBOnKhLkQ',
            435: 'price_1IT7D2AOTmDyJOmWBVvBPQJm',
            460: 'price_1IT7D2AOTmDyJOmW08YRqM5b',
            485: 'price_1IT7D2AOTmDyJOmW5gQcAUls',
            510: 'price_1IT7D2AOTmDyJOmWip2ryplH',
            535: 'price_1IT7D2AOTmDyJOmWryXnMkXX',
            560: 'price_1IT7D2AOTmDyJOmWJFJpc1KX',
            585: 'price_1IT7D2AOTmDyJOmW6Xbb1bM3',
            610: 'price_1IT7D2AOTmDyJOmWMEsBlFOG',
            635: 'price_1IT7D2AOTmDyJOmWUCZOMoJ2',
            660: 'price_1IT7D2AOTmDyJOmWQopMsthT',
            685: 'price_1IT7D2AOTmDyJOmWC3OaDDSE',
            710: 'price_1IT7D2AOTmDyJOmWeRRqdjpY',
            735: 'price_1IT7D2AOTmDyJOmWMa21ok5V',
            760: 'price_1IT7D2AOTmDyJOmWUXioFHgK',
            785: 'price_1IT7D2AOTmDyJOmWc4mDZutY',
            810: 'price_1IT7D2AOTmDyJOmWZxVfjKlL',
            835: 'price_1IT7D2AOTmDyJOmWi6WcHkLA',
            860: 'price_1IT7D2AOTmDyJOmWwLTCiowF',
            885: 'price_1IT7D2AOTmDyJOmWVz4dELx8',
            910: 'price_1IT7D2AOTmDyJOmW6FiQJKo4',
            935: 'price_1IT7D2AOTmDyJOmWM3frcLOk',
            960: 'price_1IT7D2AOTmDyJOmWANaIBvuC',
            985: 'price_1IT7D1AOTmDyJOmWVtXs7LmX',
        }, 

        costings: {
            basePrice: 110,
            selfEmploymentIncomePrice: 25,
            perPropertyPrice: 25,
            capitalGainsAndLossesPrice: 25,
            foreignIncomePrice: 25,
            anyOtherIncomePrice: 25,
            otherTaxReliefsPrice: 25
        }
    }, 

    getters: {
        getPrices(state) {
            return state.prices
        },

        getCostings(state) {
            return state.costings
        }
    }
}


