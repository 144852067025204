<template>
    <b-tab
        ref="tab"
        :title-link-class="{completed : isThisSectionComplete}"
    >   
        <template #title>
            <div>
                <img :src="getTabImage(answer)" width="30" height="30" /> 
                <span class="mb-0 ml-2 mt-2">{{ getTabTitle(answer) }}</span>
            </div>

            <div>
                <img v-if="isThisSectionComplete" src="@/assets/icons/green-tick.svg" width="25" height="25" />
                <img v-else src="@/assets/icons/warning-icon.svg" width="25" height="25" />
            </div>
        </template>

        <div class="d-block d-xl-none bg-secondary navigator">
            <div class="d-flex justify-content-between mb-3">
                <b-button variant="secondary"
                    @click.prevent="moveToPrevTab()"
                >
                    <svg width="25" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 30.6 20" style="enable-background:new 0 0 30.6 20;" xml:space="preserve">
                    <path id="arrow" style="fill: #fff" class="st0" d="M10.7,19.2v-5.6h18.9c0,0,0,0,0,0c0.5,0,1-0.4,1-1l0-5.3c0,0,0,0,0,0c0-0.5-0.4-1-1-1l-18.9,0V0.8
                        c0-0.7-0.8-1-1.3-0.5L0.2,9.5c-0.3,0.3-0.3,0.8,0,1.1l9.2,9.2C9.9,20.2,10.7,19.9,10.7,19.2z"/>
                    </svg>
                </b-button>

                <b-button variant="primary">
                    {{ getTabTitle(answer) }} <img v-if="isThisSectionComplete" src="@/assets/icons/green-tick.svg" width="25" height="25" />
                </b-button>

                <b-button variant="secondary"
                    @click.prevent="moveToNextTab()"
                >
                    <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.6 20">
                        <path d="M21.2,19.8l9.2-9.3a.7.7,0,0,0,0-1L21.2.2a.8.8,0,0,0-1.3.6V6.4H1a1.1,1.1,0,0,0-1,1H0v5.3a1,1,0,0,0,1,1H19.9v5.5A.8.8,0,0,0,21.2,19.8Z" transform="translate(0 0)" style="fill:#fff"/>
                    </svg>
                </b-button>
            </div>
        </div>
        
        <component 
            :section="answer"
            :is="getTabComponent(answer)"
            @next="moveToNextTab"
            @back="moveToPrevTab"
            @sectionComplete="isThisSectionComplete = true"
        />
    </b-tab>
</template>

<script>
import {mapGetters} from 'vuex'
import EmploymentIncomeComponent from '@/components/round-two-question-components/EmploymentIncomeComponent.vue'
import EtoroDetailsComponent from '@/components/round-two-question-components/EtoroDetailsComponent.vue'
import SelfEmploymentIncomeComponent from '@/components/round-two-question-components/SelfEmploymentIncomeComponent.vue'
import PropertyIncomeComponent from '@/components/round-two-question-components/PropertyIncomeComponent.vue'
import PensionIncomeComponent from '@/components/round-two-question-components/PensionIncomeComponent.vue'
import DividendIncomeComponent from '@/components/round-two-question-components/DividendIncomeComponent.vue'
import CapitalGainsLossesComponent from '@/components/round-two-question-components/CapitalGainsLossesComponent.vue'
import ForeignIncomeComponent from '@/components/round-two-question-components/ForeignIncomeComponent.vue'
import ChildBenefitComponent from '@/components/round-two-question-components/ChildBenefitComponent.vue'
import OtherIncomeComponent from '@/components/round-two-question-components/OtherIncomeComponent.vue'
import OtherTaxReliefsComponent from '@/components/round-two-question-components/OtherTaxReliefsComponent.vue'

import EmploymentImage from '@/assets/icons/questions/employment.svg'
import EtoroImage from '@/assets/icons/questions/list-icon-etoro.svg'
import SelfEmploymentImage from '@/assets/icons/questions/self-employed.svg'
import CapitalGainsLossesImage from '@/assets/icons/questions/capital-gains-losses.svg'
import ChildBenefitImage from '@/assets/icons/questions/child-benefit-charge.svg'
import DividendInterestImage from '@/assets/icons/questions/dividend-interest.svg'
import ForeignIncomeImage from '@/assets/icons/questions/foreign.svg'
import OtherTaxReliefsImage from '@/assets/icons/questions/other-tax-reliefs.svg'
import OtherUkIncomeImage from '@/assets/icons/questions/pricing.svg'
import PensionImage from '@/assets/icons/questions/pension.svg'
import PropertyImage from '@/assets/icons/questions/property.svg'

export default {
    name: 'TabSectionComponent',

    props: {
        answer: {},
        index: Number,
        tabIndex: Number,
    },

    data() {
        return {
            isThisSectionComplete: false
        }
    },
 
    components: {
        EmploymentIncomeComponent,
        EtoroDetailsComponent,
        SelfEmploymentIncomeComponent,
        PropertyIncomeComponent,
        PensionIncomeComponent,
        DividendIncomeComponent,
        CapitalGainsLossesComponent,
        ForeignIncomeComponent,
        ChildBenefitComponent,
        OtherIncomeComponent,
        OtherTaxReliefsComponent,
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
        })
    },

    methods: {
        getTabTitle(name) {
            var tabLabel = String

            switch(name) {
                case 'hasEmploymentIncome' :
                    tabLabel = 'Employment Income';
                break;

                case 'hasEtoro' :
                    tabLabel = 'Etoro Details';
                break;

                case 'isSelfEmployed' :
                    tabLabel = 'Self Employment Income';
                break;

                case 'hasPropertyIncome' :
                    tabLabel = 'Property Income';
                break;

                case 'hasPensionIncome' :
                    tabLabel = 'Pension Income';
                break;

                case 'hasDividendIncome' :
                    tabLabel = 'Dividend & Interest Income';
                break;

                case 'hasCapitalGainsAndLosses' :
                    tabLabel = 'Capital Gains & Losses';
                break;

                case 'hasForeignIncome' :
                    tabLabel = 'Foreign Income';
                break;

                case 'hasHigherIncomeChildBenefitCharge' :
                    tabLabel = 'Child Benefit';
                break;

                case 'hasAnyOtherIncome' :
                    tabLabel = 'Other Income';
                break;

                case 'hasOtherTaxReliefs' :
                    tabLabel = 'Other Tax Reliefs';
                break;
            }

            return tabLabel
        },

        getTabImage(name) {
            var path;

            switch(name) {
                case 'hasEmploymentIncome' :
                    path = EmploymentImage
                break;

                case 'hasEtoro' :
                    path = EtoroImage
                break;

                case 'isSelfEmployed' :
                    path = SelfEmploymentImage
                break;

                case 'hasPropertyIncome' :
                    path = PropertyImage
                break;

                case 'hasPensionIncome' :
                    path = PensionImage
                break;

                case 'hasDividendIncome' :
                    path = DividendInterestImage
                break;

                case 'hasCapitalGainsAndLosses' :
                    path = CapitalGainsLossesImage
                break;

                case 'hasForeignIncome' :
                    path = ForeignIncomeImage
                break;

                case 'hasHigherIncomeChildBenefitCharge' :
                    path = ChildBenefitImage
                break;

                case 'hasAnyOtherIncome' :
                    path = OtherUkIncomeImage
                break;

                case 'hasOtherTaxReliefs' :
                    path = OtherTaxReliefsImage
                break;
            }

            return path
        },

        getTabComponent(name) {
            var tabComponent = String

            switch(name) {
                case 'hasEmploymentIncome' :
                    tabComponent = 'EmploymentIncomeComponent';
                break;

                case 'hasEtoro' :
                    tabComponent = 'EtoroDetailsComponent';
                break;

                case 'isSelfEmployed' :
                    tabComponent = 'SelfEmploymentIncomeComponent';
                break;

                case 'hasPropertyIncome' :
                    tabComponent = 'PropertyIncomeComponent';
                break;

                case 'hasPensionIncome' :
                    tabComponent = 'PensionIncomeComponent';
                break;

                case 'hasDividendIncome' :
                    tabComponent = 'DividendIncomeComponent';
                break;

                case 'hasCapitalGainsAndLosses' :
                    tabComponent = 'CapitalGainsLossesComponent';
                break;

                case 'hasForeignIncome' :
                    tabComponent = 'ForeignIncomeComponent';
                break;

                case 'hasHigherIncomeChildBenefitCharge' :
                    tabComponent = 'ChildBenefitComponent';
                break;

                case 'hasAnyOtherIncome' :
                    tabComponent = 'OtherIncomeComponent';
                break;

                case 'hasOtherTaxReliefs' :
                    tabComponent = 'OtherTaxReliefsComponent';
                break;
            }

            return tabComponent
        }, 

        moveToNextTab() {
            this.$emit('next')
        },

        moveToPrevTab() {
            this.$emit('back')
        },
    }
}
</script>