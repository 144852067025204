<template>
    <b-card class="shadow">
        <b-row>
            <b-col>
                <h2>
                    Basic Details
                </h2>
            </b-col>
        </b-row>

        <b-row
            v-if="Object.keys(basicDetails).length != 0"
        >
            <b-col md="6"
                v-if="!loading"
            >
                <b-list-group class="list-group-flush">
                    <b-list-group-item class="d-flex flex-column px-0">
                        First name<span class="font-weight-bold color-black">{{ submissionUser.firstName }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0">
                        Surname<span class="font-weight-bold color-black">{{ submissionUser.lastName }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0">
                        Email address<span class="font-weight-bold color-black">{{ submissionUser.email }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="submissionUser.telephone"
                    >
                        Phone Number<span class="font-weight-bold color-black">{{ submissionUser.telephone }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="submissionUser.dateOfBirth"
                    >
                        Date Of Birth<span class="font-weight-bold color-black">{{ submissionUser.dateOfBirth | moment('ll') }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="submissionUser.niNumber"
                    >
                        NI Number<span class="font-weight-bold color-black">{{ submissionUser.niNumber }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="submissionUser.utr"
                    >
                        UTR Number<span class="font-weight-bold color-black">{{ submissionUser.utr }}</span>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
            
            <b-col md="6" class="mt-4 mt-md-0">
                <b-list-group class="list-group-flush">
                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="basicDetails.address.line1"
                    >
                        Address Line 1<span class="font-weight-bold color-black">{{ basicDetails.address.line1 }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="basicDetails.address.line2"
                    >
                        Address Line 2<span class="font-weight-bold color-black">{{ basicDetails.address.line2 }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="basicDetails.address.line3"
                    >
                        Address Line 3<span class="font-weight-bold color-black">{{ basicDetails.address.line3 }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="basicDetails.address.town"
                    >
                        Town<span class="font-weight-bold color-black">{{ basicDetails.address.town }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="basicDetails.address.city"
                    >
                        City<span class="font-weight-bold color-black">{{ basicDetails.address.city }}</span>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex flex-column px-0"
                        v-if="basicDetails.address.postcode"
                    >
                        Postcode<span class="font-weight-bold color-black">{{ basicDetails.address.postcode }}</span>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
export default {
    name: 'BasicDetailsReviewComponent',

    data() {
        return {
            loading: true,
            submissionUser: {}
        }
    },

    mounted() {
        this.getSubmissionUser()
    },

    computed: {
        ...mapGetters({
            userId: 'submissionStore/getUserId',
            basicDetails: 'submissionStore/getBasicDetails',
        })
    }, 

    methods: {
        async getSubmissionUser() {
            axios.get(`/users/${this.userId}`).then((response) => {
                this.submissionUser = response.data
                this.loading = false
            })
        }
    }
}
</script>