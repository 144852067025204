<template>
    <div :id="'intindex'+index" class="bg-grey p-4">
        <b-row>
            <b-col md="1" class="text-center">
                <div class="number mx-auto">
                    {{ index + 1 }}
                </div>

                <div class="remove mt-3 cursor-pointer"
                    @click.prevent="removeItem(index)"
                >
                    <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                </div>
            </b-col>

            <b-col md="11">
                <b-row>
                    <b-col md="6">
                        <ValidationProvider :vid="'Interest Provider '+(index+1)" name="Provider" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Provider"
                                label-for="provider"
                            >
                                <b-form-input
                                    name="provider"
                                    v-model="item.provider"
                                    type="text"
                                    placeholder="Provider"
                                    :class="{'is-valid' : item.provider, 'is-invalid' : errors.length}"
                                >
                                </b-form-input>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider :vid="'Interest Received Amount '+(index+1)" name="Received Amount" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group
                                label="Received Amount"
                                label-for="receivedAmount"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="receivedAmount"
                                        v-model="item.receivedAmount"
                                        placeholder="Received amount"
                                        :class="{'is-valid' : item.receivedAmount, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('receivedAmount')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <div class="d-flex flex-column flex-md-row align-items-center justify-content-between mt-5">
                    <h4 class="mb-0">
                        Is amount gross?
                    </h4>

                    <div class="mt-4 mt-md-0">
                        <b-button class="btn-bool mr-1"
                            @click="item.isAmountGross = !item.isAmountGross"
                            :class="{active: item.isAmountGross}"
                        >
                            Yes
                        </b-button>

                        <b-button class="btn-bool"
                            @click="item.isAmountGross = !item.isAmountGross"
                            :class="{negativeActive: !item.isAmountGross}"
                        >
                            No
                        </b-button>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'InterestIncomeItemComponent',

    props: {
        item: {},
        index: Number
    },

    mounted() {
        this.item.receivedAmount = (this.item.receivedAmount / 100).toFixed(2)
    },

    computed: {
        ...mapGetters({
            interestsIncomeDetails: 'submissionStore/getInterestsIncomeDetails',
        })
    },

    methods: {
        removeItem(index) {
            this.interestsIncomeDetails.splice(index, 1)

            setTimeout(() => {
                this.$scrollTo('#intindex'+(this.interestsIncomeDetails.length - 1), 800)
            }, 500)
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        }
    }
}
</script>