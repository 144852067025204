<template>
    <div :id="'index'+index">
        <hr class="mt-5"
            v-if="index !== 0"
        >

        <b-row
            :class="{'mt-5' : index !== 0}"
        >
            <b-col md="1" class="text-center">
                <div class="number mx-auto">
                    {{ index + 1 }}
                </div>

                <div class="remove mt-3 cursor-pointer"
                    v-if="index > 0"
                    @click.prevent="removeItem(index)"
                >
                    <img src="@/assets/icons/delete.svg" alt="delete item" width="40" height="40" />
                </div>
            </b-col>

            <b-col md="11" class="mt-4 mt-md-0">
                <b-row>
                    <b-col md="6">
                        <ValidationProvider ref="employerName" :vid="'Employer Name '+(index+1)" name="Employers Name" rules="required" v-slot="{ errors }">
                            <b-form-group
                                label="Employer name"
                                label-for="employerName"
                                label-class="required"
                            >
                                <b-form-input
                                    name="employerName"
                                    v-model="item.employerName"
                                    type="text"
                                    placeholder="Employer name"
                                    :class="{'is-valid' : item.employerName,'is-invalid' : errors.length}"
                                >
                                </b-form-input>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider :vid="'PAYE Reference '+(index+1)" name="Paye Reference" rules="required" v-slot="{ errors }">
                            <b-form-group>
                                <label for="payeReference" class="d-block required">PAYE reference</label>
                                <img class="helper-image" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.PayeReference.Title, Helpers.PayeReference.Body)" />
                                
                                <b-form-input
                                    name="payeReference"
                                    v-model="item.payeReference"
                                    type="text"
                                    placeholder="PAYE reference"
                                    :class="{'is-valid' : item.payeReference, 'is-invalid' : errors.length}"
                                >
                                </b-form-input>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <b-row class="mt-3">
                    <b-col md="6">
                        <ValidationProvider :vid="'Amount Received '+(index+1)" name="Amount Received" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group
                                label="Amount received"
                                label-for="receivedAmount"
                                label-class="required"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="receivedAmount"
                                        v-model="item.receivedAmount"
                                        placeholder="Amount received"
                                        :class="{'is-valid' : item.receivedAmount, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('receivedAmount')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="6">
                        <ValidationProvider :vid="'Tax Deducted '+(index+1)" name="Tax Deducted" rules="required|min_value:0|double:2" v-slot="{ errors }">
                            <b-form-group
                                label="Tax deducted"
                                label-for="taxDeducted"
                                label-class="required"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="taxDeducted"
                                        v-model="item.taxDeducted"
                                        placeholder="Tax deducted"
                                        :class="{'is-valid' : item.taxDeducted, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('taxDeducted')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <hr class="my-5"/>

                <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <h4 class="mb-0">
                        Do you have a P11D for a benefit in kind? <img class="helper-image-inline" src="@/assets/icons/question-icon.svg" width="20" height="20" @click="openHelperModal(Helpers.P11DBenefitInKind.Title, Helpers.P11DBenefitInKind.Body)" />
                    </h4>

                    <div class="mt-4 mt-md-0 flex-shrink-0 ml-4">
                        <b-button class="btn-bool mr-1"
                            @click="item.hasBenefitInKind = !item.hasBenefitInKind"
                            :class="{active: item.hasBenefitInKind}"
                        >
                            Yes
                        </b-button>

                        <b-button class="btn-bool"
                            @click="item.hasBenefitInKind = !item.hasBenefitInKind"
                            :class="{negativeActive: !item.hasBenefitInKind}"
                        >
                            No
                        </b-button>
                    </div>
                </div>

                <b-row class="mt-3"
                    v-if="item.hasBenefitInKind"
                >
                    <b-col md="4">
                        <ValidationProvider :vid="'Benefit in kind type '+(index+1)" name="Benefit in kind type" :rules="`${item.hasBenefitInKind ? 'required' : ''}`" v-slot="{ errors }">
                            <b-form-group
                                label="Benefit in kind type"
                                label-for="benefitInKindType"
                            >
                                <b-form-input
                                    name="Benefit in kind type"
                                    v-model="item.benefitInKindType"
                                    type="text"
                                    placeholder="Benefit in kind type"
                                    :class="{'is-valid' : item.benefitInKindType, 'is-invalid' : errors.length}"
                                >
                                </b-form-input>
                                
                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="4">
                        <ValidationProvider :vid="'P11D '+(index+1)" name="P11d Value" :rules="`${item.hasBenefitInKind ? 'required|min_value:0|double:2' : ''}`" v-slot="{ errors }">
                            <b-form-group
                                label="P11d Value"
                                label-for="p11dValue"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="p11dValue"
                                        v-model="item.p11dValue"
                                        placeholder="p11d value"
                                        :class="{'is-valid' : item.p11dValue, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('p11dValue')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col md="4">
                        <ValidationProvider :vid="'P11D Tax Deducted '+(index+1)" name="Tax deducted" :rules="`${item.hasBenefitInKind ? 'required|min_value:0|double:2' : ''}`" v-slot="{ errors }">
                            <b-form-group
                                label="Tax deducted"
                                label-for="p11dTaxDeducted"
                            >
                                <b-input-group prepend="£">
                                    <b-form-input
                                        name="p11dTaxDeducted"
                                        v-model="item.p11dTaxDeducted"
                                        placeholder="p11d tax deducted"
                                        :class="{'is-valid' : item.p11dTaxDeducted, 'is-invalid' : errors.length}"
                                        @change="checkForRoundNumber('p11dTaxDeducted')"
                                    >
                                    </b-form-input>
                                </b-input-group>

                                <small v-if="errors.length" class="text-danger validation-error d-block">
                                    {{ errors[0] }}
                                </small>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <h5 class="mt-5 mb-3">
                    Please upload your documents
                </h5>

                <div>
                    <p60-item-component 
                        :item="item"
                        componentTitle="Employment"
                    />
                </div>

                <div class="mt-2">
                    <p45-item-component 
                        :item="item"
                    />
                </div>

                <div class="mt-2"
                    v-if="item.hasBenefitInKind"
                >
                    <p11d-item-component 
                        :item="item"
                        :hasBenefitInKind="item.hasBenefitInKind"
                    />
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import P60ItemComponent from '@/components/partials/P60ItemComponent.vue'
import P45ItemComponent from '@/components/partials/P45ItemComponent.vue'
import P11dItemComponent from '@/components/partials/P11dItemComponent.vue'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'
import Helpers from '@/Helpers'

export default {
    name: 'EmploymentIncomeItemComponent',

    components: {
        P60ItemComponent,
        P45ItemComponent,
        P11dItemComponent
    },

    props: {
        item: {},
        index: Number,
        failures: {},
    },

    data() {
        return {
            fieldHasErrors: [],
            HelperModalComponent,
            Helpers
        }
    },

    mounted() {
        this.item.receivedAmount = (this.item.receivedAmount / 100).toFixed(2)
        this.item.taxDeducted = (this.item.taxDeducted / 100).toFixed(2)
        this.item.p11dValue = (this.item.p11dValue / 100).toFixed(2)
        this.item.p11dTaxDeducted = (this.item.p11dTaxDeducted / 100).toFixed(2) 
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId',
            employmentIncomeDetails: 'submissionStore/getEmploymentIncomeDetails',
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'
        }),

        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        },

        removeItem(index) {
            this.employmentIncomeDetails.splice(index, 1)

            setTimeout(() => {
                this.$scrollTo('#index'+(this.employmentIncomeDetails.length - 1), 800)
            }, 500)
        },

        handleP60Response(e) {
            this.item.p60DocumentId = e.documentId
        },

        handleP45Response(e) {
            this.item.p45DocumentId = e.documentId
        },

        handleP11dResponse(e) {
            this.item.p11dDocumentId = e.documentId
        }, 

        deleteP60Document() {
            this.item.p60DocumentId = null
        },

        deleteP45Document() {
            this.item.p45DocumentId = null
        },

        deleteP11dDocument() {
            this.item.p11dDocumentId = null
        },

        checkForRoundNumber(ref) {
            if(Number.isInteger(parseFloat(this.item[ref]))) {
                this.item[ref] = parseFloat(this.item[ref]).toFixed(2)
            }
        }
    }
}
</script>