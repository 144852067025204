import Vue from 'vue'
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver, extend } from 'vee-validate'
import { alpha, alpha_num, required, email, numeric, digits, min_value, max_value, double, confirmed, size, ext, regex, min } from 'vee-validate/dist/rules';
 
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('alpha', {
  ...alpha,
  message: 'This field must only contain letters'
});

extend('alpha_num', {
    ...alpha_num,
    message: 'This field must only contain letters and numbers without spaces'
  });

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('email', {
    ...email,
    message: 'This field must be a valid email'
}); 

extend('numeric', {
    ...numeric,
    message: 'This field can only be numbers'
}); 

extend('digits', {
    ...digits,
    message: 'Please use the correct amount of digits'
}); 

extend('min_value', {
    ...min_value,
    message: 'This field has a minumum value'
}); 

extend('max_value', {
    ...max_value,
    message: 'This field has a maximum value'
}); 

extend('min_plan_type', {
    ...min_value,
    message: 'This field has a minumum value of 1'
}); 

extend('max_plan_type', {
    ...max_value,
    message: 'This field has a maximum value of 2'
}); 

extend('double', {
    ...double,
    message: 'This field has to be in pounds & pence'
});

extend('percent', {
    ...double,
    message: 'This field has to be a decimal'
});

extend('passwordConfirmed', {
    ...confirmed,
    message: 'Passwords need to match'
}); 

extend('size', {
    ...size,
    message: 'This file is too large'
}); 

extend('ext', {
    ...ext,
    message: 'This file type is not allowed'
}); 

extend('regex', {
    ...regex,
    message: 'Please correct format'
});

extend('min_password_length', {
    ...min,
    message: 'Please enter at least 8 characters'
});