import Vue from 'vue'
import Vuex from 'vuex'
import userStore from '@/store/userStore'
import taxYearStore from '@/store/taxYearStore'
import submissionsStore from '@/store/submissionsStore'
import submissionStore from '@/store/submissionStore'
import sectionsCompleteStore from '@/store/sectionsCompleteStore'
import notificationsStore from '@/store/notificationsStore'
import pricingStore from '@/store/pricingStore'
import adminStore from '@/store/adminStore'
import userProgressStore from '@/store/userProgressStore'
import discountOffersStore from '@/store/discountOffersStore'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: { 
        userStore,
        taxYearStore,
        submissionsStore,
        submissionStore,
        sectionsCompleteStore,
        notificationsStore,
        pricingStore,
        adminStore,
        userProgressStore,
        discountOffersStore
    }
})