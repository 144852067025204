<template>
    <div>
        <template
            v-if="loading"
        >
            <b-container>
                <b-row class="justify-content-center mt-4">
                    <b-col>
                        <div class="loader-container">
                            <div class="loader"></div>
                            
                            <p>
                                Please wait..
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </template>

        <template
            v-else 
        >
            <div class="pb-5 pb-md-0">
                <b-container>
                    <b-row>
                        <b-col class="text-center">
                            <h2>
                                Your quote
                            </h2>
                            
                            <p class="lead"> 
                                Hi {{ user.given_name }}, here is our quote for you
                            </p>        
                        </b-col>    
                    </b-row>
            
                    <b-row class="mt-4">
                        <b-col>
                            <b-card class="quote-card shadow">
                                <b-row>
                                    <b-col md="6">
                                        <p class="mb-0">
                                            Name
                                        </p>
            
                                        <p class="text-dark lead mb-0">
                                            {{ userFullName }}
                                        </p>
                                    </b-col>
            
                                    <b-col md="6">
                                        <p class="mb-0">
                                            Email address
                                        </p>
            
                                        <p class="text-dark lead mb-0">
                                            {{ user.email }}
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-card>
            
                            <div class="price-container d-flex flex-column flex-md-row align-items-center justify-content-between bg-primary alt p-4 heading-font">
                                <div>
                                    <template
                                        v-if="discountOfferId == 0"
                                    >
                                        <p class="text-white mb-1">Discount code</p>
                                        <div class="d-flex align-items-center">
                                            <input type="text" class="form-control" placeholder="Code" 
                                                v-model="enteredDiscountCode"
                                            />
                                            <b-button
                                                class="btn btn-discount-code d-flex"
                                                :class="{disabled : discountLoading}"
                                                @click="applyDiscount()"
                                                :disabled="discountLoading"
                                            >
                                                Apply <span v-if="discountLoading" class="btn-loader ml-2"></span>
                                            </b-button>
                                        </div>
                                    </template>

                                    <template
                                        v-if="discountOfferId > null"
                                    >
                                        <div
                                            class="mt-1 lead text-white"
                                        >
                                            <img src="@/assets/icons/green-tick.svg" width="18" height="18" /> Discount applied
                                        </div>
                                    </template>
                                </div>
                            
                                <div class="text-right center-mobile">
                                    <template
                                        v-if="!quote"
                                    >
                                        <div class="loader-container">
                                            <div class="loader"></div>
                                        </div>
                                    </template>
                                    

                                    <template
                                        v-else
                                    >
                                        <div
                                            v-if="discountMessageText != ''"
                                            class="text-white"
                                        >   
                                            {{ discountMessageText }}
                                        </div>

                                        <p class="text-lg text-white font-weight-black mb-0 mt-4 mt-md-0">
                                            Total: &pound;{{ quoteInPounds }}
                                        </p>

                                        <p class="text-sm text-white mb-0">
                                            inc. VAT
                                        </p>
                                    </template>
                                </div>

                                <div class="pattern"></div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container class="my-5">
                    <b-row class="justify-content-center">
                        <b-col md="6">
                            <h2 class="text-center mb-4">Payment</h2>

                            <b-card class="p-4 payment-card shadow">
                                <h4 class="mb-3">Enter your card details</h4>

                                <stripe-element-card
                                    ref="elementRef"
                                    :pk="publishableKey"
                                    @token="tokenCreated"
                                    @error="error"
                                />
                                <b-button 
                                    :disabled="processing" 
                                    variant="success" 
                                    class="mt-3 btn-block" 
                                    :class="{disabled : processing}"
                                    @click="submit"
                                >
                                    Pay now <span v-if="processing" class="btn-loader"></span>
                                </b-button>

                                <img class="d-block img-fluid mx-auto mt-4" src="@/assets/icons/powered-by.svg" width="300" />
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row
                        v-if="showPaymentError"
                        class="mt-5"
                    >
                        <b-col>
                            <div class="payment-failure-alert p-3 d-flex flex-column flex-md-row align-items-center">
                                <div>
                                    <img src="@/assets/icons/payment-failure.svg" width="120" />   
                                </div>

                                <div class="ml-5 mt-4 mt-md-0">
                                    <h4>Sorry, the payment failed</h4>
                                    <p v-id="stripeError != null" class="mb-0">{{ stripeError }}</p>
                                    <p class="mb-0">{{ Notifications.PAYMENT_FAILURE }}</p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>

                <div class="bg-grey py-5">
                    <b-container>
                        <b-row>
                            <b-col>
                                <h4>
                                    What's next?
                                </h4>

                                <ol class="mt-4">
                                    <li>
                                        Make a one off payment against your tax year
                                    </li>

                                    <li>
                                        Get paired with a tax return accountant
                                    </li>

                                    <li>
                                        Your tax return accountant will prepare your Self Assessment and send it to you for approval
                                    </li>
                                </ol>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import axios from 'axios'
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import{mapActions, mapGetters} from 'vuex'
import SubmitPaymentModalComponent from '@/components/modal-components/SubmitPaymentModalComponent.vue'
import CookieMixin from '@/mixins/CookieMixin'
import Notifications from '@/Notifications'

export default {
    name: 'Quote',

    components: {
        StripeElementCard,
    },

    mixins: [
        CookieMixin
    ],

    data() {
        return {
            SubmitPaymentModalComponent,
            Notifications,
            loading: true,
            discountLoading: false,
            publishableKey: process.env.VUE_APP_STRIPE_KEY,
            token: null,
            discountApplied: false,
            clientSecret: '',
            processing: false,
            showPaymentError: false,
            stripeError: null,
            enteredDiscountCode: '',
            discountMessageText: '',
        }
    },

    mounted() {
        if(this.submissionId == null) return this.$router.push({name: 'MyAccount'})

        this.initQuotePage()
    },

    computed: {
        ...mapGetters({
            submissionId: 'submissionStore/getId',
            quote: 'submissionStore/getQuote',
            userFullName: 'userStore/getUserFullName',
            user: 'userStore/getUser',
            basicDetails: 'submissionStore/getBasicDetails',
            discountOffers: 'discountOffersStore/getDiscountOffers',
            discountOfferId: 'submissionStore/getDiscountOfferId',
        }),

        quoteInPounds() {
            if(this.quote > 0) return (this.quote / 100).toFixed(2); return 0;
        }
    },  

    methods: {
        ...mapActions({
            getQuote: 'submissionStore/getQuote', 
            payment: 'submissionStore/payment',
            setNotification: 'notificationsStore/setNotification',
            getDiscountOffers: 'discountOffersStore/getDiscountOffers',
            setDiscountCode: 'submissionStore/setDiscountCode'
        }),

        async initQuotePage() {
            this.loading = true

            try {
                await this.getQuote(this.submissionId)
                await this.getPaymentIntent()

                if(this.discountOfferId != null) {
                    await this.getDiscountOfferText()
                }
            }
            catch {
                this.setNotification({
                    type: 'failure',
                    message: Notifications.GENERAL_ERROR
                })
            }
            finally {
                this.loading = false
            }
        },

        async getDiscountOfferText() {
            if(!this.discountOffers.length) {
                await this.getDiscountOffers()

                let offer = this.discountOffers.filter(offer => offer.id == this.discountOfferId)

                if(offer && offer.length) {
                    this.discountMessageText = `'${offer[0].code}' discount applied: -£${(offer[0].amount / 100).toFixed(2)}`
                }
            } else {
                let offer = this.discountOffers.filter(offer => offer.id == this.discountOfferId)

                if(offer && offer.length) {
                    this.discountMessageText = `'${offer[0].code}' discount applied: -£${(offer[0].amount / 100).toFixed(2)}`
                }
            }
        },

        async applyDiscount() {
            if(this.enteredDiscountCode != '') {
                this.discountLoading = true

                try {
                    await this.getDiscountOffers()

                    let offer = this.discountOffers.filter(offer => offer.code == this.enteredDiscountCode)

                    if(offer && offer != null) {
                        await this.setDiscountCode({
                            submissionId: this.submissionId,
                            discountOfferId: offer[0].id
                        })
                    }

                    this.setNotification({
                        type: 'success',
                        message: Notifications.DISCOUNT_OFFER_SAVED
                    })
                }
                catch {
                    this.setNotification({
                        type: 'failure',
                        message: Notifications.DISCOUNT_OFFER_NOT_SAVED
                    })
                }
                finally {
                    await this.initQuotePage()
                    this.discountLoading = false
                }
            }
        },

        async getPaymentIntent() {
            try {
                let response = await axios.post(`submission/${this.submissionId}/payment-intent`)

                this.clientSecret = response.data.paymentIntentId
            }
            catch(err) {
                throw new Error(err.message)
            }
        },

        submit() {
            this.processing = true 
            this.showPaymentError = false
            this.stripeError = null
            this.$refs.elementRef.submit();
        },

        tokenCreated (token) {
            this.token = token

            this.confirmCardPayment(this.clientSecret)
        },

        error() {
            this.processing = false 
        },
        
        confirmCardPayment(clientSecret) {
            const stripe = this.$refs.elementRef.stripe
            const card = this.$refs.elementRef.element

            if (clientSecret && stripe && card) {
                stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: card,
                        billing_details: {
                            name: this.userFullName,
                            email: this.user.email,
                            address: {
                                line1: this.basicDetails.address.line1,
                                postal_code: this.basicDetails.address.postcode
                            }
                        }
                    }
                }).then(r => {
                    if (r.error) {
                        // show error
                        this.showPaymentError = true
                        
                        this.processing = false

                        this.stripeError = r.error.message
                    } else {
                        if (r.paymentIntent.status === "succeeded") {
                            this.processing = false
                            this.$router.push({name: 'PaymentSuccess', params: { id: this.submissionId }})
                        }
                    }
                })
            }
        }
    }
}
</script>