var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"5"}},[_c('h2',{staticClass:"text-center mb-4 mb-lg-0"},[_vm._v(" Create Staff User ")]),(_vm.createUserSuccess)?_c('b-alert',{staticClass:"mt-5",attrs:{"variant":"success","show":"","dismissible":""}},[_vm._v(" "+_vm._s(_vm.Notifications.CREATE_USER_SUCCESS)+" ")]):_vm._e(),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"mt-5",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"First Name","label-for":"firstName"}},[_c('b-form-input',{class:{'is-invalid' : errors.length},attrs:{"id":"email","type":"text"},model:{value:(_vm.fields.firstName),callback:function ($$v) {_vm.$set(_vm.fields, "firstName", $$v)},expression:"fields.firstName"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastName"}},[_c('b-form-input',{class:{'is-invalid' : errors.length},attrs:{"id":"email","type":"text"},model:{value:(_vm.fields.lastName),callback:function ($$v) {_vm.$set(_vm.fields, "lastName", $$v)},expression:"fields.lastName"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email Address","label-for":"emailAddress"}},[_c('b-form-input',{class:{'is-invalid' : errors.length},attrs:{"id":"email","type":"text"},model:{value:(_vm.fields.emailAddress),callback:function ($$v) {_vm.$set(_vm.fields, "emailAddress", $$v)},expression:"fields.emailAddress"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Create User "),(_vm.loading)?_c('span',{staticClass:"btn-loader"}):_vm._e()])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }