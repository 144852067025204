<template>  
    <div class="inner-modal text-center">
        <h2>
            Sorry, you cannot add another property
        </h2>

        <p class="lead mt-4">
            {{ Notifications.MAXIMUM_PROPERTIES_ERROR }}
        </p>

        <div class="mt-4 px-5">
            <b-button variant="primary" block
                @click.prevent="$emit('close')"
            >
                Close
            </b-button>
        </div>
    </div>
</template >

<script>
import Notifications from '@/Notifications'

export default {
    name: 'TooManyPropertiesErrorModalComponent',

    data() {
        return {
            Notifications
        }
    }
}
</script>