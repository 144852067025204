<template>
    <b-container>
        <template
            v-if="submissionCompletionSuccessMessage != null"
        >
            <b-row class="my-5">
                <b-col>
                    <b-alert variant="success" show dismissible>
                        {{ submissionCompletionSuccessMessage }}
                    </b-alert>
                </b-col>
            </b-row>
        </template>

        <template
            v-else-if="Object.keys(user).length !== 0"
        >
            <b-row>
                <b-col>
                    <div>
                        <h2
                            class="mb-4 mb-lg-0 mr-4"
                        >
                            My Account
                        </h2>  

                        <p class="lead font-weight-bold">
                            Welcome back, {{firstName}}. <span class="d-md-none"><br/></span>If you have any questions, please ask our team using our live chat feature.
                        </p>
                    </div> 
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col lg="4">
                    <div class="card shadow">
                        <div class="card-body">
                            <h3>Personal Details</h3>

                            <p>Need to update your details? <a target="_blank" href="https://gettaxdone.co.uk/contact-us/">Get in touch</a></p>
                              
                            <b-list-group class="list-group-flush">
                                <b-list-group-item class="d-flex flex-column px-0">
                                    First name<span class="font-weight-bold color-black">{{ user.given_name }}</span>
                                </b-list-group-item>

                                <b-list-group-item class="d-flex flex-column px-0">
                                    Surname<span class="font-weight-bold color-black">{{ user.family_name }}</span>
                                </b-list-group-item>

                                <b-list-group-item class="d-flex flex-column px-0">
                                    Email address<span class="font-weight-bold color-black">{{ user.email }}</span>
                                </b-list-group-item>

                                <b-list-group-item class="d-flex flex-column px-0"
                                    v-if="userDetails.telephone"
                                >
                                    Phone Number<span class="font-weight-bold color-black">{{ userDetails.telephone }}</span>
                                </b-list-group-item>

                                <b-list-group-item class="d-flex flex-column px-0"
                                    v-if="userDetails.niNumber"
                                >
                                    NI Number<span class="font-weight-bold color-black">{{ userDetails.niNumber }}</span>
                                </b-list-group-item>

                                <b-list-group-item class="d-flex flex-column px-0"
                                    v-if="userDetails.utr"
                                >
                                    UTR Number<span class="font-weight-bold color-black">{{ userDetails.utr }}</span>
                                </b-list-group-item>
                            </b-list-group>
                        </div>

                        <div class="p-3 font-weight-bold" style="background: #EFEFEF">
                            <span class="color-black">Total Tax Returns:</span> <span class="float-right color-black">{{ submissions.length }}</span>
                        </div>
                    </div>
                  
                </b-col>

                <b-col lg="8" class="mt-5 mt-lg-0">
                    <available-tax-returns-component />
                </b-col>    
            </b-row>
        </template>
    </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AvailableTaxReturnsComponent from '@/components/my-account-components/AvailableTaxReturnsComponent.vue'
import Notifications from '@/Notifications'

export default {
    name: 'MyAccount', 

    props: {
        submissionCompletionSuccessMessage: String
    },

    components: {
        AvailableTaxReturnsComponent
    },

    data() {
        return {
            Notifications,
        }
    },

    mounted() {
        if(Object.keys(this.user).length == 0) {
            this.getUser()
        }
        
        this.clearSectionsCompleteState()
    }, 

    computed: {
        ...mapGetters({
            user: 'userStore/getUser',
            authenticated: 'userStore/authenticated',
            userDetails: 'userStore/getUserDetails',
            firstName: 'userStore/getCapitalisedFirstName',
            submissions: 'submissionsStore/getSubmissions',
        })
    },

    methods: {
        ...mapActions({
            getUser: 'userStore/getUser',
            clearSectionsCompleteState: 'sectionsCompleteStore/clear',
            getSubmissions: 'submissionsStore/getSubmissions'
        }), 

        startNewApplication() {
            window.location.href = '/reasons'
        }
    }
}
</script>