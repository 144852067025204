<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-6 class="pl-0 btn-accordion">
                <img src="@/assets/icons/questions/dividend-interest.svg" width="30" height="30" /> Dividend Income
            </b-button>
        </b-card-header>
        
        <b-collapse visible id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text>
                    <ul class="mt-3">
                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Summary of dividend received</p>
                                    <p>If you have individends, you could receive dividends. Any dividends received should be included on your tax return. Each time a dividend is issued or paid you will receive a dividend voucher, please keep them in order to include them on your tax return.</p>
                                </b-col>
                            </b-row>
                        </li>

                        <li>
                            <b-row class="align-items-center">
                                <b-col md="10">
                                    <p class="font-weight-bold mb-0">Copy of dividend voucher</p>
                                    <p>Each time a dividend is issued or paid you will receive a dividend voucher, please keep them in order to include them on your tax return.</p>
                                </b-col>
                            </b-row>
                        </li>
                    </ul>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
export default {
    name: 'DividendIncomeWhatYouNeedComponent',

    data() {
        return {
            dividends: false,
            vouchers: false
        }
    }
}
</script>