<template>
    <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
            <b-button block v-b-toggle.accordion-10 variant="approval" class="pl-0">
                <h4>
                    Tax Refunds	
                </h4>

                <p class="mb-0">
                    Have you had any 2019/2020 income tax refunded or off-set by HMRC or Jobcentre plus?
                </p>
            </b-button>
        </b-card-header>

        <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">
                        <div>
                            <template
                                v-if="jcpRefundsDetails.length"
                            >
                                <jcp-refund-item-component 
                                    v-for="(item, index) in jcpRefundsDetails"
                                    :key="index"
                                    :item="item"
                                    :index="index"
                                />
                            </template>

                            <div class="p-4">
                                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                                    <div>
                                        <h5 class="mt-0">Add another tax refund</h5>
                                        Would you like to add details of another tax refund?
                                    </div>

                                    <b-button variant="primary" class="mt-4 mt-md-0"
                                        @click="createDefaultJcpRefundsItem()"
                                    >
                                        Add tax refund
                                    </b-button>    
                                </div> 
                            </div>

                            <div class="text-right p-4">
                                <b-button variant="success" type="submit">
                                    Save <span v-if="saving" class="btn-loader"></span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import axios from 'axios'
import JcpRefundItemComponent from '@/components/partials/JcpRefundItemComponent.vue'
import {mapActions, mapGetters} from 'vuex'
import Notifications from '@/Notifications'

export default {
    name: 'JcpRefundsComponent', 

    components: {
        JcpRefundItemComponent
    },

    data() {
        return {
            saving: false
        }
    },

    mounted() {
        if(!this.jcpRefundsDetails.length) this.createDefaultJcpRefundsItem()
    },

    computed: {
        ...mapGetters({
            jcpRefundsDetails: 'submissionStore/getJcpRefundsDetails',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getJcpRefundsDetails: 'submissionStore/getSubmissionJcpRefundsDetails',
            setNotification: 'notificationsStore/setNotification'
        }),

        createDefaultJcpRefundsItem() {
            let jcpRefundItem = {
                amount: 0,
                date: null,
                description: null,
            }

            this.jcpRefundsDetails.push(jcpRefundItem)

            setTimeout(() => {
                this.$scrollTo('#jcpindex'+(this.jcpRefundsDetails.length - 1), 800)
            }, 500)
        },

        onSubmit() {
            this.save()
        },

        save() {
            this.saving = true 

            let jcpRefundseModel = {
                submissionId: this.submissionId,
                jcpRefunds: JSON.parse(JSON.stringify(this.jcpRefundsDetails))
            }

            jcpRefundseModel.jcpRefunds.forEach(j => {
                j.amount = parseInt(j.amount * 100)
            })

            axios.post(`submission/${this.submissionId}/jcp-refunds`, jcpRefundseModel).then(() => {
                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })
            }).finally(() => this.saving = false)
        }
    }
}
</script>