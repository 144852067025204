<template>
    <div class="inner-modal text-center">
        <h2>
            Contacting stripe, please wait
        </h2>

        <div class="loader-container mt-5">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubmitPaymentModalComponent',
}
</script>