var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":'index'+_vm.index}},[(_vm.index !== 0)?_c('hr',{staticClass:"mt-5"}):_vm._e(),_c('b-row',{class:{'mt-5' : _vm.index !== 0}},[_c('b-col',{staticClass:"text-center",attrs:{"md":"1"}},[_c('div',{staticClass:"number mx-auto"},[_vm._v(" "+_vm._s(_vm.index + 1)+" ")]),(_vm.index > 0)?_c('div',{staticClass:"remove mt-3 cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.removeItem(_vm.index)}}},[_c('img',{attrs:{"src":require("@/assets/icons/delete.svg"),"alt":"delete item","width":"40","height":"40"}})]):_vm._e()]),_c('b-col',{staticClass:"mt-4 mt-md-0",attrs:{"md":"11"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{ref:"employerName",attrs:{"vid":'Employer Name '+(_vm.index+1),"name":"Employers Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Employer name","label-for":"employerName","label-class":"required"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.employerName,'is-invalid' : errors.length},attrs:{"name":"employerName","type":"text","placeholder":"Employer name"},model:{value:(_vm.item.employerName),callback:function ($$v) {_vm.$set(_vm.item, "employerName", $$v)},expression:"item.employerName"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'PAYE Reference '+(_vm.index+1),"name":"Paye Reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"d-block required",attrs:{"for":"payeReference"}},[_vm._v("PAYE reference")]),_c('img',{staticClass:"helper-image",attrs:{"src":require("@/assets/icons/question-icon.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.openHelperModal(_vm.Helpers.PayeReference.Title, _vm.Helpers.PayeReference.Body)}}}),_c('b-form-input',{class:{'is-valid' : _vm.item.payeReference, 'is-invalid' : errors.length},attrs:{"name":"payeReference","type":"text","placeholder":"PAYE reference"},model:{value:(_vm.item.payeReference),callback:function ($$v) {_vm.$set(_vm.item, "payeReference", $$v)},expression:"item.payeReference"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'Amount Received '+(_vm.index+1),"name":"Amount Received","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Amount received","label-for":"receivedAmount","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.receivedAmount, 'is-invalid' : errors.length},attrs:{"name":"receivedAmount","placeholder":"Amount received"},on:{"change":function($event){return _vm.checkForRoundNumber('receivedAmount')}},model:{value:(_vm.item.receivedAmount),callback:function ($$v) {_vm.$set(_vm.item, "receivedAmount", $$v)},expression:"item.receivedAmount"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"vid":'Tax Deducted '+(_vm.index+1),"name":"Tax Deducted","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tax deducted","label-for":"taxDeducted","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.taxDeducted, 'is-invalid' : errors.length},attrs:{"name":"taxDeducted","placeholder":"Tax deducted"},on:{"change":function($event){return _vm.checkForRoundNumber('taxDeducted')}},model:{value:(_vm.item.taxDeducted),callback:function ($$v) {_vm.$set(_vm.item, "taxDeducted", $$v)},expression:"item.taxDeducted"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1),_c('hr',{staticClass:"my-5"}),_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Do you have a P11D for a benefit in kind? "),_c('img',{staticClass:"helper-image-inline",attrs:{"src":require("@/assets/icons/question-icon.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.openHelperModal(_vm.Helpers.P11DBenefitInKind.Title, _vm.Helpers.P11DBenefitInKind.Body)}}})]),_c('div',{staticClass:"mt-4 mt-md-0 flex-shrink-0 ml-4"},[_c('b-button',{staticClass:"btn-bool mr-1",class:{active: _vm.item.hasBenefitInKind},on:{"click":function($event){_vm.item.hasBenefitInKind = !_vm.item.hasBenefitInKind}}},[_vm._v(" Yes ")]),_c('b-button',{staticClass:"btn-bool",class:{negativeActive: !_vm.item.hasBenefitInKind},on:{"click":function($event){_vm.item.hasBenefitInKind = !_vm.item.hasBenefitInKind}}},[_vm._v(" No ")])],1)]),(_vm.item.hasBenefitInKind)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'Benefit in kind type '+(_vm.index+1),"name":"Benefit in kind type","rules":("" + (_vm.item.hasBenefitInKind ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Benefit in kind type","label-for":"benefitInKindType"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.benefitInKindType, 'is-invalid' : errors.length},attrs:{"name":"Benefit in kind type","type":"text","placeholder":"Benefit in kind type"},model:{value:(_vm.item.benefitInKindType),callback:function ($$v) {_vm.$set(_vm.item, "benefitInKindType", $$v)},expression:"item.benefitInKindType"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,false,1396784573)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'P11D '+(_vm.index+1),"name":"P11d Value","rules":("" + (_vm.item.hasBenefitInKind ? 'required|min_value:0|double:2' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"P11d Value","label-for":"p11dValue"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.p11dValue, 'is-invalid' : errors.length},attrs:{"name":"p11dValue","placeholder":"p11d value"},on:{"change":function($event){return _vm.checkForRoundNumber('p11dValue')}},model:{value:(_vm.item.p11dValue),callback:function ($$v) {_vm.$set(_vm.item, "p11dValue", $$v)},expression:"item.p11dValue"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,false,2426405968)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"vid":'P11D Tax Deducted '+(_vm.index+1),"name":"Tax deducted","rules":("" + (_vm.item.hasBenefitInKind ? 'required|min_value:0|double:2' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tax deducted","label-for":"p11dTaxDeducted"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.item.p11dTaxDeducted, 'is-invalid' : errors.length},attrs:{"name":"p11dTaxDeducted","placeholder":"p11d tax deducted"},on:{"change":function($event){return _vm.checkForRoundNumber('p11dTaxDeducted')}},model:{value:(_vm.item.p11dTaxDeducted),callback:function ($$v) {_vm.$set(_vm.item, "p11dTaxDeducted", $$v)},expression:"item.p11dTaxDeducted"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,false,1611870660)})],1)],1):_vm._e(),_c('h5',{staticClass:"mt-5 mb-3"},[_vm._v(" Please upload your documents ")]),_c('div',[_c('p60-item-component',{attrs:{"item":_vm.item,"componentTitle":"Employment"}})],1),_c('div',{staticClass:"mt-2"},[_c('p45-item-component',{attrs:{"item":_vm.item}})],1),(_vm.item.hasBenefitInKind)?_c('div',{staticClass:"mt-2"},[_c('p11d-item-component',{attrs:{"item":_vm.item,"hasBenefitInKind":_vm.item.hasBenefitInKind}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }