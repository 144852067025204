<template>
    <b-container>  
        <b-row class="justify-content-center"
            v-if="showPasswordResetEmailSentSuccessMessage"
        >
            <b-col md="5">
                <b-alert variant="success" show dismissible>
                    {{ passwordResetEmailSentSuccessMessage }}
                </b-alert>
            </b-col>
        </b-row> 

        <b-row class="justify-content-center">
            <b-col md="8" lg="5">
                <b-card no-body class="p-0 shadow">
                    <div class="p-5">
                        <h2>
                            Forgot Password
                        </h2>
    
                        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.prevent="handleSubmit(onSubmit)" class="mt-4">
                                <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
                                    <b-form-group
                                    label-for="email"
                                    label="Email Address"
                                    >
                                        <b-form-input
                                            id="email"
                                            v-model="email"
                                            type="text"
                                            placeholder="Email"
                                            :class="{'is-invalid' : errors.length}"
                                        ></b-form-input>

                                        <small v-if="errors.length" class="text-danger validation-error d-block">
                                            {{ errors[0] }}
                                        </small>
                                    </b-form-group>
                                </ValidationProvider>
                        
                                <b-button type="submit" variant="primary" block>
                                    Reset <span v-if="loading" class="btn-loader"></span>
                                </b-button>
                            </b-form>
                        </ValidationObserver>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {mapActions} from 'vuex'
import axios from 'axios'
import Notifications from '@/Notifications'

export default {
    name: 'ForgotPassword',

    data() {
        return {
            email: '',
            loading: false,
            showPasswordResetEmailSentSuccessMessage: false,
            passwordResetEmailSentSuccessMessage: Notifications.PASSWORD_RESET_EMAIL_SENT_SUCCESS_MESSAGE,
        }
    }, 

    methods: {
        ...mapActions({
            setNotification: 'notificationsStore/setNotification'
        }),

        onSubmit() {
            this.submitResetPassword()
        },

        submitResetPassword() {
            this.loading = true

            axios.post('authentication/password/forgotten', {
                email: this.email
            }).then(() => {
                this.email = ''
                this.showPasswordResetEmailSentSuccessMessage = true
                this.$refs.observer.reset()
            }).catch(() => {
                this.setNotification({
                    type: 'failure',
                    message: Notifications.SEND_PASSWORD_RESET_FAILURE
                })
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>