import Vue from 'vue'
import VueRouter from 'vue-router'

// Customer Routes
import MyAccount from '../views/MyAccount.vue'
import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import Register from '../views/auth/Register.vue'
import RegisterConfirm from '../views/auth/RegisterConfirm.vue'
import Reasons from '../views/Reasons.vue'
import TaxYear from '../views/TaxYear.vue'
import Questions from '../views/Questions.vue'
import Quote from '../views/Quote.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import PaymentFailure from '../views/PaymentFailure.vue'
import PaymentComplete from '../views/PaymentComplete.vue'
import Checklist from '../views/Checklist.vue'
import Information from '../views/Information.vue'
import Approval from '../views/Approval.vue'

// Admin Routes
import Dashboard from '../views/admin/Dashboard.vue'
import Reports from '../views/admin/Reports.vue'
import CreateUser from '../views/admin/CreateUser.vue'
import ViewSubmission from '../views/admin/ViewSubmission.vue'

import Error404 from '../views/errors/Error404.vue'
import store from '@/store'
import auth from './middleware/auth'
import guest from './middleware/guest'
import redirectIfNotAdmin from './middleware/redirectIfNotAdmin'
import redirectIfAdminOrAccountant from './middleware/redirectIfAdminOrAccountant'
import middlewarePipeline from './kernel/middlewarePipeline'


Vue.use(VueRouter)

const routes = [
  // Customer Routes
  {
    path: '*',
    name: '404',
    component: Error404,
    meta: { 
      hideNavigation: true 
    }
  },
  {
    path: '/',
    name: 'MyAccount',
    component: MyAccount,
    props: true,
    meta: {
      middleware: [
        auth,
        redirectIfAdminOrAccountant,
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
    meta: { 
      hideNavigation: true,
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    props: true,
    meta: { 
      hideNavigation: true,
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/account/password/reset',
    name: 'ResetPassword',
    component: ResetPassword,
    props: true,
    meta: { 
      hideNavigation: true,
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { 
      hideNavigation: true,
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/register/confirm',
    name: 'RegisterConfirm',
    component: RegisterConfirm,
    props: true,
    meta: { 
      hideNavigation: true,
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/reasons',
    name: 'Reasons',
    component: Reasons,
    meta: {
      requiresFirstListInHeader: true,
      showCurrentTaxYear: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/tax-year',
    name: 'TaxYear',
    component: TaxYear,
    meta: {
      requiresFirstListInHeader: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/questions',
    name: 'Questions',
    component: Questions,
    props: true,
    meta: {
      requiresFirstListInHeader: true,
      showCurrentTaxYear: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/quote',
    name: 'Quote',
    component: Quote,
    meta: {
      showCurrentTaxYear: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/payment-success',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
    props: true,
    meta: {
      hideListsInNavigation: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/payment-failure',
    name: 'PaymentFailure',
    component: PaymentFailure,
    meta: {
      hideListsInNavigation: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/payment-complete',
    name: 'PaymentComplete',
    component: PaymentComplete,
    meta: {
      hideListsInNavigation: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/checklist',
    name: 'Checklist',
    component: Checklist,
    meta: {
      requiresSecondListInHeader: true,
      showCurrentTaxYear: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: {
      requiresSecondListInHeader: true,
      showCurrentTaxYear: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  {
    path: '/approval',
    name: 'Approval',
    component: Approval,
    meta: {
      requiresSecondListInHeader: true,
      showCurrentTaxYear: true,
      middleware: [
        auth,
        redirectIfAdminOrAccountant
      ]
    }
  },
  // End Customer Routes


  // Admin Routes  
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      showAdminNavigation: true,
      middleware: [
        auth,
        redirectIfNotAdmin
      ]
    }
  },

  {
    path: '/admin/reports',
    name: 'Reports',
    component: Reports,
    props: true,
    meta: {
      showAdminNavigation: true,
      middleware: [
        auth,
        redirectIfNotAdmin
      ]
    }
  },

  {
    path: '/admin/create-user',
    name: 'CreateUser',
    component: CreateUser,
    props: true,
    meta: {
      showAdminNavigation: true,
      middleware: [
        auth,
        redirectIfNotAdmin
      ]
    }
  },

  {
    path: '/admin/view-submission/:id',
    name: 'ViewSubmission',
    component: ViewSubmission,
    props: true,
    meta: {
      showAdminNavigation: true,
      middleware: [
        auth,
        redirectIfNotAdmin
      ]
    }
  },
  // End Admin Routes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

router.beforeEach((to, from, next) => {

  if(!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    router,
    store
  }

  return middleware[0]({...context, next: middlewarePipeline(context, middleware, 1)})
 
})

export default router
