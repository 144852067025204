<template>
    <b-card class="shadow mt-5" v-if="Object.keys(scottishTaxpayerDetails).length != 0">
        <b-row>
            <b-col>
                <h2 class="mb-3">
                    Scottish Taxpayer Details
                </h2>

                <b-table outlined responsive striped hover :fields="filteredScottishTaxpayerDetails" :items="scottishTaxpayerDetailsToArray">
                    <template #cell(isScottishTaxpayer)="data">
                        <b-badge 
                            style="font-size: 1rem"
                            :class="{
                                'badge-success' : data.value == 'Yes',
                                'badge-danger' : data.value == 'No'
                            }"
                        >
                            {{ data.value }}
                        </b-badge>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'ScottishTaxpayerDetailsReviewComponent',

    computed: {
        ...mapGetters({
            scottishTaxpayerDetails: 'submissionStore/getScottishTaxpayerDetails',
        }),

        scottishTaxpayerDetailsToArray() {
            let array = []

            array.push(this.scottishTaxpayerDetails)

            return array
        },

        filteredScottishTaxpayerDetails() {
            return [
                {
                    key: 'isScottishTaxpayer',
                    label: 'Is Scottish Taxpayer',
                    formatter: 'formatBool'
                },
            ]
        }
    },

    methods: {
        formatDate(value) {
            return this.$moment(value).format('ll')
        },

        formatBool(value) {
            if(value) return 'Yes'; return 'No'
        }
    }
}
</script>