import axios from 'axios'
import router from '../router'

export default {
    namespaced: true,

    state: {
        authenticated: false,
        authError: null,
        accessToken: null,
        user: {},
        userDetails: {}
    },

    getters: {
        authenticated(state) {
            return state.authenticated && state.accessToken !== null
        },

        getUser(state) {
            return state.user
        },

        getUserDetails(state) {
            return state.userDetails
        },

        getCapitalisedFirstName(state) {
            return state.user.given_name.charAt(0).toUpperCase() + state.user.given_name.substring(1)
        },  

        getUserFullName(state) {
            return state.user.given_name.charAt(0).toUpperCase() + state.user.given_name.substring(1) + ' ' + state.user.family_name.charAt(0).toUpperCase() + state.user.family_name.substring(1)
        },

        getAuthError(state) {
            return state.authError
        },

        getIsUserDetailsComplete(state) {
            return Boolean(state.userDetails.telephone !== null && state.userDetails.dateOfBirth !== null && state.userDetails.utr !== null && state.userDetails.niNumber !== null)
        },

        getIsUserAdmin(state) {
            return state.user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] == 'Admin'
        },

        getIsUserCustomer(state) {
            return state.user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] == 'Customer'
        },

        getIsUserAccountant(state) {
            return state.user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] == 'Accountant'
        },

        getIsUserEtoroAdmin(state) {
            return state.user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] == 'EtoroAdmin'
        }
    },

    mutations: {
        SET_AUTHENTICATED(state, data) {
            state.authenticated = data
        },

        PUSH_USER(state, data) {
            state.user = data
        },

        PUSH_USER_DETAILS(state, data) {
            state.userDetails = data
        },

        SET_USER_TOKEN(state, data) {
            state.accessToken = data
        },

        SET_AUTH_ERROR(state, data) {
            state.authError = data
        },

        SET_DEFAULT_DOB(state) {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

            const defaultDate = new Date(today)
            defaultDate.setYear(defaultDate.getFullYear() - 25)

            state.userDetails.dateOfBirth = defaultDate
        }
    }, 

    actions: {
        getToken({dispatch, commit}, data) {
            commit('SET_AUTH_ERROR', null) 
            
            axios.post('authentication/token', data).then((response) => {  
                dispatch('getUser', response.data.accessToken)
            }).catch((err) => {
                commit('SET_AUTH_ERROR', err.data.message)
            })
        }, 

        getUser({commit, state}, token) {
            if(token) {
                commit('SET_USER_TOKEN', token)
                commit('PUSH_USER', JSON.parse(atob(token.split('.')[1])))
            }

            if(!state.accessToken || !token) {
                console.log('hit login')
                if(window.location.pathname == '/account/password/reset') {
                    return
                } 

                return router.replace({name: 'Login'})
            } 

            return axios.get('users/me').then((response) => {
                commit('SET_AUTHENTICATED', true)
                commit('SET_AUTH_ERROR', null)
                commit('PUSH_USER_DETAILS', response.data)

                return response
            }).catch((err) => {
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER_TOKEN', null)
                commit('PUSH_USER_DETAILS', null)

                return err
            })
        },

        signout({commit}) {
            commit('SET_AUTHENTICATED', false)
            commit('SET_USER_TOKEN', null)
            commit('PUSH_USER_DETAILS', null)
            
            if(window.location.pathname == '/login') return 
            
            router.replace({name: 'Register'})
        },

        setDefaultDob({commit}) {
            commit('SET_DEFAULT_DOB')
        }
    }
}