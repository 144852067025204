var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mt-3",attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-13",modifiers:{"accordion-13":true}}],staticClass:"pl-0",attrs:{"block":"","variant":"approval"}},[_c('h4',[_vm._v(" Marriage Allowance Receipt ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Has your spouse or civil partner made an election to transfer £1,190.00 of their personal allowance to you? ")])])],1),_c('b-collapse',{attrs:{"id":"accordion-13","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',[(Object.keys(_vm.marriageAllowanceReceiptDetails).length != 0)?[_c('div',{staticClass:"bg-white p-4"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"name","label-class":"required"}},[_c('b-form-input',{class:{'is-valid' : _vm.marriageAllowanceReceiptDetails.name, 'is-invalid' : errors.length},attrs:{"name":"name","type":"text","placeholder":"Name"},model:{value:(_vm.marriageAllowanceReceiptDetails.name),callback:function ($$v) {_vm.$set(_vm.marriageAllowanceReceiptDetails, "name", $$v)},expression:"marriageAllowanceReceiptDetails.name"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Income Received Amount","rules":"required|min_value:0|double:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Income Received Amount","label-for":"incomeReceivedAmount","label-class":"required"}},[_c('b-input-group',{attrs:{"prepend":"£"}},[_c('b-form-input',{class:{'is-valid' : _vm.marriageAllowanceReceiptDetails.incomeReceivedAmount, 'is-invalid' : errors.length},attrs:{"name":"incomeReceivedAmount","placeholder":"Income Received Amount"},on:{"change":function($event){return _vm.checkForRoundNumber('incomeReceivedAmount')}},model:{value:(_vm.marriageAllowanceReceiptDetails.incomeReceivedAmount),callback:function ($$v) {_vm.$set(_vm.marriageAllowanceReceiptDetails, "incomeReceivedAmount", $$v)},expression:"marriageAllowanceReceiptDetails.incomeReceivedAmount"}})],1),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Ni Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{staticClass:"d-block required",attrs:{"for":"descrniNumbeription"}},[_vm._v("Ni Number")]),_c('img',{staticClass:"helper-image",attrs:{"src":require("@/assets/icons/question-icon.svg"),"width":"20","height":"20"},on:{"click":function($event){return _vm.openHelperModal(_vm.Helpers.MarriageAllowanceReceiptNiNumber.Title, _vm.Helpers.MarriageAllowanceReceiptNiNumber.Body)}}}),_c('b-form-input',{class:{'is-valid' : _vm.marriageAllowanceReceiptDetails.niNumber, 'is-invalid' : errors.length},attrs:{"name":"niNumber","type":"text","placeholder":"Ni Number"},model:{value:(_vm.marriageAllowanceReceiptDetails.niNumber),callback:function ($$v) {_vm.$set(_vm.marriageAllowanceReceiptDetails, "niNumber", $$v)},expression:"marriageAllowanceReceiptDetails.niNumber"}}),(_vm.responseFailures && _vm.responseFailures.NiNumber)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.responseFailures.NiNumber[0])+" ")]):_vm._e(),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date Of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date Of Birth","label-for":"dateOfBirth","label-class":"required"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.marriageAllowanceReceiptDetails.dateOfBirth, 'is-invalid' : errors.length},attrs:{"name":"dateOfBirth","placeholder":"Choose a date","hide-header":"","show-decade-nav":"","no-flip":"","max":_vm.max},model:{value:(_vm.marriageAllowanceReceiptDetails.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.marriageAllowanceReceiptDetails, "dateOfBirth", $$v)},expression:"marriageAllowanceReceiptDetails.dateOfBirth"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Married On","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Married On","label-for":"marriedOn","label-class":"required"}},[_c('b-form-datepicker',{class:{'is-valid' : _vm.marriageAllowanceReceiptDetails.marriedOn, 'is-invalid' : errors.length},attrs:{"name":"marriedOn","placeholder":"Choose a date","hide-header":"","show-decade-nav":"","no-flip":"","max":_vm.maxMarryDate},model:{value:(_vm.marriageAllowanceReceiptDetails.marriedOn),callback:function ($$v) {_vm.$set(_vm.marriageAllowanceReceiptDetails, "marriedOn", $$v)},expression:"marriageAllowanceReceiptDetails.marriedOn"}}),(errors.length)?_c('small',{staticClass:"text-danger validation-error d-block"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,true)})],1)],1)],1)]:_vm._e(),_c('div',{staticClass:"text-right p-4"},[_c('b-button',{attrs:{"variant":"success","type":"submit"}},[_vm._v(" Save "),(_vm.saving)?_c('span',{staticClass:"btn-loader"}):_vm._e()])],1)],2)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }