<template>
    <div class="accordion gtd-accordion" role="tablist">
        <b-card no-body>
            <b-card-header header-tag="header" role="tab">
                <b-button block v-b-toggle.accordion-1 class="pl-0 btn-accordion">
                    <img src="@/assets/icons/questions/basic-details.svg" width="30" height="30" /> Basic Details
                </b-button>
            </b-card-header>
            
            <b-collapse visible id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <b-card-text>
                        <ul class="mt-3">
                            <li>
                                <b-row class="align-items-center">
                                    <b-col md="10">
                                        <p class="font-weight-bold mb-0">Unique taxpayer reference (UTR)</p>
                                        <p>Your UTR is a 10 digit number issued by HMRC when you first register for self assessment. Your UTR can be found on any documents HMRC have sent to you.</p>
                                    </b-col>
                                </b-row>
                            </li>

                            <li>
                                <b-row class="align-items-center">
                                    <b-col md="10">
                                        <p class="font-weight-bold mb-0">National insurance number (NI)</p>
                                        <p>Your national insurance will normally be on your payslip if not, you may have this on your national insurance card.</p>
                                    </b-col>
                                </b-row>
                            </li>

                            <li>
                                <b-row class="align-items-center">
                                    <b-col md="10">
                                        <p class="font-weight-bold mb-0">Copy of drivers licence / passport</p>
                                        <p>Passport or Driving Licence</p>
                                    </b-col>
                                </b-row>
                            </li>
                        </ul>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
import Helpers from '@/Helpers'
import HelperModalComponent from '@/components/modal-components/HelperModalComponent.vue'

export default {
    name: 'BasicDetailsWhatYouNeedComponent',

    data() {
        return {
            Helpers,
            HelperModalComponent
        }
    },

    methods: {
        openHelperModal(title, body) {
            this.$modal.show(HelperModalComponent, {title: title, body: body })
        }
    }
}
</script>