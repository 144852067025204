<template>
    <div class="tab-content">
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="tab-border">
                    <div class="p-4">
                        <h4>
                            Please tell us more about capital gains / losses	
                        </h4>

                        <p class="mb-0">
                            For example, shares, property, including your main residence, share of a partnership. If you have an investment portfolio managed by a third party, e.g. your bank, please obtain statements from that third party.  
                        </p>
                    </div>

                    <template
                        v-if="capitalGainsDetails.length && Object.keys(capitalGainsLossesDetails).length != 0"
                    >
                        <capital-gains-losses-item-component 
                            v-for="(item, index) in capitalGainsDetails"
                            :key="index"
                            :item="item"
                            :index="index"
                            :failures="failures"
                        />
                    </template>
                </div>

                <div class="p-4 mt-5 add-item-container d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div class="media">
                        <img src="@/assets/icons/questions/capital-gains-losses.svg" width="60" height="60" class="mr-3" alt="add employment">
                        <div class="media-body">
                            <h5 class="mt-0">Add another capital gain</h5>
                            Would you like to add details of another capital gain?
                        </div>
                    </div>

                    <b-button variant="primary" class="mt-4 mt-md-0"
                        @click="createDefaultItem()"
                    >
                        Add capital gain
                    </b-button>     
                </div>

                <capital-gains-documents-component class="mt-5" />

                <div class="p-4 mt-5 border">
                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <h4 class="mb-0 center-mobile">
                            Did you make any gains on UK Life Assurance policies, Bonds etc?
                        </h4>

                        <div class="flex-shrink-0 ml-0 ml-md-4 mt-4 mt-md-0">
                            <b-button class="btn-bool mr-1"
                                @click="capitalGainsLossesDetails.hasBondGains = !capitalGainsLossesDetails.hasBondGains"
                                :class="{active: capitalGainsLossesDetails.hasBondGains}"
                            >
                                Yes
                            </b-button>

                            <b-button class="btn-bool"
                                @click="capitalGainsLossesDetails.hasBondGains = !capitalGainsLossesDetails.hasBondGains"
                                :class="{negativeActive: !capitalGainsLossesDetails.hasBondGains}"
                            >
                                No
                            </b-button>
                        </div>
                    </div>
                </div>

                <save-and-continue-component
                    :saving="saving"
                    :componentName="$options.name"
                    @clickBack="$emit('back')"
                />

                <p v-if="invalid" class="text-sm text-danger text-right center-mobile mt-1">Please fill out the required fields before saving</p>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions,mapGetters} from 'vuex'
import SaveAndContinueComponent from '@/components/partials/SaveAndContinueComponent.vue'
import CapitalGainsLossesItemComponent from '@/components/partials/CapitalGainsLossesItemComponent.vue'
import CapitalGainsDocumentsComponent from '@/components/partials/CapitalGainsDocumentsComponent.vue'
import Notifications from '@/Notifications'
import ValidationErrorModalComponent from '@/components/modal-components/ValidationErrorModalComponent.vue'

export default {
    name: 'CapitalGainsLossesComponent',

    components: {
        CapitalGainsLossesItemComponent,
        CapitalGainsDocumentsComponent,
        SaveAndContinueComponent
    },

    data() {
        return {
            failures: {},
            saving: false,
            ValidationErrorModalComponent
        }
    },

    mounted() {
        if(this.capitalGainsDetails.length) this.markSectionAsComplete()
        if(!this.capitalGainsDetails.length) this.createDefaultItem()
    },

    computed: {
        ...mapGetters({
            completedSubmissionSections: 'sectionsCompleteStore/getSections',
            capitalGainsLossesDetails: 'submissionStore/getCapitalGainsLossesDetails',
            capitalGainsDetails: 'submissionStore/getCapitalGainsDetails',
            capitalGainsDocuments: 'submissionStore/getCapitalGainsDocuments',
            submissionId: 'submissionStore/getId',
        })
    },

    methods: {
        ...mapActions({
            getCapitalGainsLossesDetails: 'submissionStore/getSubmissionCapitalGainsLossesDetails',
            setNotification: 'notificationsStore/setNotification',
            setCapitalGainsLosses: 'sectionsCompleteStore/setCapitalGainsLosses'
        }), 

        markSectionAsComplete() {
            this.$emit('sectionComplete')
            this.setCapitalGainsLosses(true)
        },

        createDefaultItem() {
            let capitalGainsLossesItem = {
                name: null,
                purchasedOn: null,
                purchasedUnits: null,
                purchasedUnitPrice: 0,
                purchasePrice: 0,
                soldOn: null,
                saleUnits: null,
                saleUnitPrice: 0,
                salePrice: 0,
                hasEntrepreneursRelief: false,
                hasPrivateResidenceRelief: false
            }

            this.capitalGainsDetails.push(capitalGainsLossesItem)

            setTimeout(() => {
                this.$scrollTo('#index'+(this.capitalGainsDetails.length - 1), 800)
            }, 500)
        },

        async submit() {            
            const isValid = await this.$refs.observer.validate()

            if(!isValid) {
                return this.$modal.show(ValidationErrorModalComponent, {errors: this.$refs.observer.errors})
            }

            this.save()
        },

        async save() {
            this.saving = true 

            let capitalGainsLossesModel = {
                submissionId: this.submissionId,
                capitalGains: JSON.parse(JSON.stringify(this.capitalGainsDetails)),
                hasBondGains: this.capitalGainsLossesDetails.hasBondGains
            }

            let capitalGainsDocumentsModel = {
                submissionId: this.submissionId,
                capitalGainDocuments: this.capitalGainsDocuments
            }

            capitalGainsLossesModel.capitalGains.forEach(c => {
                c.purchasePrice = parseInt(c.purchasePrice * 100)
                c.salePrice = parseInt(c.salePrice * 100)
                c.purchasedUnitPrice = String(parseFloat(c.purchasedUnitPrice * 100).toFixed(5))
                c.saleUnitPrice = String(parseFloat(c.saleUnitPrice * 100).toFixed(5))
                c.purchasedUnits = String(parseFloat(c.purchasedUnits * 100).toFixed(5))
                c.saleUnits = String(parseFloat(c.saleUnits * 100).toFixed(5))
            })

            try {
                await axios.post(`submission/${this.submissionId}/capital-gains-details`, capitalGainsLossesModel)
                await axios.post(`submission/${this.submissionId}/capital-gains-documents`, capitalGainsDocumentsModel)

                this.setNotification({
                    type: 'success',
                    message: Notifications.SAVED_SUCCESS
                })

                this.failures = {}
                this.markSectionAsComplete()
                this.$emit('next')
            }   
            catch(err) {
                this.failures = err.data.failures

                this.setNotification({
                    type: 'failure',
                    message: Notifications.GENERAL_ERROR
                })
            }
            finally {
                this.saving = false
            }
        }
    }
}
</script>