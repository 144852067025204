import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        sections: {}
    }, 

    getters: {
        getSections(state) {
            return state.sections
        }
    }, 

    mutations: {
        SET_BASIC_DETAILS(state, data) {
            Vue.set(state.sections, 'isBasicDetailsComplete', data)
        },

        SET_EMPLOYMENT_INCOME(state, data) {
            Vue.set(state.sections, 'isEmploymentIncomeComplete', data)
        },

        SET_ETORO_DETAILS(state, data) {
            Vue.set(state.sections, 'isEtoroDetailsComplete', data)
        },

        SET_SELF_EMPLOYMENT_INCOME(state, data) {
            Vue.set(state.sections, 'isSelfEmploymentDetailsComplete', data)
        },

        SET_PROPERTY_INCOME(state, data) {
            Vue.set(state.sections, 'isPropertyIncomeComplete', data)
        },

        SET_PENSION_INCOME(state, data) {
            Vue.set(state.sections, 'isPensionDetailsComplete', data)
        },

        SET_DIVIDEND_INCOME(state, data) {
            Vue.set(state.sections, 'isDividendDetailsComplete', data)
        }, 

        SET_CAPITAL_GAINS_INCOME(state, data) {
            Vue.set(state.sections, 'isCapitalGainsLossesComplete', data)
        },

        SET_FOREIGN_INCOME(state, data) {
            Vue.set(state.sections, 'isForeignIncomeComplete', data)
        },

        SET_CHILD_BENEFIT_INCOME(state, data) {
            Vue.set(state.sections, 'isChildBenefitComplete', data)
        },

        SET_OTHER_INCOME(state, data) {
            Vue.set(state.sections, 'isOtherIncomeComplete', data)
        },

        SET_OTHER_TAX_RELIEF(state, data) {
            Vue.set(state.sections, 'isOtherTaxReliefsComplete', data)
        }, 

        CLEAR_SECTIONS(state) {
            state.sections = {}
        }
    }, 

    actions: {
        setBasicDetails({commit}, data) {
            commit('SET_BASIC_DETAILS', data)
        },

        setEmploymentIncome({commit}, data) {
            commit('SET_EMPLOYMENT_INCOME', data)
        },

        setEtoroDetails({commit}, data) {
            commit('SET_ETORO_DETAILS', data)
        },

        setSelfEmploymentIncome({commit}, data) {
            commit('SET_SELF_EMPLOYMENT_INCOME', data)
        },

        setPropertyIncome({commit}, data) {
            commit('SET_PROPERTY_INCOME', data)
        },

        setPensionIncome({commit}, data) {
            commit('SET_PENSION_INCOME', data)
        },

        setDividendIncome({commit}, data) {
            commit('SET_DIVIDEND_INCOME', data)
        }, 

        setCapitalGainsLosses({commit}, data) {
            commit('SET_CAPITAL_GAINS_INCOME', data)
        },

        setForeignIncome({commit}, data) {
            commit('SET_FOREIGN_INCOME', data)
        },

        setChildBenefitIncome({commit}, data) {
            commit('SET_CHILD_BENEFIT_INCOME', data)
        },

        setOtherIncome({commit}, data) {
            commit('SET_OTHER_INCOME', data)
        },

        setOtherTaxRelief({commit}, data) {
            commit('SET_OTHER_TAX_RELIEF', data)
        }, 

        clear({commit}) {
            commit('CLEAR_SECTIONS')
        }
    }
}